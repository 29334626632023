import React from 'react'

class ReviewPhoto extends React.Component {
    render() {
        return(
            <a className="inline-gallery__item zoom-link js-open-gallery" href='https://revladov.com/vuute/i/files/cases/case-6.jpg' data-fancybox="gallery-1">
                <img src='https://revladov.com/vuute/i/files/cases/case-6_100x80.jpg' alt="" />
            </a>
        )
    }
}
export default ReviewPhoto