import React from 'react'

import DateService from '../../services/DateService'

import Rating from '../../common/Rating'

class FindOrderItem extends React.Component {

    render() {

        const order = this.props.order

        var cityName = ""
        if (order.city !== null) {
            if (order.city.title !== undefined) {
                cityName = order.city.title
            }
        }

        const createdOrderPassedTime = DateService.passedTimeCalc(order.createdDate)
        const createdOwnerProdilePassedTime = DateService.passedTimeCalc(order.owner.created)

        return (
            <div className="orders-catalog-item catalog-item">
                <div className="orders-catalog-item__main catalog-item__main"><a className="orders-catalog-item__title" href="javascript:" onClick={(e) => this.onOrderItemClick(order.id, e)}>{order.title}</a>
                    <div className="orders-catalog-item__meta">
                        <div className="orders-catalog-item__city">{cityName}</div>
                        <div className="orders-catalog-item__state">{createdOrderPassedTime + " назад"}</div>
                    </div>
                    <div className="orders-catalog-item__desc">
                        <p>{order.description}</p>
                    </div>
                </div>
                <div className="orders-catalog-item__sidebar catalog-item__sidebar">
                    <div className="orders-catalog-item__price">
                        <div className="price">{order.price}<span className="rouble">₽</span></div>
                    </div>
                    <div className="orders-catalog-item__customer"><a className="orders-catalog-item__customer-name" href="javascript:;" onClick={(e) => this.onProfileItemClick(order.owner.id, e)}>{order.owner.firstName}</a>
                        <div className="orders-catalog-item__customer-exp">{createdOwnerProdilePassedTime + " на сайте"}</div>
                    </div>
                    <div className="rating rating--sm">
                        <Rating rating={order.owner.rate} isShow={false} isReadOnly={true} />
                        <div className="rating-value">{order.owner.rate.toFixed(2)}</div>
                    </div>
                </div>
            </div>
        );
    }

    onOrderItemClick(orderId, event) {
        this.props.onOrderItemClick(orderId, event)
    }

    onProfileItemClick(profileId, event) {
        this.props.onProfileItemClick(profileId, event)
    }
}

export default FindOrderItem