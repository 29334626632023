import React from 'react'

import EditButton from '../../common/EditButton'
import AuthService from '../../services/AuthService'
import PortfolioItem from './PortfolioItem'

class Portfolio extends React.Component {
    state = {
        isEdit: false
    }

    render() {
        let isMyProfile = AuthService.shared().isMyProfile()
        return (
            <section className={this.state.isEdit ? "master-portfolio page-section js-live-edit-wrap is-editable" : "master-portfolio page-section js-live-edit-wrap"}>
                <header className="config-header">
                    <h2 className="page-section-title">Портфолио</h2>
                    {isMyProfile ? (<EditButton isEdit={this.isHandleClick} />) : ''}
                </header>
                <div className="portfolio">
                    <div className="portfolio-list">
                        <PortfolioItem/>
                        <PortfolioItem/>
                        <PortfolioItem/>
                    </div>
                    <div className="editable">
                        <button className="add-portfolio-btn btn--1 btn--sm btn--flex" type="button" data-state="Новая работа">Добавить работу</button>
                        <button className="live-edit-cancel-btn btn--4-2 btn--sm btn--flex" type="button" onClick={this.isHandleClick}>Отменить</button>
                    </div>
                    <ul className="pagination pagination--sm">
                        <li className="pagination__item"><span>1</span></li>
                        <li className="pagination__item"><a href="javascript:;">2</a></li>
                        <li className="pagination__item"><a href="javascript:;">3</a></li>
                        <li className="pagination__item"><a href="javascript:;">4</a></li>
                        <li className="pagination__item"><a href="javascript:;">5</a></li>
                    </ul>
                </div>
            </section>            
        );
    }

    isHandleClick = () => {
        this.setState({
            isEdit: !this.state.isEdit
        });
      }
}

export default Portfolio