import React from 'react'
import _ from 'lodash';

import ProfilePhotoEdit from '../../common/ProfilePhotoEdit'
import ProfileNameItemEdit from '../../common/ProfileNameItemEdit'
import CitySelector from '../../common/CitySelector'
import CityList from '../../profile/components/CityList'
import ClientDescriptionEdit from '../components/ClientDescriptionEdit'
import ProfileSaveEscButtons from '../../common/ProfileSaveEscButtons'

import autoBind from 'react-autobind';
import { connect } from 'react-redux';

import * as profileActions from '../../store/profile/actions';
import * as profileSelectors from '../../store/profile/reducer';

import * as vuuteServiceActions from '../../store/vuuteServices/actions';
import * as vuuteServiceSelectors from '../../store/vuuteServices/reducer';

class ClientPersonInfoEdit extends React.Component {
  
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentWillMount() {
    if (this.props.cities.length === 0) {
      this.props.dispatch(vuuteServiceActions.requestVuuteCities(1));
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="person-info">
        {this.props.modifiedProfile ? (
            <ProfilePhotoEdit bio={this.props.modifiedProfile.bio}/>
          ) : (
            <ProfilePhotoEdit/>
          )}

          {this.props.modifiedProfile ? (
            <ProfileNameItemEdit bio={this.props.modifiedProfile.bio} 
            onFirstNameChanged={this.onFirstNameChanged}
            onMidNameChanged={this.onMidNameChanged}
            onLastNameChanged={this.onLastNameChanged}/>
          ) : (
            <ProfileNameItemEdit 
            onFirstNameChanged={this.onFirstNameChanged}
            onMidNameChanged={this.onMidNameChanged}
            onLastNameChanged={this.onLastNameChanged}/>
          )}

          {this.props.modifiedProfile ? (
            <div className="person-info__props editable">
              <CityList availableCities={this.props.cities}
                selectedCity={this.props.modifiedProfile.bio.city}
                onCityChanged={this.onCityChanged} />
            </div>
            // <CitySelector bio={this.props.modifiedProfile.bio}/>
          ) : (
            <div className="person-info__props editable">
              <CityList availableCities={this.props.cities}
                onCityChanged={this.onCityChanged} />
            </div>
            // <CitySelector/>
          )} 
          
        </div>

        {this.props.modifiedProfile ? (
            <ClientDescriptionEdit bio={this.props.modifiedProfile.bio}
            onDescriptionChanged={this.onDescriptionChanged}/>
          ) : (
            <ClientDescriptionEdit 
            onDescriptionChanged={this.onDescriptionChanged}/>
          )}
        
        <ProfileSaveEscButtons onCancelClick={this.onCancelClick} onSaveClick={this.onSaveClick}/>
      </React.Fragment>
    );
  }

  onSaveClick(event) {
    event.preventDefault();
    this.props.dispatch(profileActions.saveProfile(this.props.modifiedProfile))
  }

  onCancelClick(event) {
    event.preventDefault();
    this.props.dispatch(profileActions.discardChanges())
  }

  onFirstNameChanged(event) {
    this.props.dispatch(profileActions.changeFirstName(event.target.value))
  }

  onMidNameChanged(event) {
    this.props.dispatch(profileActions.changeMiddleName(event.target.value))
  }

  onLastNameChanged(event) {
    this.props.dispatch(profileActions.changeLastName(event.target.value))
  }

  onDescriptionChanged(event) {
    this.props.dispatch(profileActions.changeDescription(event.target.value))
  }

  onCityChanged(cityId) {
    var newCityId = parseInt(cityId, 10)
    var newCity = null
    _.forEach(this.props.cities, function(city) {
        if (newCityId == city.id) {
            newCity = city
        }
    });

    this.props.dispatch(profileActions.changeCity(newCity))

  }

}

// export default ClientPersonInfoEditContainer
function mapStateToProps(state) {
  const modifiedProfile = profileSelectors.getModifiedProfile(state);
  const cities = vuuteServiceSelectors.getAvailableVuuteCities(state);
  return {
      modifiedProfile,
      cities,
  }
}

export default connect(mapStateToProps)(ClientPersonInfoEdit);