import JSONParser from './JSONParser'
import _ from 'lodash';
import APIError from "../model/APIError";

class InternalAPIParser extends JSONParser {
    parse() {
        super.parse()

        return new APIError(_.get(this.data, 'exception_text'),
                            _.get(this.data, 'internal_code'))
    }
}

export default InternalAPIParser;