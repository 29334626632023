import _ from 'lodash';
import Notification from '../model/Notification'

const { default: JSONParser } = require("../../common/parsers/JSONParser");

class NotificationParser extends JSONParser {

    parse() {
        super.parse()

        var createdDate = new Date(_.get(this.data, 'created_date'))

        let notification = new Notification(_.get(this.data, 'id'),
                                            _.get(this.data, 'title'),
                                            _.get(this.data, 'data'),
                                            _.get(this.data, 'is_read'),
                                            createdDate)
        
        return notification

    }

}

export default NotificationParser;