import React from 'react'

import SvgIcon from '../../../common/SvgIcon'

class JobRejectedSidebar extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <div className="order__sidebar stick-sidebar-wrap">
                <div className="guarantor">
                    <div className="guarantor__icon-wrap">
                        <div className="order-promo__is-active">
                            <SvgIcon name='icon-checked' />
                            {this.props.jobInfo.order.permissions.isClient ? (
                                <div className="guarantor__desc">
                                    Исполнитель отклонен! Выберите другого для выполнения заказа. Спасибо, что пользуетесь нашим сервисом 😀
                                    </div>
                            ) : (
                                    <div className="guarantor__desc">
                                        К сожалению, заказчик отклонил ваше предложение. Повышайте свой рейтинг и просите оставить вам отзыв - это увеличит ваши шансы в следующий раз. А пока вы можете найти новый заказ!
                                    </div>
                                )}

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default JobRejectedSidebar