import _ from 'lodash';
import * as jobTypes from './actionTypes';
import apiService from '../../services/APIService';
import JobState from '../../jobs/model/JobState';


export function createJob(orderId, bidComment) {
    return async (dispatch) => {
        try {
            const createJobData = await apiService.createJob(orderId, bidComment)
            dispatch({ type: jobTypes.BID_JOB_CONFIRMATION_CALLBACK_RECIEVED, error: createJobData.error})
        } catch (error) {
            console.error(error)
        }
    }
}

export function getJobInfoById(jobId) {
    return async (dispatch) => {
        try {
            const jobData = await apiService.getJobInfoById(jobId);
            dispatch({ type: jobTypes.GET_JOB_INFO_BY_ID, job: jobData.job, error: jobData.error })
        } catch (error) {
            console.log(error)
        }
    }
}

export function bidJobRejectOpen() {
    return ({ type: jobTypes.REJECTED_JOB_CONFIRMATION_OPEN })
}

export function bidJobRejectClose() {
    return ({ type: jobTypes.REJECTED_JOB_CONFIRMATION_CLOSE })
}

export function bidJobConfirmationOpen() {
    return ({ type: jobTypes.BID_JOB_CONFIRMATION_OPEN })
}

export function bidJobConfirmationClose() {
    return ({ type: jobTypes.BID_JOB_CONFIRMATION_CLOSE })
}

export function clearBidOrderErrors() {
    return ({ type: jobTypes.CLEAR_BID_JOB_ERRORS })
}

export function bidJobConfirmationAccept(jobId) {
    return async (dispatch) => {
        try {
            const jobData = await apiService.changeJobStatus(jobId, JobState.ACCEPTED)
            dispatch({ type: jobTypes.BID_JOB_CONFIRMATION_ACCEPT, job: jobData.job});
        } catch (error) {
            console.error(error)
        }
    }
}

export function rejectConfirmationAccept(jobId) {
    return async (dispatch) => {
        try {
            const jobData = await apiService.changeJobStatus(jobId, JobState.REJECTED)
            dispatch({ type: jobTypes.REJECTED_JOB_CONFIRMATION_ACCEPT, job: jobData.job, error: jobData.error });
        } catch (error) {
            console.error(error)
        }
    }
}



export function onReviewConfirmationOpen() {
    return ({ type: jobTypes.ON_REVIEW_JOB_CONFIRMATION_OPEN })
}

export function onReviewConfirmationClose() {
    return ({ type: jobTypes.ON_REVIEW_JOB_CONFIRMATION_CLOSE })
}

export function onReviewConfirmationAccept(jobId) {
    return async (dispatch) => {
        try {
            const jobData = await apiService.changeJobStatus(jobId, JobState.ON_REVIEW)
            dispatch({ type: jobTypes.ON_REVIEW_JOB_CONFIRMATION_ACCEPT, job: jobData.job, error: jobData.error })
        } catch (error) {
            console.error(error)
        }
    }
}

export function returnConfirmationOpen() {
    return ({ type: jobTypes.RETURN_JOB_CONFIRMATION_OPEN })
}

export function returnConfirmationClose() {
    return ({ type: jobTypes.RETURN_JOB_CONFIRMATION_CLOSE })
}

export function returnConfirmationAccept(jobId) {
    return async (dispatch) => {
        try {
            const jobData = await apiService.changeJobStatus(jobId, JobState.RETURN)
            dispatch({ type: jobTypes.RETURN_JOB_CONFIRMATION_ACCEPT, job: jobData.job, error: jobData.error })
        } catch (error) {
            console.error(error)
        }
    }
}

export function closeConfirmationOpen() {
    return ({ type: jobTypes.CLOSE_JOB_CONFIRMATION_OPEN })
}

export function closeConfirmationClose() {
    return ({ type: jobTypes.CLOSE_JOB_CONFIRMATION_CLOSE })
}

export function closeConfirmationAccept(jobId) {
    return async (dispatch) => {
        try {
            const jobData = await apiService.changeJobStatus(jobId, JobState.CLOSED)
            dispatch({ type: jobTypes.CLOSE_JOB_CONFIRMATION_ACCEPT, job: jobData.job, error: jobData.error })
        } catch (error) {
            console.error(error)
        }
    }
}