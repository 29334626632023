import _ from 'lodash';
import ProfileParser from './ProfileParser';

import JSONParser from '../../common/parsers/JSONParser'

class ProfilesParser extends JSONParser {
    parse() {
        let data = super.parse()
        if (data === null) { return null }

        let profilesData = _.get(this.data, 'profiles')

        let profiles = []
        _.forEach(profilesData, function(profileData) {
            let profile = new ProfileParser(profileData).parse()
            profiles.push(profile)
        });
        

        return profiles
    }
}

export default ProfilesParser;