import * as menuTypes from './actionTypes';
import apiService from '../../services/APIService';

export function selectMenuItem(newMenuItem) {
    return ({type: menuTypes.SELECT_MENU, newMenuItem: newMenuItem})
}

export function addNewMessageCount() {
    return ({type: menuTypes.ADD_NEW_MESSAGE_COUNT})
}

export function getUnreadCountMsgRequested() {
    return async(dispatch, getState) => {
        try {
            const unreadCountData = await apiService.getUnreadMsgCount();
            dispatch({type: menuTypes.GET_UNREAD_COUNT, 
                      unreadMsgCount: unreadCountData.unreadCount});
        } catch (error) {
            console.error(error)
        }
    }
}   