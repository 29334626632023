import React from 'react'

import CheckOrderContentView from '../../order/components/CheckOrderContentView'
import JobSidebarContainerView from './jobSidebar/JobSidebarContainerView'

class JobView extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <div className="content content--with-back bg-block">
                <div className="container">
                    <div className="order job">
                        <div className="order__inner">
                            <div className="order__main">
                                <CheckOrderContentView orderInfo={this.props.jobInfo.order} isEditable={false} />
                            </div>
                            <JobSidebarContainerView
                                jobInfo={this.props.jobInfo}
                                onMasterConfirmationClick={this.props.onMasterConfirmationClick}
                                onMessageButtonClick={this.props.onMessageButtonClick}
                                onReviewConfirmationClick={this.props.onReviewConfirmationClick}
                                onReturnConfirmationClick={this.props.onReturnConfirmationClick}
                                onCloseConfirmationClick={this.props.onCloseConfirmationClick}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default JobView