import Immutable from 'seamless-immutable';
import * as types from './actionTypes';

import { menuItem } from '../../page/components/MenuEnum';

const initialState = Immutable({
    selectedMenu: menuItem.PROFILE,
    unreadMsgCount: 0
})

export default function reduce(state = initialState, action = {}) {
    switch(action.type) {

        case types.SELECT_MENU:
            return state.merge({
                selectedMenu: action.newMenuItem
            });
        
        case types.GET_UNREAD_COUNT:
            return state.merge({
                unreadMsgCount: action.unreadMsgCount
            });

        case types.ADD_NEW_MESSAGE_COUNT:

            // var mutableState = Immutable.asMutable(state, {deep: true});
            var currentMsgCount = state.unreadMsgCount
            currentMsgCount += 1

            return state.merge({
                unreadMsgCount: currentMsgCount
            })

        default:
            return state;
    }
}


//selectors
export function getCurrentMenuItem(state) {
    const selectedMenu = state.menu.selectedMenu;
    return selectedMenu;
}

export function getUnreadMsgCount(state) {
    const unreadMsgCount = state.menu.unreadMsgCount;
    return unreadMsgCount
}