import React from 'react'

import PriceItemEdit from './PriceItemEdit'
import ServiceList from './ServiceListContainer'
import RemoveButton from '../../common/RemoveButton'

class ServiceItemEdit extends React.Component {

  render() {

    const availableServices = this.props.availableServices
    const selectedService = this.props.service

    return (
      <tr>
        <td id={selectedService.id}>
          <ServiceList multiple={false} 
          availableServices={availableServices}
          selectedService={selectedService}
          onSelectListChange={this.props.onSelectListChange}
          onSelectListOpen={this.props.onSelectListOpen}
          onSelectListClose={this.props.onSelectListClose}
          orderNumber={this.props.orderNumber}/>
        </td>
        <PriceItemEdit service={this.props.service} 
        orderNumber={this.props.orderNumber}
        onPriceChanged={this.props.onPriceChanged}/>
        <td>
            <RemoveButton onClick={this.props.onServiceItemRemoveClick} value={this.props.orderNumber}/>
        </td>
      </tr>
    );
  }
}

export default ServiceItemEdit