import React from 'react'
// import $ from 'jquery'
// import 'select2'
import _ from 'lodash';
import Parser from 'html-react-parser';

import Select2 from 'react-select2-wrapper';

//TODO: Maybe it will be need to change params or config or REFACTOR
class SelectList extends React.Component {

  render() {
    const className = this.props.className
    const id = this.props.id
    const name = this.props.name
    const options = this.props.options
    var ismultiple = this.props.multiple
    if (!ismultiple) {
      ismultiple = false
    }
    const orderNumber = this.props.orderNumber
    const data = this.props.data
    const selectedId = this.props.selectedId

    return (
      <Select2
        multiple={ismultiple}
        value={selectedId}
        data={data}
        onSelect={(e) => this.props.onSelectListChange(orderNumber, e)}
        onUnselect={(e) => this.props.onSelectListUnselect(orderNumber, e)}
        onOpen={this.props.onSelectListOpen}
        onClose={this.props.onSelectListClose}
        // onSelect={this.props.onSelectListChange}
        options={
          {
            placeholder: 'Выбрать',
            minimumResultsForSearch: Infinity
          }
        }
      />
    );
      }

    // var data = [
    //   {
    //     id: 0,
    //     text: 'enhancement'
    //   },
    //   {
    //     id: 1,
    //     text: 'bug',
    //     selected: true,
    //   },
    //   {
    //     id: 2,
    //     text: 'duplicate'
    //   },
    //   {
    //     id: 3,
    //     text: 'invalid'
    //   },
    //   {
    //     id: 4,
    //     text: 'wontfix'
    //   }
    // ];

    

  //   $(document).ready(function () {
  //     $('.' + className).select2({
  //       placeholder: "Выбрать",
  //       minimumResultsForSearch: Infinity,
  //       data: data,
  //       dropdownParent: id,
  //     });
  //   })

  //   return (
  //     <select className={className}
  //       id={id}
  //       name={name}
  //       multiple={ismultiple}>
  //     </select>
  //   );
  // }
}

export default SelectList;