import React from 'react'

import Menu from '../../page/components/Menu';
import ClientProfileInfo from '../containers/ClientProfileInfo'
import ClientOrdersInfoContainer from '../containers/ClientOrdersInfoContainer';
import ClientActiveJobs from '../containers/ClientActiveJobs'

import Footer from '../../page/components/Footer'
import AuthService from '../../services/AuthService';

import { withRouter } from 'react-router-dom'
import ClientClosedJobs from '../containers/ClientClosedJobs';


class ClientProfile extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {

    let isMyProfile = AuthService.shared().isMyProfile()    

    return (
      <div className="page">
        <Menu />
        <ClientProfileInfo />
        <div className="profile-body container">
          {isMyProfile && 
            <ClientOrdersInfoContainer />
          }
          <ClientActiveJobs />
          <ClientClosedJobs />
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(ClientProfile);