import Immutable from 'seamless-immutable';
import _ from 'lodash';
import * as jobTypes from './actionTypes';

const initialState = Immutable({
    job: undefined,
    error: null,

    jobBidError: undefined,

    isBidJobConfirmationPopupOpen: false,
    isRejectedJobConfirmationPopupOpen: false,
    isOnReviewJobConfirmationPopupOpen: false,
    isReturnJobConfirmationPopupOpen: false,
    isCloseJobConfirmationPopupOpen: false,

})

export default function reduce(state = initialState, action = {}) {

    switch (action.type) {
        case jobTypes.GET_JOB_INFO_BY_ID:
            return state.merge({
                job: action.job,
                error: action.error,
            })

        case jobTypes.BID_JOB_CONFIRMATION_CALLBACK_RECIEVED:
            return state.merge({
                jobBidError: action.error,
            })

        case jobTypes.CLEAR_BID_JOB_ERRORS:
            return state.merge({
                jobBidError: undefined,
            })

        case jobTypes.BID_JOB_CONFIRMATION_OPEN:
            return state.merge({
                isBidJobConfirmationPopupOpen: true
            })
        case jobTypes.BID_JOB_CONFIRMATION_CLOSE:
            return state.merge({
                isBidJobConfirmationPopupOpen: false
            })
        case jobTypes.BID_JOB_CONFIRMATION_ACCEPT:
            return state.merge({
                job: action.job,
                isBidJobConfirmationPopupOpen: false
            })

        case jobTypes.REJECTED_JOB_CONFIRMATION_OPEN:
            return state.merge({
                isRejectedJobConfirmationPopupOpen: true
            })

        case jobTypes.REJECTED_JOB_CONFIRMATION_CLOSE:
            return state.merge({
                isRejectedJobConfirmationPopupOpen: false
            })
            
        case jobTypes.REJECTED_JOB_CONFIRMATION_ACCEPT:
            return state.merge({
                isRejectedJobConfirmationPopupOpen: false,
                job: action.job
            })


        case jobTypes.ON_REVIEW_JOB_CONFIRMATION_OPEN:
            return state.merge({
                isOnReviewJobConfirmationPopupOpen: true
            })
        case jobTypes.ON_REVIEW_JOB_CONFIRMATION_CLOSE:
            return state.merge({
                isOnReviewJobConfirmationPopupOpen: false
            })
        case jobTypes.ON_REVIEW_JOB_CONFIRMATION_ACCEPT:
            return state.merge({
                isOnReviewJobConfirmationPopupOpen: false,
                job: action.job
            })


        case jobTypes.RETURN_JOB_CONFIRMATION_OPEN:
            return state.merge({
                isReturnJobConfirmationPopupOpen: true
            })
        case jobTypes.RETURN_JOB_CONFIRMATION_CLOSE:
            return state.merge({
                isReturnJobConfirmationPopupOpen: false
            })
        case jobTypes.RETURN_JOB_CONFIRMATION_ACCEPT:
            return state.merge({
                isReturnJobConfirmationPopupOpen: false,
                job: action.job
            })


        case jobTypes.CLOSE_JOB_CONFIRMATION_OPEN:
            return state.merge({
                isCloseJobConfirmationPopupOpen: true
            })
        case jobTypes.CLOSE_JOB_CONFIRMATION_CLOSE:
            return state.merge({
                isCloseJobConfirmationPopupOpen: false
            })
        case jobTypes.CLOSE_JOB_CONFIRMATION_ACCEPT:
            return state.merge({
                isCloseJobConfirmationPopupOpen: false,
                job: action.job
            })

        default:
            return state;
    }
}

//selectors
export function getJobInfo(state) {
    const jobInfo = state.job.job
    const error = state.job.error
    return [jobInfo, error];
}

export function getCurrentJobPopupState(state) {
    const isBidJobConfirmationPopupOpen = state.job.isBidJobConfirmationPopupOpen
    const isRejectedJobConfirmationPopupOpen = state.job.isRejectedJobConfirmationPopupOpen
    const isOnReviewJobConfirmationPopupOpen = state.job.isOnReviewJobConfirmationPopupOpen
    const isReturnJobConfirmationPopupOpen = state.job.isReturnJobConfirmationPopupOpen
    const isCloseJobConfirmationPopupOpen = state.job.isCloseJobConfirmationPopupOpen

    return [
        isBidJobConfirmationPopupOpen, isRejectedJobConfirmationPopupOpen, isOnReviewJobConfirmationPopupOpen,
        isReturnJobConfirmationPopupOpen, isCloseJobConfirmationPopupOpen
    ]
}

export function getJobBidError(state) {
    return state.job.jobBidError
}