import React from 'react'

class PaginatorItem extends React.Component {
    constructor(props) {
        super(props);

        this.onCurrentPageChanged = this.onCurrentPageChanged.bind(this)
    }

    render() {
        var paginatorNumber = this.props.paginatorNumber
        var isActive = this.props.isActive

        return (
            <li className="pagination__item" id={paginatorNumber} onClick={this.onCurrentPageChanged}>
                {isActive ? <span>{paginatorNumber}</span> : <a href="javascript:;">{paginatorNumber}</a>}
            </li>
        );
    }
    onCurrentPageChanged() {
        this.props.onCurrentPageChanged(this.props.paginatorNumber)
    }
}

export default PaginatorItem