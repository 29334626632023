import React from 'react'

class CreateOrderDescriptionView extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        let description = ''
        if (this.props.description) {
            description = this.props.description
        }

        return (
            <div className="form-row">
                <div className="option-wrap">
                    <label htmlFor="order-desc">Описание проекта <span className="imp">*</span></label>
                    {this.props.isValidInput.isOrderDescriptionValid === false ? (
                        <React.Fragment>
                            <textarea className="error js-data-input" id="order-desc" name="order-desc" value={description} onChange={this.props.onDescriptionChanged} />
                            <div className="error-msg">Введите описание заказа.</div>
                        </React.Fragment>
                    ) : (
                            <textarea className="js-data-input" id="order-desc" name="order-desc" value={description} onChange={this.props.onDescriptionChanged} />
                        )}
                </div>
            </div>
        )
    }
}

export default CreateOrderDescriptionView