export const ENV = {
    PROD: 'PROD',
    LOCAL: 'LOCAL'
}

class Configuration {
    #envType = ENV.PROD;
    #API_DOMAIN;
    #WS_DOMAIN;

    constructor(envType) {
        this.#envType = envType
        switch (this.#envType) {
            case ENV.PROD:
                this.#API_DOMAIN = 'https://vr-python-prod.herokuapp.com'
                this.#WS_DOMAIN = 'wss://vr-python-prod.herokuapp.com'
                // this.#API_DOMAIN = 'http://vr-python-selectel.eu-central-1.elasticbeanstalk.com'
                // this.#WS_DOMAIN = 'ws://127.0.0.1:8000'
                break
            case ENV.LOCAL:
                this.#API_DOMAIN = 'http://127.0.0.1:8000'
                this.#WS_DOMAIN = 'ws://127.0.0.1:8000'
                break
        }
    }

    get API_ENDPOINT() {
        return `${this.#API_DOMAIN}/api`;
    }

    get WS_ENDPOINT() {
        return this.#WS_DOMAIN
    }
}

export default Configuration;