export const EMAIL_CHANGED = 'settings.EMAIL_CHANGED'
export const GET_EMAIL = 'settings.GET_EMAIL'
export const EMAIL_SAVED = 'settings.EMAIL_SAVED'
export const CLEAR_SETTINGS_CONTACT_FORM_ERRORS = 'settings.CLEAR_SETTINGS_CONTACT_FORM_ERRORS';

export const OLD_PASSWORD_CHANGED = 'settings.OLD_PASSWORD_CHANGED'
export const NEW_PASSWORD_CHANGED = 'settings.NEW_PASSWORD_CHANGED'
export const REPEATED_NEW_PASSWORD_CHANGED = 'settings.REPEATED_NEW_PASSWORD_CHANGED'
export const PASSWORD_SAVED = 'settings.PASSWORD_SAVED'
export const CLEAR_SETTINGS_SECURITY_FORM_ERRORS = 'settings.CLEAR_SETTINGS_SECURITY_FORM_ERRORS';

export const NOTIFY_ITEM_CLICKED = 'settings.NOTIFY_ITEM_CLICKED'
export const GET_RECEIVE_SETTINGS = 'settings.GET_RECEIVE_SETTINGS'