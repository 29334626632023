import AuthService from './services/AuthService';

export const API_CALL  = {
    LOGIN: 'LOGIN',
    SIGNUP: 'SIGNUP',
    RECOVER_PASSWORD: 'RECOVER_PASSWORD',
    GET_EMAIL: 'GET_EMAIL',
    CHANGE_EMAIL: 'CHANGE_EMAIL',
    CHANGE_PASSWORD: 'CHANGE_PASSWORD',
    LOGOUT: 'LOGOUT',
    SEND_SMS_CODE: 'SEND_SMS_CODE',
    CHECK_SMS_CODE: 'CHECK_SMS_CODE',
    CREATE_PROFILE: 'CREATE_PROFILE',
    GET_PROFILE: 'GET_PROFILE',
    UPDATE_PROFILE_BIO: 'UPDATE_PROFILE_BIO',
    UPDATE_PROFILE_SERVICES: 'UPDATE_PROFILE_SERVICES',
    UPDATE_PROFILE_EXPERIENCES: 'UPDATE_PROFILE_EXPERIENCES',
    UPDATE_PROFILE_EDUCATIONS: 'UPDATE_PROFILE_EDUCATIONS',
    UPDATE_PHOTO: 'UPDATE_PHOTO',
    GET_CITIES: 'GET_CITIES',
    GET_COUNTRIES: 'GET_COUNTRIES',
    GET_EDUCATION_OBJECTS: 'GET_EDUCATION_OBJECTS',
    GET_SERVICES: 'GET_SERVICES',
    GET_CATEGORIES: 'GET_CATEGORIES',
    GET_UNREAD_COUNT: 'GET_UNREAD_COUNT',
    CREATE_ORDER: 'CREATE_ORDER',
    UPDATE_ORDER: 'UPDATE_ORDER',
    GET_ACTIVE_ORDERS: 'GET_ACTIVE_ORDERS',
    GET_ORDER: 'GET_ORDER',
    FIND_ORDER: 'FIND_ORDER',
    FIND_MASTER: 'FIND_MASTER',
    CREATE_JOB: 'CREATE_JOB',
    GET_JOB: 'GET_JOB',
    CHANGE_JOB_STATUS: 'CHANGE_JOB_STATUS',
    GET_JOBS: 'GET_JOBS',
    GET_JOBS_FOR_VIEW: 'GET_JOBS_FOR_VIEW',
    GET_CHATS: 'GET_CHATS',
    GET_MESSAGES: 'GET_MESSAGES',
    CREATE_REVIEW: 'CREATE_REVIEW',
    GET_RANDOM_REVIEWS:'GET_RANDOM_REVIEWS',
    GET_NOTIFICATIONS: 'GET_NOTIFICATIONS',
    READ_NOTIFICATION: 'READ_NOTIFICATION',
    GET_NOTIFICATIONS_SETTINGS: 'GET_NOTIFICATIONS_SETTINGS',
    CHANGE_NOTIFICATIONS_SETTINGS: 'CHANGE_NOTIFICATIONS_SETTINGS',
    OPEN_DISPUT: 'OPEN_DISPUT'
}

class HttpRouter {
    #apiCall;
    #params;
    #pk;

    constructor(apiCall, params = null, pk = null) {
        this.#apiCall = apiCall
        this.#params = params
        this.#pk = pk
    }

    get HTTP_METHOD() {
        switch (this.#apiCall) {
            case API_CALL.LOGIN:
            case API_CALL.SIGNUP:
            case API_CALL.CREATE_PROFILE:
            case API_CALL.RECOVER_PASSWORD:
            case API_CALL.SEND_SMS_CODE:
            case API_CALL.CHECK_SMS_CODE:
            case API_CALL.UPDATE_PROFILE_SERVICES:
            case API_CALL.UPDATE_PROFILE_EXPERIENCES:
            case API_CALL.UPDATE_PROFILE_EDUCATIONS:
            case API_CALL.CREATE_ORDER:
            case API_CALL.CREATE_JOB:
            case API_CALL.OPEN_DISPUT:    
            case API_CALL.CREATE_REVIEW:
                return 'POST'
                break
            case API_CALL.GET_PROFILE:
            case API_CALL.GET_CITIES:
            case API_CALL.GET_COUNTRIES:
            case API_CALL.GET_EDUCATION_OBJECTS:
            case API_CALL.GET_SERVICES:
            case API_CALL.GET_CATEGORIES:
            case API_CALL.GET_UNREAD_COUNT:
            case API_CALL.GET_ACTIVE_ORDERS:
            case API_CALL.GET_ORDER:
            case API_CALL.FIND_ORDER:
            case API_CALL.FIND_MASTER:
            case API_CALL.GET_JOB:
            case API_CALL.GET_JOBS:
            case API_CALL.GET_CHATS:
            case API_CALL.GET_MESSAGES:
            case API_CALL.GET_NOTIFICATIONS:
            case API_CALL.GET_EMAIL:
            case API_CALL.GET_NOTIFICATIONS_SETTINGS:
            case API_CALL.GET_JOBS_FOR_VIEW:
                return 'GET'
                break
            case API_CALL.LOGOUT:
                return 'DELETE'
                break
            case API_CALL.UPDATE_PROFILE_BIO:
            case API_CALL.UPDATE_PHOTO:
            case API_CALL.UPDATE_ORDER:
            case API_CALL.CHANGE_JOB_STATUS:
            case API_CALL.READ_NOTIFICATION:
            case API_CALL.CHANGE_EMAIL:
            case API_CALL.CHANGE_PASSWORD:
            case API_CALL.CHANGE_NOTIFICATIONS_SETTINGS:
                return 'PUT'
                break
        }

    }

    get URL_PATH() {
        switch (this.#apiCall) {
            case API_CALL.LOGIN:
                return '/users/login/'
                break
            case API_CALL.SIGNUP:
                return '/users/signup/'
                break
            case API_CALL.LOGOUT:
                return '/users/logout/'
                break
            case API_CALL.SEND_SMS_CODE:
                return '/users/send-code/'
                break
            case API_CALL.CHECK_SMS_CODE:
                return '/users/check-code/'
                break
            case API_CALL.RECOVER_PASSWORD:
                return '/users/recover/'
            case API_CALL.CHANGE_PASSWORD:
                return `/users/change-password/`
            case API_CALL.GET_EMAIL:
                return `/users/email/`
            case API_CALL.CHANGE_EMAIL:
                return `/users/email/`
            case API_CALL.CREATE_PROFILE:
                return '/profiles/'
                break
            case API_CALL.GET_PROFILE:
            case API_CALL.UPDATE_PROFILE_BIO:
                if ((this.#pk !== null) && (this.#pk !== undefined)) {
                    return `/profiles/${this.#pk}/`
                } else {
                    return '/profiles/'
                }
            case API_CALL.UPDATE_PROFILE_SERVICES:
                return '/profiles/service/'
                break
            case API_CALL.UPDATE_PROFILE_EXPERIENCES:
                return '/profiles/experience/'
            case API_CALL.UPDATE_PROFILE_EDUCATIONS:
                return '/profiles/education/'
            case API_CALL.UPDATE_PHOTO:
                return '/profiles/photo/'
            case API_CALL.GET_CITIES:
                if ((this.#pk !== null) && (this.#pk !== undefined)) {
                    return `/service/cities/?country_id=${this.#pk}`
                } else {
                    return '/service/cities/'
                }
            case API_CALL.GET_COUNTRIES:
                return '/service/countries/'
            case API_CALL.GET_EDUCATION_OBJECTS:
                if ((this.#pk !== null) && (this.#pk !== undefined)) {
                    return `/service/education/?${this.#pk}`
                } else {
                    return '/service/education/'
                }
            case API_CALL.GET_SERVICES:
                return `/service/services/`
            case API_CALL.GET_CATEGORIES:
                return `/service/categories/`
            case API_CALL.GET_UNREAD_COUNT:
                return '/chat/unread_count/'
            case API_CALL.CREATE_ORDER:
            case API_CALL.UPDATE_ORDER:
            case API_CALL.GET_ACTIVE_ORDERS:
            case API_CALL.GET_ORDER:
                if ((this.#pk !== null) && (this.#pk !== undefined)) {
                    return `/orders/${this.#pk}/`
                } else {
                    return `/orders/`
                }

            case API_CALL.FIND_ORDER:
                return `/search/orders/?${this.#pk}`
            case API_CALL.FIND_MASTER:
                return `/search/profiles/?${this.#pk}`

            case API_CALL.CREATE_JOB:
            case API_CALL.GET_JOB:
            case API_CALL.CHANGE_JOB_STATUS:
            case API_CALL.GET_JOBS:
            case API_CALL.GET_JOBS_FOR_VIEW:
                if ((this.#pk !== null) && (this.#pk !== undefined)) {
                    return `/jobs/${this.#pk}/`
                } else {
                    return `/jobs/`
                }
            
            case API_CALL.GET_CHATS:
            case API_CALL.GET_MESSAGES:
                if ((this.#pk !== null) && (this.#pk !== undefined)) {
                    return `/chat/${this.#pk}`
                } else {
                    return `/chat/`
                }

            case API_CALL.CREATE_REVIEW:
                return `/reviews/${this.#pk}/`       
            case API_CALL.GET_RANDOM_REVIEWS:
                return `/reviews/random/`       
                
            case API_CALL.GET_NOTIFICATIONS:
                return `/notifications/`
            
            case API_CALL.READ_NOTIFICATION:
                if ((this.#pk !== null) && (this.#pk !== undefined)) {
                    return `/notifications/${this.#pk}/`
                } else {
                    return `/notifications/`
                }

            case API_CALL.GET_NOTIFICATIONS_SETTINGS:
            case API_CALL.CHANGE_NOTIFICATIONS_SETTINGS:
                return `/notifications/settings/`
            case API_CALL.OPEN_DISPUT:
                return `/reports/${this.#pk}/`
        }
    }

    get JSON_PAYLOAD() {
        if (this.#params == null) {
            return null
        } else {
            if (this.#apiCall == API_CALL.UPDATE_PHOTO) {
                return this.#params
            } else {
                return JSON.stringify(this.#params)
            }
        }
        
    }

    get HEADERS() {
        switch (this.#apiCall) {
            case API_CALL.LOGIN:
            case API_CALL.SIGNUP:
            case API_CALL.RECOVER_PASSWORD:
            case API_CALL.GET_CITIES:
            case API_CALL.GET_COUNTRIES:
            case API_CALL.GET_EDUCATION_OBJECTS:
            case API_CALL.GET_SERVICES:
            case API_CALL.GET_CATEGORIES:
                return {
                    "Content-Type": "application/json",
                }
            case API_CALL.GET_EMAIL:
            case API_CALL.CHANGE_EMAIL:
            case API_CALL.CHANGE_PASSWORD:
            case API_CALL.LOGOUT:
            case API_CALL.SEND_SMS_CODE:
            case API_CALL.CHECK_SMS_CODE:
            case API_CALL.CREATE_PROFILE:
            case API_CALL.GET_PROFILE:
            case API_CALL.UPDATE_PROFILE_BIO:
            case API_CALL.UPDATE_PROFILE_SERVICES:
            case API_CALL.UPDATE_PROFILE_EXPERIENCES:
            case API_CALL.UPDATE_PROFILE_EDUCATIONS:
            case API_CALL.GET_UNREAD_COUNT:
            case API_CALL.CREATE_ORDER:
            case API_CALL.UPDATE_ORDER:
            case API_CALL.GET_ACTIVE_ORDERS:
            case API_CALL.GET_ORDER:
            case API_CALL.FIND_ORDER:
            case API_CALL.FIND_MASTER:
            case API_CALL.CREATE_JOB:
            case API_CALL.OPEN_DISPUT:
            case API_CALL.GET_JOB:
            case API_CALL.CHANGE_JOB_STATUS:
            case API_CALL.GET_JOBS:
            case API_CALL.GET_CHATS:
            case API_CALL.GET_MESSAGES:
            case API_CALL.CREATE_REVIEW:
            case API_CALL.GET_NOTIFICATIONS:
            case API_CALL.READ_NOTIFICATION:
            case API_CALL.GET_NOTIFICATIONS_SETTINGS:
            case API_CALL.CHANGE_NOTIFICATIONS_SETTINGS:
            case API_CALL.GET_JOBS_FOR_VIEW:
                return {
                    "Content-Type": "application/json",
                    "Authorization": "Token " + AuthService.shared().getToken(),
                }
            case API_CALL.UPDATE_PHOTO:
                return {
                    "Authorization": "Token " + AuthService.shared().getToken(),
                }
        }
        
    }
}

export default HttpRouter;