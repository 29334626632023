
class EducationVuute {
    id;
    title;
    city;

    constructor(id, title, city) {
        this.id = id
        this.title = title
        this.city = city
    }
}

export default EducationVuute;