
class User {
    id;
    phone;
    name;
    isVerified;

    constructor(id, phone, name, isVerified) {
        this.id = id
        this.phone = phone
        this.name = name
        this.isVerified = isVerified
    }
}

export default User;