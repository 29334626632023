class OrderPermissions {
    hasBid;
    isClient;
    isOrderOwner;
    isAuthorizedUser;
    isOrderClosed;

    constructor(hasBid, isClient, isOrderOwner, isAuthorizedUser, isOrderClosed) {
        this.hasBid = hasBid
        this.isClient = isClient
        this.isOrderOwner = isOrderOwner
        this.isAuthorizedUser = isAuthorizedUser
        this.isOrderClosed = isOrderClosed
    }
}

export default OrderPermissions;