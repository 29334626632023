import React from 'react';

import MyJobFormTemplate from './MyJobFormTemplate';
import AuthService from '../../services/AuthService';

class IncomeJobsView extends React.Component {
	// constructor(props) {
	// 	super(props);
	//     this.state {
	//         profileType: AuthService.shared().getProfileBio().profileType
	//     };
	// }

	constructor(props) {
		super(props);
		this.state = {
			profileType: AuthService.shared().getProfileBio().profileType,
		};
	}

	render() {
		if (this.props.incomeJobsInfo.length > 0) {
			var incomeJobItems = this.props.incomeJobsInfo.map((job) => (
				<MyJobFormTemplate
					key={job.id.toString()}
					jobInfo={job}
					jobType={'incomeJob'}
					onJobTitleClick={this.props.onJobTitleClick}
					onMessageButtonClick={this.props.onMessageButtonClick}
				/>
			));
		}

		return (
			<div className='bg-block'>
				<section className='offers page-section container'>
					<header>
						<h2 className='page-section-title'>
							{this.state.profileType === 'CLIENT'
								? 'Отклики по заказам'
								: 'Предложения по заказам'}
						</h2>
					</header>
					<div className='jobs-list'>
						{this.props.incomeJobsInfo.length > 0 ? (
							<React.Fragment>{incomeJobItems}</React.Fragment>
						) : (
							<div className='empty-data'>
								<p>
									На данный момент у Вас нет
									{this.state.profileType === 'CLIENT'
										? ' откликов'
										: ' предложений'}{' '}
									по заказам.
								</p>
							</div>
						)}
					</div>
				</section>
			</div>
		);
	}
}

export default IncomeJobsView;
