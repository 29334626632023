import React from 'react'

import dateService from '../../services/DateService'
import SvgIcon from '../../common/SvgIcon';

class JobFormButton extends React.Component {
    constructor(props) {
        super(props);

        this.onHandleClick = this.onHandleClick.bind(this)
    }

    render() {

        var hasReview = false
        if (this.props.jobInfo.reviewOwner) {
            var hasReview = true
        }

        var feedbackReview = null

        if (this.props.jobInfo.reviewMaster) {
            feedbackReview = this.props.jobInfo.reviewMaster
        }
        
        const jobType = this.props.jobType

        const leftTime = dateService.leftTimeCalc(this.props.jobInfo.order.endDate)

        switch (jobType) {
            case 'incomeJob':
                return ''

            case 'activeJob':
                return <div className="job__still">{leftTime}</div>

            case 'closedJob':
                if(hasReview) {
                    if (feedbackReview !== null) {
                        return (
                            <div className="job__grade">
                                <div className="grade">
                                    <div className="grade__meta"><a className="grade__name" href="javascript:;">{feedbackReview.profile_owner}</a>
                                        {/* <div className="grade__date">21 января 2018</div> */}
                                    </div>
                                    {(feedbackReview.rate >= 4) ? (
                                        <div className="grade__mark grade__mark--positive">
                                            <SvgIcon name='icon-like' />
                                        </div>
                                    ) : (
                                            <div className="grade__mark grade__mark--negative">
                                                <SvgIcon name='icon-dislike' />
                                            </div>
                                        )}
                                </div>
                            </div>
                        )
                    } else {
                        return ''
                    }
                } else {
                    return (
                        <div className="job__grade">
                            <div className="grade">
                                <a className="job__add-review-btn btn--1" href="javascksript:;" onClick={this.onHandleClick}>Оставить отзыв</a>
                            </div>
                        </div>
                    )
                }

            default:
                return ('')
        }
    }

    onHandleClick(event) {
        event.preventDefault()

        this.props.onReviewButtonClick(this.props.jobInfo.id)
    }
}

export default JobFormButton