import Immutable from 'seamless-immutable';
import * as smsTypes from './actionTypes';

import AuthService from '../../services/AuthService';

const initialState = Immutable({
    smsCode: '',
    isSent: false,
    isVerified: false,
    error: undefined,
})

export default function reduce(state = initialState, action = {}) {
    
    switch(action.type) {
        case smsTypes.SMS_CODE_CHANGED:
            return state.merge({
                smsCode: action.smsCode
            });
        case smsTypes.SEND_CODE:
            return state.merge({
                isSent: action.isSent
            });
        case smsTypes.VERIFY_CODE:
            let user = AuthService.shared().getUser()
            user.isVerified = action.isVerified
            AuthService.shared().updateUser(user)
            return state.merge({
                isVerified: action.isVerified,
                error: action.error,
            });
        case smsTypes.CLEAR_SMS_FORM_ERRORS:
            return state.merge({
                error: undefined
            })
        case smsTypes.CLEAR_SMS_FORM:
            return initialState
        default:
            return state;
    }
}


//selectors
export function getSMSVerificationStatus(state) {
    return [state.sms.smsCode, state.sms.isSent, state.sms.isVerified, state.sms.error];
}