import React from 'react';

import dateService from '../../services/DateService'

class NotificationItemView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isHidden: true
        }

        this.onHandleClick = this.onHandleClick.bind(this)
    }

    render() {
        const createdNotificationPassedTime = dateService.passedTimeCalc(this.props.notificationInfo.created)

        const unShowContent = { display: 'none' }
        const showContent = { display: 'block' }

        return (
            <React.Fragment>
                <tr className={this.props.notificationInfo.isRead ? '' : ("new-notify")} >
                    <td className="js-acc-item">
                        <a className={this.state.isHidden ? "notify-open js-acc-title" : "notify-open js-acc-title opened"} href="javascript:;" onClick={this.onHandleClick}>
                            {this.props.notificationInfo.title}
                        </a>
                        <div className="notify-text js-acc-content" style={this.state.isHidden ? unShowContent : showContent}>
                            {this.props.notificationInfo.data}
                        </div>
                    </td>
                    <td>{createdNotificationPassedTime + ' назад'}</td>
                </tr>
            </React.Fragment>
        );
    }

    onHandleClick() {
        this.setState({
            isHidden: !this.state.isHidden
        })

        if (this.state.isHidden && !this.props.notificationInfo.isRead) {
            this.props.onUnreadNotificationClick(this.props.notificationInfo.id)
        }
    }
}

export default NotificationItemView