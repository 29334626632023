import React from "react"

import SvgIcon from "../../../../../../../common/SvgIcon"

class AdvItem extends React.Component {
    
    render() {
        const {article} = this.props
        const body = article.text
        const svgName = this.props.svgName

        return(
            <li className="excellence-item">
                <div className="excellence-item__icon-wrap">
                    <SvgIcon name={svgName}/>
                </div>
                <div className="excellence-item__title">
                    {article.title}
                </div>
                <div className="excellence-item__desc">
                    {body}
                </div>
            </li>
        )

    }
}

export default AdvItem