import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';

import InputMask from 'react-input-mask';

import * as registerActions from '../../store/register/actions';
import * as registerSelectors from '../../store/register/reducer';
import * as popupActions from '../../store/popup/actions';

import LoginMsgBox from '../components/LoginMsgBox';

class RegisterContainer extends Component {
	constructor(props) {
		super(props);
		autoBind(this);
		this.state = { regulationAgree: true,localError:null, isProfileChecked:false };
        this.handleChange = this.handleChange.bind(this);
	}

	handleChange(event) {
        this.setState({regulationAgree: event.target.checked});
	}

	render() {
		if (this.props.user) {
			this.props.dispatch(popupActions.showSMSPopup());
		}

        const apiError = this.props.error
	

		let isClientProfileChecked = null
		if (this.props.profileType === 'CLIENT') {
			isClientProfileChecked = true;
		} else if (this.props.profileType === 'MASTER') {
			isClientProfileChecked = false;
		}

		const onClientTypeSelected = (evt) => {
			this.props.dispatch(registerActions.clientTypeSelected());
			this.setState({isProfileChecked:true});
		};

		const onMasterTypeSelected = (evt) => {
			this.props.dispatch(registerActions.masterTypeSelected());
			this.setState({isProfileChecked:true});
		};

		const onNameChanged = (evt) => {
			this.props.dispatch(registerActions.changeName(evt.target.value));
		};

		const onPhoneChanged = (evt) => {
			this.props.dispatch(registerActions.changePhoneNumber(evt.target.value));
		};

		const onPasswordChanged = (evt) => {
			this.props.dispatch(registerActions.changePassword(evt.target.value));
		};

		const onConfirmPasswordChanged = (evt) => {
			this.props.dispatch(
				registerActions.changeConfirmPassword(evt.target.value)
			);
		};

		const onRegisterClick = (evt) => {
			evt.preventDefault();

			if (this.state.isProfileChecked)
			{
				//if regulation agree
				if (this.state.regulationAgree)
				{
					// if passwords filled
					if (this.props.password && this.props.confirmPassword)
					{
						// and matched
						if (this.props.password === this.props.confirmPassword)
						{
							this.setState({ localError: null })
							this.props.dispatch(registerActions.clearRegisterErrors())
							this.props.dispatch(registerActions.register(this.props.name,
								this.props.phoneNumber,
								this.props.password,
								this.props.profileType));
							// call backend
						}
						else
						{
							this.props.dispatch(registerActions.clearRegisterErrors())
							this.setState({ localError: { message: "Пароли не совпадают" } })
						}
				 
					}
					else
					{
						this.props.dispatch(registerActions.clearRegisterErrors())
						this.setState({ localError: { message: "Введите пароль" } })
					}
			   
				}
				else
				{
					this.props.dispatch(registerActions.clearRegisterErrors())
					this.setState({ localError: { message: "Подтвердите согласие с правилами сервиса" } })
				}
			}
			else
			{
				this.props.dispatch(registerActions.clearRegisterErrors())
				this.setState({ localError: { message: "Выберите тип профиля" } })
			}
			
		};

		return (
			<>
				<form className='popup-frm ajax-form'>
					<div className='popup__inner'>
						<div className='input-row'>
							<div className='radio-wrap'>
								<input
									id='reg-person-1'
									type='radio'
									name='reg-person'
									onChange={onClientTypeSelected}
								/>
								<label htmlFor='reg-person-1'>
									<span>
										<span />
									</span>
									Я заказчик
								</label>
							</div>
							<div className='radio-wrap'>
								<input
									id='reg-person-2'
									type='radio'
									name='reg-person'
									onChange={onMasterTypeSelected}
								/>
								<label htmlFor='reg-person-2'>
									<span>
										<span />
									</span>
									Я исполнитель
								</label>
							</div>
						</div>
						<p className='centered'>Заполните форму</p>
						{this.state.localError && console.log(this.state.localError.message)}
						{apiError != null ? (
							<LoginMsgBox msgText={apiError.message} msgType={'error-msg'} />
						) : (
							''
						)}
						{(this.state.localError!=null) ? <LoginMsgBox msgText={this.state.localError && this.state.localError.message} msgType={"error-msg"} /> : ''}
						<input
							className='is-required'
							id='reg-name'
							type='text'
							name='reg-name'
							placeholder='Представьтесь, пожалуйста'
							value={this.props.name}
							onChange={onNameChanged}
						/>
						<InputMask
							className='is-required js-phone'
							id='reg-phone'
							type='tel'
							name='reg-phone'
							placeholder='Телефон'
							mask='+7 (999)-999-99-99'
							value={this.props.phoneNumber}
							onChange={onPhoneChanged}
						/>
						<div className='double-input-vertical'>
							<input
								className='is-required js-new-password'
								id='reg-pass-1'
								type='password'
								name='reg-pass-1'
								placeholder='Пароль'
								value={this.props.password}
								onChange={onPasswordChanged}
							/>
							<div className='input-wrap'>
								<input
									className='is-required js-confirm-password'
									id='reg-pass-2'
									type='password'
									name='reg-pass-2'
									placeholder='Повторите пароль'
									value={this.props.confirmPassword}
									onChange={onConfirmPasswordChanged}
								/>
								<span className='filled-success'>
									<svg className='icon icon-check'>
										<use xlinkHref='https://revladov.com/vuute/popups.html#icon-check' />
									</svg>
								</span>
							</div>
						</div>
						<div className='check-wrap'>
							<input
								id='regulation-agree'
								type='checkbox'
								name='regulation-agree'
								checked={this.state.regulationAgree}
								onChange={this.handleChange}
							/>
							<label htmlFor='regulation-agree'>
								<span />
								<div>
									Я ознакомился и согласен с{' '}
									<a href='https://vuuteimagespoc.s3.eu-central-1.amazonaws.com/docs/UserAgreementVuute.pdf' target="_blank">
										условиями использования
									</a>
									<br /> сервиса и {/* Заменить ссылку на актуальную: */}
									<a href='https://vuuteimagespoc.s3.eu-central-1.amazonaws.com/docs/GDRPVuute.pdf' target="_blank">
										политикой обработки персональных данных
									</a>
								</div>
							</label>
						</div>
					</div>
					<button
						type='button'
						className='popup-frm__submit btn--2 popup__wide-btn'
						onClick={onRegisterClick}
					>
						зарегистрироваться
					</button>
				</form>
			</>
		);
	}
}

function mapStateToProps(state) {
	const [name, phoneNumber, password, confirmPassword, profileType] =
		registerSelectors.getUserFormData(state);
	const [user, token, error] = registerSelectors.getUser(state);
	return {
		name,
		phoneNumber,
		password,
		confirmPassword,
		profileType,
		user,
		token,
		error,
	};
}

export default connect(mapStateToProps)(RegisterContainer);
