import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import RoomViewSearch from './RoomViewSearch';
import RoomPersonItem from './RoomPersonItem';

class CustomScrollbars extends React.Component {
	render() {
		var scrollHeight = document.getElementById('MessagesContainerView');

		if (scrollHeight == null) {
			scrollHeight = 500;
		} else {
			// MAGIC: For some reason each update scrollHeight increase in 21 px
			scrollHeight = scrollHeight.clientHeight - 21;
		}

		return (
			<Scrollbars
				id='mCSB_2'
				className='mCustomScrollBox mCS-light mCSB_vertical mCSB_inside'
				style={{ height: scrollHeight }}
				renderTrackVertical={(props) => (
					<div
						{...props}
						style={{
							right: 0,
							position: 'absolute',
							width: 6,
							bottom: 2,
							top: 2,
							borderRadius: 3,
						}}
					/>
				)}
				renderTrackHorizontal={(props) => (
					<div {...props} style={{ display: 'none' }} />
				)}
				renderThumbVertical={(props) => (
					<div {...props} style={{ backgroundColor: 'rgb(255, 176, 91)' }} />
				)}
				renderView={(props) => (
					<div
						{...props}
						id='mCSB_2_container'
						className='mCSB_container'
						// style={{ height: scrollHeight }}
					/>
				)}
			>
				{this.props.children}
			</Scrollbars>
		);
	}
}

/**
 * View which show dialogs with last messages and unread messages count
 *
 * @class RoomListView
 * @extends {React.Component}
 */
class RoomListView extends React.Component {
	/**
	 * Generate table view with loaded rooms
	 *
	 * @returns HTML table with RoomPersonItems
	 * @memberof RoomListView
	 */
	generateRoomList() {
		const roomList = [];

		const chats = this.props.chats;
		const selectedChat = this.props.selectedChat;
		var selectedChatId = undefined;
		if (selectedChat !== undefined) {
			selectedChatId = selectedChat.id;
		}

		var counter = 0;

		// grouping with order title
		let sortedChatsCopy = [...chats].sort((a, b) => {
			if (a.job.order.title < b.job.order.title) return -1;
			if (a.job.order.title > b.job.order.title) return 1;
			return 0;
		});

		sortedChatsCopy.forEach((element, idx) => {
			// debugger
			const { title } = element.job.order;

			// if first message
			if (idx === 0) {
				roomList.push(
					<div class='messages-contacts__category-title'>{title}</div>
				);
				counter = counter + 1;
			}
			// if current message order title is not equal previous one title, then push current title
			if (
				sortedChatsCopy[idx - 1] &&
				element.job.order.title !== sortedChatsCopy[idx - 1].job.order.title
			) {
				roomList.push(
					<div class='messages-contacts__category-title'>{title}</div>
				);
				counter = counter + 1;
			}
			roomList.push(
				<RoomPersonItem
					chat={element}
					orderNumber={counter}
					selectedChatId={selectedChatId}
					onChatItemSelectClick={this.props.onChatItemSelectClick}
				/>
			);
			counter = counter + 1;
		});
		this.props.setChatsAndSeparators(roomList)
		return roomList;
	}

	/**
	 * Basic render function for React component
	 *
	 * @returns
	 * @memberof RoomListView
	 */
	render() {
		return (
			<div className='messages__sidebar'>
				{/* <RoomViewSearch /> */}
				<div className='messages-contacts js-scrollable'>
					<CustomScrollbars>{this.generateRoomList()}</CustomScrollbars>
				</div>
			</div>
		);
	}
}

export default RoomListView;
