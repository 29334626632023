import DateService from "../../services/DateService";

class Job {
    id;
    order;
    master;
    state;
    createdDate;
    reviewOwner;
    reviewMaster;
    acceptedDate;
    closedDate;
    rejectedDate;
    canceledDate;

    constructor(id, order, master, state, createdDate, acceptedDate = null, closedDate = null, rejectedDate = null, canceledDate = null) {
        this.id = id
        this.order = order
        this.master = master
        this.state = state
        this.createdDate = createdDate
        this.acceptedDate = acceptedDate
        this.closedDate = closedDate
        this.rejectedDate = rejectedDate
        this.canceledDate = canceledDate

        this.reviewOwner = null
        this.reviewMaster = null
    }
}

export default Job;