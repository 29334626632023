import Immutable from 'seamless-immutable';
import _ from 'lodash';
import * as orderTypes from './actionTypes';

const initialState = Immutable({
    order: undefined,
    error: undefined,

    orderTitle: undefined,
    orderCategory: undefined,
    orderDescription: undefined,
    orderSliderPrice: undefined,
    orderPrice: undefined,
    orderStartDate: undefined,
    orderEndDate: undefined,
    orderBidComment: undefined,

    isOrderBidPopupOpen: false,
    isValidInput: {
        isOrderTitleValid: true,
        isOrderCategoryValid: true,
        isOrderDescriptionValid: true,
        isOrderPriceValid: true,
        isOrderStartDateValid: true,
        isOrderEndDateValid: true
    },

    clientOrders: {
        newOrders: [],
    }
})

export default function reduce(state = initialState, action = {}) {

    switch (action.type) {
        case orderTypes.CLEAR_STORE:
            return state.merge({
                order: undefined
            })

        case orderTypes.CREATE_ORDER:
            return state.merge({
                order: action.order,
                error: action.error,
                
                orderTitle: state.orderTitle,
                orderCategory: state.orderCategory,
                orderDescription: state.orderDescription,
                orderSliderPrice: state.orderSliderPrice,
                orderPrice: state.orderPrice,
                orderStartDate: state.orderStartDate,
                orderEndDate: state.orderEndDate,
            })
        
        case orderTypes.CLEAR_CREATE_ORDER_FORM:
            return state.merge({
                orderTitle: undefined,
                orderCategory: undefined,
                orderDescription: undefined,
                orderSliderPrice: undefined,
                orderPrice: undefined,
                orderStartDate: undefined,
                orderEndDate: undefined,
                orderBidComment: undefined,
            })

        case orderTypes.CLEAR_CREATE_ORDER_FORM_ERRORS:
            return state.merge({
                    order: state.order,
                    error: undefined,

                    orderTitle: state.orderTitle,
                    orderCategory: state.orderCategory,
                    orderDescription: state.orderDescription,
                    orderSliderPrice: state.orderSliderPrice,
                    orderPrice: state.orderPrice,
                    orderStartDate: state.orderStartDate,
                    orderEndDate: state.orderEndDate,
                    orderBidComment: state.orderBidComment,

                    isOrderBidPopupOpen: state.isOrderBidPopupOpen,

                    clientOrders: state.clientOrders
            })

        case orderTypes.ON_BID_ORDER_FORM_CLEAR:
            return state.merge({
                orderBidComment: undefined,
            })
            
        case orderTypes.EDIT_ORDER:
            return state.merge({
                orderTitle: action.orderTitle,
                orderCategory: action.orderCategory,
                orderDescription: action.orderDescription,
                orderPrice: action.orderPrice,
                orderStartDate: action.orderStartDate,
                orderEndDate: action.orderEndDate,
            })

        case orderTypes.GET_ORDER_INFO_BY_ID:
            return state.merge({
                order: action.order,
                error: action.error
            })

        case orderTypes.FIELDS_VALIDATOR:
            return state.merge({
                isValidInput: {
                    isOrderTitleValid: true,//action.isOrderTitleValid,
                    isOrderCategoryValid: true,//action.isOrderCategoryValid,
                    isOrderDescriptionValid: true,//action.isOrderDescriptionValid,
                    isOrderPriceValid: true,//action.isOrderPriceValid,
                    isOrderStartDateValid: true,//action.isOrderStartDateValid,
                    isOrderEndDateValid: true//action.isOrderEndDateValid
                }
            })

        case orderTypes.TITLE_CHANGED:
            return state.merge({
                orderTitle: action.newTitle
            })

        case orderTypes.CATEGORY_CHANGED:
            return state.merge({
                orderCategory: action.newCategory
            })

        case orderTypes.DESCRIPTION_CHANGED:
            return state.merge({
                orderDescription: action.newDescription
            })

        case orderTypes.PRICE_CHANGED:
            return state.merge({
                orderPrice: action.newPrice
            })

        case orderTypes.START_DATE_CHANGED:
            return state.merge({
                orderStartDate: action.newStartDate
            })

        case orderTypes.END_DATE_CHANGED:
            return state.merge({
                orderEndDate: action.newEndDate
            })

        case orderTypes.BID_COMMENT_CHANGED:
            return state.merge({
                orderBidComment: action.newBidComment
            })

        case orderTypes.ON_BID_ORDER_CLICK:
            return state.merge({
                isOrderBidPopupOpen: true
            })

        case orderTypes.ON_BID_ORDER_CLOSE_CLICK:
            return state.merge({
                isOrderBidPopupOpen: false
            })

        case orderTypes.ON_BID_ORDER_SEND_CLICK:
            return state.merge({
                isOrderBidPopupOpen: false
            })

        case orderTypes.GET_CLIENT_ORDERS:
            return state.merge({
                clientOrders: {
                    newOrders: action.newOrders,
                    error: action.error,
                }
            })
        
        case orderTypes.SUCCESS_ORDER_BID:
            var mutableState = Immutable.asMutable(state, {deep:true});

            var order = mutableState.order
            order.permissions.hasBid = true

            return state.merge({
                isOrderBidPopupOpen: false,
                order: order,
            })

        default:
            return state;
    }
}

//selectors
export function getOrderInfo(state) {
    const orderTitle = state.order.orderTitle
    const orderCategory = state.order.orderCategory
    const orderDescription = state.order.orderDescription
    const orderSliderPrice = state.order.orderSliderPrice
    const orderPrice = state.order.orderPrice
    const orderStartDate = state.order.orderStartDate
    const orderEndDate = state.order.orderEndDate
    const isValidInput = state.order.isValidInput
    const error = state.order.error
    return [orderTitle, orderCategory, orderDescription, orderSliderPrice, orderPrice, orderStartDate, orderEndDate, isValidInput, error]
}

export function getOrderInfoById(state) {
    const orderInfo = state.order.order
    const error = state.order.error
    return [orderInfo, error];
}

export function getOrderCategory(state) {
    const orderCategory = state.order.orderCategory;
    return orderCategory;
}

export function getCurrentOrderBidPopupState(state) {
    const orderBidComment = state.order.orderBidComment
    const isOrderBidPopupOpen = state.order.isOrderBidPopupOpen
    return [orderBidComment, isOrderBidPopupOpen]
}

export function getClientOrdersInfo(state) {
    const clientOrders = state.order.clientOrders
    return clientOrders
}