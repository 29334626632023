import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import autoBind from 'react-autobind';

import { menuItem } from '../../page/components/MenuEnum';

import Menu from '../components/Menu'
import Footer from '../components/Footer'

import svg404 from '../../images/404.svg'

import * as menuActions from '../../store/menu/actions';

class Page404 extends React.Component {

  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentWillMount() {
    this.props.dispatch(menuActions.selectMenuItem(menuItem.UNSELECTED));
  }

  render() {
    return (
      <div className="page">
        <Menu/>
        <div className="error-404">
          <div className="container">
            <h1 className="error-404__title">Упс. Ошибочка</h1>
            <div className="error-404__desc">
              <p>К сожалению, что-то пошло не так и страница не нашлась.</p>
              <p>Мы уже работаем над ее возвращением!</p>
            </div><a className="error-404__back-btn btn--2 btn--lg" href="javascript:;" onClick={this.onMainClicked}>Вернуться на главную</a><img className="error-404__img" src={svg404} alt="" />
            <div className="error-404__footer">
              <div className="copy">© времонт.рф, 2017 — 2018</div>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }

  onMainClicked(event) {
    this.props.history.push(`/profile`)
  }

}

function mapStateToProps(state) {

}


export default withRouter(connect(mapStateToProps)(Page404));








