import React from 'react';

import JobUserSidebar from './JobUserInfoSidebarView';
import SvgIcon from '../../../common/SvgIcon';
import DisputPopupContainer from '../../../jobs/components/popups/DisputPopup/DisputPopupContainer';

class InProgressSidebar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isDisputOpened: null,
		};
	}

	render() {
		return (
			<div className='order__sidebar stick-sidebar-wrap'>
				{this.props.jobInfo.order.permissions.isClient ? (
					<React.Fragment>
						<div className='guarantor__desc'>
							Со всем старанием Вашим заказом занимается{' '}
						</div>
						<JobUserSidebar masterInfo={this.props.jobInfo.master} />
						<div className='sidebar-btns'>
							<button
								className='btn--2 add-money-btn'
								type='button'
								onClick={this.props.onCloseConfirmationClick}
							>
								Закрыть работу
							</button>
							<button
								className='order-frm__submit btn--1'
								onClick={() => {
									this.setState({ isDisputOpened: true });
								}}
								type='button'
							>
								Открыть спор
							</button>
						</div>
						{this.state.isDisputOpened && (
							<DisputPopupContainer
								onClose={() => {
									this.setState({ isDisputOpened: false });
								}}
								jobInfo={this.props.jobInfo}
								isClient={this.props.jobInfo.order.permissions.isClient}
							/>
						)}
					</React.Fragment>
				) : (
					<React.Fragment>
						<div className='guarantor'>
							<div className='guarantor__icon-wrap'>
								<div className='order-promo__is-active'>
									<SvgIcon name='icon-list' />
									<div className='guarantor__desc'>
										После окончания работы попросите заказчика оставить Вам
										отзыв. Чем выше Ваш рейтинг тем больше вероятность быть
										выбраным заказчиком!
									</div>
								</div>
							</div>
						</div>
						<div className='sidebar-btns'>
							<button
								className='btn--2 add-money-btn'
								type='button'
								onClick={this.props.onReviewConfirmationClick}
							>
								Работа выполнена!
							</button>
							<button
								className='order-frm__submit btn--1'
								type='button'
								onClick={() => {
									this.setState({ isDisputOpened: true });
								}}
							>
								Открыть спор
							</button>
						</div>
						{this.state.isDisputOpened && (
							<DisputPopupContainer
								onClose={() => {
									this.setState({ isDisputOpened: false });
								}}
								jobInfo={this.props.jobInfo}
								isClient={this.props.jobInfo.order.permissions.isClient}
							/>
						)}
					</React.Fragment>
				)}
			</div>
		);
	}
}

export default InProgressSidebar;
