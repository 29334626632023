import React from 'react'
import DateService from '../../services/DateService'

import autoBind from 'react-autobind';
import { withRouter } from 'react-router-dom'

class ContractHeaderView extends React.Component {

  constructor(props) {
    super(props);
    autoBind(this);
  }

  render() {

    const job = this.props.job
    const startDate = DateService.getExperienceDate(job.acceptedDate)
    const endDate = DateService.getExperienceDate(job.closedDate)

    const isEqualDates = (startDate === endDate)

    return (  
        <div className="contract__header">
            <div><a className="contract__title" href="javascript:;" onClick={(e) => this.onJobClick(job.id, e)}>{job.order.title}</a>
                {isEqualDates ? (
                  <div className="contract__term">{startDate}</div>
                ) : (
                  <div className="contract__term">{startDate} — {endDate}</div>
                )}
                
            </div>
            <div className="contract__price price">{job.order.price}<span className="rouble">₽</span></div>
            {/* <p>Данная работа оформлена в портфолио: <a href="javascript:;">Укладка плитки</a></p> */}
        </div>
    );
  }

  onJobClick(jobId, e) {
    e.preventDefault()
    this.props.history.push(`/job/${jobId}/`)
  }

}

export default withRouter(ContractHeaderView);