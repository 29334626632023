import React from 'react'

class FindMasterPrice extends React.Component {
  onSlide = (render, handle, value, un) => {
    this.props.onSliderValuesChanged(value)
  }

  render() {
    const searchCriteria = this.props.searchCriteria

    return (
      <div className='option-wrap range-slider-wrap'>
        <label htmlFor='masters-filter-price-1'>Стоимость заказа</label>
        <div className='range-slider range-slider--with-select'>
          <div className='range-slider__data'>
            <div className='range-slider-inputs'>
              <div className='input-wrap'>
                <input id='masters-filter-price-1' name='masters-filter-price-1' type='number' value={searchCriteria.findMinPrice} onChange={this.props.onMinPriceChanged} />
              </div>
              <div className='input-wrap'>
                <input id='masters-filter-price-2' name='masters-filter-price-2' type='number' value={searchCriteria.findMaxPrice} onChange={this.props.onMaxPriceChanged} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default FindMasterPrice
