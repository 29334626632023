import React from 'react'

import SvgIcon from '../../../common/SvgIcon'

class JobClosedSidebarView extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <div className="order__sidebar stick-sidebar-wrap">
                <div className="guarantor">
                    <div className="guarantor__icon-wrap">
                        <div className="order-promo__is-active">
                            <SvgIcon name='icon-checked' />
                            {this.props.jobInfo.order.permissions.isClient ? (
                                <div className="guarantor__desc">
                                    Заказ выполнен, но вы всегда можете создать новый! Спасибо, что пользуетесь нашим сервисом :)
                                    </div>
                            ) : (
                                    <div className="guarantor__desc">
                                        Заказ выполнен, но вы всегда можете найти новый! Спасибо, что пользуетесь нашим сервисом :)
                                    </div>
                                )}

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default JobClosedSidebarView