import _ from 'lodash';
import CategoryVuuteParser from '../parsers/CategoryVuuteParser';

import JSONParser from '../../common/parsers/JSONParser'

class CategoriesVuuteParser extends JSONParser {
    parse() {
        let data = super.parse()
        if (data === null) { return null }

        let categories = []
        _.forEach(this.data, function(categoryData) {
            let category = new CategoryVuuteParser(categoryData).parse()
            categories.push(category)
        });
        

        return categories
    }
}

export default CategoriesVuuteParser;