import React from 'react'

import autoBind from 'react-autobind';
import { connect } from 'react-redux';

import EditButton from '../../common/EditButton'
import Exp from './Exp'
import ExpEdit from './ExpEdit'

import * as profileActions from '../../store/profile/actions';
import * as profileSelectors from '../../store/profile/reducer';
import AuthService from '../../services/AuthService';

//TODO: - REFACTOR -> make it as a container

class Experience extends React.Component {
  
  constructor(props) {
    super(props);
    autoBind(this);
  }

  render() {

    let isMyProfile = AuthService.shared().isMyProfile()

    return ( 
      <section className={this.props.isExperienceEditMode ? "master-experience page-section js-live-edit-wrap is-editable" : "master-experience page-section js-live-edit-wrap"}>
        <header className="config-header">
          <h2 className="page-section-title">Опыт работы</h2>
          {isMyProfile ? [(!this.props.isExperienceEditMode ? <EditButton onClick={this.onEditClick}/> : '')] : ''}
        </header>
        {this.props.isExperienceEditMode ? (
          <ExpEdit isEdit={this.isHandleClick}/>
        ) : (
          <Exp/>
        )}     
      </section>
    );
  }

  onEditClick(event) {
    event.preventDefault();
    this.props.dispatch(profileActions.experienceEditStateChanged(!this.props.isExperienceEditMode))
  }
}

// export default ClientProfileInfoContainer
function mapStateToProps(state) {
  const isExperienceEditMode = profileSelectors.isExperienceEditModeEnabled(state);
  return {
      isExperienceEditMode
  }
}

export default connect(mapStateToProps)(Experience);