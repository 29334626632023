import React from 'react'

import autoBind from 'react-autobind';
import { connect } from 'react-redux';

import ExpItem from '../components/ExpItem'

import * as profileActions from '../../store/profile/actions';
import * as profileSelectors from '../../store/profile/reducer';
import AuthService from '../../services/AuthService';

//TODO: - REFACTOR -> make it as a container

class Exp extends React.Component {

  constructor(props) {
    super(props);
    autoBind(this);
  }

  createExperienceTable() {

    let table = []

    if (this.props.profile == undefined) {
        return table
    }
    
    const experiences = this.props.profile.experience
    

    experiences.forEach(element => {
        table.push(<ExpItem experience={element} />)
    });

    return table
  }

  render() {

    let isMyProfile = AuthService.shared().isMyProfile()

    var isEmpty = true
    if (this.props.profile == undefined) {
        isEmpty = false
    } else {
        if (this.props.profile.experience) {
            const experiences = this.props.profile.experience
            if (experiences.length == 0) {
                isEmpty = true
            } else {
                isEmpty = false
            }
        }
    }

    return (  
      <React.Fragment>
        {isEmpty ? (
          <div className="empty-data">
            { isMyProfile ?
              (<p>Пожалуйста, укажите свой опыт работы. Это позволит заказчикам оценить Ваш профессиональный уровень.</p>)
              :
              (<p>Мастер не указал свой опыт работы.</p>)
            }
            
          </div>
        ) : (
            <div className="experience not-editable">
              {this.createExperienceTable()}
            </div>
          )
        }
      </React.Fragment>

      
    );
  }
}

// export default Exp
function mapStateToProps(state) {
  const profile = profileSelectors.getProfile(state);
  return {
    profile,
  }
}

export default connect(mapStateToProps)(Exp);