import React from 'react'

import Rating from 'react-rating'
import SvgIcon from './SvgIcon'

class Rate extends React.Component {
  constructor(props) {
    super(props);
}

  render() {

    let roundedRating = this.props.rating.toFixed(2)

    return (  
      <div className='rating-visual'>
        <Rating
          start={0}
          stop={5}
          step={1}
          className='rating-visual__item'
          emptySymbol={<SvgIcon name='icon-star'/>}
          fullSymbol={<SvgIcon name='icon-star-filled'/>}
          onChange={(rate) => this.props.onRateChanged(rate)}
          initialRating={roundedRating}
          readonly={this.props.isReadOnly}
        />
        {this.props.isShow ? <div className="rating-value">{roundedRating}</div> : ''}
      </div>
    );
  }
}

export default Rate