import React from 'react';

import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import InputMask from 'react-input-mask';

import * as settingsActions from '../../store/settings/actions';
import * as settingsSelectors from '../../store/settings/reducer';

import LoginMsgBox from '../../user/components/LoginMsgBox';

export class SettingsContactInfo extends React.Component {
    constructor(props) {
        super(props);

        autoBind(this);
    }

    componentDidMount() {
        this.props.dispatch(settingsActions.getEmailInfo())
    }

    render() {
        let email = ''
        if (this.props.contactInfo.email) {
            email = this.props.contactInfo.email
        }

        let error = this.props.contactInfo.error

        return (<form className="settings-section settings-frm ajax-form" id="contact">
            <div className="settings-frm__main">
                <h2 className="settings-section__title">Почтовый адрес для связи</h2>

                {(error !== undefined) ? <LoginMsgBox msgText={error.message} msgType={"error-msg"} /> : ''}

                <div className="option-wrap">
                    <input
                        className="js-data-input"
                        id="order-short-title"
                        placeholder="email@example.com"
                        type="email"
                        name="order-short-title"
                        value={email}
                        onChange={this.onEmailChanged}>
                    </input>
                </div>
            </div>
            <div className="settings-frm__sidebar">
                <button className="btn--1" type="submit" onClick={this.onChangeEmailButtonClick}>Сохранить</button>
            </div>
        </form>);
    }

    onEmailChanged(event) {
        event.preventDefault()
        this.props.dispatch(settingsActions.changedEmail(event.target.value))
    }

    onChangeEmailButtonClick(event) {
        event.preventDefault()
        this.props.dispatch(settingsActions.clearContactInfoSettingsErrors())
        this.props.dispatch(settingsActions.changeEmail(this.props.contactInfo.email))
    }

}

function mapStateToProps(state) {
    const contactInfo = settingsSelectors.getContactInfo(state)

    return {
        contactInfo
    }
}

export default withRouter(connect(mapStateToProps)(SettingsContactInfo));