import _ from 'lodash';

class AuthService {

    static _authService = null

    static shared() {
        if (AuthService._authService === null) {
            AuthService._authService = new AuthService()
        }
        return AuthService._authService
    }

    /// log in user to browser localStorage
    saveCredentials(user, token) {
        localStorage.setItem('user', JSON.stringify(user))
        localStorage.setItem("token", token)
    }


    /// logout user from browser localStorage
    clearCredentials() {
        localStorage.removeItem("user")
        localStorage.removeItem("token")
        localStorage.removeItem("profile_type")
        localStorage.removeItem("profile_photo")
        localStorage.removeItem('profile_bio')
    }

    /// return user token from browser localStorage
    getToken() {
        return localStorage.getItem('token')
    }

    /// return user object from browser localStorage
    getUser() {
        const userInStorage = localStorage.getItem('user')
        let user = null
        if (userInStorage != null) {
            user = JSON.parse(userInStorage)
        }
        return user
    }

    /// update user object in browser localStorage
    updateUser(user) {
        localStorage.setItem('user', JSON.stringify(user))
    }

    /// check does user is verified and does token exist
    isCorrectUserExist() {

        const userInStorage = localStorage.getItem('user')

        let user = null
        if (userInStorage != null) {
            user = JSON.parse(userInStorage)
        }
         
        const token = localStorage.getItem('token')

        if ((token == null) || (user == null)) {
            return false
        } else {
            return user.isVerified
        }
    }

    /// save profile bio
    saveProfileBio(bio) {
        localStorage.setItem('profile_bio', JSON.stringify(bio))
    }

    /// return current profile bio
    getProfileBio() {
        const profileInStorage = localStorage.getItem('profile_bio')
        let profileBio = null
        if (profileInStorage != null) {
            profileBio = JSON.parse(profileInStorage)
        }
        return profileBio
    }

    /// check does current user own loaded profile or not
    isMyProfile() {
        // get window url path
        const path = window.location.pathname

        // split by /
        let pathArray = path.split('/')

        // filter empty str and profile path
        _.remove(pathArray, function(item) {
            return _.indexOf(['', 'profile'], item) !== -1
        })

        var urlId
        // get first number -> it's profile id
        if (pathArray.length > 0) {
            if (!isNaN(pathArray[0])) {
                urlId = parseInt(pathArray[0])
            } else {
                return false
            }
        } else {
            return true
        }
        
        const savedProfileId = this.getProfileBio().id

        if (urlId === undefined) {
            return true
        }

        if (savedProfileId) {
            if (savedProfileId === urlId) {
                return true
            }
        }

        return false
    }

}

export default AuthService