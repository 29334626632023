import JSONParser from '../../common/parsers/JSONParser'
import _ from 'lodash';

class TokenParser extends JSONParser {
    parse() {
        super.parse()
        return _.get(this.data, 'token')
    }
}

export default TokenParser;