import React, { Component } from "react"
import CookieConsent from "react-cookie-consent";

import IconClose from './IconClose'

class CookiePopup extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <CookieConsent
                debug={false}
                contentStyle={{ maxWidth: "1280px", margin: "auto" }}
                style={{ background: "#f9f7f2", color: "black", minHeight: "100px" }}
                ButtonComponent={IconClose}
                >
                Мы используем файлы cookie для повышения удобства работы с сайтом. В cookie содержатся данные о Ваших прошлых посещениях сайта.
                Если Вы не хотите, чтобы эти данные обрабатывались, отключите cookie в настройках браузера.
            </CookieConsent>
        )
    }
}

export default CookiePopup

// <CookieConsent
//     debug={true}
//     // disableStyles={true}
//     style={{background: "#f9f7f2", color:"black", minHeight: "100px"}}
//     disableButtonStyles={true}
//     // buttonClasses="main-promo__btn add-order-btn btn--2 btn--lg js-open-form"
//     // buttonText="Всё понятно!"
// >
//     Мы используем файлы cookie для повышения удобства работы с сайтом. В cookie содержатся данные о Ваших прошлых посещениях сайта. Если Вы не хотите, чтобы эти данные обрабатывались, отключите cookie в настройках браузера
    
//     <div className="fancybox-inner">
//         <div className="fancybox-stage">
//                 <button data-fancybox-close className="fancybox-close-small" title="Закрыть" onClick={this.props.onCloseConfirmationCloseClick}>
//                     <SvgIcon name='icon-close' />
//                 </button>
//                 <form className="popup-frm">
//                     <div className="popup__inner">
//                         <p>Мы используем файлы cookie для повышения удобства работы с сайтом. В cookie содержатся данные о Ваших прошлых посещениях сайта. Если Вы не хотите, чтобы эти данные обрабатывались, отключите cookie в настройках браузера.</p>
//                     </div>
//                 </form>
//         </div>
//     </div>
// </CookieConsent>