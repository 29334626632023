import Immutable from 'seamless-immutable';
import * as registerTypes from './actionTypes';

const initialState = Immutable({
    name: '',
    phoneNumber: '',
    password: '',
    confirmPassword: '',
    profileType: 'CLIENT',
    token: '',
    user: undefined,
    error: undefined,
})

export default function reduce(state = initialState, action = {}) {

    switch(action.type) {
        case registerTypes.NAME_CHANGED:
            return state.merge({
                name: action.name
            });
        case registerTypes.PHONE_CHANGED:
            return state.merge({
                phoneNumber: action.phoneNumber
            });
        case registerTypes.PASSWORD_CHANGED:
            return state.merge({
                password: action.password
            });
        case registerTypes.CONFIRM_PASSWORD_CHANGED:
            return state.merge({
                confirmPassword: action.confirmPassword
            });
        case registerTypes.PROFILE_TYPE_CHANGED:
            return state.merge({
                profileType: action.profileType
            });  
        case registerTypes.REGISTER_CLICKED:
            return state.merge({
                token: action.token,
                user: action.user,
                error: action.error,
            });
        
        case registerTypes.CLEAR_REGISTER_FORM_ERRORS:
            return state.merge({
                error: undefined
            })
        case registerTypes.CLEAR_REGISTER_FORM:
            return initialState
        default:
            return state;
    }
}


//selectors
export function getUserFormData(state) {
    return [state.register.name, 
            state.register.phoneNumber, 
            state.register.password, 
            state.register.confirmPassword, 
            state.register.profileType];
}

export function getUser(state) {
    return [state.register.user, state.register.token, state.register.error];
}
export function getError(state) {
    return state.register.error
}