import _ from 'lodash';
import * as notificationTypes from './actionTypes';
import apiService from '../../services/APIService';


export function getNotifications() {
    return async (dispatch) => {
        try {
            const notificationsData = await apiService.getNotifications()
            dispatch({ type: notificationTypes.GET_NOTIFICATIONS, unread: notificationsData.unread, notifications: notificationsData.notifications, error: notificationsData.error })
        } catch (error) {
            console.log(error)
        }
    }
}

export function readAllNotifications(noticationId) {
    return async (dispatch) => {
        try {
            const notification = await apiService.readAllNotifications()
            dispatch({ type: notificationTypes.READ_ALL_NOTIFICATIONS })

        } catch (error) {
            console.log(error)
        }
    }
}

export function readSingleNotification(noticationId) {
    return async (dispatch) => {
        try {
            const notificationId = await apiService.readSingleNotification(noticationId)
            dispatch({ type: notificationTypes.READ_SINGLE_NOTIFICATION, notificationId: notificationId })
        } catch (error) {
            console.log(error)
        }
    }
}

export function changeCurrentPage(newCurrentPage) {
    return ({ type: notificationTypes.CHANGE_CURRENT_PAGE, newCurrentPage: newCurrentPage })
}