import React, {Component} from 'react'
import AdvGrid from './advGrid/AdvGrid'
import AdvBottomBlock from './advBottomBlock/AdvBottomBlock'
import bottomArticle from '../data/bottomArticle'

class Advantages extends Component {
    render () {
        return (
            <div className="excellence bg-block">
                <div className="container">
                    <section className="main-page-section">
                        <h2 className="main-page-section__title">Наши преимущества</h2>
                        <AdvGrid/>
                        <AdvBottomBlock article = {bottomArticle[0]}/>
                    </section>
                </div>
            </div>
        )
    }
} 

export default Advantages