import React from 'react';

import CountryList from './CountryList';
import CityList from './CityList';
import EducationsObjectsList from './EducationsObjectsList';

import SvgIcon from '../../common/SvgIcon';

import LoginMsgBox from '../../user/components/LoginMsgBox';
import EduCalendar from '../../common/EduCalendar';
import '../../css/blocks/caret/_hidden/caret_hidden.css';

class EducationEditForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			speciality: {
				value: null,
			},
			dateStarted: {
				value: null,
			},
			dateFinished: {
				value: null,
			},
			currentYear: new Date().getFullYear(),
		};
	}

	handleYearChanging = (e, yearSetterFunc, isFirstYear) => {
		const year = e.getFullYear();
		const { currentYear, dateStarted, dateFinished } = this.state;
		const { value: firstYear } = dateStarted;
		const { value: lastYear } = dateFinished;
		if (year > currentYear) {
			// Если год > текущего года
			return;
		}
		if ((isFirstYear && lastYear) || (firstYear && !isFirstYear)) {
			// Если обрабатываем первый год обучения и оба года указаны
			if (isFirstYear) {
				if (year > lastYear.getFullYear()) {
					// Если год начала > года окончания
					return;
				}
			} else if (year < firstYear.getFullYear()) {
				// Если год окончания < года начала
				return;
			}
		}
		yearSetterFunc(e);
	};

	render() {
		const {
			errorEducation,
			availableCountries,
			selectedCountry,
			onCountryChanged,
			availableCities,
			selectedCity,
			onCityChanged,
			availableEducationsObjects,
			selectedEducationObject,
			onEducationObjectChanged,
			onSpecialityChanged,
			onEducationCloseClick,
			newEducationObject: newEducation,
			orderNumber,
			onStartYearChanged,
			onEndYearChanged,
			onEducationSaveClick,
		} = this.props;

		const { dateFinished, dateStarted, speciality } = this.state;

		if (newEducation) {
			if (newEducation.speciality) {
				speciality.value = newEducation.speciality;
			}

			if (newEducation.dateStarted) {
				dateStarted.value = new Date(newEducation.dateStarted);
			}

			if (newEducation.dateFinished) {
				dateFinished.value = new Date(newEducation.dateFinished);
			}
		}

		return (
			<div
				className='fancybox-container fancybox-is-open'
				role='dialog'
				tabIndex={-1}
				id='fancybox-container-11'
				style={{
					transitionDuration: '350ms',
				}}
				data-select2-id='fancybox-container-11'
			>
				<div className='fancybox-bg' />
				<div className='fancybox-inner' data-select2-id={26}>
					<div className='fancybox-infobar'>
						<span data-fancybox-index>1</span>
						&nbsp;/&nbsp;
						<span data-fancybox-count>1</span>
					</div>
					<div className='fancybox-toolbar compensate-for-scrollbar'>
						<button
							data-fancybox-zoom
							className='fancybox-button fancybox-button--zoom'
							title='Zoom'
							disabled
							type='button'
						>
							<svg viewBox='0 0 40 40'>
								<path d='M18,17 m-8,0 a8,8 0 1,0 16,0 a8,8 0 1,0 -16,0 M24,22 L31,29' />
							</svg>
						</button>
						<button
							type='button'
							data-fancybox-thumbs
							className='fancybox-button fancybox-button--thumbs'
							title='Thumbnails'
							style={{
								display: 'none',
							}}
						>
							<svg viewBox='0 0 120 120'>
								<path d='M30,30 h14 v14 h-14 Z M50,30 h14 v14 h-14 Z M70,30 h14 v14 h-14 Z M30,50 h14 v14 h-14 Z M50,50 h14 v14 h-14 Z M70,50 h14 v14 h-14 Z M30,70 h14 v14 h-14 Z M50,70 h14 v14 h-14 Z M70,70 h14 v14 h-14 Z' />
							</svg>
						</button>
						<button
							type='button'
							data-fancybox-close
							className='fancybox-button fancybox-button--close'
							title='Закрыть'
							onClick={onEducationCloseClick}
						>
							<SvgIcon name='icon-close' />
						</button>
					</div>
					<div className='fancybox-navigation'>
						<a
							data-fancybox-prev
							className='fancybox-button fancybox-button--arrow_left disabled'
							title='Previous'
							href='javascript:;'
						>
							<svg viewBox='0 0 40 40'>
								<path d='M18,12 L10,20 L18,28 M10,20 L30,20' />
							</svg>
						</a>
						<a
							data-fancybox-next
							className='fancybox-button fancybox-button--arrow_right disabled'
							title='Next'
							href='javascript:;'
						>
							<svg viewBox='0 0 40 40'>
								<path d='M10,20 L30,20 M22,12 L30,20 L22,28' />
							</svg>
						</a>
					</div>
					<div className='fancybox-stage'>
						<div
							className='fancybox-slide popup-form fancybox-slide--html fancybox-slide--current fancybox-slide--complete'
							data-select2-id={25}
						>
							<div
								className='popup popup--md2 fancybox-content'
								id='master-profile-portfolio'
								style={{
									display: 'inline-block',
									overflow: 'visible',
								}}
							>
								<button
									type='button'
									data-fancybox-close
									className='fancybox-close-small'
									title='Закрыть'
									onClick={onEducationCloseClick}
								>
									<SvgIcon name='icon-close' />
								</button>
								<div className='popup__title'>
									<span>Образование</span>
								</div>
								<form className='popup-frm'>
									<div className='popup__inner'>
										{errorEducation !== undefined ? (
											<LoginMsgBox
												msgText={errorEducation.message}
												msgType='error-msg'
											/>
										) : (
											''
										)}
										<div className='input-holder'>
											<CountryList
												availableCountries={availableCountries}
												selectedCountry={selectedCountry}
												onCountryChanged={onCountryChanged}
											/>
										</div>
										<div className='input-holder'>
											<CityList
												availableCities={availableCities}
												selectedCity={selectedCity}
												onCityChanged={onCityChanged}
											/>
										</div>
										<div className='input-holder'>
											<EducationsObjectsList
												availableEducationsObjects={availableEducationsObjects}
												selectedEducationObject={selectedEducationObject}
												onEducationObjectChanged={onEducationObjectChanged}
											/>
										</div>
										<div className='input-holder'>
											<input
												type='text'
												placeholder='Специальность'
												name='master-portfolio-title'
												value={speciality.value}
												onChange={onSpecialityChanged}
											/>
										</div>
										<div className='input-wrap'>
											<label htmlFor='exp-date-from-1'>Года обучения</label>
											<div className='double-input caret caret_hidden'>
												<EduCalendar
													orderNumber={orderNumber}
													selectedDate={dateStarted.value}
													onChange={(e) => {
														this.handleYearChanging(
															e,
															onStartYearChanged,
															true
														);
													}}
												/>
												<EduCalendar
													orderNumber={orderNumber}
													selectedDate={dateFinished.value}
													onChange={(e) => {
														this.handleYearChanging(e, onEndYearChanged, false);
													}}
												/>
											</div>
										</div>
									</div>
									<button
										aria-label='Save'
										className='popup-frm__submit close-popup btn--2 popup__wide-btn'
										type='button'
										onClick={onEducationSaveClick}
									>
										Сохранить
									</button>
								</form>
							</div>
						</div>
					</div>
					<div className='fancybox-caption' />
				</div>
			</div>
		);
	}
}

export default EducationEditForm;
