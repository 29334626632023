import _ from 'lodash';
import ProfileExperience from '../model/ProfileExperience';

import JSONParser from '../../common/parsers/JSONParser'

class ProfileExperienceParser extends JSONParser {
    parse() {
        super.parse()
        let profileExperience = new ProfileExperience(_.get(this.data, 'id'), 
                                                    _.get(this.data, 'company_name'), 
                                                    _.get(this.data, 'position'),
                                                    _.get(this.data, 'date_started'),
                                                    _.get(this.data, 'date_finished'))
        return profileExperience
    }
}

export default ProfileExperienceParser;