import React from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { Element, Events, scroller } from 'react-scroll';
import { withRouter } from 'react-router-dom';

import Menu from '../../page/components/Menu';
import { menuItem } from '../../page/components/MenuEnum';
import MessagesContainerView from '../components/messagesView/MessagesContainerView';
import RoomListView from '../components/roomListView/RoomListView';
import Footer from '../../page/components/Footer';
import Popup from '../../jobs/components/popups/Popup';

import * as chatSelectors from '../../store/chat/reducer';
import * as chatActions from '../../store/chat/actions';

import * as menuActions from '../../store/menu/actions';

import * as jobSelectors from '../../store/job/reducer';
import * as jobActions from '../../store/job/actions';

import WSService from '../../services/WSService';

/**
 * React-Redux Chat container which implement all chat block logic
 *
 * @class ChatContainer
 * @extends {React.Component}
 */
//TODO: Refactor this class for function component

class ChatContainer extends React.Component {
	/**
	 * Creates an instance of ChatContainer.
	 * @param {*} props
	 * @memberof ChatContainer
	 */
	constructor(props) {
		super(props);
		autoBind(this);

		this.state = {
			chatsAndSeparators: [],
			setChatsAndSeparators: (array) => {
				this.state.chatsAndSeparators = array
			},
			MessagesContainerHeight: '',
			selectedChat: {
				id: '',
				orderNumber: ''
			}
		};
	}

	// MARK: React.Component lifecycle
	/** React.Component lifecycle */
	/**
	 * Fetch chats when view loading
	 *
	 * @memberof ChatContainer
	 */

	componentWillMount() {
		this.props.dispatch(menuActions.selectMenuItem(menuItem.CHAT));
		this.props.dispatch(chatActions.deselectChat());
		this.props.dispatch(chatActions.getChats());
	}

	componentWillUnmount() {
	}

	/**
	 * Handle chat selection by user
	 *
	 * @param {*} formattedChat - selected chat id
	 * @param {*} event
	 * @memberof ChatContainer
	 */
	 onChatItemSelectClick(clickedChatData, event) {
		//  debugger
		const activeChatSelector = 'messages-contacts-item--active'
		const chatElementSelecotr = 'messages-contacts-item'
		const removeSelectedMark = () => {
			const messagesContainerSelector = 'messages__sidebar'
			const ChatElements = Array.from(event.target.closest(`.${messagesContainerSelector}`).querySelectorAll('.messages-contacts-item'))
			const selectedChat = ChatElements.find((chat => chat.classList.value.includes(activeChatSelector)))
			if (selectedChat) selectedChat.classList.remove(activeChatSelector)
		};
		const addSelectedMark = (chatId) => {
			if (!chatId) return
			const chatElement = event.target.closest(`.${chatElementSelecotr}`)
			chatElement.classList.add(activeChatSelector)
		}	
		//mark chat as selected
		this.props.dispatch(chatActions.selectChat(clickedChatData.id));
		removeSelectedMark()
		if (this.state.selectedChat.id !== clickedChatData.id) {
			// if clicked chat is not the previous chat
			this.state.selectedChat.id = clickedChatData.id
			//fetch messages for selected chat
		this.props.dispatch(
			chatActions.getMessages(clickedChatData.id, 6, 0, undefined)
		);
		//open websocket for this chat room
		WSService.shared().configureChatRoomClient(clickedChatData.id);
		} 	
		addSelectedMark(this.state.selectedChat.id)

	}

	render() {
		return (
			<>
				<Element id='chat_page'>
					<div className='page'>
						<Menu />
						<div className='content-holder'>
							<div className='messages'>
								<div className='messages__inner'>
									<MessagesContainerView
										selectedChat={this.props.selectedChat}
										messages={this.props.messages}
										limitOffset={this.props.messagesLimitOffset}
										onNewMessageChanged={this.onNewMessageChanged}
										onLoadMoreMessages={this.onLoadMoreMessages}
										updateFirstMessageId={this.updateFirstMessageId}
										firstMessageId ={this.props.firstMessageId}
										messagesAllMessagesLoaded = {this.props.messagesAllMessagesLoaded}
										onBidConfirmationClick={this.onBidConfirmationClick}
										onBidRejectClick={this.onBidRejectClick}
										onJobClick={this.onJobClick}
									/>
									<RoomListView
										setChatsAndSeparators={this.state.setChatsAndSeparators}
										messageViewHeight={this.state.MessagesContainerHeight}
										chats={this.props.chats}
										selectedChat={this.props.selectedChat}
										onChatItemSelectClick={this.onChatItemSelectClick}
									/>
								</div>
							</div>
						</div>
						<Footer />
					</div>
				</Element>
				<>
					{this.props.isBidJobConfirmationPopupOpen && (
						<Popup
							title={`Выбрать мастера`}
							question={`Выбрать мастера на выполнение заказа?`}
							onClose={this.onMasterConfirmationCloseClick}
							onSubmit={this.onMasterConfirmationAcceptClick}
						/>
					)}
				</>
				<>
					{this.props.isRejectedJobConfirmationPopupOpen && (
						<Popup
							title={`Отклонить заявку`}
							question={`Отклонить заявку?`}
							submitBtnText={'Отклонить'}
							onClose={this.onRejectConfirmationCloseClick}
							onSubmit={this.onRejectConfirmationAcceptClick}
						/>
					)}
				</>
			</>
		);
	}

	// MARK: Actions and events

	/**
	 * Handle chat selection by user
	 *
	 * @param {*} formattedChat - selected chat id
	 * @param {*} event
	 * @memberof ChatContainer
	 */
	onChatItemSelectClick(formattedChat, event) {
		//get chat number
		const selectedChatId = formattedChat.formattedChat;

		//mark chat as selected
		this.props.dispatch(chatActions.selectChat(selectedChatId));
		//fetch messages for selected chat
		this.props.dispatch(
			chatActions.getMessages(selectedChatId, 6, 0, undefined)
		);

		//open websocket for this chat room
		WSService.shared().configureChatRoomClient(selectedChatId);
	}

	onLoadMoreMessages() {
		if (this.props.messagesAllMessagesLoaded == true) {
			return;
		}
		var selectedChat = this.props.selectedChat;
		this.props.dispatch(
			chatActions.getMessages(
				selectedChat.id,
				this.props.messagesLimitOffset[1],
				this.props.messagesLimitOffset[0],
				this.props.messagesInitialDate
			)
		);
	}

	/**
	 * Handle users new message sending
	 *
	 * @param {*} msg - msg object
	 * @param {*} event
	 * @memberof ChatContainer
	 */
	onNewMessageChanged(msg, event) {
		event.preventDefault();
		let messageToSocket = `{"text": "${msg.nM}"}`;
		WSService.shared().sendMessageToRoomSocket(messageToSocket);
	}

	/**
	 * TODO: FINISH IT
	 *
	 * @memberof ChatContainer
	 */
	onBidConfirmationClick() {
		this.props.dispatch(jobActions.bidJobConfirmationOpen());
	}

	onBidRejectClick() {
		this.props.dispatch(jobActions.bidJobRejectOpen());
	}

	/**
	 * TODO: FINISH IT
	 *
	 * @memberof ChatContainer
	 */
	onMasterConfirmationCloseClick() {
		this.props.dispatch(jobActions.bidJobConfirmationClose());
	}

	/**
	 * TODO: FINISH IT
	 *
	 * @memberof ChatContainer
	 */
	async onMasterConfirmationAcceptClick(event) {
		event.preventDefault();
		const jobId = this.props.selectedChat.job.id;
		await this.props
			.dispatch(jobActions.bidJobConfirmationAccept(jobId))
			.then(() => {
				window.location.reload();
			});
	}

	onRejectConfirmationCloseClick() {
		this.props.dispatch(jobActions.bidJobRejectClose());
	}

	async onRejectConfirmationAcceptClick(event) {
		event.preventDefault();
		const jobId = this.props.selectedChat.job.id;
		await this.props
			.dispatch(jobActions.rejectConfirmationAccept(jobId))
			.then(() => {
				window.location.reload();
			});
	}

	onJobClick(jobId, event) {
		event.preventDefault();
		this.props.history.push(`job/${jobId}`);
	}
	updateFirstMessageId(id)
	{
		this.props.dispatch(chatActions.setFirstMessageId(id));
	}
}

// export default ClientProfileInfoContainer
function mapStateToProps(state) {
	const messagesLimitOffset = chatSelectors.getMessagesOffsetLimit(state);
	const messagesInitialDate =
		chatSelectors.getMessagesRequestInitialDatetime(state);
	const messagesAllMessagesLoaded = chatSelectors.getAllMessagesLoaded(state);

	const chats = chatSelectors.getChats(state);
	const selectedChat = chatSelectors.getSelectedChat(state);
	const messages = chatSelectors.getMessages(state);
	const firstMessageId = chatSelectors.getFirstMessageId(state);
	const [
		isBidJobConfirmationPopupOpen,
		isRejectedJobConfirmationPopupOpen,
		isOnReviewJobConfirmationPopupOpen,
		isReturnJobConfirmationPopupOpen,
		isCloseJobConfirmationPopupOpen,
	] = jobSelectors.getCurrentJobPopupState(state);
	return {
		chats,
		selectedChat,
		messages,
		messagesLimitOffset,
		messagesInitialDate,
		messagesAllMessagesLoaded,
		isBidJobConfirmationPopupOpen,
		isRejectedJobConfirmationPopupOpen,
		isOnReviewJobConfirmationPopupOpen,
		isReturnJobConfirmationPopupOpen,
		isCloseJobConfirmationPopupOpen,
		firstMessageId,
	};
}

export default withRouter(connect(mapStateToProps)(ChatContainer));
