import React from 'react'

import CheckOrderHeaderView from './CheckOrderHeaderView'
import OrderAdditionalContent from './OrderAdditionalContentView'

class CheckOrderContentView extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {

    // check does additional content exist
    var isAdditionalContentAvailable = false
    if (this.props.orderInfo.geolocation) {
      if (this.props.orderInfo.geolocation.latitude == 0 && this.props.orderInfo.geolocation.longitude == 0) {
        isAdditionalContentAvailable = false
      } else {
        isAdditionalContentAvailable = true
      }
    }


    return (
      <div className="order__job">
        <CheckOrderHeaderView orderInfo={this.props.orderInfo} />
        <div className="job__info">
          {this.props.orderInfo.description ? (
            <p>{this.props.orderInfo.description}</p>
          ) : (
              <p>Описание заказа пока отсутствует.</p>
            )}
        </div>
        {isAdditionalContentAvailable && 
          <OrderAdditionalContent orderInfo={this.props.orderInfo} isEditable={this.props.isEditable} />
        }
      </div>
    );
  }
}

export default CheckOrderContentView