class Review {
    id;
    owner;
    rate;
    reviewText;
    qualityRate;
    deadlineRate;
    communitationRate;
    createdDate;

    constructor(id, owner, rate, reviewText, qualityRate, deadlineRate, communitationRate, createdDate) {
        this.id = id
        this.owner = owner
        this.rate = rate
        this.reviewText = reviewText
        this.qualityRate = qualityRate
        this.deadlineRate = deadlineRate
        this.communitationRate = communitationRate
        this.createdDate = createdDate
    }
}

export default Review;