import React from 'react'

import FindMasterSelect from './FindMasterSelect'
import FindOrderItem from './FindOrderItem'
import SearchPageCatalog from '../../common/SearchPageCatalog'

class FindOrderResults extends React.Component {


    createOrderResultsTable() {

        let table = []
    
        const results = this.props.results[0]
    
        var counter = 0
    
        results.forEach(element => {
            table.push(<FindOrderItem 
                order={element} 
                orderNumber={counter}
                onOrderItemClick={this.props.onOrderItemClick}
                onProfileItemClick={this.props.onProfileItemClick}/>)
            
            counter = counter + 1
    
        });
    
        return table
    }



    render() {

        const results = this.props.results[0]
        const showMoreIsHidden = this.props.results[1]
        const searchCriteria = this.props.searchCriteria

        return (
            <div className="catalog">
                <div className="catalog__inner">
                    <div className="catalog__main">
                        <div className="catalog__sort" style={{ justifyContent: 'space-between' }}>
                            <div className="search-result">
                                {results.length} заказов найдено
                            </div>
                            <FindMasterSelect findSortListItems={this.props.findSortListItems}
                                        selectedSortId={searchCriteria.findOrderBy}
                                        onSortChanged={(categoryId) =>
                                            {
                                                new Promise(async(resolve)=>{
                                                    resolve(this.props.onSortChanged(categoryId))
                                                }).then(()=>{
                                                    const event=new Event('click')
                                                    this.props.onApplyFiltersClick(event)
                                                })
                                }}
                            />
                        </div>
                        <div class="catalog-list">
                            {this.createOrderResultsTable()}
                        </div>

                        {!showMoreIsHidden ?
                            <button className="back-link btn--1" type="submit" onClick={this.props.onLoadMoreClicked}>ДАЛЕЕ</button>
                            :
                            ''
                        }
                        
                    </div>
                    <SearchPageCatalog 
                        searchCriteria={this.props.searchCriteria}
                        categories={this.props.categories}
                        onCategoryChanged={(categoryId)=>{
                            new Promise(async(resolve)=>{
                                resolve(this.props.onCategoryChanged(categoryId))
                            }).then(()=>{
                                const event=new Event('click')
                                this.props.onApplyFiltersClick(event)
                            })
                           
                         
                            }} />
                </div>
            </div>
        );
    }
}

export default FindOrderResults