import React from 'react'

import InlineGalary from '../../common/inlineGalary'
import Grade from '../../common/Grade'


class ReviewFooter extends React.Component {

  render() {
    return (  
        <div className="review__footer">
            {/* <InlineGalary/> */}
            <Grade review={this.props.review}/>
        </div>
    );
  }
}

export default ReviewFooter