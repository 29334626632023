import React from 'react'

import JobUserSidebar from './JobUserInfoSidebarView'
import SvgIcon from '../../../common/SvgIcon'

class MatchingSidebar extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="order__sidebar stick-sidebar-wrap">
                {this.props.jobInfo.order.permissions.isClient ? (
                    <React.Fragment>
                        <div className="guarantor__desc">На ваш заказ откликнулся мастер </div>
                        <JobUserSidebar masterInfo={this.props.jobInfo.master} />
                        <div className="sidebar-btns">
                            <button className="btn--2 add-money-btn" type="button" onClick={this.props.onMasterConfirmationClick}>Принять заявку</button>
                        </div>
                    </React.Fragment>
                ) : (
                        <React.Fragment>
                            <div className="guarantor">
                                <div className="guarantor__icon-wrap">
                                    <div className="order-promo__is-active">
                                        <SvgIcon name='icon-list' />
                                        <div className="guarantor__desc">
                                            Заказчик рассматривает Вашу заявку.
                                            Чем выше Ваш рейтинг тем больше вероятность быть выбраным заказчиком!
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="sidebar-btns">
                                <button className="btn--2 add-money-btn" type="button" onClick={this.props.onMessageButtonClick}>Написать сообщение</button>
                            </div>
                        </React.Fragment>
                    )}
            </div>
        )
    }
}

export default MatchingSidebar