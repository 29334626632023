import React from 'react'

import DateService from '../../services/DateService'

class ExpItem extends React.Component {

  render() {

    var company = ''
    var position = ''
    var startDate = ''
    var endDate = ''

    var startDateString = ''
    var endDateString = ''
    var differenceBetweenDates = ''

    const experience = this.props.experience

    if (experience) {
      company = experience.companyName
      position = experience.position
      startDate = new Date(experience.dateStarted)

      if (startDate !== undefined)  {
        startDateString = DateService.getExperienceDate(startDate)
      }

      endDate = new Date(experience.dateFinished)
      if (endDate === undefined) {
        endDateString = 'наст. время'
      } else {
        endDateString = DateService.getExperienceDate(endDate)
      }

      differenceBetweenDates = DateService.getExperienceDifferenceBetweenDates(startDate, endDate)

    }

    return (  
        <div className="experience-item">
            <div className="experience-item__term">
                <p>{differenceBetweenDates}</p>
                <p className="light-text">{startDateString} — {endDateString}</p>
            </div>
            <div className="experience-item__source">
                <p>{company}</p>
                <p className="light-text">{position}</p>
            </div>
        </div>
    );
  }
}

export default ExpItem