import Immutable from 'seamless-immutable';
import _ from 'lodash';
import * as disputTypes from './actionTypes';

const initialState = Immutable({
	disputText: '',
	disputError: undefined,
});

export default function reduce(state = initialState, action = {}) {
	switch (action.type) {
		case disputTypes.CREATE_DISPUT:
			return state.merge({
				disputText: action.text,
				disputError: action.error,
			});
		case disputTypes.CLEAR_DISPUT_ERROR:
			return state.merge({
				disputError: undefined,
			});
		case disputTypes.CHANGE_DISPUT_TEXT:
			return state.merge({
				disputText: action.newDisputText,
			});
		default:
			return state;
	}
}

//selectors
export function getDisputText(state) {
	const reviewInfo = state.disput.text;
	return reviewInfo;
}

export function getDisputError(state) {
	return state.disput.disputError;
}
