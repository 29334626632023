import React from 'react'
import _ from 'lodash';

import autoBind from 'react-autobind';
import { connect } from 'react-redux';

import * as profileActions from '../../store/profile/actions';
import * as profileSelectors from '../../store/profile/reducer';

import { useParams, withRouter } from 'react-router-dom'
import AuthService from '../../services/AuthService';
import ClientContractItem from '../components/ClientContractItem';

function EmptyData(props) {
    let isMyProfile = AuthService.shared().isMyProfile()
    return (
        <div className="empty-data">
            { isMyProfile ?
                (<p>На данный момент у Вас нет выполненных заказов</p>)
                :
                (<p>На данный момент у клиента нет выполненных заказов.</p>)
            }

        </div>
    )
}

function ContractItemsList(props) {

    let table = []

    const contracts = props.closedJobs

    contracts.forEach(element => {
        table.push(<ClientContractItem job={element} />)
    });

    return (
        <div class="contracts-list">
            {table}
        </div>
    )
}

class ClientClosedJobs extends React.Component {

    constructor(props) {
        super(props);

        autoBind(this);
    }

    componentDidMount() {
        let urlParamId = this.props.match.params.id
        if (urlParamId === undefined) {
            urlParamId = AuthService.shared().getProfileBio().id
        }
        // let profileId = AuthService.shared().getProfileBio().id
        // todo: check -> crash
        this.props.dispatch(profileActions.getContracts(urlParamId))
    }

    render() {

        const closedJobs = this.props.jobs[2]

        return (
            <section class="active-contracts page-section">
                <header>
                    <h2 class="page-section-title">Завершённые заказы</h2>
                </header>
                { closedJobs.length > 0 ?
                    (
                        <ContractItemsList closedJobs={closedJobs} />
                    ) : (
                        <EmptyData />
                    )}

            </section>
        );
    }

}

function mapStateToProps(state) {
    const jobs = profileSelectors.getProfileJobs(state)
  return {
    jobs,
  }
}
  
export default withRouter(connect(mapStateToProps)(ClientClosedJobs));