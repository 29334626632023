import _ from 'lodash';
import * as registerTypes from './actionTypes';
import apiService from '../../services/APIService';
import AuthService from '../../services/AuthService';

export function changeName(newName) {
    return ({ type: registerTypes.NAME_CHANGED, name: newName})
}

export function changePhoneNumber(newNumber) {
    newNumber = newNumber.replace(/[^\d\+]/g, '')
    return ({ type: registerTypes.PHONE_CHANGED, phoneNumber: newNumber})
}

export function changePassword(newPassword) {
    return ({ type: registerTypes.PASSWORD_CHANGED, password: newPassword})
}

export function changeConfirmPassword(newConfirmPassword) {
    return ({ type: registerTypes.CONFIRM_PASSWORD_CHANGED, confirmPassword: newConfirmPassword})
}

export function clientTypeSelected() {
    return ({ type: registerTypes.PROFILE_TYPE_CHANGED, profileType: 'CLIENT'})
}

export function masterTypeSelected() {
    return ({ type: registerTypes.PROFILE_TYPE_CHANGED, profileType: 'MASTER'})
}

export function clearRegisterErrors() {
    return ({ type: registerTypes.CLEAR_REGISTER_FORM_ERRORS})
}

export function clearRegisterForm() {
    return ({ type: registerTypes.CLEAR_REGISTER_FORM})
}

export function register(name, phone, password, profileType) {
    return async(dispatch, getState) => {
        try {
            dispatch({type: registerTypes.REGISTER_CLICKED, 
                      token: '',
                      user: undefined,
                      error: undefined});
            const newUserData = await apiService.register(name, phone, password);

            if (newUserData.token != null) {
                AuthService.shared().saveCredentials(newUserData.user, newUserData.token)
                const profileData = await apiService.createProfile(name, profileType);
                if (profileData.profile !== null) {
                    AuthService.shared().saveProfileBio(profileData.profile.bio)
                }
            }

            dispatch({type: registerTypes.REGISTER_CLICKED, 
                      token: newUserData.token,
                      user: newUserData.user,
                      error: newUserData.error});
        } catch (error) {
            console.error(error)
        }
    }
}