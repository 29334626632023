import React from 'react'

import ClientPersonInfo from './ClientPersonInfo'
import ClientPersonInfoEdit from '../containers/ClientPersonInfoEdit'
import Sidebar from '../../common/Sidebar'

import autoBind from 'react-autobind';
import { connect } from 'react-redux';

import * as profileSelectors from '../../store/profile/reducer';

class ClientProfileInfo extends React.Component {
    
    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {
        return (
            <div className="profile-header bg-block">
                <div className="container">
                    <div className="user-card user-card--master">
                        <div className="user-card__inner">
                            {this.props.isEditMode ? (
                                <form className="user-card__main user-data-frm js-card-main js-live-edit-wrap is-editable" encType="multipart/form-data">
                                    <ClientPersonInfoEdit/> 
                                </form>                   
                            ) : (
                                <form className="user-card__main user-data-frm js-card-main js-live-edit-wrap" encType="multipart/form-data">
                                    <ClientPersonInfo/>
                                </form>
                            )}                  
                            <Sidebar isOwner={true} bio={this.props.profileBio}/> 
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


// export default ClientProfileInfoContainer
function mapStateToProps(state) {
    const isEditMode = profileSelectors.isEditModeEnabled(state);
    const profileBio = profileSelectors.getProfileBio(state);
    return {
        isEditMode,
        profileBio,
    }
}
  
export default connect(mapStateToProps)(ClientProfileInfo);