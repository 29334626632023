import _ from 'lodash';
import * as chatTypes from './actionTypes';
import apiService from '../../services/APIService';

export function getFirstMessageId() {
    return ({type: chatTypes.GET_FIRST_MESSAGE_ID})
}

export function setFirstMessageId(id) {
    return ({type: chatTypes.SET_FIRST_MESSAGE_ID, payload: id})
}

export function selectChat(id) {
    return ({type: chatTypes.SELECT_CHAT, selectedId: id})
}

export function deselectChat() {
    return ({type: chatTypes.DESELECT_CHAT})
}

export function getChats() {
    return async(dispatch, getState) => {
        try {
            const chatsData = await apiService.getChats();
            dispatch({type: chatTypes.GET_CHATS, chats: chatsData.chats, error: chatsData.error});
        } catch (error) {
            console.error(error)
        }
    }
}

export function getMessages(chatId, limit, offset, datetime) {
    return async(dispatch, getState) => {
        try {

            if (datetime == undefined) {
                datetime = new Date(new Date()).toISOString()
                var getMessagesType = chatTypes.GET_MESSAGES
            } else {
                var getMessagesType = chatTypes.LOAD_MORE_MESSAGES
            }

            const messagesData = await apiService.getMessages(chatId, limit, offset, datetime)

            dispatch({type: getMessagesType, 
                    messages: messagesData.messages,
                    limit:limit,
                    datetime: datetime,
                    error: messagesData.error});
        } catch (error) {
            console.error(error)
        }
    }
}

export function newMessageIncome(newMessage) {
    return ({type: chatTypes.NEW_MESSAGE, newMessage: newMessage})
}

export function newMessageStreamIncome(roomId, newMessage) {
    return ({type: chatTypes.NEW_MESSAGE_STREAM, roomId: roomId, newMessage: newMessage})
}

