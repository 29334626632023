import React, {Component} from 'react'

import StepItem from '../step/StepItem'

import clientStepsData from'../../data/clientStepsData'

class ClientGrid extends Component {
    render () {
        return(
            <div className="tabs-content">
                <div className="box visible">
                    <div className="steps-list">
                        <StepItem article = {clientStepsData[0]} svgName='icon-girl-1'/>
                        <StepItem article = {clientStepsData[1]} svgName='icon-girl-2'/>
                        <StepItem article = {clientStepsData[2]} svgFile='man-1'/>
                        <StepItem article = {clientStepsData[3]} svgName='icon-girl-3'/>
                    </div>
                </div>
            </div>
        )
    }
}   

export default ClientGrid