import React from 'react'

class InviteButton extends React.Component {

  render() {
    return (
        <React.Fragment>
            <a className="invite-btn btn--2" href="javascript:;">Пригласить</a>
        </React.Fragment>
    );
  }
}

export default InviteButton