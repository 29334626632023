import React from 'react'

import SvgIcon from '../../common/SvgIcon'

class CreateOrderSidebarView extends React.PureComponent {
    
    render() {
        return (
            <div className="order-frm__sidebar stick-sidebar-wrap">
                <div className="stick-sidebar">
                    {/* <h2 className="order-frm__title">Срочный заказ</h2> */}
                    <div className="guarantor">
                        <div className="order-promo__is-active">
                            <SvgIcon name='icon-girl-2' />
                        </div>
                        <div className="guarantor__desc">Ваш заказ почти создан! Проверьте правильность данных и нажмите кнопку внизу :)</div>
                    </div>
                    {/* <div className="check-wrap">
                        <input id="order-is-rush" type="checkbox" name="order-is-rush" />
                        <label className="check-label-lg" htmlFor="order-is-rush"><span />
                            <div>Срочный заказ</div>
                        </label>
                    </div> */}
                    {this.props.orderInfo ? (
                        <React.Fragment>
                            <button className="order-frm__submit btn--1" type="submit" onClick={this.props.onSaveOrderChangesClick}>Сохранить изменения</button>
                            <button className="btn--2 add-money-btn" type="button" onClick={this.props.onDiscardOrderChangesClick}>Отмена</button>
                        </React.Fragment>
                    ) : (
                            <button className="order-frm__submit btn--2" type="submit" onClick={this.props.onCreateOrderClick}>Создать заказ</button>
                        )}

                </div>
            </div>
        )
    }
}

export default CreateOrderSidebarView