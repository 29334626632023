import React from 'react';

import SvgIcon from './SvgIcon';
import noAva from '../images/no-ava.png';

class DropDownMenu extends React.Component {
	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.onMouseEnter = this.onMouseEnter.bind(this);
		this.onMouseLeave = this.onMouseLeave.bind(this);
		this.state = {
			dropdownOpen: false,
		};
	}

	toggle() {
		this.setState((prevState) => ({
			dropdownOpen: !prevState.dropdownOpen,
		}));
	}

	onMouseEnter() {
		this.setState({ dropdownOpen: true });
		this.props.closeHamburgerMenu();
	}

	onMouseLeave() {
		this.setState({ dropdownOpen: false });
	}

	render() {
		const icon_src = this.props.icon_src;
		const name = this.props.name;

		return (
			<div
				className={
					this.state.dropdownOpen ? 'user-menu-wrap active' : 'user-menu-wrap'
				}
				onMouseOver={this.onMouseEnter}
				onMouseLeave={this.onMouseLeave}
				isOpen={this.state.dropdownOpen}
				toggle={this.toggle}
			>
				<div className='user-info'>
					{icon_src ? (
						<img className='user-info__img' src={icon_src} alt='' />
					) : (
						<img className='user-info__img' src={noAva} alt='' />
					)}

					<div className='user-info__name'>{name}</div>
					<div
						className='user-info__dropdown-icon-wrapper'
						onClick={
							this.state.dropdownOpen ? this.onMouseLeave : this.onMouseEnter
						}
					>
						<SvgIcon name='icon-down' />
					</div>
				</div>
				<ul
					className={this.state.dropdownOpen ? 'user-menu opened' : 'user-menu'}
				>
					<li className='user-menu__item'>
						<a
							className='user-menu__link'
							href='javascript:;'
							onClick={this.props.onSettingsClick}
						>
							Настройки
						</a>
					</li>
					<li className='user-menu__item'>
						<a
							className='user-menu__link'
							href='javascript:;'
							onClick={this.props.onLogoutClick}
						>
							Выход
						</a>
					</li>
				</ul>
			</div>
		);
	}
}

export default DropDownMenu;
