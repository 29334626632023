class Geolocation {
    longitude;
    latitude;
    address;

    constructor(longitude, latitude, address) {
        this.longitude = longitude
        this.latitude = latitude
        this.address = address
    }
}

export default Geolocation;