import React from 'react'
import _ from 'lodash';

import autoBind from 'react-autobind';
import { connect } from 'react-redux';

import Menu from '../../page/components/Menu'
import ClosedJobItem from '../components/review/ClosedJobItem'
import ReviewInfo from '../components/review/ReviewInfo'
import Page404 from '../../page/components/Page404'

import Footer from '../../page/components/Footer'

import * as reviewActions from '../../store/review/actions'
import * as reviewSelectors from '../../store/review/reducer'

import * as jobActions from '../../store/job/actions'
import * as jobSelectors from '../../store/job/reducer'

import { withRouter } from 'react-router-dom'



class ReviewContainer extends React.Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    componentDidMount() {
        this.props.dispatch(jobActions.getJobInfoById(this.props.match.params.id))
    }

    render() {


        if (this.props.jobInfo) {
            return (
                <div className="page">
                    <Menu />
                    <ClosedJobItem
                        jobInfo={this.props.jobInfo}
                    />
                    <ReviewInfo
                        jobInfo={this.props.jobInfo}
                        reviewError={this.props.reviewError}
                        reviewInfo={this.props.reviewInfo}
                        onReviewTextChanged={this.onReviewTextChanged}
                        onQualityRateChanged={this.onQualityRateChanged}
                        onDeadlineRateChanged={this.onDeadlineRateChanged}
                        onCommunicationRateChanged={this.onCommunicationRateChanged}
                        onCreateReviewClick={this.onCreateReviewClick}
                    />
                    <Footer />
                </div>
            )
        } else {
            return <div></div>
            // return <Page404 />
        }
    }

    onReviewTextChanged(event) {
        this.props.dispatch(reviewActions.changeReviewText(event.target.value))
    }

    onQualityRateChanged(rate) {
        this.props.dispatch(reviewActions.changeQualityRate(rate))
    }

    onDeadlineRateChanged(rate) {
        this.props.dispatch(reviewActions.changeDeadlineRate(rate))
    }

    onCommunicationRateChanged(rate) {
        this.props.dispatch(reviewActions.changeCommunicationRate(rate))
    }

    async onCreateReviewClick(event) {
        event.preventDefault()
        this.props.dispatch(reviewActions.clearReviewErrors())
        await this.props.dispatch(reviewActions.createReview(this.props.jobInfo, this.props.reviewInfo))
        .then(() => { 
            if (this.props.reviewError === undefined) {
                this.props.dispatch(reviewActions.clearReviewForm())
                this.props.history.push(`/job/${this.props.jobInfo.id}`) 
            }
        })
    }
}

function mapStateToProps(state) {
    const [jobInfo, error] = jobSelectors.getJobInfo(state)

    const reviewInfo = reviewSelectors.getReviewInfo(state)
    const reviewError = reviewSelectors.getReviewError(state)

    return {
        jobInfo,
        error,
        reviewError,
        reviewInfo
    }
}

export default withRouter(connect(mapStateToProps)(ReviewContainer));