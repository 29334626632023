import React from 'react'

import Location from '../../common/Location'

class OrderAdditionalContentView extends React.PureComponent {

    render() {

        return (
            <div className="order-frm__additional-data hidden-wrap">
                <div className="hidden-content opened">
                    <Location
                        isEditable={this.props.isEditable}
                        orderAddress={this.props.orderAddress}
                        orderCheckedAddress={this.props.orderCheckedAddress}
                        orderCoords={this.props.orderCoords}
                        orderInfo={this.props.orderInfo}
                        onAddressChanged={this.props.onAddressChanged}
                        onLocationChanged={this.props.onLocationChanged}
                    />
                </div>
            </div>
        )
    }
}

export default OrderAdditionalContentView