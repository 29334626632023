
import React from 'react'
import autoBind from 'react-autobind';
import SvgIcon from './SvgIcon'

class SearchPageCatalog extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { hideCategoryList: true };
    this.handleChange = this.handleChange.bind(this);
  }
	handleChange(){
    this.setState({hide: !this.state.hide});
  }
  createCategoriesTable() {

    let table = []

    const categories = this.props.categories
    const selectedCategory = this.props.searchCriteria.findCategory
    var selectedCategoryId = undefined
    if (selectedCategory) {
      selectedCategoryId = selectedCategory.id
    }

    if (!categories) {
      return table
    }

    //TODO: ADD SELECT HANDLER
    categories.forEach(element => {
      if (element.id == selectedCategoryId) {
        table.push(
          <li className="cat-menu__item">
            <a className="cat-menu__item_selected" href="javascript:;"
              onClick={() => this.props.onCategoryChanged(element.id)}
              >{element.title}</a>
          </li>
        )
      } else {
        table.push(
          <li className="cat-menu__item">
            <a href="javascript:;"
              onClick={() => this.props.onCategoryChanged(element.id)}
              // onClick={this.props.onCategoryChanged} 
              >{element.title}</a>
          </li>
        )
      }
        

    });

    return table
  }


  render() {

    return (
        <div className="catalog__sidebar acc-item js-acc-item">
            <div className="catalog__sidebar-title">Все категории</div>
        <div className="catalog__sidebar-show acc-item__title js-acc-title"
          onClick = {this.handleChange}
        >Показать категории
                <SvgIcon name='icon-down'/>
            </div>
            <ul className="catalog__cat-menu cat-menu js-acc-content" style={{display: this.state.hide ? "none":"block"}}>
                {this.createCategoriesTable()}
                {/* <li className="cat-menu__item"><a href="javascript:;">Администрирование сайтов</a></li>
                <li className="cat-menu__item"><a href="javascript:;">Архитектура и Инжиниринг</a></li>
                <li className="cat-menu__item"><a href="javascript:;">Аудио, Видео и Мультимедиа</a></li>
                <li className="cat-menu__item"><a href="javascript:;">Веб-дизайн и Интерфейсы</a></li>
                <li className="cat-menu__item"><a href="javascript:;">Веб-программирование</a></li>
                <li className="cat-menu__item"><a href="javascript:;">Графика и Фотография</a></li>
                <li className="cat-menu__item"><a href="javascript:;">Полиграфия и Айдентика</a></li>
                <li className="cat-menu__item"><a href="javascript:;">Программирование ПО</a></li>
                <li className="cat-menu__item"><a href="javascript:;">Продвижение сайтов (SEO)</a></li>
                <li className="cat-menu__item"><a href="javascript:;">Тексты и Переводы</a></li>
                <li className="cat-menu__item"><a href="javascript:;">Управление и Менеджмент</a></li>
                <li className="cat-menu__item"><a href="javascript:;">Учеба и Репетиторство</a></li>
                <li className="cat-menu__item"><a href="javascript:;">Экономика, Финансы и Право</a></li> */}
            </ul>
        </div>
    );
  }
}

export default SearchPageCatalog