import React from 'react'
import autoBind from 'react-autobind';
import _ from 'lodash';

import SelectList from '../../common/SelectList'

class CountryList extends React.Component {
    
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            isSelectListOpenned: false
        }
    }

    prepareSelectListData() {

        const vuuteCountries = this.props.availableCountries
        var selectedCountryId = undefined
        if (this.props.selectedCountry !== undefined) {
            selectedCountryId = this.props.selectedCountry.id
        }
        

        var data = []

        _.forEach(vuuteCountries, function(value) {

            var item = {}

            if (selectedCountryId !== undefined) {
                if (selectedCountryId == value.id) {
                    item = {
                        id: value.id,
                        text: value.title,
                        selected: true,
                    }
    
                } else {
                    item = {
                        id: value.id,
                        text: value.title,
                    }
                }
            } else {
                item = {
                    id: value.id,
                    text: value.title,
                }
            }

            
            
            data.push(item)

        });

        return [data, selectedCountryId]

    }

    render() {

        const [data, selectedCountryId] = this.prepareSelectListData()

        return (
            <React.Fragment>
                <SelectList
                    data={data}
                    // orderNumber={orderNumber}
                    selectedId={selectedCountryId}
                    onSelectListChange={this.onSelectListChange}
                    onSelectListOpen={this.onSelectListOpen}
                    onSelectListClose={this.onSelectListClose}
                />
            </React.Fragment>
        );
    }

    onSelectListChange(inputId, event) {
        if (this.state.isSelectListOpenned) {
          return
        }

        this.props.onCountryChanged(event.params.data.id)
    }
    
    onSelectListOpen = () => {
        this.setState({
            isSelectListOpenned: true
        })
    }
            
    onSelectListClose = () => {
        this.setState({
            isSelectListOpenned: false
        })
    }
}

export default CountryList