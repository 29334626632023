import User from '../model/User';
import JSONParser from '../../common/parsers/JSONParser'
import _ from 'lodash';


class UserParser extends JSONParser {

    parse() {
        super.parse()
        let user = new User(_.get(this.data, 'user.id'),
                         _.get(this.data, 'user.phone'),
                         _.get(this.data, 'user.name'),
                         _.get(this.data, 'user.is_verified'))
        return user
    }
}

export default UserParser;