import _ from 'lodash';


// LEGACY
// this method used in GeolocationService only -> REFACTOR
class JSParseService {

    parseCheckedAddresses(data) {

        let addresses = []

        _.forEach(data.suggestions, function(value) {
            let address = {
                address: _.get(value, 'label'),
                locationId: _.get(value, 'locationId'),
                country: _.get(value, 'address.country'),
                city: _.get(value, 'address.city'),
                street: _.get(value, 'address.street'),
                houseNumber: _.get(value, 'address.houseNumber'),
                postalCode: _.get(value, 'address.postalCode')
            }
            addresses.push(address)
        }.bind(this));

        return addresses  
    }

}

export default new JSParseService();