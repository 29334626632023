import React from 'react'

import Rating from '../../../common/Rating'

class EvaluationItem extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <div className="evaluation-param">
                <div className="evaluation-param__title">{this.props.title}</div>
                <Rating
                    rating={this.props.rating}
                    isReadOnly={false}
                    isShow={false}
                    onRateChanged={this.props.onRateChanged}
                />
            </div>
        );
    }
}

export default EvaluationItem