
class DimensionParser {

    getDimension(jsDimension) {
        if (jsDimension == "METER") {
            return 'м<sup>2</sup>';
        } else if (jsDimension == "HOUR") {
            return 'час';
        } else if (jsDimension == "ITEM") {
            return 'шт';
        } else {
            //default value
            return 'м<sup>2</sup>';
        }
    }

}

export default new DimensionParser();

