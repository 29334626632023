import React from 'react'
import autoBind from 'react-autobind';
import _ from 'lodash';

import SelectList from '../../common/SelectList'

class EducationsObjectsList extends React.Component {
    
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            isSelectListOpenned: false
        }
    }

    prepareSelectListData() {

        const vuuteEducations = this.props.availableEducationsObjects

        var selectedEducationObjectId = undefined
        if (this.props.selectedEducationObject !== undefined) {
            selectedEducationObjectId = this.props.selectedEducationObject.id
        }

        var data = []

        _.forEach(vuuteEducations, function(value) {

            var item = {}

            if (selectedEducationObjectId !== undefined) {
                if (selectedEducationObjectId == value.id) {
                    item = {
                        id: value.id,
                        text: value.title,
                        selected: true,
                    }
    
                } else {
                    item = {
                        id: value.id,
                        text: value.title,
                    }
                }
            } else {
                item = {
                    id: value.id,
                    text: value.title,
                }
            }

            
            
            data.push(item)

        });

        return [data, selectedEducationObjectId]

    }

    render() {

        const [data, selectedEducationObjectId] = this.prepareSelectListData()

        return (
            <React.Fragment>
                <SelectList
                    data={data}
                    // orderNumber={orderNumber}
                    selectedId={selectedEducationObjectId}
                    onSelectListChange={this.onSelectListChange}
                    onSelectListOpen={this.onSelectListOpen}
                    onSelectListClose={this.onSelectListClose}
                />
            </React.Fragment>
        );
    }

    onSelectListChange(inputId, event) {
        if (this.state.isSelectListOpenned) {
          return
        }

        this.props.onEducationObjectChanged(event.params.data.id)
    
    }
    
    onSelectListOpen = () => {
        this.setState({
            isSelectListOpenned: true
        })
    }
            
    onSelectListClose = () => {
        this.setState({
            isSelectListOpenned: false
        })
    }
}

export default EducationsObjectsList