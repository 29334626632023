import React from 'react'

import MessagesHeaderView from './MessagesHeaderView'
import MessagesListView from './MessagesListView'
import NewMessageView from './NewMessageView'

import SvgIcon from '../../../common/SvgIcon'

/**
 * View which contain all needed views for messages view - messaes header, messages list 
 * and new message input
 *
 * @class MessagesContainerView
 * @extends {React.Component}
 */
class MessagesContainerView extends React.Component {

  /**
   * Prepare data for MessagesContainerView
   *
   * @returns [selectedChat, messages] - selectedChat and messages if it's defined
   * @memberof MessagesContainerView
   */

  prepareMsgData() {
    const selectedChat = this.props.selectedChat
    var messages = this.props.messages
    return [selectedChat, messages]
  }


  /**
   * Basic render function for React component
   *
   * @returns
   * @memberof MessagesContainerView
   */
  render() {

    const [selectedChat, messages] = this.prepareMsgData()

    return (
      <div className="messages__main" id='MessagesContainerView'>
        <div className="messages-chat">
          {(selectedChat == undefined) ? ('') : (
            <React.Fragment>
              <MessagesHeaderView
                selectedChat={selectedChat}
                onBidConfirmationClick={this.props.onBidConfirmationClick}
                onBidRejectClick={this.props.onBidRejectClick}
                onJobClick={this.props.onJobClick} />
              <MessagesListView selectedChat={selectedChat}
                messages={messages}
                updateFirstMessageId = {this.props.updateFirstMessageId}
                limitOffset={this.props.limitOffset}
                onLoadMoreMessages={this.props.onLoadMoreMessages}
                messagesAllMessagesLoaded={this.props.messagesAllMessagesLoaded}
                firstMessageId = {this.props.firstMessageId}
              />
              <NewMessageView onNewMessageChanged={this.props.onNewMessageChanged} />
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default MessagesContainerView