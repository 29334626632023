import React from 'react';
import _ from 'lodash';
import CityList from '../profile/components/CityList';
import * as vuuteServiceSelectors from '../store/vuuteServices/reducer';
import * as profileSelectors from '../store/profile/reducer';
import * as vuuteServiceActions from '../store/vuuteServices/actions';
import * as profileActions from '../store/profile/actions';
import {connect} from 'react-redux';
import autoBind from 'react-autobind';
class CityPopup extends React.Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }
    componentDidMount() {
        if (this.props.cities.length == 0) {
            this.props.dispatch(vuuteServiceActions.requestVuuteCities(1));
        }
    }
    render() {

        return (
            <div
                className='fancybox-container fancybox-is-open'
                role='dialog'
                tabIndex={-1}
                id='fancybox-container-11'
                style={{transitionDuration: '350ms'}}
                data-select2-id='fancybox-container-11'
            >
                <div className='fancybox-bg' />
                <div className='fancybox-inner' data-select2-id={26}>
                    <div className='fancybox-stage'>
                        <div
                            className='fancybox-slide popup-form fancybox-slide--html fancybox-slide--current fancybox-slide--complete'
                            data-select2-id={25}
                        >
                            <div
                                className='popup popup--md2 fancybox-content'
                                id='master-profile-portfolio'
                                style={{display: 'inline-block'}}
                            >
                                <div className='popup__title'>
                                    <span>Пожалуйста укажите ваш город</span>
                                </div>
                                <form className='popup-frm'>
                                    <div className='popup__inner'>
                                        <div className='input-holder'>
                                            <CityList
                                                availableCities={
                                                    this.props.cities
                                                }
                                                onCityChanged={
                                                    this.onCityChanged
                                                }
                                            />
                                        </div>
                                    </div>
                                    <button
                                        className='popup-frm__submit close-popup btn--2 popup__wide-btn'
                                        type='button'
                                        onClick={this.onSaveClick}
                                    >
                                        Сохранить
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className='fancybox-caption' />
                </div>
            </div>
        );
    }
    onCityChanged(cityId) {
        var newCityId = parseInt(cityId, 10);
        var newCity = null;
        _.forEach(this.props.cities, function (city) {
            if (newCityId == city.id) {
                newCity = city;
            }
        });
        this.props.dispatch(profileActions.changeCity(newCity));
    }
    onSaveClick(event) {
        event.preventDefault();
        this.props.dispatch(
            profileActions.saveProfile(this.props.modifiedProfile)
        );
    }
}

function mapStateToProps(state) {
    const cities = vuuteServiceSelectors.getAvailableVuuteCities(state);
    const modifiedProfile = profileSelectors.getModifiedProfile(state);

    return {
        modifiedProfile,
        cities,
    };
}
export default connect(mapStateToProps)(CityPopup);
