import React from "react"
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { menuItem } from '../../page/components/MenuEnum';

import * as profileActions from '../../store/profile/actions';
import * as profileSelectors from '../../store/profile/reducer';

import * as menuActions from '../../store/menu/actions';

import AuthService from '../../services/AuthService';

import MasterProfile from '../components/MasterProfile';
import ClientProfile from '../components/ClientProfile';

class ProfilePageContainer extends React.Component {

    constructor(props) {
        super(props);
        autoBind(this);
    }

    componentWillMount() {

        if (AuthService.shared().isMyProfile()) {
            this.props.dispatch(menuActions.selectMenuItem(menuItem.PROFILE));
        } else {
            this.props.dispatch(menuActions.selectMenuItem(menuItem.UNSELECTED));
        }

        
    }

    componentDidMount() {
        var profileId = undefined
        if (this.props.match.params.id !== undefined) {
            profileId = parseInt(this.props.match.params.id)
        }

        this.props.dispatch(profileActions.getProfile(profileId));
    }

    render() {
        
        var profileType = ''
        if (this.props.profile !== undefined) {
            profileType = this.props.profile.bio.profileType
        }

        

        if (profileType == 'CLIENT') {
            return <ClientProfile profile={this.props.profile} modifiedProfile={this.props.modifiedProfile}/>
        } else if (profileType == 'MASTER') {
            return <MasterProfile profile={this.props.profile} modifiedProfile={this.props.modifiedProfile}/>
        } else {
            return <div></div>
        }
    }
}

// export default ProfilePageContainer
function mapStateToProps(state) {
    const profile = profileSelectors.getProfile(state);
    const modifiedProfile = profileSelectors.getModifiedProfile(state);
    return {
        profile,
        modifiedProfile,
    }
}

export default connect(mapStateToProps)(ProfilePageContainer);