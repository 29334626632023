import React from 'react'

class Buttons extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <div className="user-data-frm__footer editable">
          <button className="live-edit-cancel-btn btn--4-2 btn--sm btn--flex" type="button" onClick={this.props.onCancelClick}>Отменить</button>
          <button className="live-edit-submit-btn btn--2 btn--sm btn--flex" type="button" onClick={this.props.onSaveClick}>Сохранить изменения</button>
        </div>
    );
  }
}

export default Buttons