import React from 'react'
import PriceRcSlider from '../../common/PriceRcSlider';
import PriceSlider from '../../common/PriceSlider';

import TermCalendar from '../../common/TermCalendar';

class CreateOrderPriceView extends React.Component {
    
    render() {
        let price = ''
        if (this.props.price) {
            price = this.props.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        }

        return (
        <>
            <div className="form-row" >
                <div className="option-wrap ">
                    <PriceRcSlider price = {this.props.price} onChange={this.props.onPriceChangedSlider}/>
                </div>
            </div>    
            <div className="form-row">    
                <div className="option-wrap price-option-wrap">
           
                    
                    <label htmlFor="order-strict-price">Укажите точную стоимость <span className="imp">*</span></label>
                    {this.props.isValidInput.isOrderPriceValid === false ? (
                        <div className="input-holder"><span className="rouble">₽</span>
                            <input className="error js-data-input" id="order-strict-price" type="text" name="order-strict-price" value={price} onChange={this.props.onPriceChanged} />
                            <div className="error-msg">Введите планируемую стоимость заказа.</div>
                        </div>
                    ) : (
                            <div className="input-holder"><span className="rouble">₽</span>
                                <input className="js-data-input" id="order-strict-price" type="text" name="order-strict-price" value={price} onChange={this.props.onPriceChanged} />
                            </div>
                        )}
                </div>
                <div className="option-wrap price-option-wrap">
                    <label htmlFor="order-strict-price">Когда надо выполнить заказ?</label>
                    <div className="double-input">
                        <TermCalendar
                            selectedDate={this.props.orderStartDate}
                            onChange={(date) => this.props.onOrderStartDateChanged(date)}
                        />
                        <TermCalendar
                            selectedDate={this.props.orderEndDate}
                            onChange={(date) => this.props.onOrderEndDateChanged(date)}
                        />
                    </div>
                    {this.props.isValidInput.isOrderStartDateValid === false && this.props.isValidInput.isOrderEndDateValid === false ? (
                        <div className="error-msg">Укажите корректные сроки заказа.</div>
                    ) : ''}
                </div>
            </div>
        </>
        )
    }
}

export default CreateOrderPriceView