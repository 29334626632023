import _ from 'lodash';
import Profile from "../model/Profile";
import ProfileBioParser from "./ProfileBioParser";
import ProfileEducationsParser from './ProfileEducationsParser';
import ProfileExperiencesParser from './ProfileExperiencesParser';
import ProfileServicesParser from './ProfileServicesParser';

import JSONParser from '../../common/parsers/JSONParser'


class ProfileParser extends JSONParser {

    parse() {
        super.parse()
        
        let profileBio = new ProfileBioParser(_.get(this.data, 'bio')).parse()
        let profileEducations = new ProfileEducationsParser(_.get(this.data, 'education')).parse()
        let profileServices = new ProfileServicesParser(_.get(this.data, 'services')).parse()
        let profileExperiences = new ProfileExperiencesParser(_.get(this.data, 'experience')).parse()

        let profile = new Profile(profileBio, profileEducations, profileServices, profileExperiences)
        return profile


    }

}

export default ProfileParser;