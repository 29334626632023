import _ from 'lodash';
import OrderPermissions from '../model/OrderPermissions';

import JSONParser from '../../common/parsers/JSONParser'


class OrderPermissionsParser extends JSONParser {

    parse() {
        super.parse()

        let hasBid = _.get(this.data, 'has_bid')
        let isClient = _.get(this.data, 'is_client')
        let isOrderOwner = _.get(this.data, 'is_order_owner')
        let isAuthorizedUser = _.get(this.data, 'is_authorized_user')
        let isOrderClosed = _.get(this.data, 'is_job_closed')

        let orderPermissions = new OrderPermissions(hasBid, isClient, isOrderOwner, isAuthorizedUser, isOrderClosed)
        return orderPermissions

    }

}

export default OrderPermissionsParser;