export default [
    {
        'id': 0,
        'title': 'Детализация заказа',
        'text': 'Вся необходимая информация о заказе указывается сразу и поможет избежать лишних вопросов и сэкономить время',
    },
    {
        'id': 1,
        'title': 'Проверка пользователей',
        'text': 'Мы проверяем наших пользователей, выявляя недобросовестных исполнителей и заказчиков',
    },
    {
        'id': 2,
        'title': 'Начисляем бонусы',
        'text': 'Дарим бонусы тем, кто давно вместе с нами, чтобы сделать использование сервиса еще приятнее',
    },
]