
class CategoryVuute {
    id;
    title;

    constructor(id, title) {
        this.id = id
        this.title = title
    }
}

export default CategoryVuute;