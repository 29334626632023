import React from 'react'

import ReviewContent from './ReviewContent'
import Rating from '../../common/Rating'
import SvgIcon from '../../common/SvgIcon'

class ReviewItem extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpened: false
    }

    this.onHandleClick = this.onHandleClick.bind(this)
  }

  render() {
    const isClient = this.props.isClient

    let review = this.props.job.reviewMaster
    if (isClient) {
      review = this.props.job.reviewMaster
    } else {
      review = this.props.job.reviewOwner
    }

    return (
      <div className='review acc-item'>
        <div onClick={this.onHandleClick} className={this.state.isOpened ? 'review__header acc-item__title js-acc-title opened' : 'review__header acc-item__title js-acc-title'}>
          {/* if client's review is exist render icon */}
          {review && <SvgIcon name='icon-down' />}
          {isClient ? 'Отзыв заказчика' : 'Отзыв исполнителя'}
          {/* if client's review is exist render stars */}
          {review && (
            <div className='rating'>
              <Rating rating={review.rate} isShow={false} isReadOnly={true} />
            </div>
          )}
        </div>
        {review ? <ReviewContent isContentOpened={this.state.isOpened} review={review} /> : <div>Отзыв еще не оставлен</div>}
      </div>
    )
  }

  onHandleClick() {
    this.setState({
      isOpened: !this.state.isOpened
    })
  }
}

export default ReviewItem
