import React from 'react'

import SvgIcon from '../../../../../../common/SvgIcon'

class Reviewer extends React.Component {
    render() {
        const reviewer = this.props.reviewer
        return(
            <div className="grade">
                <div className="grade__meta"><a className="grade__name">{reviewer.name}</a>
                    <div className="grade__date">{reviewer.date}</div>
                </div>
                {reviewer.isPositive ? (
                    <div className="grade__mark grade__mark--positive">
                        <SvgIcon name='icon-like'/>
                    </div>
                ) : (
                    <div className="grade__mark grade__mark--negative">
                        <SvgIcon name='icon-dislike'/>
                    </div>
                )}
            </div>
        )
    }
}

export default Reviewer