class ChatRoom {
    id;
    unreadCount;
    profile;
    job;
    message;

    constructor(id, unreadCount, profile, job, message) {
        this.id = id
        this.unreadCount = unreadCount
        this.profile = profile
        this.job = job
        this.message = message
    }
}

export default ChatRoom;