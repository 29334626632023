import React from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

import * as settingsActions from '../../store/settings/actions';
import * as settingsSelectors from '../../store/settings/reducer';

import LoginMsgBox from '../../user/components/LoginMsgBox';

export class SettingsSecurity extends React.Component {
    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            isOldPassword: true,
            isNewPassword: true,
            isRepeatedNewPassword: true,
            arePasswordsMatch: true
        }

        // this.onChangePasswordClick = this.onChangePasswordClick.bind(this)
    }

    render() {

        let error = this.props.securityInfo.error

        return (
            <form className="settings-section settings-frm ajax-form" id="security">
                <div className="settings-frm__main">
                    
                    {(error !== undefined) ? <LoginMsgBox msgText={error.message} msgType={"error-msg"} /> : ''}

                    <h2 className="settings-section__title">Изменение пароля</h2>
                    <label className="check-label-lg" htmlFor="position-notify"><span />
                        <div>Старый пароль</div>
                    </label>
                    <div className="option-wrap">
                        <input
                            className="js-data-input"
                            id="1"
                            type="password"
                            name="login-pass"
                            value={this.props.securityInfo.oldPassword}
                            onChange={this.onOldPasswordChanged}
                        />
                        {!this.state.isOldPassword ? <div className="error-msg">Введите старый пароль.</div> : ''}
                    </div>
                    <label className="check-label-lg" htmlFor="position-notify"><span />
                        <div>Новый пароль</div>
                    </label>
                    <div className="option-wrap">
                        <input
                            className="js-data-input"
                            id="2"
                            type="password"
                            name="login-pass"
                            value={this.props.securityInfo.newPassword}
                            onChange={this.onNewPasswordChanged}
                        />
                        {!this.state.isNewPassword ? <div className="error-msg">Введите новый пароль.</div> : ''}
                        {!this.state.arePasswordsMatch ? <div className="error-msg">Пароли не совпадают.</div> : ''}
                    </div>
                    <label className="check-label-lg" htmlFor="position-notify"><span />
                        <div>Повторите новый пароль</div>
                    </label>
                    <div className="option-wrap">
                        <input
                            className="js-data-input"
                            id="3"
                            type="password"
                            name="login-pass"
                            value={this.props.securityInfo.repeatedNewPassword}
                            onChange={this.onRepeatedNewPasswordChanged}
                        />
                        {!this.state.isRepeatedNewPassword ? <div className="error-msg">Повторите новый пароль.</div> : ''}
                        {!this.state.arePasswordsMatch ? <div className="error-msg">Пароли не совпадают.</div> : ''}
                    </div>
                </div>
                <div className="settings-frm__sidebar">
                    <button className="btn--1" type="submit" onClick={this.onChangePasswordClick}>Сохранить</button>
                </div>
            </form>
        );
    }


    onOldPasswordChanged(event) {
        event.preventDefault()
        this.props.dispatch(settingsActions.changedOldPassword(event.target.value))
    }

    onNewPasswordChanged(event) {
        event.preventDefault()
        this.props.dispatch(settingsActions.changedNewPassword(event.target.value))
    }

    onRepeatedNewPasswordChanged(event) {
        event.preventDefault()
        this.props.dispatch(settingsActions.changedRepeatedNewPassword(event.target.value))
    }


    onChangePasswordClick(event) {
        event.preventDefault()

        let oldPassword = this.props.securityInfo.oldPassword
        let newPassword = this.props.securityInfo.newPassword
        let repNewPassword = this.props.securityInfo.repeatedNewPassword


        if ([undefined, ''].includes(oldPassword)) {
            this.setState({
                isOldPassword: false,
            })
        } else {
            this.setState({
                isOldPassword: true,
            })
        }

        if ([undefined, ''].includes(newPassword) && [undefined, ''].includes(repNewPassword)) {
            this.setState({
                isNewPassword: false,
                isRepeatedNewPassword: false,
                arePasswordsMatch: true
            })
        } else {
            if (([undefined, ''].includes(newPassword) || [undefined, ''].includes(repNewPassword))) {
                this.setState({
                    isNewPassword: true,
                    isRepeatedNewPassword: true,
                    arePasswordsMatch: false
                })
            } else {
                if (newPassword === repNewPassword) {
                    this.setState({
                        arePasswordsMatch: true
                    })
                } else {
                    this.setState({
                        arePasswordsMatch: false
                    })
                }
            }
        }

        let oldPasswordValue = this.props.securityInfo.oldPassword
        let newPasswordValue = this.props.securityInfo.newPassword
        let repeatedPasswordValue = this.props.securityInfo.repeatedNewPassword

        // if all fields filled
        if (oldPasswordValue && newPasswordValue && repeatedPasswordValue) {
            // if new values equal
            if (newPasswordValue === repeatedPasswordValue) {
                // call backend method
                this.props.dispatch(settingsActions.clearSecurityInfoSettingsErrors())
                this.props.dispatch(settingsActions.changePassword(this.props.securityInfo.oldPassword, this.props.securityInfo.newPassword))
            }
        }
    }
}

function mapStateToProps(state) {
    const securityInfo = settingsSelectors.getSecurityInfo(state)

    return {
        securityInfo
    }
}

export default withRouter(connect(mapStateToProps)(SettingsSecurity));

// export default SettingsSecurity