import React from "react";

const style = {
  ok: {
    width: "32px",
    height: "32px",
    borderRadius: "5px",
    backgroundImage:
      "url(https://cdn.worldvectorlogo.com/logos/ok-ru.svg)",
    backgroundPosition: "center",
    backgroundSize: " cover",
  },
  container: {
    display: "flex",
  },
  item: {
    margin: "0 3px ",
  },
};

class Socials extends React.Component {
  render() {
    const vkSetting = [
      {
        url: `https://www.xn--b1ag2bd9c.xn--p1ai/profile/${this.props.id}`,
        title: "Посмотрите мой профайл на сайте ВУюте.РФ",
        message:
          "Вуюте.рф — удобный способ найти исполнителя и заказчика для ремонтных работ любой сложности",
        image:
          "https://vuuteimagespoc.s3.eu-central-1.amazonaws.com/vuuteLogoMedium.png",
        noparse: true,
      },
      {
        type: "custom",
        text: '<img src="https://vk.com/images/share_32.png" width="32" height="32" />',
      },
    ];
    const OkSetting = {
      url: `https://www.xn--b1ag2bd9c.xn--p1ai/profile/${this.props.id}`,
      descr:
        "Вуюте.рф — удобный способ найти исполнителя и заказчика для ремонтных работ любой сложности",
      image:
        "https://vuuteimagespoc.s3.eu-central-1.amazonaws.com/vuuteLogoMedium.png",
      title: "Посмотрите мой профайл на сайте ВУюте.РФ",
    };
    return (
      <div style={style.container}>
        <div
          style={style.item}
          dangerouslySetInnerHTML={{
            __html: window.VK && window.VK.Share.button(...vkSetting),
          }}
        />
        <div style={style.item}>
          <a style={{display: "table-cell"}} target="_blank"
            href={`https://connect.ok.ru/offer?url=${OkSetting.url}&title=${OkSetting.title}&description=${OkSetting.descr}&imageUrl=${OkSetting.image}`}
          >
            <div style={style.ok}></div>
          </a>
        </div>
      </div>
    );
  }
}

export default Socials;
