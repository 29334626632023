export default [
    {
        'id': 0,
        'title': 'Расскажите о себе',
        'is_icon': true,
        'icon': 'icon-list',
        'text': 'Расскажите о себе и своих навыках, а также стоимости Ваших услуг',
    },
    {
        'id': 1,
        'title': 'Найдите заказ',
        'is_icon': false,
        'icon': 'man-2',
        'text': 'Найдите заказ с учетом ваших личных пожеланий. Гибкая система поиска позволит выбрать лучший вариант',
    },
    {
        'id': 2,
        'title': 'Выполните заказ',
        'is_icon': false,   
        'icon': 'man-1',
        'text': 'Свяжитесь с заказчиком напрямую, обсудите условия и приступайте к работе - никаких скрытых условий',
    },
    {
        'id': 3,
        'title': 'Получите оплату',
        'is_icon': true,
        'icon': 'icon-cache',
        'text': 'После выполнения заказа получите оплату и оставьте отзыв. Вместе мы сделаем наш сервис лучше!',
    },
]