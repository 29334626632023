
class DateService {

    constructor() {
        this.monthNames = [
            "Январь", "Февраль", "Март",
            "Апрель", "Май", "Июнь", "Июль",
            "Август", "Сентябрь", "Октябрь",
            "Ноябрь", "Декабрь"
        ];

        this.fullDateMonthsNames = [
            "января", "февраля", "марта",
            "апреля", "мая", "июня", "июля",
            "августа", "сентября", "октября",
            "ноября", "декабря"
        ];

        this.passedSecondsNames = ['секунду', 'секунды', 'секунд'];

        this.passedMinutesNames = ['минуту', 'минуты', 'минут'];

        this.passedHoursNames = ['час', 'часа', 'часов'];

        this.passedDayesNames = ['день', 'дня', 'дней'];

        this.passedMonthsNames = ['месяц', 'месяца', 'месяцев'];

        this.passedYearsNames = ['год', 'года', 'лет'];
    }

    isValidDate(d) {
        return d instanceof Date && !isNaN(d);
    }

    convertExperienceDateToStr(date) {
        var month = date.getMonth() + 1;
        var year = date.getFullYear();
        return '01-' + month + '-' + year
    }

    getMessageTime(date) {
        var hours = date.getHours()
        var minutes = (date.getMinutes()<10?'0':'') + date.getMinutes()
        return `${hours}:${minutes}`
    }

    getExperienceDate(date) {
        // fix issue when date is null or undefined
        if (date === undefined) { return undefined }
        if (date === null) { return undefined }
        var monthIndex = date.getMonth();
        var year = date.getFullYear();
        return this.monthNames[monthIndex] + ' ' + year;
    }


    getExperienceDifferenceBetweenDates(date1, date2) {
        var endDate = date2
        if (date2 === undefined) {
            endDate = new Date()
        }
        var differenceInTime = endDate.getTime() - date1.getTime();
        var differenceInDays = differenceInTime / (1000 * 3600 * 24)
        var differenceInMonths = differenceInDays / 31

        var months = parseInt(differenceInMonths % 12)
        var years = Math.floor(differenceInMonths / 12)

        if (date2 === undefined) {
            return 'уже' + ' ' + years + ' года и ' + months + 'месяц'
        } else {
            return years + ' года и ' + months + ' месяцев'
        }

    }

    getCreatedAtDateToStr(date) {
        var month = ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1);
        var day = (date.getDate() < 10 ? '0' : '') + date.getDate();
        var year = date.getFullYear();
        return (`${day}.${month}.${year}`)
    }

    isStartDateValid(startDate) {
        var todayDate = new Date()

        if (startDate < todayDate) {
            return todayDate
        } else {
            return startDate
        }
    }

    isEndDateValid(startDate, endDate) {
        var todayDate = new Date()

        if (startDate > endDate) {
            var newEndDate = startDate
            return new Date(todayDate.setTime(newEndDate.getTime() + 1 * 86400000))
        } else {
            return endDate
        }
    }

    fromFullDateTransform(date) {
        if (date === undefined) { return undefined }
        if (date === null) { return undefined }
        var yyyy = date.getFullYear();

        var MM = ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1);

        var dd = (date.getDate() < 10 ? '0' : '') + date.getDate();

        return (yyyy + "-" + MM + "-" + dd)
    }

    toFullDateTransform(date) {
        if (date === undefined) { return undefined }
        if (date === null) { return undefined }

        return (new Date(date))
    }

    toDayMonthYearDateTransform(date) {
        if (date === undefined) { return undefined }
        if (date === null) { return undefined }

        let transformedDate
        if (typeof date !== "string") {
            transformedDate = this.fromFullDateTransform(date)
        } else {
            transformedDate = date
        }

        const [year, month, day] = transformedDate.split("-")

        return (day + ' ' + this.fullDateMonthsNames[Number(month) - 1] + ' ' + year)
    }


    passedTimeCalc(date) {
        const newDate = new Date(date)
        const currentTime = new Date()

        const secondsPassed = parseInt((currentTime - newDate) / 1000)
        const minutesPassed = parseInt(secondsPassed / 60)
        const hoursPassed = parseInt(minutesPassed / 60)
        const daysPassed = parseInt(hoursPassed / 24)
        const monthsPassed = parseInt(daysPassed / 30)
        const yearsPassed = parseInt(daysPassed / 365)

        if (yearsPassed >= 1) {
            return (yearsPassed + ' ' + this.passedYearsNames[this.getTimeWordFormNumber(yearsPassed)])
        } else {
            if (monthsPassed >= 1) {
                return (monthsPassed + ' ' + this.passedMonthsNames[this.getTimeWordFormNumber(monthsPassed)])
            } else {
                if (daysPassed >= 1) {
                    return (daysPassed + ' ' + this.passedDayesNames[this.getTimeWordFormNumber(daysPassed)])
                } else {
                    if (hoursPassed >= 1) {
                        return (hoursPassed + ' ' + this.passedHoursNames[this.getTimeWordFormNumber(hoursPassed)])
                    } else {
                        if (minutesPassed >= 1) {
                            return (minutesPassed + ' ' + this.passedMinutesNames[this.getTimeWordFormNumber(minutesPassed)])
                        } else {
                            return (secondsPassed + ' ' + this.passedSecondsNames[this.getTimeWordFormNumber(secondsPassed)])
                        }
                    }
                }
            }
        }
    }

    getTimeWordFormNumber(number) {
        var separatedDigits = number.toString().split('')
        var lastDigit = Number(separatedDigits[separatedDigits.length - 1])

        // Digits which we have to check to change form
        const lastDigits = [2, 3, 4]
        // Numbers which doesn't change a form of word
        const falseNumbers = [11, 12, 13, 14]

        if (lastDigit == 1 && number != 11) {
            return 0
        } else {
            if (lastDigits.includes(lastDigit) && !falseNumbers.includes(number)) {
                return 1
            } else {
                return 2
            }
        }
    }

    leftTimeCalc(date) {
        const endTime = new Date(date)
        const currentTime = new Date()

        const secondsLeft = parseInt((endTime - currentTime) / 1000)
        const minutesLeft = parseInt(secondsLeft / 60)
        const hoursLeft = parseInt(minutesLeft / 60)
        const daysLeft = parseInt(hoursLeft / 24)
        const monthsLeft = parseInt(daysLeft / 30)
        const yearsLeft = parseInt(monthsLeft / 365)

        if (yearsLeft >= 1) {
            return ('До окончания: ' + yearsLeft + ' ' + this.passedYearsNames[(this.getTimeWordFormNumber(yearsLeft))])
        } else {
            if (monthsLeft >= 1) {
                return ('До окончания: ' + monthsLeft + ' ' + this.passedMonthsNames[(this.getTimeWordFormNumber(monthsLeft))])
            } else {
                if (daysLeft >= 1) {
                    return ('До окончания: ' + daysLeft + ' ' + this.passedDayesNames[(this.getTimeWordFormNumber(daysLeft))])
                }
                if (!date) {
                    return ('Срок не указан')
                }
                return ('Время истекло')
            }
        }
    }
}


export default new DateService();