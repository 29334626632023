import React, {Component} from 'react'
import ReviewMaster from './ReviewMaster'
import ReviewBody from './ReviewBody'

class Review extends Component {
  render()
  {
    console.log(this.props.review);
    if (!this.props.review)
    {
      return <></>;
    }
    const master = this.props.review.master
    const review = this.props.review.review
    return (
        <div className="reviews-item">
          <ReviewMaster master = {master}/>
          <ReviewBody review = {review}/>
        </div>
    )
  }
}

export default Review