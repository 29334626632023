import React, {Component} from 'react'
import AdvItem from './advItem/AdvItem'
import articles from'../../data/gridArticles'

class AdvGrid extends Component {
    render () {
        return(
            <ul className="excellence-list">
                <AdvItem article={articles[0]} svgName='icon-list'/>
                <AdvItem article={articles[1]} svgName='icon-guard'/>
                <AdvItem article={articles[2]} svgName='icon-wallet'/>
            </ul>
        )
    }
}   

export default AdvGrid