import _ from 'lodash';
import * as loginTypes from './actionTypes';
import apiService from '../../services/APIService';
import APIError from '../../common/model/APIError';

export function changePhoneNumber(newNumber) {
    newNumber = newNumber.replace(/[^\d\+]/g, '')
    return ({ type: loginTypes.PHONE_CHANGED, phoneNumber: newNumber})
}

export function changePassword(newPassword) {
    return ({ type: loginTypes.PASSWORD_CHANGED, password: newPassword})
}

export function clearLoginErrors() {
    return ({ type: loginTypes.CLEAR_LOGIN_FORM_ERRORS})
}

export function clearLoginForm() {
    return ({ type: loginTypes.CLEAR_LOGIN_FORM})
}

export function forgotPasswordRequested(phoneNumber) {
    return async(dispatch, getState) => {
        try {

            dispatch({type: loginTypes.CLEAR_LOGIN_FORM_ERRORS})
            // if (phoneNumber === '') {
            //     dispatch({type: loginTypes.FORGOT_PASSWORD_CLICKED, 
            //               isPasswordRecovered: false,
            //               error: new APIError(`Phone number shouldn't be empty`)});
            //     return
            // }

            // dispatch({type: loginTypes.FORGOT_PASSWORD_CLICKED, 
            //           isPasswordRecovered: false,
            //           error: null});

            const isPasswordRecoveredData = await apiService.forgotPassword(phoneNumber);
            dispatch({type: loginTypes.FORGOT_PASSWORD_CLICKED, 
                      isPasswordRecovered: isPasswordRecoveredData.recovered,
                      error: isPasswordRecoveredData.error});
        } catch (error) {
            console.error(error)
        }
    }
}   

