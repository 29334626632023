import React from 'react'
import SvgIcon from '../../common/SvgIcon'
import RemoveButton from '../../common/RemoveButton';

class EducationItemEdit extends React.Component {

  render() {

    let speciality = ''
    let educationTitle = ''
    let startYear = ''
    let endYear = ''

    if (this.props.education) {
      speciality = this.props.education.speciality
      educationTitle = this.props.education.educationObject.title
      const startDate = new Date(this.props.education.dateStarted)
      startYear = startDate.getFullYear()
      const endDate = new Date(this.props.education.dateFinished)
      endYear = endDate.getFullYear()
    }

    return (
        <div className="user-edu-item">
            <div className="user-edu-item__data">
                <div className="user-edu-item__spec"><span className="js-edu-spec">{speciality}</span>, <span className="js-edu-college">{educationTitle}</span></div>
                <div className="user-edu-item__date js-edu-date"><span className="date-from">{startYear}</span> — <span className="date-to">{endYear}</span></div>
            </div>
            <div className="user-edu-item__control editable">
                <a className="edit-edu-btn change-btn" href="javascript:;" data-state="Редактирование образования" title="Редактировать"
                onClick={(e) => this.props.onEducationItemEditClick(this.props.orderNumber, e)}>
                    <SvgIcon name='icon-edit'/>
                </a>
                <RemoveButton onClick={this.props.onEducationItemRemoveClick} value={this.props.orderNumber}/>
            </div>
        </div>
    );
  }
}

export default EducationItemEdit