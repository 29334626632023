class JobsListState {
    static INCOME = new JobsListState('INCOME')
    static ACTIVE = new JobsListState('ACTIVE')
    static CLOSED = new JobsListState('CLOSED')

    constructor(statusTitle) {
        this.statusTitle = statusTitle
    }

    toRequestString() {
        return this.statusTitle.toLowerCase();
    }
}

export default JobsListState;