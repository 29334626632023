import React from 'react'

import MyJobFormTemplate from './MyJobFormTemplate';

class ClosedJobsView extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        if (this.props.closedJobsInfo.length > 0) {
            var closedJobItems = this.props.closedJobsInfo.map((job) =>
                <MyJobFormTemplate
                    key={job.id.toString()}
                    jobInfo={job}
                    jobType={'closedJob'}
                    onJobTitleClick={this.props.onJobTitleClick}
                    onReviewButtonClick={this.props.onReviewButtonClick}
                    onMessageButtonClick={this.props.onMessageButtonClick}
                />
            )
        }

        return (
            <section className="completed-jobs page-section">
                <header>
                    <h2 className="page-section-title">Завершенные заказы</h2>
                </header>
                <div className="jobs-list">
                    {this.props.closedJobsInfo.length > 0 ? (
                        <React.Fragment>
                            {closedJobItems}
                        </React.Fragment>
                    ) : (
                            <div className="empty-data">
                                <p>На данный момент у Вас нет завершенных заказов.</p>
                            </div>
                        )}
                </div>
            </section>
        );
    }
}

export default ClosedJobsView