import React, {Component} from 'react'
import ClientGrid from './clientGrid/ClientGrid'
import MasterGrid from './masterGrid/MasterGrid'

class Steps extends Component {
    state = {
        isCustomer: true
    }

    render () {
        return(
            <div className="steps">
                <section className="main-page-section">
                    <h2 className="main-page-section__title">Все очень просто</h2>
                    <div className="tabs-wrap">     
                            {this.state.isCustomer ? (
                                <ul className="steps-list-tabs tabs js-tabs">
                                    <li className="tabs__item current" onClick={this.customerClick}><span>я заказчик</span></li>
                                    <li className="tabs__item" onClick={this.performerClick}><span>Я исполнитель</span></li>
                                </ul>
                            ) : (
                                <ul className="steps-list-tabs tabs js-tabs">
                                    <li className="tabs__item" onClick={this.customerClick}><span>я заказчик</span></li>
                                    <li className="tabs__item current" onClick={this.performerClick}><span>Я исполнитель</span></li>
                                </ul>
                            )}           
                        <div className="tabs-content">
                            {this.state.isCustomer ? (
                                <ClientGrid/>
                            ) : (
                                <MasterGrid/>
                            )}
                        </div>
                    </div>
                </section>
            </div>
        )
    }

    customerClick = () => {
        this.setState({
            isCustomer: true
        })
    }
    
    performerClick = () => {
        this.setState({
            isCustomer: false
        })
    }
}   

export default Steps