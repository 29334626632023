class Order {
    id;
    category;
    owner;
    city;
    title;
    description;
    price;
    geolocation;
    startDate;
    endDate;
    createdDate;
    permissions;

    constructor(id, category, owner, city, title, description, price) {
        this.id = id
        this.category = category
        this.owner = owner
        this.city = city
        this.title = title
        this.description = description
        this.price = price
    }
}

export default Order;