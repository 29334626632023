export const CLEAR_STORE = 'order.CLEAR_STORE'
export const CREATE_ORDER = 'order.CREATE_ORDER';
export const EDIT_ORDER = 'order.EDIT_ORDER'
export const GET_ORDER_INFO_BY_ID = 'order.GET_ORDER_INFO_BY_ID'
export const CLEAR_CREATE_ORDER_FORM = 'order.CLEAR_CREATE_ORDER_FORM';
export const CLEAR_CREATE_ORDER_FORM_ERRORS = 'order.CLEAR_CREATE_ORDER_FORM_ERRORS';

export const ON_BID_ORDER_CLICK = 'order.ON_BID_ORDER_CLICK'
export const ON_BID_ORDER_CLOSE_CLICK = 'order.ON_BID_ORDER_CLOSE_CLICK'
export const ON_BID_ORDER_SEND_CLICK = 'order.ON_BID_ORDER_SEND_CLICK'
export const ON_BID_ORDER_FORM_CLEAR = 'order.ON_BID_ORDER_FORM_CLEAR'

export const TITLE_CHANGED = 'order.TITLE_CHANGED';
export const CATEGORY_CHANGED = 'order.CATEGORY_CHANGED'
export const DESCRIPTION_CHANGED = 'order.DESCRIPTION_CHANGED'
export const SLIDER_CHANGED = 'order.SLIDER_CHANGED'
export const PRICE_CHANGED = 'order.PRICE_CHANGED'
export const START_DATE_CHANGED = 'order.START_DATE_CHANGED'
export const END_DATE_CHANGED = 'order.END_DATE_CHANGED'
export const BID_COMMENT_CHANGED = 'order.BID_COMMENT_CHANGED'

export const FIELDS_VALIDATOR = 'order.FIELDS_VALIDATOR' 

export const GET_CLIENT_ORDERS = 'order.GET_CLIENT_ORDERS'

export const SUCCESS_ORDER_BID = 'order.SUCCESS_ORDER_BID'