import React from 'react';

export class NotifyItem extends React.Component {
    constructor(props) {
        super(props);

        this.onHandleClick = this.onHandleClick.bind(this)
    }

    render() {

        return (
            <div className="check-wrap" >
                <input id={this.props.id} type="checkbox" className="position-notify"
                checked={this.props.keyStatus} 
                />
                <label className="check-label-lg" htmlFor="position-notify"><span onClick={this.onHandleClick}/>
                    <div>{this.props.content}</div>
                </label>
            </div>
        )
    }

    onHandleClick() {
        this.props.onNotifyItemClick(this.props.title)
    }
}

export default NotifyItem