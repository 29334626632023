class ProfileBio {
    id;
    firstName;
    lastName;
    middleName;
    profileType;
    photoUrl;
    rate;
    city;
    created;

    constructor(id, firstName, lastName, middleName, profileType, photoUrl, rate, city, created) {
        this.id = id
        this.firstName = firstName
        this.lastName = lastName
        this.middleName = middleName
        this.profileType = profileType
        this.photoUrl = photoUrl
        this.rate = rate
        this.city = city
        this.created = created
    }
}

export default ProfileBio;