import _ from 'lodash';
import EducationVuuteParser from '../../vuuteServices/parsers/EducationVuuteParser';
import ProfileEducation from '../model/ProfileEducation';

import JSONParser from '../../common/parsers/JSONParser'

class ProfileEducationParser extends JSONParser {
    parse() {
        super.parse()
        let educationObject = new EducationVuuteParser(_.get(this.data, 'education_object')).parse()
        let profileEducation = new ProfileEducation(_.get(this.data, 'id'), 
                                                    educationObject, 
                                                    _.get(this.data, 'specialty'),
                                                    _.get(this.data, 'date_started'),
                                                    _.get(this.data, 'date_finished'))
        return profileEducation
    }
}

export default ProfileEducationParser;