import React from 'react'
import Parser from 'html-react-parser';

import DimensionParser from '../../services/DimensionParser';

class ServiceItem extends React.Component {

  render() {

    let price = ''
    let title = ''
    let dimesion = ''

    if (this.props.service) {
      price = this.props.service.price
      title = this.props.service.serviceObject.title
      dimesion = DimensionParser.getDimension(this.props.service.serviceObject.dimension)
    }

    return (
        <React.Fragment>
            <tr>
                <td><span>{title}</span></td>
                <td>{price}<span className="rouble">₽</span> / {Parser(dimesion)}</td>
            </tr>
        </React.Fragment>
    );
  }
}

export default ServiceItem