import React from 'react'

import UnauthorisedMenu from '../containers/UnauthorisedMenu';
import ClientMenu from '../../common/clientMenu';
import MasterMenu from '../../common/masterMenu';

import AuthService from '../../services/AuthService';

class Menu extends React.Component {

    render () {   

        const isAuthorised = AuthService.shared().isCorrectUserExist()

        if (isAuthorised) {
            const profileBio = AuthService.shared().getProfileBio()
            const profileType = profileBio.profileType

            switch (profileType) {
                case 'CLIENT': 
                    return (<ClientMenu/>)
                case 'MASTER':
                    return (<MasterMenu/>)
                default:
                    return (<UnauthorisedMenu/>)
            } 
        } else {
            return (<UnauthorisedMenu/>)
        }

        
    }
}

export default Menu