import React from 'react'
import AuthService from '../../services/AuthService'

import ContractItem from './ContractItem'

import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { useParams, withRouter } from 'react-router-dom';

import * as profileActions from '../../store/profile/actions';
import * as profileSelectors from '../../store/profile/reducer';


function EmptyData(props) {
  let isMyProfile = AuthService.shared().isMyProfile()
  return (
    <div className="empty-data">
    { isMyProfile ?
      (<p>На данный момент у Вас нет выполненных заказов</p>)
      :
      (<p>На данный момент мастер еще не выполнил ни одного заказа.</p>)
    }

    </div> 
  )
}

function ContractItemsList(props) {

  let table = []

  const contracts = props.closedJobs

  contracts.forEach(element => {
      table.push(<ContractItem job={element} />)
  });

  return table
}

class Contracts extends React.Component {
  state = {
    isHidden: true
  }

  constructor(props) {
    super(props);
    autoBind(this)
  }

  componentDidMount() {
    let urlParamId = this.props.match.params.id
    if (urlParamId === undefined) {
      urlParamId = AuthService.shared().getProfileBio().id
    }
    // todo: check -> crash
    // let profileId = AuthService.shared().getProfileBio().id
    this.props.dispatch(profileActions.getContracts(urlParamId))
  }

  

  render() {

    
    let closedJobs = this.props.finishedJobs[2]

    return (  
      <section className="contracts page-section hidden-wrap">
          <header className="config-header">
              <h2 className="page-section-title">Последние заказы</h2>
              {/* {this.state.isHidden ? (
                <button className="all-btn js-show-hidden" type="button" onClick={this.isHandleClick}>Посмотреть все</button>
              ) : (
                <button className="all-btn js-show-hidden" type="button" onClick={this.isHandleClick}> Cкрыть все</button>
              )} */}
          </header>
          { closedJobs.length > 0 ? 
          (
            <div className="hidden-content opened">
              <ContractItemsList closedJobs={closedJobs}/>
            </div>
          ) : (
            <EmptyData />
          )}
             
          {/* <ContractItem/> */}
          {!this.state.isHidden ? (
            <div className="hidden-content-opened">
              {/* <ContractItem/> */}
              {/* <ContractItem/> */}
            </div>
          ) : ('')}
      </section>
    );
  }

  isHandleClick = () => {
    this.setState({
        isHidden: !this.state.isHidden
    });
  }
}

function mapStateToProps(state) {
  const finishedJobs = profileSelectors.getProfileJobs(state)
  return {
    finishedJobs,
  }
}

export default withRouter(connect(mapStateToProps)(Contracts));



