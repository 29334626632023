import _ from 'lodash';
import CountryVuuteParser from '../parsers/CountryVuuteParser';

import JSONParser from '../../common/parsers/JSONParser'

class CountriesVuuteParser extends JSONParser {
    parse() {
        let data = super.parse()
        if (data === null) { return null }

        let countries = []
        _.forEach(this.data, function(countryData) {
            let country = new CountryVuuteParser(countryData).parse()
            countries.push(country)
        });
        

        return countries
    }
}

export default CountriesVuuteParser;