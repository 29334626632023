import ProfileBio from "./ProfileBio";


class ClientBio extends ProfileBio {
    description;

    constructor(id, firstName, lastName, middleName, photoUrl, rate, city, created, description) {
        super(id, firstName, lastName, middleName, 'CLIENT', photoUrl, rate, city, created)
        this.description = description
    }
}

export default ClientBio;