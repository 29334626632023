import React from 'react'

import ReviewFooter from './ReviewFooter'

class ReviewContent extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {

    const review = this.props.review

    const unShowContent = { display: 'none' }
    const showContent = { display: 'block' }

    let reviewText = ''
    if (review !== null && review !== undefined) { 
      reviewText = review.reviewText
    }

    return (  
        <div className="acc-item__content js-acc-content" style={this.props.isContentOpened ? showContent : unShowContent}>
            {/* <div className="review__title">Сроки соблюдены, цена не изменилась, работы выполнены качественно.</div> */}
            <div className="review__body">
                <p>{reviewText}</p>
            </div>
            <ReviewFooter review={review}/>
        </div>
    );
  }
}

export default ReviewContent