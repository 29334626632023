import React from 'react'
import AuthService from '../../services/AuthService';

import ServiceItem from './ServiceItem'

class Prices extends React.Component {

  constructor(props) {
    super(props);
  }


  createTable() {

    const services = this.props.services

    let table = []

    let children = []
    services.forEach(element => {
      children.push(<ServiceItem service={element}/>)
    });

    //Create the parent and add the children
    table.push(children)
    
    return table
  }

  render() {
    var isEmpty = true
    if (this.props.services) {  
      const services = this.props.services
      if (services.length == 0) {
        isEmpty = true
      } else {
        isEmpty = false
      }
    }

    let isMyProfile = AuthService.shared().isMyProfile()

    return (
        <section className="user-card-section">
          <h2 className="user-card-section-title">Стоимость услуг</h2>
          <div className="not-editable">
              {isEmpty ? (
                <div className="empty-data">
              { isMyProfile ?
                (<p>Пожалуйста, укажите перечень услуг, которые Вы оказываете, иначе заказчики не смогут Вас найти.</p>)
                :
                (<p>Мастер не указал свой перечень услуг.</p>)
              }
                    
                </div>                
              ) : (
                <table className="params-tbl">
                  {this.createTable()}
                </table>
              )}
          </div>
        </section>  
    );
  }
}

export default Prices