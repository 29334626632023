import React from 'react'
import Parser from 'html-react-parser';
import DateService from '../../services/DateService'

import noAva from '../../images/no-ava.png'

import Rate from '../../common/Rating';
import DimensionParser from '../../services/DimensionParser';

class FindMasterItem extends React.Component {

    createProfileServicesTable(services) {

        let table = []
    
        services.forEach(element => {

            const dimesion = DimensionParser.getDimension(element.serviceObject.dimension)

            table.push(
                <tr>
                    <td><span>{element.serviceObject.title}</span></td>
                    <td>{element.price}<span className="rouble">₽</span> / {Parser(dimesion)}</td>
                </tr>
            )
    
        });
    
        return table
    }

    render() {

        const profile = this.props.profile
        var city = "Не определен"
        if (profile.bio.city != null) {
            if (profile.bio.city.title != undefined) {
                city = profile.bio.city.title
            }
        }

        var registeredAtDateString = ""
        if (profile.bio.created != undefined) {
            var registeredAtDate = new Date(profile.bio.created)
            registeredAtDateString = DateService.getCreatedAtDateToStr(registeredAtDate)
        }

        var photoLink = ''
        if (profile.bio.photoUrl !== undefined) {
            photoLink = profile.bio.photoUrl
        }

        return (
            <div className="masters-catalog-item catalog-item">
                <div className="masters-catalog-item__main catalog-item__main">
                    <div className="masters-catalog-item__person">
                        {(photoLink !== '') ? 
                            <div className="masters-catalog-item__ava"><img src={photoLink} alt="" /></div>
                            :
                            <div className="masters-catalog-item__ava"><img src={noAva} alt="" /></div>
                        }
                        <div>
                            <a className="masters-catalog-item__name person-name" href="javascript:;" onClick={(e) => this.onMasterItemClicked(profile.bio.id, e)}>
                                <span className="person-name__surname">{profile.bio.lastName}</span>
                                <span className="person-name__name">{profile.bio.firstName}</span>
                                <span className="person-name__patr">{profile.bio.middleName}</span></a>
                            <div className="masters-catalog-item__props">
                                <div className="location">{city}</div>
                                {/* <div className="state">Сейчас онлайн</div> */}
                            </div>
                        </div>
                    </div>
                    <table className="data-tbl">
                        <tbody>
                            {this.createProfileServicesTable(profile.services)}
                        </tbody>
                    </table>
                </div>
                <div className="masters-catalog-item__sidebar catalog-item__sidebar">
                    <div className="rating rating--sm">
                        <Rate rating={profile.bio.rate} isShow={true} isReadOnly={true} />
                    </div>
                    <table className="stat-tbl">
                        <tbody>
                            {/* <tr>
                                <td>Средний чек:</td>
                                <td>NaN<span className="rouble">₽</span></td>
                            </tr>
                            <tr>
                                <td>Заказы:</td>
                                <td>NaN</td>
                            </tr> */}
                            <tr>
                                <td>Регистрация:</td>
                                <td>{registeredAtDateString}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    onMasterItemClicked(profileId, event) {
        this.props.onProfileItemClick(profileId, event)
    }


}

export default FindMasterItem