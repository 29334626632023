import React from 'react'

import ClientOrderItem from './ClientOrderItem';

class ClientNewOrders extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.newOrders.length > 0) {
            var newOrderItems = this.props.newOrders.map((order) =>
                <ClientOrderItem
                    key={order.id.toString()}
                    orderInfo={order}
                    onNewOrderClick={this.props.onNewOrderClick}
                />
            )
        }

        return (
            <section className="active-orders page-section">
                <header>
                    <h2 className="page-section-title">Активные заказы</h2>
                </header>
                <ul className="orders-list marked-list">
                    {this.props.newOrders.length > 0 ? (
                        <React.Fragment>
                            {newOrderItems}
                        </React.Fragment>
                    ) : (
                            <div className="empty-data">
                                <p>На данный момент у Вас нет активных заказов.</p>
                            </div>
                        )}
                </ul>
            </section>
        );
    }
}

export default ClientNewOrders