import React from 'react'
import autoBind from 'react-autobind';
import _ from 'lodash';

import SelectList from '../../common/SelectList'

class CategoryList extends React.Component {
    
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            isSelectListOpened: false
        }
    }

    prepareSelectListData() {

        const vuuteCategories = this.props.availableCategories
        var selectedCategoryId = undefined
        if (this.props.orderSelectedCategory !== undefined) {
            selectedCategoryId = this.props.orderSelectedCategory.id
        }

        var data = []

        _.forEach(vuuteCategories, function(value) {

            var item = {}

            if (selectedCategoryId !== undefined) {
                if (selectedCategoryId == value.id) {
                    item = {
                        id: value.id,
                        text: value.title,
                        selected: true,
                    }
    
                } else {
                    item = {
                        id: value.id,
                        text: value.title,
                    }
                }
            } else {
                item = {
                    id: value.id,
                    text: value.title,
                }
            }

            data.push(item)
        });

        return [data, selectedCategoryId]

    }

    render() {

        const [data, selectedCategoryId] = this.prepareSelectListData()

        return (
            <React.Fragment>
                <SelectList
                    data={data}
                    selectedId={selectedCategoryId}
                    onSelectListChange={this.onSelectListChange}
                    onSelectListOpen={this.onSelectListOpen}
                    onSelectListClose={this.onSelectListClose}
                />
            </React.Fragment>
        );
    }

    onSelectListChange(inputId, event) {
        if (this.state.isSelectListOpened) {
          return
        }

        this.props.onCategoryChanged(event.params.data.id)
    }

    onSelectListOpen = () => {
        this.setState({
            isSelectListOpened: true
        })
    }
            
    onSelectListClose = () => {
        this.setState({
            isSelectListOpened: false
        })
    }
}

export default CategoryList