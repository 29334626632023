import React from 'react'

class EducationItem extends React.Component {

  render() {

    let speciality = ''
    let educationTitle = ''
    let startYear = ''
    let endYear = ''

    if (this.props.education) {
      speciality = this.props.education.speciality
      educationTitle = this.props.education.educationObject.title
      const startDate = new Date(this.props.education.dateStarted)
      startYear = startDate.getFullYear()
      const endDate = new Date(this.props.education.dateFinished)
      endYear = endDate.getFullYear()
    }

    return (
        <div class="user-edu-item">
            <div class="user-edu-item__data">
                <div class="user-edu-item__spec"><span class="js-edu-spec">{speciality}</span>, <span class="js-edu-college">{educationTitle}</span></div>
                <div class="user-edu-item__date js-edu-date"><span class="date-from">{startYear}</span> — <span class="date-to">{endYear}</span></div>
            </div>
        </div>
    );
  }
}

export default EducationItem