import Immutable from 'seamless-immutable';
import * as types from './actionsTypes';
import AuthFormType from '../../enum/AuthFormType';
import { type } from 'jquery';

const initialState = Immutable({
    token: '',
    user: undefined,
    error: undefined,
    authFormType: AuthFormType.LOGIN,
})

export default function reduce(state = initialState, action = {}) {
    
    switch(action.type) {
        case types.LOGIN:
            return state.merge({
                token: action.token,
                user: action.user,
                error: action.error,
            });
        case types.AUTH_FORM_TYPE_CHANGED:
            return state.merge({
                authFormType: action.authFormType
            });
        case types.LOGOUT:
            return state.merge({
                token: '',
                user: undefined,
                error: undefined,
                authFormType: AuthFormType.LOGIN,
            })
        case types.CLEAR_AUTH_FORM_ERRORS:
            return state.merge({
                error: undefined,
            })
        case types.CLEAR_AUTH_FORM:
            return initialState
        default:
            return state;
    }
}


//selectors
export function getUser(state) {
    return [state.auth.user, state.auth.token, state.auth.error]
}

export function getAuthFormType(state) {
    return state.auth.authFormType
}