import _ from 'lodash';
import ProfileBioParser from '../../profile/parsers/ProfileBioParser';
import Review from '../model/Review';

import JSONParser from '../../common/parsers/JSONParser'

class ReviewParser extends JSONParser {

    parse() {
        super.parse()

        let owner = new ProfileBioParser(_.get(this.data, 'profile_owner')).parse()

        let review = new Review(_.get(this.data, 'id'),
                                owner, 
                                _.get(this.data, 'rate'),
                                _.get(this.data, 'review_text'),
                                _.get(this.data, 'quality_rate'),
                                _.get(this.data, 'deadline_rate'),
                                _.get(this.data, 'communication_rate'),
                                _.get(this.data, 'created_date'))

        return review

    }

}

export default ReviewParser;