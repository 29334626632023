import React from 'react'
import _ from 'lodash';

import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { menuItem } from '../../page/components/MenuEnum';

import Menu from '../../page/components/Menu'
import Footer from '../../page/components/Footer'
import NotificationsListView from '../components/NotificationsListView';
import Page404 from '../../page/components/Page404'

import * as notificationActions from '../../store/notification/actions'
import * as notificationSelectors from '../../store/notification/reducer'

import * as menuActions from '../../store/menu/actions';

import { withRouter } from 'react-router-dom'

class NotificationsContainer extends React.Component {
    constructor(props) {
        super(props);

        autoBind(this);
    }

    componentWillMount() {
        this.props.dispatch(menuActions.selectMenuItem(menuItem.UNSELECTED));
    }

    componentDidMount() {
        this.props.dispatch(notificationActions.getNotifications())
    }

    render() {
        if (this.props.notifications) {
            return (
                <div className="page">
                    <Menu />
                    <div className="bg-block">
                        <div className="container">
                            <div className="notifications">
                                <div className="notifications__header">
                                    {this.props.unread === 0 ? (
                                        <h1 className="notifications__title">Непрочитанных уведомлений нет</h1>
                                    ) : (
                                            <React.Fragment>
                                                <h1 className="notifications__title">Непрочитанных уведомлений: <span>{this.props.unread}</span></h1>
                                                <button className="notification-read-btn btn--1 btn--lg" type="button" onClick={this.onAllNotifReadClick}>Все прочитаны</button>
                                            </React.Fragment>
                                        )}
                                </div>
                                <NotificationsListView
                                    notifications={this.props.notifications}
                                    onUnreadNotificationClick={this.onUnreadNotificationClick}
                                    currentPaginatorPage={this.props.currentPaginatorPage}
                                    onCurrentPageChanged={this.onCurrentPageChanged}
                                />
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            )
        } else {
            return <div></div>
            // return <Page404 />
        }
    }

    onAllNotifReadClick() {
        this.props.dispatch(notificationActions.readAllNotifications())
    }

    onUnreadNotificationClick(notificationId) {
        this.props.dispatch(notificationActions.readSingleNotification(notificationId))
    }

    onCurrentPageChanged(newCurrentPage) {
        this.props.dispatch(notificationActions.changeCurrentPage(newCurrentPage))
    }
}

function mapStateToProps(state) {
    const [unread, notifications, currentPaginatorPage] = notificationSelectors.getNotifications(state)

    return {
        unread,
        notifications,
        currentPaginatorPage,
    }
}

export default withRouter(connect(mapStateToProps)(NotificationsContainer));