import _ from 'lodash';
import * as geolocationTypes from './actionTypes';
import geolocationService from '../../services/GeolocationService';

export function clearStore() {
    return ({ type: geolocationTypes.CLEAR_STORE})
}

export function changeAddress(newAddress) {
    return ({ type: geolocationTypes.ADDRESS_CHANGED, inputAddress: newAddress })
}

export function getdAddressByInputData(newAddress) {
    return async (dispatch, getState) => {
        try {
            if (newAddress) {
                var availableAddress = await geolocationService.getAddresses(newAddress);
                var [latitude, longtitude] = await geolocationService.getCoordinates(availableAddress)

                if (availableAddress.houseNumber !== undefined) {
                    availableAddress.address = availableAddress.country + ', ' + availableAddress.city + ', '
                        + availableAddress.street + ', ' + availableAddress.houseNumber
                } else {
                    if (availableAddress.street !== undefined) {
                        availableAddress.address = availableAddress.country + ', ' + availableAddress.city + ', ' + availableAddress.street
                    } else {
                        if (availableAddress.city !== undefined) {
                            availableAddress.address = availableAddress.country + ', ' + availableAddress.city
                        } else {
                            if (availableAddress.country !== undefined) {
                                availableAddress.address = availableAddress.country
                            } else {
                                availableAddress.address = ''
                            }
                        }
                    }
                }

                dispatch({ type: geolocationTypes.GET_ADDRESS_BY_INPUT_DATA, availableAddress: availableAddress, newLatitude: latitude, newLongtitude: longtitude });
            } else {
                dispatch({ type: geolocationTypes.GET_ADDRESS_BY_INPUT_DATA, availableAddress: undefined, newLatitude: undefined, newLongtitude: undefined });
            }
        } catch (error) {
            console.error(error)
        }
    }
}

export function changeLocation(newCoords) {
    return async (dispatch, getState) => {
        try {
            const { latLng } = newCoords;
            const latitude = latLng.lat();
            const longtitude = latLng.lng();

            var address = await geolocationService.getAddressesByCoords(latitude, longtitude)

            if (address.Address.HouseNumber !== undefined) {
                var newInputAddress = address.Address.AdditionalData[0].value + ', ' + address.Address.City + ', ' + address.Address.Street + ', ' + address.Address.HouseNumber
            } else {
                if (address.Address.Street !== undefined) {
                    newInputAddress = address.Address.AdditionalData[0].value + ', ' + address.Address.City + ', ' + address.Address.Street
                } else {
                    if (address.Address.City !== undefined) {
                        newInputAddress = address.Address.AdditionalData[0].value + ', ' + address.Address.City
                    } else {
                        if (address.Address.AdditionalData[0].value !== undefined) {
                            newInputAddress = address.Address.AdditionalData[0].value
                        } else {
                            newInputAddress = ''
                        }
                    }
                }
            }

            dispatch({
                type: geolocationTypes.LOCATION_CHANGED, newInputAddress: newInputAddress, availableAddresses: address,
                newLatitude: latitude, newLongtitude: longtitude
            });

        } catch (error) {
            console.error(error)
        }
    }
}

export function importOrderLocation(address, latitude, longtitude) {
    return ({type: geolocationTypes.IMPORT_ORDER_LOCATION, inputAddress: address, atitude: latitude, longtitude: longtitude})
}
