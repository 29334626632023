import React from 'react'
import AuthService from '../services/AuthService';

import EditButton from './EditButton'

class ProfileNameItem extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {

    let firstName = ''
    let lastName = ''
    let middleName = ''
    if (this.props.bio) {
      firstName = this.props.bio.firstName
      lastName = this.props.bio.lastName
      middleName = this.props.bio.middleName
    }
    
    let isMyProfile = AuthService.shared().isMyProfile()

    return (  
      <div className="person-info__name person-name not-editable">
        {lastName.length > 0 && 
          <span className="person-name__surname">{lastName}</span>
        }
        
        {firstName.length > 0 && 
          <span className="person-name__name">{firstName}</span>
        }

        {middleName.length > 0 && 
          <span className="person-name__patr">{middleName}</span>
        }
        
        {isMyProfile ? (<EditButton onClick={this.props.onEditClick}/>) : ''}
      </div>
    );
  }

}

export default ProfileNameItem