import _ from 'lodash';
import ProfileExperienceParser from './ProfileExperienceParser';
import JSONParser from '../../common/parsers/JSONParser'


class ProfileExperiencesParser extends JSONParser {
    parse() {
        super.parse()
        let experiences = []
        _.forEach(this.data, function(experienceData) {
            let experience = new ProfileExperienceParser(experienceData).parse()
            experiences.push(experience)
        });
        
        
        return experiences
    }
}

export default ProfileExperiencesParser;