import React from 'react';
import { Element, Events, scroller } from 'react-scroll';
import MessageItem from './MessageItem';
import CustomScrollbars from './CustomScrollbars';
import * as chatActions from '../../../store/chat/actions';
import * as chatSelectors from '../../../store/chat/reducer';
import '../../../css/blocks/messages-chat/__separator/messages-chat__separator.css';
import '../../../css/blocks/messages-chat/__separator-date/messages-chat__separator-date.css';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';


/**
 * View which show messages list for selected room
 *
 * @class MessagesListView
 * @extends {React.Component}
 */

class MessagesListView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			areNewMessagesLoaded: false,
		};
	}

	//Does user scrolled to top? If yes -> load more messages
	isTop() {
		const parentEl = document.getElementsByClassName('mCSB_container')[0];
		return parentEl.scrollTop === 0
	}

	//Track chat view scrolling
	trackScrolling = () =>
	{
		if (this.isTop() && !this.state.areNewMessagesLoaded && !this.props.messagesAllMessagesLoaded) {
			
			this.props.onLoadMoreMessages();
			this.setState({ areNewMessagesLoaded: true });
			
		} else {
			this.setState({ areNewMessagesLoaded: false });
		}
	};


	componentWillReceiveProps = (nextProps) => {
		if (nextProps.firstMessageId !== this.props.firstMessageId)
		{
				const previousMessage = document.querySelector(`#chat-message-${this.props.firstMessageId}`);
				const currentMessage = document.querySelector(`#chat-message-${nextProps.firstMessageId}`);
			if (previousMessage != null)
			{
				const scroller = document.querySelector(`#chatContainer`);
				const container = document.querySelector(`#messages`);
				scroller.scrollTop += previousMessage.getBoundingClientRect().top  - currentMessage.getBoundingClientRect().top
			}
		 }
  }
	/**
	 * Generate table view loaded messages
	 *
	 * @returns
	 * @memberof MessagesListView
	 */
	generateMessagesList() {
		const messagesList = [];

		const { messages, selectedChat } = this.props;

		let profileId = 0;

		// try to get sender id
		if (selectedChat !== undefined) {
			profileId = selectedChat.profile.id;
		} else {
			return messagesList;
		}

		// order number for message in list
		let counter = 0;

		const compareMessagesDates = (previousMessageObj, currentMessageObj) => {
			const getDate = (messageObj) =>
				messageObj.created.toISOString().split('T')[0];
			return getDate(previousMessageObj) === getDate(currentMessageObj);
		};

		const convertDate = (wrongFormatDate) => {
			const months = [
				'января',
				'февраля',
				'марта',
				'апреля',
				'мая',
				'июня',
				'июля',
				'августа',
				'сентября',
				'октября',
				'ноября',
				'декабря',
			];

			const timeCancel = wrongFormatDate;
			const date = new Date(timeCancel);
			const m = date.getMonth();
			const currentMonth = months[m];

			return currentMonth;
		};

		// generate messages list
		messages.forEach((element, index) => {
			if (index === 0 && element.id != this.props.firstMessageId)
			{

				this.props.updateFirstMessageId(element.id);
			}
		
			if (index === 0 || !compareMessagesDates(messages[index - 1], element)) {
				// Если текущее сообщение первое || дата текущего и предыдущего сообещния сообщений не одинакова, то добавить разделитель в виде даты
				messagesList.push(
					<div className='messages-chat__separator'>
						<span className='messages-chat__separator-date'>
							{`${
								element.created
									.toISOString()
									.split('T')[0]
									.split('-')
									.reverse()[0]
							} ${convertDate(element.created.toISOString())}`}
						</span>
					</div>
				);
			}
			messagesList.push(
				<MessageItem
					key={element.id}
					message={element}
					orderNumber={counter}
					chatRoomProfile={profileId}
					index={index}
				/>
			);
			counter += 1;
		});

		messagesList.unshift(
			<div id='first_el' ref={(el) => (this.firstItem = el)} />
		);

		messagesList.push(<Element name='last_element' />);
		return messagesList;
	}

	/**
	 * Basic render function for React component
	 *
	 * @returns
	 * @memberof MessagesListView
	 */
	render() {
		return (
			<div className='messages-chat__body js-scrollable'>
				<CustomScrollbars
					messages={this.props.messages}
					isTop={() => this.isTop()}
					onScroll={() => this.trackScrolling()}
					onLoadMoreMessages={() => this.props.onLoadMoreMessages()}
				>
					{this.generateMessagesList()}
					{/* {this.scrollToBottom()} */}
				</CustomScrollbars>
			</div>
		);
	}
}


export default MessagesListView;