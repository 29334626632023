import React from "react"
import MainPromo from './promo/components/MainPromo'
import MainPromoFooter from './promo/components/MainPromoFooter'
import Advantages from './advantages/components/Advantages'
import Steps from './steps/components/Steps'
import Reviews from './reviews/components/Reviews'
import Footer from '../Footer'
import CookiePopup from '../../../common/CookiePopup'

import AuthService from '../../../services/AuthService';

import Menu from '../Menu'

import { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router-dom'

class MainPage extends React.Component {

    componentWillMount() {
        const isAlreadyLoggedIn = AuthService.shared().isCorrectUserExist()
        if (isAlreadyLoggedIn) {
            // return <Redirect to='/profile' />
            this.props.history.push('/profile')
        }
    }

    render() {

        return (
            <div className="page page--main">
                <Menu />
                <MainPromo />
                <MainPromoFooter />
                <Advantages />
                <Steps />
                <Reviews />
                <Footer />
                <CookiePopup/>
            </div>
        )
    }

}

// export default MainPage
export default withRouter(MainPage);