import _ from 'lodash';
import * as types from './actionsTypes';
import apiService from '../../services/APIService';
import AuthService from '../../services/AuthService';
import WSService from '../../services/WSService';
import AuthFormType from '../../enum/AuthFormType';
import * as authSelectors from './reducer';
import { async } from 'q';
// import * as authActions from '../auth/'

export function login(phone, password) {
    return async(dispatch, getState) => {
        try {
            dispatch({type: types.LOGIN, 
                      token: '',
                      user: undefined,
                      error: undefined});

            const authData = await apiService.login(phone, password);

            if (authData.token != null) {
                AuthService.shared().saveCredentials(authData.user, authData.token)
                const profileData = await apiService.getProfile()
                if (profileData.profile !== null) {
                    AuthService.shared().saveProfileBio(profileData.profile.bio)
                }
            }

            dispatch({type: types.LOGIN, 
                      token: authData.token,
                      user: authData.user,
                      error: authData.error});
        } catch (error) {
            console.error(error)
        }
    }
}

export function logout() {
    return async(dispatch, getState) => {
        try {
            WSService.shared().closeSockets()
            await apiService.logout();
            AuthService.shared().clearCredentials()
            dispatch({type: types.LOGOUT});
            window.location.href = "/"
        } catch (error) {
            console.error(error)
        }
    }
}

export function loginFormTypeSelected() {
    return ({ type: types.AUTH_FORM_TYPE_CHANGED, authFormType: AuthFormType.LOGIN})
}

export function signUpFormTypeSelected() {
    return ({ type: types.AUTH_FORM_TYPE_CHANGED, authFormType: AuthFormType.REGISTER})
}

export function clearAuthErrors() {
    return ({ type: types.CLEAR_AUTH_FORM_ERRORS})
}

export function clearAuthForm() {
    return ({ type: types.CLEAR_AUTH_FORM})
}