import React from 'react'
import DateService from '../services/DateService';
import SvgIcon from './SvgIcon';

import autoBind from 'react-autobind';
import { withRouter } from 'react-router-dom'

function EmptyGrade(props) {
  return (
      <div></div>
  )
}

function FilledGrade(props) {

  const review = props.review
  let createdDate = DateService.toDayMonthYearDateTransform(new Date(review.createdDate))
  const isPositive = (review.rate >= 4.0)

  return (
    <div className="grade">
            <div className="grade__meta"><a className="grade__name" href="javascript:;" onClick={(e) => props.onProfileClick(review.owner.id, e)}>{review.owner.firstName}</a>
                <div className="grade__date">{createdDate}</div>
            </div>
            {isPositive ? (
              <div className="grade__mark grade__mark--positive">
                <SvgIcon name='icon-like'/>
              </div>
            ) : (
              <div className="grade__mark grade__mark--negative">
                <SvgIcon name='icon-dislike'/>
              </div>
            )}
        </div>
  )
}

class Grade extends React.Component {

  constructor(props) {
    super(props);
    autoBind(this);
  }

  render() {
    const review = this.props.review
    // var isPositive = true
    // let createdDate = ''
    let isShowReview = true
    if ((review === undefined) || (review === null)) {
      isShowReview = false
      // if (review.rate !== undefined) {
      //   isPositive = (review.rate >= 4.0)
      // } else {
      //   if (this.props.isPositive !== undefined) {
      //     isPositive = this.props.isPositive
      //   } else {
      //     isPositive = true
      //   }
      // }

      // if (review.createdDate !== undefined) {
      //   createdDate = DateService.toDayMonthYearDateTransform(new Date(review.createdDate))
      // } else {
      //   createdDate = ''
      // }
    } 

    
    
    
    return (  
      <React.Fragment>
      {isShowReview ? (
        <FilledGrade review={review} onProfileClick={this.onProfileClick}/>
      ) : (
        <EmptyGrade />
      )}
      </React.Fragment>
        // <div className="grade">
        //     <div className="grade__meta"><a className="grade__name" href="javascript:;" onClick={(e) => this.onProfileClick(review.owner.id, e)}>{review.owner.firstName}</a>
        //         <div className="grade__date">{createdDate}</div>
        //     </div>
        //     {isPositive ? (
        //       <div className="grade__mark grade__mark--positive">
        //         <SvgIcon name='icon-like'/>
        //       </div>
        //     ) : (
        //       <div className="grade__mark grade__mark--negative">
        //         <SvgIcon name='icon-dislike'/>
        //       </div>
        //     )}
        // </div>
    );
  }

  onProfileClick(profileId, e) {
    e.preventDefault()
    this.props.history.push(`/profile/${profileId}/`)
    this.props.history.go(0)
  }
}

export default withRouter(Grade);