import React from 'react'
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

import logo from '../images/logo.svg'
import logo_short from '../images/logo-short.svg'

import SvgIcon from './SvgIcon'
import DropDownMenu from './DropDownMenu'
import { menuItem } from '../page/components/MenuEnum';

import * as authActions from '../store/auth/actions';
import * as menuSelectors from '../store/menu/reducer';
import * as menuActions from '../store/menu/actions';
import * as chatActions from '../store/chat/actions';
import AuthService from '../services/AuthService';
import WSService from '../services/WSService';

//TODO: REFACTOR. Create authorised menu with general logic. And separate client/master menus with separate logic
class MasterMenu extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isHamburgerMenuOpen: false,
        }

        autoBind(this);
    }

    componentWillMount() {
        this.props.dispatch(menuActions.getUnreadCountMsgRequested());

        WSService.shared().configureStreamClient()
    }

    renderMsgNotification(isFullSize, unreadMsgCount) {
        if (unreadMsgCount != 0) {
            if (isFullSize) {
                return <span className="items-count msg-count">{unreadMsgCount}</span>;
            } else {
                return <span className="user-msg__count">{unreadMsgCount}</span>;
            }
        } else {
            return '';
        }
    }

    render() {

        const selectedMenu = this.props.selectedMenu
        const unreadMsgCount = this.props.unreadMsgCount
        const user = AuthService.shared().getUser()
        const profileBio = AuthService.shared().getProfileBio()

        return (
            <React.Fragment>
                <header className="page-header">
                    <div className="page-header__inner">
                        <div className="container"><a className="logo" href="/"><img className="logo-lg-img" src={logo} alt="" /><img className="logo-sm-img" src={logo_short} alt="" /></a>
                            {/* <form className="search-job-frm">
                                <div className="input-wrap select-input-wrap">
                                    <input className="input-sm search-input" id="search-job" type="text" placeholder="Найти заказ" data-search-category="cat-2" />
                                    <select className="input-select js-input-select" id="search-job-select" name="search-job-select" data-input="#search-job">
                                        <option value="cat-1">Найти исполнителя</option>
                                        <option value="cat-2" selected>Найти заказ</option>
                                    </select>
                                    <button className="search-btn" type="submit">
                                        <SvgIcon name='icon-search' />
                                    </button>
                                </div>
                            </form> */}
                            {/* <nav className="fixed-main-menu menu"><a className="fixed-main-menu__item menu__item" href="javascript:;">Заказы</a><a className="fixed-main-menu__item menu__item" href="javascript:;">Исполнители</a></nav> */}
                            {/* begin .main-menu*/}
                            <nav className={this.state.isHamburgerMenuOpen ? "main-menu menu js-main-menu opened" : "main-menu menu js-main-menu"}>
                                {selectedMenu == menuItem.PROFILE ?
                                    <div className="main-menu__item menu__item menu__item--active">Профиль</div> :
                                    <a className="main-menu__item menu__item" href="javascript:;" onClick={this.onProfileClick}>Профиль</a>
                                }
                                {selectedMenu == menuItem.FIND_ORDER ?
                                    <div className="main-menu__item menu__item menu__item--active">Поиск</div> :
                                    <a className="main-menu__item menu__item" href="javascript:;" onClick={this.onFindOrderClick}>Поиск</a>
                                }
                                {selectedMenu == menuItem.CHAT ?
                                    <div className="main-menu__item menu__item menu__item--active">Сообщения{this.renderMsgNotification(true, unreadMsgCount)}</div> :
                                    <a className="main-menu__item menu__item" href="javascript:;" onClick={this.onChatClick}>Сообщения{this.renderMsgNotification(true, unreadMsgCount)}</a>
                                }
                                {selectedMenu == menuItem.JOBS ?
                                    <div className="main-menu__item menu__item menu__item--active">Мои заказы</div> :
                                    <a className="main-menu__item menu__item" href="javascript:;" onClick={this.onMyOrdersClick}>Мои заказы</a>
                                }
                                {/* <a className="add-order-btn btn--2 btn--sm" href="javascript:;" onClick={this.onCreateOrderClick}>Разместить заказ</a> */}
                            </nav>
                            {/* end .main-menu*/}
                            {/*begin .user-menu*/}<a className="user-notify" href="javascript:;" onClick={this.onNotificationsClick}>
                                <SvgIcon name='icon-lamp' />
                            </a><a className="user-msg" href="javascript:;" onClick={this.onChatClick}>{this.renderMsgNotification(false, unreadMsgCount)}
                                <SvgIcon name='icon-cover-opened' />
                            </a>
                            <DropDownMenu name={user.name} icon_src={profileBio.photoUrl}
                                onSettingsClick={this.onSettingsClick}
                                onLogoutClick={this.onLogoutClick}
                                closeHamburgerMenu={() =>
									this.setState({
										isHamburgerMenuOpen: false,
									})
								} />
                            {/*end .user-menu*/}
                            <button className={this.state.isHamburgerMenuOpen ? "menu-btn hamburger js-main-menu-btn active" : "menu-btn hamburger js-main-menu-btn"}
                                type="button"
                                onClick={this.onHamburderMenuClick}>
                                <span /><span /><span /><span />
                            </button>
                        </div>
                    </div>
                </header>
            </React.Fragment>
        )
    }

    onHamburderMenuClick() {
        this.setState({
            isHamburgerMenuOpen: !this.state.isHamburgerMenuOpen
        })
    }

    onProfileClick(event) {
        event.preventDefault();
        this.props.history.push('/profile')
        this.props.history.go(0)
        this.props.dispatch(menuActions.selectMenuItem(menuItem.PROFILE));
        this.props.dispatch(chatActions.deselectChat());
    }

    onFindOrderClick(event) {
        event.preventDefault();
        this.props.history.push('/order_find')
        this.props.dispatch(menuActions.selectMenuItem(menuItem.FIND_ORDER));
        this.props.dispatch(chatActions.deselectChat());
    }

    onChatClick(event) {
        event.preventDefault();
        this.props.history.push('/chat')
        this.props.dispatch(menuActions.selectMenuItem(menuItem.CHAT));
        this.props.dispatch(chatActions.deselectChat());
    }

    onMyOrdersClick(event) {
        event.preventDefault();
        this.props.history.push('/my_jobs')
        this.props.dispatch(menuActions.selectMenuItem(menuItem.JOBS));
        this.props.dispatch(chatActions.deselectChat());
    }

    onCreateOrderClick(event) {
        event.preventDefault()
        this.props.history.push('/orders/create')
        this.props.dispatch(menuActions.selectMenuItem(menuItem.UNSELECTED));
        this.props.dispatch(chatActions.deselectChat());
    }

    onNotificationsClick(event) {
        event.preventDefault()
        this.props.history.push('/notifications')
        this.props.dispatch(menuActions.selectMenuItem(menuItem.UNSELECTED));
        this.props.dispatch(chatActions.deselectChat());
    }

    onSettingsClick(event) {
        event.preventDefault()
        this.props.history.push('/settings')
        this.props.dispatch(menuActions.selectMenuItem(menuItem.UNSELECTED));
        this.props.dispatch(chatActions.deselectChat());
    }

    onLogoutClick(event) {
        event.preventDefault()

        this.props.dispatch(menuActions.selectMenuItem(menuItem.PROFILE));
        this.props.dispatch(chatActions.deselectChat());
        
        this.props.dispatch(authActions.logout());

        

        // this.props.history.push('/')
        // this.props.history.go()
    }

}

function mapStateToProps(state) {
    const selectedMenu = menuSelectors.getCurrentMenuItem(state);
    const unreadMsgCount = menuSelectors.getUnreadMsgCount(state);
    return {
        selectedMenu,
        unreadMsgCount,
    }
}

export default withRouter(connect(mapStateToProps)(MasterMenu));
// export default MasterMenu