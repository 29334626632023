import _ from 'lodash';
import OrderParser from '../parsers/OrderParser'

import JSONParser from '../../common/parsers/JSONParser'

class OrdersParser extends JSONParser {
    parse() {
        let data = super.parse()
        if (data === null) { return null }

        let ordersData = _.get(this.data, 'orders')

        let orders = []
        _.forEach(ordersData, function(orderData) {
            let order = new OrderParser(orderData).parse()
            orders.push(order)
        });
        

        return orders
    }
}

export default OrdersParser;