import React from 'react'

class InlineGalary extends React.Component {

  render() {
    return (
        <div className="atachment inline-gallery">
            <a className="attachment__item inline-gallery__item zoom-link js-open-gallery" href="i/files/cases/case-4.jpg" data-fancybox="gallery-1">
                <img src="https://revladov.com/vuute/i/files/cases/case-6_100x80.jpg" alt="" />
            </a>
            <a className="attachment__item inline-gallery__item zoom-link js-open-gallery" href="i/files/cases/case-5.jpg" data-fancybox="gallery-1">
                <img src="https://revladov.com/vuute/i/files/cases/case-6_100x80.jpg" alt="" />
            </a>
            <a className="attachment__item inline-gallery__item zoom-link js-open-gallery" href="i/files/cases/case-6.jpg" data-fancybox="gallery-1">
                <img src="https://revladov.com/vuute/i/files/cases/case-6_100x80.jpg" alt="" />
            </a>
        </div>
    );
  }
}

export default InlineGalary










