import _ from 'lodash';
import * as smsTypes from './actionTypes';
import * as popupActions from '../popup/actions';
import { async } from 'q';
import apiService from '../../services/APIService';

export function changeSMSCode(newCode) {
    return ({ type: smsTypes.SMS_CODE_CHANGED, smsCode: newCode})
}


export function sendSMSCode() {
    return async(dispatch, getState) => {
        try {
            const smsCodeSendResult = await apiService.sendSMSCode();
            dispatch({type: smsTypes.SEND_CODE, isSent: smsCodeSendResult});
        } catch (error) {
            console.error(error)
        }
    }
}

export function checkSMSCode(enteredValue) {
    return async(dispatch, getState) => {
        try {
            const codesMatchedData = await apiService.checkSMSCode(enteredValue);
            dispatch({type: smsTypes.VERIFY_CODE, isVerified: codesMatchedData.isMatched, error: codesMatchedData.error});
            if (codesMatchedData.isMatched === true) {
                dispatch({type: popupActions.hidePopup()})
                dispatch({type: smsTypes.CLEAR_SMS_FORM})
            }
        } catch (error) {
            console.error(error)
        }
    }

}

export function clearSMSErrors() {
    return ({ type: smsTypes.CLEAR_SMS_FORM_ERRORS})
}

export function clearSMSForm() {
    return ({ type: smsTypes.CLEAR_SMS_FORM})
}