import React from 'react'

import noAva from '../images/no-ava.png'

class ProfilePhoto extends React.Component {

  render() {

    var photoLink = ''
    if (this.props.bio !== undefined) {
      photoLink = this.props.bio.photoUrl
    }
    
    
    return (  
        <div className="person-info__media ava-wrap">
            <div className="ava-img-wrap">
              {(photoLink !== '') ? 
                <img className="ava-img" src={photoLink} alt="" />
                : 
                <img className="ava-img" src={noAva} alt="" />
              }              
            </div>
        </div>
    );
  }
}

export default ProfilePhoto