import Immutable from 'seamless-immutable';
import * as popupTypes from './actionTypes';

const initialState = Immutable({
    isLoginPopupHidden: true,
    isSMSPopupHidden: true,
})

export default function reduce(state = initialState, action = {}) {

    switch(action.type) {
        case popupTypes.AUTH_POPUP_SHOW_CLICKED:
            return state.merge({
                isLoginPopupHidden: false,
                isSMSPopupHidden: true
            });
        case popupTypes.AUTH_POPUP_HIDE_CLICKED:
            return state.merge({
                isLoginPopupHidden: true,
                isSMSPopupHidden: true
            });
        case popupTypes.SMS_POPUP_SHOW_CLICKED:
            return state.merge({
                isLoginPopupHidden: true,
                isSMSPopupHidden: false
            });
        case popupTypes.SMS_POPUP_HIDE_CLICKED:
            return state.merge({
                isLoginPopupHidden: true,
                isSMSPopupHidden: true
            });
        case popupTypes.POPUP_HIDE_CLICKED:
            return state.merge({
                isLoginPopupHidden: true,
                isSMSPopupHidden: true
            });    
        default:
            return state;
    }
}


//selectors
export function getCurrentPopupState(state) {
    const isLoginPopupHidden = state.popup.isLoginPopupHidden;
    const isSMSPopupHidden = state.popup.isSMSPopupHidden;
    
    return [isLoginPopupHidden, isSMSPopupHidden];
}