class MainReview
{
  job;
  review;
  reviewer;
  constructor(job, review, reviewer)
  {
    this.job = job;
    this.review = review;
    this.reviewer = reviewer;
  }
}

export default MainReview;