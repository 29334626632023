import React from 'react'

import SvgIcon from '../../common/SvgIcon';
import dateService from '../../services/DateService'


class CheckOrderHeaderView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orderJustCreated: null
        }
    }

    render() {
        const startDate = dateService.toDayMonthYearDateTransform(this.props.orderInfo.startDate)
        const endDate = dateService.toDayMonthYearDateTransform(this.props.orderInfo.endDate)

        const createdOrderPassedTime = dateService.passedTimeCalc(this.props.orderInfo.createdDate)

        const changeOrderPassedTimeWithShortcut = (seconds) => {
            if (seconds <= 5) {
                this.state.orderJustCreated = true
            }
        }

        if (createdOrderPassedTime.includes('секунд')) {
            const seconds = createdOrderPassedTime.split(' ', 1)
            changeOrderPassedTimeWithShortcut(seconds)
        }

        const orderPrice = this.props.orderInfo.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

        var cityName = ''
        if (this.props.orderInfo.city !== undefined) {
            cityName = this.props.orderInfo.city.title
        }

        return (
            <React.Fragment>
                <div className="job__header js-card-main">
                    <div><a className="job__title" href="javascript:;">{this.props.orderInfo.title}</a>
                        <div className="job__meta">
                            <div className="job__city">{cityName}</div>
                            <div className="job__ago">{this.state.orderJustCreated ? 'только что' : createdOrderPassedTime + ' назад'}</div>
                        </div>
                        <div className="job__meta">
                            <div className="job__city">Сроки заказа: </div>
                            <div className="job__ago">{startDate} - {endDate}</div>
                        </div>
                    </div>
                    <div className="job__price">
                        <div className="job__is-active">
                            <SvgIcon name='icon-time' />
                        </div>
                        <div className="price">{orderPrice}<span className="rouble"> ₽</span></div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default CheckOrderHeaderView