import React, { useState, useEffect } from 'react';
import './disputPopup.css';
import IconClose from '../../../../common/IconClose';
import LoginMsgBox from '../../../../user/components/LoginMsgBox';
function DisputPopup(props) {
	return (
		<div className='popup__wrapper'>
			<div className='popup popup_disput' id='disput-popup'>
				<div className='popup__title'>
					<span>Открытие спора</span>
				</div>

				<form className='popup-frm'>
					<div className='popup__inner'>
						{props.disputError !== undefined ? (
							<LoginMsgBox
								msgText={props.disputError && props.disputError.message}
								msgType={'error-msg'}
							/>
						) : (
							''
						)}
						<textarea
							onChange={props.handleChange}
							id='comment'
							name='master-bid-msg'
							placeholder='Опишите суть проблемы'
						/>
						<button
							onClick={props.handleSubmit}
							type='submit'
							className='popup-frm__submit btn--2'
						>
							Открыть спор
						</button>
					</div>
				</form>
				<IconClose onClick={props.handleClose} />
			</div>
		</div>
	);
}
export default DisputPopup;
