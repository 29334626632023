import React from 'react'

class ClientDescriptionEdit extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {

    let description = ''
    if (this.props.bio) {
      description = this.props.bio.description
    }

    return (  
        <section className="user-card-section">
            <div className="editable">
                <textarea id="customer-profile-desc" name="customer-profile-desc" defaultValue={description}
                onChange={this.props.onDescriptionChanged} />
            </div>
        </section>
    );
  }
}

export default ClientDescriptionEdit