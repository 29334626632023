import React from 'react'

class ProfileNameItemEdit extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {

    let firstName = ''
    let lastName = ''
    let middleName = ''
    if (this.props.bio) {
      firstName = this.props.bio.firstName
      lastName = this.props.bio.lastName
      middleName = this.props.bio.middleName
    }

    return (
        <div className="person-info__name person-name editable">
            <div className="form-row">
                <input type="text" name="master-profile-name" defaultValue={firstName}
                onChange={this.props.onFirstNameChanged} />
                <input type="text" name="master-profile-patronymic" defaultValue={middleName} 
                onChange={this.props.onMidNameChanged} />
                <input type="text" name="master-profile-surname" defaultValue={lastName} 
                onChange={this.props.onLastNameChanged} />
            </div>
        </div>
    );
  }
}

export default ProfileNameItemEdit