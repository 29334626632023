
class ProfileService {
    id;
    serviceObject;
    price;

    constructor(id, serviceObject, price) {
        this.id = id
        this.serviceObject = serviceObject
        this.price = price
    }
}

export default ProfileService;