import SvgIcon from '../../../common/SvgIcon'
import React, { useEffect } from 'react'
import freezeScrollbar from '../../../services/utils/freezeScrollbar'

function Popup(props) {
  const closePopup = evt => {
    freezeScrollbar(false)
    props.onClose(evt)
  }

  const handleSubmit = evt => {
    props.onSubmit(evt)
    closePopup(evt)
  }

  useEffect(() => {
    freezeScrollbar(true)
  }, [])

  return (
    <div className='fancybox-container fancybox-is-open fancybox-can-drag' role='dialog' tabIndex='-1' id='fancybox-container-3' style={{ transitionDuration: '350ms' }}>
      <div className='fancybox-bg'></div>
      <div className='fancybox-inner'>
        <div className='fancybox-stage'>
          <div className='fancybox-slide popup-form fancybox-slide--html fancybox-slide--current fancybox-slide--complete'>
            <div className='popup fancybox-content' id='agree-popup' style={{ display: 'inline-block' }}>
              <button data-fancybox-close className='fancybox-close-small' title='Закрыть' onClick={closePopup}>
                <SvgIcon name='icon-close' />
              </button>
              <div className='popup__title'>
                <span>{props.title}</span>
              </div>
              <form className='popup-frm'>
                <div className='popup__inner'>
                  <p>{props.question}</p>
                  <div className='btns'>
                    {!props.submitBtnText ? (
                      <button className='btns__item btn--1' type='submit' onClick={handleSubmit}>
                      Принять
                      </button>
                    ) : (
                      <button className='btns__item btn--1' type='submit' onClick={handleSubmit}>
                      {props.submitBtnText}
                      </button>
                    )}
                    <button className='btns__item btn--2' onClick={closePopup}>
                      Отмена
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Popup
