import _ from 'lodash';
import NotificationParser from './NotificationParser';

const { default: JSONParser } = require("../../common/parsers/JSONParser");

class NotificationsParser extends JSONParser {
    parse() {
        let data = super.parse()
        if (data === null) { return null }


        let notifications = []
        _.forEach(this.data, function(notificationData) {
            let notification = new NotificationParser(notificationData).parse()
            notifications.push(notification)
        });
        

        return notifications
    }
}

export default NotificationsParser;