import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';

import * as smsActions from '../../store/sms/actions';
import * as smsSelectors from '../../store/sms/reducer';
import * as popupActions from '../../store/popup/actions';
import * as authActions from '../../store/auth/actions';

import { Redirect } from 'react-router-dom';
import freezeScrollbar from '../../services/utils/freezeScrollbar';

import LoginMsgBox from '../components/LoginMsgBox';
import IconClose from '../../common/IconClose';
import '../../css/blocks/popup-frm/_bg/_grey/popup-frm_bg_grey.css';
import '../../css/blocks/popup-frm/__btn/popup-frm__btn.css';

import AuthService from '../../services/AuthService';
class SMSPopupContainer extends Component {
	constructor(props) {
		super(props);
		autoBind(this);
	}

	componentDidMount() {
		this.props.dispatch(smsActions.sendSMSCode());
	}

	render() {
		if (this.props.isVerified === true) {
			this.props.dispatch(smsActions.clearSMSForm());
			return <Redirect to='/profile' />;
		}

		return (
			<>
				<div
					className='fancybox-container fancybox-is-open fancybox-can-drag'
					role='dialog'
					tabIndex={-1}
					id='fancybox-container-3'
					style={{ transitionDuration: '350ms' }}
				>
					<div className='fancybox-bg' />
					<div className='fancybox-slide popup-form fancybox-slide--html fancybox-slide--current fancybox-slide--complete'>
						<div
							className='popup popup--msg popup--sm fancybox-content'
							id='sms-popup'
							style={{ display: 'inline-block' }}
						>
							<div className='popup__title' style={{ justifyContent:'space-between'}}>
								<span>Подтверждение</span>

									<div style={{display:'flex', alignItems:'center',fontSize:'16px',marginRight:'30px',cursor:'pointer',color:'#999'}} onClick={() =>
										{
											AuthService.shared().clearCredentials()
											this.props.dispatch(authActions.signUpFormTypeSelected());
											this.props.dispatch(popupActions.showAuthPopup());
	
											freezeScrollbar(false);
								}}>
									<div className=""> Назад</div>
								</div>
		
								<IconClose
									onClick={() => {
										this.props.dispatch(popupActions.hideSMSPopup());
										freezeScrollbar(false);
									}}
								/>
							</div>
							<form className='popup-frm popup-frm_bg_white'>
								<div className='popup__inner'>
									{this.props.error !== undefined ? (
										<LoginMsgBox
											msgText={this.props.error.message}
											msgType={'error-msg'}
										/>
									) : (
										''
									)}
									<div className='popup__msg'>
										<p>
											Мы отправили СМС-код <br />
											на указанный вами телелефон.
										</p>
										<p>Введите его, пожалуйста</p>
									</div>
									<input
										id='sms-code'
										type='text'
										name='sms-code'
										placeholder='Код из СМС'
										value={this.props.smsCode}
										onChange={this.onSMSCodeChanged}
									/>
								</div>
								{/* <button className='popup-frm__btn' type='button'>
									Изменить номер телефона
								</button> */}
								<button
									className='popup-frm__btn'
									onClick={() => {
										this.props.dispatch(smsActions.sendSMSCode());
									}}
									type='button'
								>
									Отправить СМС снова
								</button>
								<button
									className='popup-frm__submit btn--2 popup__wide-btn'
									onClick={(event) => {
										this.onSMSCodeCheckClick(event);
										freezeScrollbar(false);
									}}
								>
									Подтвердить телефон
								</button>
							</form>
						</div>
					</div>
				</div>
			</>
		);
	}

	onSMSCodeCheckClick(event) {
		event.preventDefault();
		this.props.dispatch(smsActions.clearSMSErrors());
		this.props.dispatch(smsActions.checkSMSCode(this.props.smsCode));
	}

	onSMSCodeChanged(event) {
		this.props.dispatch(smsActions.changeSMSCode(event.target.value));
	}
}

function mapStateToProps(state) {
	const [smsCode, isSent, isVerified, error] =
		smsSelectors.getSMSVerificationStatus(state);
	return {
		smsCode,
		isSent,
		isVerified,
		error,
	};
}

export default connect(mapStateToProps)(SMSPopupContainer);
