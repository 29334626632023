import _ from 'lodash';
import JSONParser from '../../common/parsers/JSONParser'
import ProfileEducationParser from './ProfileEducationParser'


class ProfileEducationsParser extends JSONParser {
    parse() {
        super.parse()
        let educations = []
        _.forEach(this.data, function(educationData) {
            let education = new ProfileEducationParser(educationData).parse()
            educations.push(education)
        });
        

        return educations
    }
}

export default ProfileEducationsParser;