import React from 'react';

import './App.css'
import './css/vendors/vendors.min.css';
import './css/common.min.css';
import './css/blocks/scrollbar/_disabled/scrollbar_disabled.css'


import VuuteRoute from './routing/VuuteRoute';

//TODO: Implement

class App extends React.Component {

  render() {
    return (
        <div>
          <VuuteRoute></VuuteRoute>
        </div>
    );
  }
}

export default App;
