import React from 'react'
import _ from 'lodash';

import autoBind from 'react-autobind';
import { connect } from 'react-redux';

import ClientNewOrders from '../components/ClientNewOrders';

import * as orderActions from '../../store/order/actions'
import * as orderSelectors from '../../store/order/reducer'

import { useParams, withRouter } from 'react-router-dom'
import AuthService from '../../services/AuthService';

class ClientOrdersInfoContainer extends React.Component {

    constructor(props) {
        super(props);

        autoBind(this);
    }

    componentDidMount() {
        this.props.dispatch(orderActions.getClientOrders())
    }

    render() {
        return (
            <ClientNewOrders
                newOrders={this.props.clientOrders.newOrders}
                onNewOrderClick={this.onNewOrderClick}
            />
        );
    }

    onNewOrderClick(id) {
        this.props.history.push(`/order/${id}`)
      }
}

function mapStateToProps(state) {
    const clientOrders = orderSelectors.getClientOrdersInfo(state)
    return {
        clientOrders,
    }
}
  
export default withRouter(connect(mapStateToProps)(ClientOrdersInfoContainer));