import React from 'react'

import Menu from '../../page/components/Menu'
import MasterProfileInfo from '../containers/MasterProfileInfo'
import Contracts from './Contracts'
import Portfolio from './Portfolio'
import Experience from '../containers/Experience'

import Footer from '../../page/components/Footer'
import CityPopup from '../../common/CityPopup';
import AuthService from '../../services/AuthService';

class MasterProfile extends React.Component {
  constructor(props) {
    super(props);
}

  render() {
    let isMyProfile = AuthService.shared().isMyProfile() 
    return (
        <div className="page">
            {isMyProfile && this.props.profile.bio.city === null && <CityPopup></CityPopup>}
            <Menu/>
            <MasterProfileInfo/>
            <div className="profile-body container">
              <Contracts/>
              {/* <Portfolio/> */}
              <Experience/>
            </div>
            <Footer/>
        </div>
    );
  }
}

export default MasterProfile