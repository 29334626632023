import _ from 'lodash';
import * as profileSelectors from '../../store/profile/reducer';
import * as profileTypes from './actionTypes';
import apiService from '../../services/APIService';
import AuthService from '../../services/AuthService';
import { auth } from '../reducers';

export function enableEditMode() {
    return ({ type: profileTypes.EDIT_MODE_ENABLE})
}

export function disableEditMode() {
    return ({ type: profileTypes.EDIT_MODE_DISABLE})
}

export function educationEditStateChanged(newState) {
    return ({type: profileTypes.EDUCATION_EDIT_MODE_STATE_CHANGED, newState: newState})
}

export function educationFormHiddenStateChanged(newState) {
    return ({type: profileTypes.CHANGE_EDUCATION_FORM_HIDDEN_STATE, newState: newState})
}

export function changeFirstName(newName) {
    return ({ type: profileTypes.FIRSTNAME_CHANGED, firstname: newName})
}

export function changeLastName(newLastName) {
    return ({ type: profileTypes.LASTNAME_CHANGED, lastname: newLastName})
}

export function changeMiddleName(newMidName) {
    return ({ type: profileTypes.MIDDLENAME_CHANGED, middlename: newMidName})
}

export function changeDescription(newDescr) {
    return ({ type: profileTypes.DESCRIPTION_CHANGED, description: newDescr})
}

export function changeCity(newCity) {
    return ({ type: profileTypes.CITY_CHANGED, city: newCity})
}

export function discardChanges() {
    return ({ type: profileTypes.DISCARD_CHANGES})
}

export function deleteProfileService(serviceNumber) {
    return ({type: profileTypes.DELETE_PROFILE_SERVICE, serviceNumber: serviceNumber})
}

export function addProfileService(availableVuuteServices) {
    return ({type: profileTypes.ADD_PROFILE_SERVICE, availableVuuteServices: availableVuuteServices})
}

export function changeServicePrice(orderNumber, newPrice) {
    return ({type: profileTypes.CHANGE_PROFILE_SERVICE_PRICE, serviceNumber: orderNumber, newPrice: newPrice})
}

export function changeServiceItem(orderNumber, newItem) {
    return ({type: profileTypes.CHANGE_PROFILE_SERVICE_ITEM, serviceNumber: orderNumber, newService: newItem})
}

export function changeSelectListState(newState) {
    return ({type: profileTypes.CHANGE_SELECT_LIST_STATE, newState: newState})
}

export function selectEducation(education) {
    return ({type: profileTypes.SELECT_EDUCATION, newEducation: education})
}

export function editEducationItem(orderNumber) {
    return ({type: profileTypes.EDIT_PROFILE_EDUCATION_ITEM, orderNumber: orderNumber})
}

export function changeEducationSpeciality(newSpeciality) {
    return ({type: profileTypes.EDUCATION_SPECIALITY_CHANGED, newSpeciality: newSpeciality})
}

export function changeStartYear(newYear) {
    return ({type: profileTypes.EDUCATION_START_YEAR_CHANGED, newYear: newYear})
}

export function changeEndYear(newYear) {
    return ({type: profileTypes.EDUCATION_END_YEAR_CHANGED, newYear: newYear})
}

export function selectCountry(country) {
    return ({type: profileTypes.SELECT_COUNTRY, newCountry: country})
}

export function selectCity(city) {
    return ({type: profileTypes.SELECT_CITY, newCity: city})
}

export function addProfileEducation(newEducationObject) {
    return ({type: profileTypes.ADD_PROFILE_EDUCATION, newEducationObject: newEducationObject})
}


export function experienceEditStateChanged(newState) {
    return ({type: profileTypes.EXPERIENCE_EDIT_MODE_STATE_CHANGED, newState: newState})
}

export function addProfileExperience() {
    return ({type: profileTypes.ADD_PROFILE_EXPERIENCE})
}

export function deleteProfileExperience(experienceNumber) {
    return ({type: profileTypes.DELETE_PROFILE_EXPERIENCE, experienceNumber: experienceNumber})
}

export function changeExperienceCompany(orderNumber, newCompany) {
    return ({type: profileTypes.EXPERIENCE_COMPANY_CHANGED, newCompany: newCompany, orderNumber: orderNumber})
}

export function changeExperiencePosition(orderNumber, newPosition) {
    return ({type: profileTypes.EXPERIENCE_POSITION_CHANGED, newPosition: newPosition, orderNumber: orderNumber})
}

export function changeExperienceStartDate(orderNumber, newStartDate) {
    return ({type: profileTypes.EXPERIENCE_STARTDATE_CHANGED, newStartDate: newStartDate, orderNumber: orderNumber})
}

export function changeExperienceEndDate(orderNumber, newEndDate) {
    return ({type: profileTypes.EXPERIENCE_ENDDATE_CHANGED, newEndDate: newEndDate, orderNumber: orderNumber})
}

export function saveExperience(modifiedProfile) {
    return async(dispatch, getState) => {
        try {
            const experienceData = await apiService.updateProfileExperience(modifiedProfile);
            dispatch({type: profileTypes.SAVE_EXPERIENCE,
                      experience: experienceData.experience,
                      error: experienceData.error});
        } catch (error) {
            console.error(error)
        }
    }
}

export function clearSaveExperienceErrors() {
    return ({ type: profileTypes.CLEAR_SAVE_EXPERIENCE_FORM_ERRORS})
}

export function clearSaveBioErrors() {
    return ({ type: profileTypes.CLEAR_SAVE_BIO_FORM_ERRORS})
}

export function clearSaveServicesErrors() {
    return ({ type: profileTypes.CLEAR_SAVE_SERVICES_FORM_ERRORS})
}

export function clearSaveEducationErrors() {
    return ({ type: profileTypes.CLEAR_SAVE_EDUCATION_FORM_ERRORS})
}

export function createProfile(name, profileType) {
    return async(dispatch, getState) => {
        try {
            const newProfileData = await apiService.createProfile(name, profileType);
            dispatch({type: profileTypes.CREATE_PROFILE, 
                      profile: newProfileData.profile,
                      error: newProfileData.error});
        } catch (error) {
            console.error(error)
        }
    }
}

export function saveProfile(modifiedProfile) {
    return async(dispatch, getState) => {
        try {
            const updatedBio = await apiService.updateProfileBio(modifiedProfile);
            
            let updatedServices = []
            if (modifiedProfile.bio.profileType == "MASTER") {
                updatedServices = await apiService.updateProfileServices(modifiedProfile);
            }

            // let apiError = null
            // if (updatedBio.error !== null) {
            //     apiError = updatedBio.error
            // } else if (updatedServices.error) {
            //     apiError = updatedServices.error
            // }

            if ((updatedBio.error === undefined) && (updatedBio.bio !== undefined)) {
                let user = AuthService.shared().getUser()
                user.name = updatedBio.bio.firstName
                AuthService.shared().updateUser(user)
                AuthService.shared().saveProfileBio(updatedBio.bio)
            }

            dispatch({type: profileTypes.SAVE_PROFILE, 
                      bio: updatedBio.bio,
                      services: updatedServices.services,
                      bioError: updatedBio.error,
                      servicesError: updatedServices.error});
        } catch (error) {
            console.error(error)
        }
    }
}

export function saveEducation(modifiedProfile) {
    return async(dispatch, getState) => {
        try {
            const educationData = await apiService.updateProfileEducation(modifiedProfile);
            dispatch({type: profileTypes.SAVE_EDUCATION,
                      educations: educationData.education,
                      error: educationData.error});
        } catch (error) {
            console.error(error)
        }
    }
}

export function savePhoto(photoFile) {
    return async(dispatch, getState) => {
        try {
            const updatedPhotoData = await apiService.uploadPhoto(photoFile)
            dispatch({type: profileTypes.SAVE_PHOTO,
                      photoUrl: updatedPhotoData.photoUrl,
                      error: updatedPhotoData.error});
        } catch (error) {
            console.error(error)
        }
    }
}

export function previewPhoto(photoFile) {
    return ({type: profileTypes.PREVIEW_PHOTO, photoFile: photoFile})
}

export function closePhoto() {
    return ({type: profileTypes.CLOSE_PHOTO})
}

export function deleteProfileEducation(educationNumber) {
    return ({type: profileTypes.DELETE_PROFILE_EDUCATION, educationNumber: educationNumber})
}

export function getProfile(profileId) {
    return async(dispatch, getState) => {
        try {
            const profileData = await apiService.getProfile(profileId);
            if ((profileData.error === null) && (profileData.profile.bio !== null)) {
                // TODO: SAVE ONLY IF PROFILE OWNER REQUEST GET PROFILE
                if (!profileId) {
                    let user = AuthService.shared().getUser()
                    user.name = profileData.profile.bio.firstName
                    AuthService.shared().updateUser(user)
                    AuthService.shared().saveProfileBio(profileData.profile.bio)
                }
            }
            dispatch({type: profileTypes.GET_PROFILE, 
                      profile: profileData.profile,
                      error: profileData.error});
        } catch (error) {
            console.error(error)
        }
    }
}

export function getContracts(profileId) {
    return async(dispatch, getState) => {
        try {
            const jobsData = await apiService.getContracts(profileId);
            dispatch({ type: profileTypes.GET_CONTRACTS, 
                incomeJobs: jobsData.incomeJobs,
                activeJobs: jobsData.activeJobs,
                closedJobs: jobsData.closedJobs })
        } catch (error) {
            console.error(error)
        }
    }
}