import React, { Component } from 'react';
import Review from '../review/Review';
import APIService from '../../../../../../services/APIService';
class ReviewGrid extends React.Component {
	constructor() {
		super();
		this.state = {
			reviews: [],
		};
	}
	componentDidMount() {
		APIService.getRandomReviews().then((data) => {
			console.log(data);
			this.setState({ reviews: data.reviews });

		});
	}
	render() {
		return (
			<div className='reviews-list'>
				<Review review={this.state.reviews[0]} />
				<Review review={this.state.reviews[1]} />
				<Review review={this.state.reviews[2]} />
			</div>
		);
	}
}

export default ReviewGrid;
