import _ from 'lodash';
import * as disputTypes from './actionTypes';
import apiService from '../../services/APIService';
export function changeDisputText(newDisputText) {
	return { type: disputTypes.CHANGE_DISPUT_TEXT, newDisputText: newDisputText };
}

export function clearDisputErrors() {
	return { type: disputTypes.CLEAR_DISPUT_ERROR };
}

export function createDisput(jobInfo, disputText) {
	return async (dispatch) => {
		try {
			const disputData = await apiService.createDisput(jobInfo, disputText);
			dispatch({
				type: disputTypes.CREATE_DISPUT,
				text: disputText,
				error: disputData.error,
			});
			return disputData;
		} catch (error) {
			console.log(error);
		}
	};
}
