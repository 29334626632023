import React from 'react';
import _ from 'lodash';

import autoBind from 'react-autobind';
import { withRouter } from 'react-router-dom';

import DateService from '../../services/DateService';
import Grade from '../../common/Grade';
import AuthService from '../../services/AuthService';

class ClientContractItem extends React.Component {
	constructor(props) {
		super(props);
		autoBind(this);
	}

	onJobClick(jobId, e) {
		e.preventDefault();

		// redirect only for user owned jobs
		if (AuthService.shared().isMyProfile()) {
			this.props.history.push(`/job/${jobId}`);
		}
	}

	render() {
		const job = this.props.job;

		let review;
		if (this.props.profileType === 'CLIENT') {
			review = this.props.job.reviewOwner;
		} else {
			review = this.props.job.reviewMaster;
		}

		const startDate = DateService.getExperienceDate(job.acceptedDate);
		const endDate = DateService.getExperienceDate(job.closedDate);

		var isEndDateValid = startDate === endDate;
		if (endDate === undefined) {
			isEndDateValid = true;
		}

		const reviewMaster = job.reviewMaster;
		const reviewClient = job.reviewOwner;

		return (
			<div class='contracts-list-item'>
				<div class='contracts-list-item__job'>
					<a
						class='contracts-list-item__job-title'
						href='javascript;'
						onClick={(e) => this.onJobClick(job.id, e)}
					>
						{job.order.title}
					</a>
					{isEndDateValid ? (
						<p class='light-text'>{startDate}</p>
					) : (
						<p class='light-text'>
							{startDate} — {endDate}
						</p>
					)}
				</div>
				<div class='contracts-list-item__grade grade'>
					{reviewMaster && reviewClient && <Grade review={review} />}
				</div>
				<div class='contracts-list-item__stages'></div>
				<div class='contracts-list-item__price'>
					<div class='price'>
						{job.order.price}
						<span class='rouble'>₽</span>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(ClientContractItem);
