import React from 'react'

class ClientOrderItem extends React.Component {

    constructor(props) {
        super(props);

        this.onHandleClick = this.onHandleClick.bind(this)
    }

    render() {
        return (
            <li>
                <a href="javascript:;" onClick={this.onHandleClick}>{this.props.orderInfo.title}</a>
            </li>
        );
    }

    onHandleClick() {
        this.props.onNewOrderClick(this.props.orderInfo.id)
    }
}


export default ClientOrderItem