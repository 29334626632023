import React from 'react'
import $ from 'jquery'
// import Nouislider from "nouislider-react";
import noUiSlider from 'nouislider'
import wNumb from 'wnumb'

class CreateOrderSliderView extends React.Component {
    componentDidMount() {
        var connectSlider = document.getElementById('slider-connect');

        noUiSlider.create(connectSlider, {
            start: 3,
            step: 1,
            connect: 'lower',
            tooltips: true,
            pips: {
                mode: 'range',
                density: 100/7
            },
            range: {
                min: 0,
                max: 7
            },
            format: wNumb({
                decimals: 0,
                thousand: ' ',
                prefix: 'Бюджет до ',
                suffix: ' рублей',
                from: Number,
            }),
            cssPrefix: 'noUi-',
            cssClasses: {
                target: 'target',
                base: "base",
                origin: "origin",
                handle: "handle",
                handleLower: "handle-lower",
                handleUpper: "handle-upper",
                touchArea: "touch-area",
                horizontal: "horizontal",
                vertical: "vertical",
                background: "background",
                connect: "connect",
                connects: "connects",
                ltr: "ltr",
                rtl: "rtl",
                draggable: "draggable",
                drag: "state-drag",
                tap: "state-tap",
                active: "active",
                tooltip: "tooltip",
                pips: "pips",
                pipsHorizontal: "pips-horizontal",
                pipsVertical: "pips-vertical",
                marker: "marker",
                markerHorizontal: "marker-horizontal",
                markerVertical: "marker-vertical",
                markerNormal: "marker-normal",
                markerLarge: "marker-large",
                markerSub: "marker-sub",
                value: "value",
                valueHorizontal: "value-horizontal",
                valueVertical: "value-vertical",
                valueNormal: "value-normal",
                valueLarge: "value-large",
                valueSub: "value-sub"
            }
        })        
    } 

    render() {
        return (
            <div className="form-row">
                <div className="option-wrap range-slider-wrap range-budget-slider-wrap">
                    <label htmlFor="order-budget">На какой бюджет вы рассчитываете?</label>
                    <div className="range-slider range-slider-budget">
                        <div className="range-slider__data">
                            <div className='range-slider__line js-budget-slider' id="slider-connect">
                                {/* <Nouislider
                                className='range-slider__line js-budget-slider'
                                start={4}
                                step={1}
                                range={{
                                    min: 1,
                                    max: 7
                                  }}
                                pips={{
                                    mode: 'range',
                                    density: 100/6
                                }}
                                tooltips
                                  /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            // <div className="range-slider__data">
            //     <input id="order-budget" name="order-budget" type="hidden" />
            //     <div className="range-slider__line js-budget-slider" data-min={10000} data-max={10000000} />
            // </div>
        )
    }
}

export default CreateOrderSliderView