import React from 'react'

import ContractHeaderView from './ContractHeaderView'
import ReviewItem from './ReviewItem'

class ContractItem extends React.Component {

  render() {
    return (  
        <div className="contract">
        <ContractHeaderView job={this.props.job} />
        { this.props.job?.reviewOwner && this.props.job?.reviewMaster &&
          <>
            <ReviewItem isClient={true} job={this.props.job}/>
            <ReviewItem isClient={false} job={this.props.job}/>
          </>
        }
      
        </div>
    );
  }
}

export default ContractItem