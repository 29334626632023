import _ from 'lodash';
import ProfileBioParser from '../../profile/parsers/ProfileBioParser';
import CategoryVuuteParser from '../../vuuteServices/parsers/CategoryVuuteParser';
import CityVuuteParser from '../../vuuteServices/parsers/CityVuuteParser';
import Order from '../model/Order'
import OrderPermissionsParser from '../parsers/OrderPermissionsParser';
import GeolocationParser from './GeolocationParser';

const { default: JSONParser } = require("../../common/parsers/JSONParser");


class OrderParser extends JSONParser {

    parse() {
        super.parse()
        
        let category = new CategoryVuuteParser(_.get(this.data, 'category')).parse()
        let owner = new ProfileBioParser(_.get(this.data, 'owner')).parse()
        let city = new CityVuuteParser(_.get(this.data, 'city')).parse()
        let geolocation = new GeolocationParser(_.get(this.data, 'geolocation')).parse()

        let createdDate = new Date(_.get(this.data, 'created_date'))

        let order = new Order(_.get(this.data, 'id'), 
                              category, 
                              owner, 
                              city, 
                              _.get(this.data, 'title'),
                              _.get(this.data, 'description'),
                              _.get(this.data, 'price'))

        order.geolocation = geolocation
        order.createdDate = createdDate
        order.startDate = _.get(this.data, 'start_date')
        order.endDate = _.get(this.data, 'end_date')

        let orderPermissions = new OrderPermissionsParser(_.get(this.data, 'permissions')).parse()
        order.permissions = orderPermissions

        return order


    }

}

export default OrderParser;