import React from 'react'

import SvgIcon from './SvgIcon'

class EditButton extends React.Component {

    render() {
        return (
            <button className="edit-btn js-data-edit-btn" type="button" title="Редактировать" onClick={this.props.onClick}>
                <SvgIcon name='icon-tool'/>
            </button>
        )
    }
}

export default EditButton