class NotificationsSettings {
    id;
    messages;
    jobStates;
    sales;
    news;

    constructor(id, messages, jobStates, sales, news) {
        this.id = id
        this.messages = messages
        this.jobStates = jobStates
        this.sales = sales
        this.news = news
    }
}

export default NotificationsSettings;