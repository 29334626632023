import React from 'react'
import Parser from 'html-react-parser';

import DimensionParser from '../../services/DimensionParser';

class PriceItemEdit extends React.Component {

  render() {

    let price = ''
    let dimesion = ''

    if (this.props.service) {
      price = this.props.service.price
      if (this.props.service.serviceObject) {
        dimesion = DimensionParser.getDimension(this.props.service.serviceObject.dimension)
      }
    }

    let orderNumber = this.props.orderNumber
    let inputName = "master-profile-service-price-" + orderNumber

    return (
        <React.Fragment>
            <td>
              <input name={inputName} type="number" value={price} 
              onChange={this.props.onPriceChanged}/>
            </td>
            <td>
                <span className="master-profile-inits">₽ / {Parser(dimesion)}</span>
            </td>
        </React.Fragment>
    );
  }
}

export default PriceItemEdit