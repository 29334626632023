import React from 'react'
import _ from 'lodash';

import SelectList from '../../common/SelectList'
import ReactSelect from '../../common/ReactSelect';

class ServiceList extends React.Component {

    constructor(props) {
        super(props);
    }

    prepareSelectListData() {

        const orderNumber = this.props.orderNumber

        const vuuteServices = this.props.availableServices
        const serviceObject = this.props.selectedService.serviceObject
        const htmlListId = this.props.selectedService.id
        var selectedService =null
        if (serviceObject) {
            selectedService = {
                value: this.props.selectedService.serviceObject.id,
                label:this.props.selectedService.serviceObject.title
            };
        }

        var data = []


        vuuteServices.forEach((value)=> {

            const item = {
                value: value.id,
                label: value.title,
            };
            if (value.id===-1){
                data.push(item);
            }
            else{
            const catServiceId=value.category.id
            const catServiceTitle=value.category.title

            const dataCat=data.find(item=>item.value===catServiceId)
            if (dataCat=== undefined){
                data.push({value:catServiceId,label:catServiceTitle,options:[item]})
            }
            else{
                dataCat.options.push(item)
            }
        }

        });

        return [data, htmlListId, selectedService]

    }

    render() {

        const [data, listId, selectedService] = this.prepareSelectListData()

        return (
            <React.Fragment>
                <ReactSelect
                    data={data}
                    selected={selectedService}
                    onSelect={(e) => this.props.onSelectListChange(this.props.orderNumber, e)}
                ></ReactSelect>
            </React.Fragment>
        );
    }
}

export default ServiceList;