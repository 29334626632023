import _ from 'lodash';
import ProfileBioParser from '../../profile/parsers/ProfileBioParser';
import Message from '../model/Message';

import JSONParser from '../../common/parsers/JSONParser'

class MessageParser extends JSONParser {

    parse() {
        super.parse()

        let sender = new ProfileBioParser(_.get(this.data, 'sender')).parse()

        var createdDate = new Date(_.get(this.data, 'created'))

        let message = new Message(_.get(this.data, 'id'),
                                  _.get(this.data, 'message'),
                                  sender,
                                  _.get(this.data, 'is_read'),
                                  createdDate)

        return message

    }

}

export default MessageParser;