import Configuration, { ENV } from '../config';
import { w3cwebsocket as W3CWebSocket } from 'websocket';
import AuthService from '../services/AuthService';
import autoBind from 'react-autobind';

import * as menuSelectors from '../store/menu/reducer';
import * as menuActions from '../store/menu/actions';

import * as chatActions from '../store/chat/actions';

import store from '../store';
import MessageParser from '../chat/parsers/MessageParser';

class WSService {
    #config;
    #streamClient = null;
    #roomClient = null;
    #roomId = null;

    constructor() {
        this.#config = new Configuration(ENV.PROD)
        autoBind(this);
    }

    static _wsservice = null;

    static shared() {
        if (WSService._wsservice === null) {
            WSService._wsservice = new WSService()
        }
        return WSService._wsservice
    }

    configureStreamClient() {
        let token = AuthService.shared().getToken()
        if (!token) { return }
        if (this.#streamClient === null) {
            let wsUrl = `${this.#config.WS_ENDPOINT}/stream/${token}/`
            this.#streamClient = new W3CWebSocket(wsUrl)
        }

        this.#streamClient.onopen = () => {
            console.log('WEBSOCKET STREAM: SOCKET OPENNED');
        };

        this.#streamClient.onmessage = (message) => {
            console.log(`WEBSOCKET STREAM: ${message}`);

            var socketData = message.data
            var js = JSON.parse(socketData)

            let newMessage = new MessageParser(js.payload.data).parse()
            let roomId = js.payload.data.room

            this.onNewMessageIncome(roomId, newMessage)
        };

        this.#streamClient.onerror = (error) => {
            console.log(`WEBSOCKET STREAM: ${error}`);
        };
    }

    closeSockets() {
        if (this.#roomClient !== null) {
            this.#roomClient.close()
        }

        if (this.#streamClient !== null) {
            this.#streamClient.close()
        }
        
        this.#roomClient = null
        this.#streamClient = null
    }

    configureChatRoomClient(roomId) {
        let token = AuthService.shared().getToken()
        if (!token) { return }
        if ((this.#roomClient === null) || (this.#roomId !== roomId)) {
            let wsUrl = `${this.#config.WS_ENDPOINT}/chat/${roomId}/${token}/`
            if (this.#roomClient !== null) {
                this.#roomClient.close()
            }
            this.#roomClient = new W3CWebSocket(wsUrl)
        }

        this.#roomClient.onopen = () => {
            console.log('WEBSOCKET ROOM: SOCKET OPENNED');
            this.#roomId = roomId
        };

        this.#roomClient.onmessage = (message) => {
            console.log(`WEBSOCKET ROOM: ${message}`);

            var socketData = message.data
            var js = JSON.parse(socketData)
            let newMessage = new MessageParser(js).parse()

            this.onNewRoomMessageIncome(newMessage)
        };

        this.#roomClient.onerror = (error) => {
            console.log(`WEBSOCKET ROOM: ${error}`);
        };

    }

    sendMessageToRoomSocket(message) {
        this.#roomClient.send(message)
    }

    onNewMessageIncome(roomId, message) {
        store.dispatch(chatActions.newMessageStreamIncome(roomId, message))
        if (this.#roomId !== roomId) {
            store.dispatch(menuActions.addNewMessageCount())
        }
    }

    onNewRoomMessageIncome(message) {
        store.dispatch(chatActions.newMessageIncome(message))
    }
}

export default WSService;