import _ from 'lodash';
import CityVuuteParser from '../parsers/CityVuuteParser';
import EducationVuute from '../model/EducationVuute';

import JSONParser from '../../common/parsers/JSONParser'

class EducationVuuteParser extends JSONParser {
    parse() {
        let data = super.parse()
        if (data === null) { return null }
        
        let city = new CityVuuteParser(_.get(this.data, 'city_vuute')).parse()

        let education = new EducationVuute(_.get(this.data, 'id'),
                                           _.get(this.data, 'title'),
                                           city)
        
        return education

    }
}

export default EducationVuuteParser;