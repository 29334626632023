import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';

import AuthPopupContainer from '../../user/containers/AuthPopupContainer';
import SMSPopupContainer from '../../user/containers/SMSPopupContainer';

import * as popupActions from '../../store/popup/actions';
import * as popupSelectors from '../../store/popup/reducer';

import logo from '../../images/logo.svg'
import logo_short from '../../images/logo-short.svg'

import MainSearchDropdownView from '../components/MainSearchDropdownView'
import SvgIcon from '../../common/SvgIcon'

import { Redirect } from 'react-router-dom';

class UnauthorisedMenu extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isHamburgerMenuOpen: false,
        }

        autoBind(this);
    }

    render() {
        return (
            <React.Fragment>
                <header className="page-header page-header--main">
                    <div className="page-header__inner">
                        <div className="container">
                            <div className="logo" href="/">
                                <img className="logo-lg-img" src={logo} alt="" />
                                <img className="logo-sm-img" src={logo_short} alt="" />
                            </div>
                            {/* <MainSearchDropdownView />
                            <button className="search-show-btn" type="button">
                                <SvgIcon name='icon-close' />
                                <SvgIcon name='icon-search' />
                            </button> */}
                            {/* <nav className="fixed-main-menu menu">
                                <a className="fixed-main-menu__item menu__item" href="javascript:;">Заказы</a>
                                <a className="fixed-main-menu__item menu__item" href="javascript:;">Исполнители</a>
                            </nav> */}
                            {/* begin .main-menu*/}
                            {/* <nav className={this.state.isHamburgerMenuOpen ? "main-menu menu js-main-menu opened" : "main-menu menu js-main-menu"}>
                                <a className="main-menu__item menu__item" href="javascript:;">Заказы</a>
                                <a className="main-menu__item menu__item" href="javascript:;">Исполнители</a>
                            </nav> */}
                            {/* end .main-menu*/}
                            <a className="login-btn js-open-form" data-fancybox data-src="#login-popup" href="#" onClick={this.onLoginClick}>
                                <SvgIcon name='icon-user-2' />
                                Вход
                        </a>
                            {/* <button className="menu-btn hamburger js-main-menu-btn" type="button" onClick={this.onHamburderMenuClick}>
                                <span />
                                <span />
                                <span />
                                <span />
                            </button> */}
                        </div>
                    </div>
                </header>
                <div>
                    {(!this.props.isLoginPopupHidden) ? <AuthPopupContainer /> : ''}
                </div>
                <div>
                    {(!this.props.isSMSPopupHidden) ? <SMSPopupContainer /> : ''}
                </div>
            </React.Fragment>
        )
    }

    onHamburderMenuClick() {
        this.setState({
            isHamburgerMenuOpen: !this.state.isHamburgerMenuOpen
        })
    }

    onLoginClick(event) {
        event.preventDefault();
        this.props.dispatch(popupActions.showAuthPopup());
    }

}


function mapStateToProps(state) {
    const [isLoginPopupHidden, isSMSPopupHidden] = popupSelectors.getCurrentPopupState(state);
    return {
        isLoginPopupHidden,
        isSMSPopupHidden
    };
}

export default connect(mapStateToProps)(UnauthorisedMenu);