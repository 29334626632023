import React, { Component } from 'react'
import autoBind from 'react-autobind'
import { connect } from 'react-redux'
import * as authActions from '../../store/auth/actions'
import * as authSelectors from '../../store/auth/reducer'
import * as loginActions from '../../store/login/actions'
import * as registerActions from '../../store/register/actions'
import * as popupActions from '../../store/popup/actions'
import AuthFormType from '../../enum/AuthFormType'
import LoginContainer from './LoginContainer'
import RegisterContainer from './RegisterContainer'
import SvgIcon from '../../common/SvgIcon'
import freezeScrollbar from '../../services/utils/freezeScrollbar'

class AuthPopupContainer extends Component {
  constructor(props) {
    super(props)
    autoBind(this)
  }

  render() {
    freezeScrollbar(true)
    return (
      <React.Fragment>
        <div className='fancybox-container fancybox-is-open fancybox-can-drag' role='dialog' tabIndex='-1' id='fancybox-container-3' style={{ transitionDuration: '350ms' }}>
          <div className='fancybox-bg'></div>
          <div className='fancybox-inner'>
            <div className='fancybox-stage'>
              <div className='fancybox-slide popup-form fancybox-slide--html fancybox-slide--current fancybox-slide--complete'>
                <div className='popup popup--sm fancybox-content' id='login-popup' style={{ display: 'inline-block' }}>
                  <button
                    data-fancybox-close=''
                    className='fancybox-close-small'
                    title='Закрыть'
                    onClick={e => {
                      this.onCloseClick(e)
                      freezeScrollbar(false)
                    }}>
                    <SvgIcon name='icon-close' />
                  </button>

                  {this.props.authFormType == AuthFormType.LOGIN ? (
                    <div className='popup__title'>
                      <span className='active'>Вход</span>
                      <a className='popup-reg-btn' onClick={this.onRegisterClick}>
                        Регистрация
                      </a>
                    </div>
                  ) : (
                    <div className='popup__title'>
                      <a className='popup-login-btn' onClick={this.onLoginClick}>
                        Вход
                      </a>
                      <span className='active'>Регистрация</span>
                    </div>
                  )}

                  {this.props.authFormType == AuthFormType.LOGIN ? <LoginContainer onClick={() => freezeScrollbar(false)}></LoginContainer> : <RegisterContainer></RegisterContainer>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }

  onLoginClick(event) {
    event.preventDefault()
    this.props.dispatch(authActions.loginFormTypeSelected())
  }

  onRegisterClick(event) {
    event.preventDefault()
    this.props.dispatch(authActions.signUpFormTypeSelected())
  }

  onCloseClick(event) {
    event.preventDefault()
    this.props.dispatch(popupActions.hidePopup())
    this.props.dispatch(loginActions.clearLoginForm())
    this.props.dispatch(registerActions.clearRegisterForm())
    this.props.dispatch(authActions.clearAuthForm())
  }
}

function mapStateToProps(state) {
  const authFormType = authSelectors.getAuthFormType(state)
  return {
    authFormType
  }
}

export default connect(mapStateToProps)(AuthPopupContainer)
