import React from 'react'

import Rating from '../../../common/Rating'
import dateService from '../../../services/DateService'

class JobUserInfoSidebar extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        const createdProfilePassedTime = dateService.passedTimeCalc(this.props.masterInfo.created)

        return (
            <div className="js-card-sidebar stick-sidebar">
                <div className="job__customer"><a className="job__customer-name" href="javascript:;">{this.props.masterInfo.firstName}</a>
                    <div className="job__customer-exp">{createdProfilePassedTime} на сайте</div>
                </div>
                <Rating rating={this.props.masterInfo.rate} isShow={true} isReadOnly={true} />
                <table className="stat-tbl">
                    {/* <tbody>
                        <tr>
                            <td>Заказов:</td>
                            <td>20</td>
                        </tr>
                        <tr>
                            <td>Успешность:</td>
                            <td><div className="stat-cases"><span className="stat-cases__success">5</span> / <span className="stat-cases__total">15</span></div></td>
                        </tr>
                    </tbody> */}
                </table>
            </div>
        );
    }
}

export default JobUserInfoSidebar