import _ from 'lodash';
import ProfileBioParser from '../../profile/parsers/ProfileBioParser';
import ChatRoom from '../model/ChatRoom';
import JobParser from '../../jobs/parsers/JobParser';
import MessageParser from '../../chat/parsers/MessageParser';

const { default: JSONParser } = require("../../common/parsers/JSONParser");

class ChatRoomParser extends JSONParser {

    parse() {
        super.parse()

        let profile = new ProfileBioParser(_.get(this.data, 'profile')).parse()
        let job = new JobParser(_.get(this.data, 'job')).parse()
        let message = new MessageParser(_.get(this.data, 'message')).parse()

        let chatRoom = new ChatRoom(_.get(this.data, 'id'),
                                  _.get(this.data, 'unread_count'),
                                  profile,
                                  job,
                                  message)

        return chatRoom

    }

}

export default ChatRoomParser;