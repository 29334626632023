import _ from 'lodash';
import * as findTypes from './actionTypes';
import apiService from '../../services/APIService';

export const FIND_FILTER_TYPE = {
    FIND_MASTERS: 'FIND_MASTERS',
    FIND_ORDERS: 'FIND_ORDERS',
}

export function changeCategory(newCategory) {
    return ({ type: findTypes.CATEGORY_CHANGED, newCategory: newCategory})
}

export function changeCity(newCity) {
    return ({ type: findTypes.CITY_CHANGED, newCity: newCity})
}

export function changeMinPrice(newMinPrice) {
    return ({type: findTypes.MIN_PRICE_CHANGED, newMinPrice: newMinPrice})
}

export function changeMaxPrice(newMaxPrice) {
    return ({type: findTypes.MAX_PRICE_CHANGED, newMaxPrice: newMaxPrice})
}

export function changeRate(newRate) {
    return ({type: findTypes.RATE_CHANGED, newRate: newRate})
}

export function changeName(newName) {
    return ({type: findTypes.NAME_CHANGED, newName: newName})
}

export function addSkill(newSkill) {
    return ({type: findTypes.SKILL_ADDED, newSkill: newSkill})
}

export function removeSkill(removedSkill) {
    return ({type: findTypes.SKILL_REMOVED, removedSkill: removedSkill})
}

export function changeHasHistory(newValue) {
    return ({type: findTypes.HAS_HISTORY_CHANGED, newValue: newValue})
}

export function changeHasAttachment() {
    return ({type: findTypes.HAS_ATTACHMENT_CHANGED})
}

export function changeSortFilter(newValue) {
    return ({type: findTypes.SORT_FILTER_CHANGED, sortBy: newValue})
}

export function applyFilter(filterType, searchCriteria, datetime) {
    
    return async(dispatch, getState) => {
        try {
            
            //if datetime undefined -> it mean that it's new request -> set datetime as now
            //reset offset to start loading
            if (datetime == undefined) {
                datetime = new Date(new Date()).toISOString()
                var findType = findTypes.APPLY_FILTER
                searchCriteria.offset = 0
            } else {
                //else simple continue loading and set correct findType 
                var findType = findTypes.LOAD_MORE
            }

            const resultObjectsData = await apiService.findResults(
                filterType, 
                searchCriteria,
                datetime);

            //pass to reducer datetime used in initial request
            dispatch({type: findType,
                      results: resultObjectsData.results,
                      datetime: datetime,
                      error: resultObjectsData.error});
        } catch (error) {
            console.error(error)
        }
    }

}