import JSONParser from './JSONParser'
import _ from 'lodash';
import APIError from "../model/APIError";

class APIErrorParser extends JSONParser {
    parse() {
        super.parse()
        let simpleError = _.get(this.data, 'error')

        if (simpleError !== undefined) {
            return new APIError(simpleError)
        }

        let errors = _.get(this.data, 'errors')

        if (errors !== undefined) {
            let nonFieldsErrors = errors["non_field_errors"]
            if (nonFieldsErrors !== undefined) {
                return new APIError(nonFieldsErrors[0])
            } else {
                let incorrectFields = Object.keys(errors)
                let msg = `This fields are required: ${incorrectFields.join(', ')}`
                return new APIError(msg)
            }
        } else {
            let nonFieldsErrors = this.data["non_field_errors"]
            if (nonFieldsErrors !== undefined) {
                return new APIError(nonFieldsErrors[0])
            } else {
                let incorrectFields = Object.keys(errors)
                let msg = `This fields are required: ${incorrectFields.join(', ')}`
                return new APIError(msg)
            }
        }

        let apiError = new APIError('undefined error')
        return apiError
    }
}

export default APIErrorParser;