export const CREATE_PROFILE = 'profile.CREATE_PROFILE';
export const GET_PROFILE = 'profile.GET_PROFILE';
export const EDIT_MODE_ENABLE = 'profile.EDIT_MODE_ENABLE';
export const EDIT_MODE_DISABLE = 'profile.EDIT_MODE_DISABLE';
export const DISCARD_CHANGES = 'profile.DISCARD_CHANGES';

export const SAVE_PROFILE = 'profile.SAVE_PROFILE';

export const FIRSTNAME_CHANGED = 'profile.FIRSTNAME_CHANGED';
export const LASTNAME_CHANGED = 'profile.LASTNAME_CHANGED';
export const MIDDLENAME_CHANGED = 'profile.MIDDLENAME_CHANGED';
export const DESCRIPTION_CHANGED = 'profile.DESCRIPTION_CHANGED';
export const CITY_CHANGED = 'profile.CITY_CHANGED';
export const CLEAR_SAVE_BIO_FORM_ERRORS = 'profile.CLEAR_SAVE_BIO_FORM_ERRORS';

export const DELETE_PROFILE_SERVICE = 'profile.DELETE_PROFILE_SERVICE';
export const ADD_PROFILE_SERVICE = 'profile.ADD_PROFILE_SERVICE';
export const CHANGE_PROFILE_SERVICE_PRICE = 'profile.CHANGE_PROFILE_SERVICE_PRICE';
export const CHANGE_PROFILE_SERVICE_ITEM = 'profile.CHANGE_PROFILE_SERVICE_ITEM';
export const CHANGE_SELECT_LIST_STATE = 'profile.CHANGE_SELECT_LIST_STATE';
export const CLEAR_SAVE_SERVICES_FORM_ERRORS = 'profile.CLEAR_SAVE_SERVICES_FORM_ERRORS';

export const EDUCATION_EDIT_MODE_STATE_CHANGED = 'profile.EDUCATION_EDIT_MODE_STATE_CHANGED';
export const CHANGE_EDUCATION_FORM_HIDDEN_STATE = 'profile.CHANGE_EDUCATION_FORM_HIDDEN_STATE';
export const DELETE_PROFILE_EDUCATION = 'profile.DELETE_PROFILE_EDUCATION';
export const EDUCATION_SPECIALITY_CHANGED = 'profile.EDUCATION_SPECIALITY_CHANGED';
export const EDUCATION_START_YEAR_CHANGED = 'profile.EDUCATION_START_YEAR_CHANGED';
export const EDUCATION_END_YEAR_CHANGED = 'profile.EDUCATION_END_YEAR_CHANGED';
export const SELECT_EDUCATION = 'profile.SELECT_EDUCATION';
export const SELECT_COUNTRY = 'profile.SELECT_COUNTRY';
export const SELECT_CITY = 'profile.SELECT_CITY';
export const ADD_PROFILE_EDUCATION = 'profile.ADD_PROFILE_EDUCATION';
export const EDIT_PROFILE_EDUCATION_ITEM = 'profile.EDIT_PROFILE_EDUCATION_ITEM';
export const SAVE_EDUCATION = 'profile.SAVE_EDUCATION';
export const CLEAR_SAVE_EDUCATION_FORM_ERRORS = 'profile.CLEAR_SAVE_EDUCATION_FORM_ERRORS';


export const EXPERIENCE_EDIT_MODE_STATE_CHANGED = 'profile.EXPERIENCE_EDIT_MODE_STATE_CHANGED';
export const ADD_PROFILE_EXPERIENCE = 'profile.ADD_PROFILE_EXPERIENCE';
export const DELETE_PROFILE_EXPERIENCE = 'profile.DELETE_PROFILE_EXPERIENCE';
export const EXPERIENCE_COMPANY_CHANGED = 'profile.EXPERIENCE_COMPANY_CHANGED';
export const EXPERIENCE_POSITION_CHANGED = 'profile.EXPERIENCE_POSITION_CHANGED';
export const EXPERIENCE_STARTDATE_CHANGED = 'profile.EXPERIENCE_STARTDATE_CHANGED';
export const EXPERIENCE_ENDDATE_CHANGED = 'profile.EXPERIENCE_ENDDATE_CHANGED';
export const SAVE_EXPERIENCE = 'profile.SAVE_EXPERIENCE';
export const CLEAR_SAVE_EXPERIENCE_FORM_ERRORS = 'profile.CLEAR_SAVE_EXPERIENCE_FORM_ERRORS';

export const SAVE_PHOTO = 'profile.SAVE_PHOTO';
export const CLOSE_PHOTO = 'profile.CLOSE_PHOTO';
export const PREVIEW_PHOTO = 'profile.PREVIEW_PHOTO';

export const GET_CONTRACTS = 'GET_CONTRACTS';