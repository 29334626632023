import React from 'react'

class GalaryItem extends React.Component {

    render() {
        return (
            <a className="compact-gallery__item zoom-link js-open-gallery" href="i/files/portfolio/p-1_282x226.jpg" data-fancybox="gallery-2">
                <img src="i/files/portfolio/p-1_282x226.jpg" alt="" />
            </a>
        )
    }
}

export default GalaryItem