import React from 'react'

import DateService from '../../services/DateService'

import ExpCalendar from '../../common/Calendar'
import RemoveButton from '../../common/RemoveButton'

class JobFormItem extends React.Component {

  render() {

    var company = ''
    var position = ''
    var startDate = ''
    var endDate = ''

    var startDateString = ''
    var endDateString = ''
    var differenceBetweenDates = ''

    const experience = this.props.experience

    if (experience) {
      company = experience.companyName
      position = experience.position
      startDate = new Date(experience.dateStarted)

      if (startDate !== undefined)  {
        startDateString = DateService.getExperienceDate(startDate)
      }

      endDate = new Date(experience.dateFinished)
      if (endDate === undefined) {
        endDateString = 'наст. время'
      } else {
        endDateString = DateService.getExperienceDate(endDate)
      }

      differenceBetweenDates = DateService.getExperienceDifferenceBetweenDates(startDate, endDate)

    }

    return (  
        <div className="form-section form-section--removable">
            <div className="form-row">
                <div className="input-wrap">
                    <label className="double-input-label" htmlFor="exp-company-1">Место и должность</label>
                    <div className="double-input double-input--full double-input--double">
                        <div className="input-holder">
                            <label htmlFor="exp-company-1">Место</label>
                            <input id="exp-company-1" type="text" name="exp-company-1" value={company} 
                            onChange={(e) => this.props.onExperienceCompanyChanged(this.props.orderNumber, e)}/>
                        </div>
                        <div className="input-holder">
                            <label htmlFor="exp-position-1">Должность</label>
                            <input id="exp-position-1" type="text" name="exp-position-1" value={position}
                            onChange={(e) => this.props.onExperiencePositionChanged(this.props.orderNumber, e)}/>
                        </div>
                    </div>
                </div>
                <div className="input-wrap">
                    <label htmlFor="exp-date-from-1">Период работы</label>
                    <div className="double-input">
                        <ExpCalendar 
                            orderNumber={this.props.orderNumber}
                            selectedDate={startDate}
                            onChange={(e) => this.props.onExperienceStartDateChanged(this.props.orderNumber, e)}/>
                        <ExpCalendar 
                            orderNumber={this.props.orderNumber}
                            selectedDate={endDate}
                            onChange={(e) => this.props.onExperienceEndDateChanged(this.props.orderNumber, e)}/>                        
                    </div>
                </div>
            </div>
            <RemoveButton onClick={this.props.onExperienceItemRemoveClick} value={this.props.orderNumber}/>
        </div>
    );
  }
}

export default JobFormItem