import Immutable from 'seamless-immutable';
import _ from 'lodash';
import * as findTypes from './actionTypes';

const initialState = Immutable({
    results: [],
    error: null,

    availableRatingItems: [
        { id: 0, title: "с любым рейтингом", value: 0.0 },
        { id: 1, title: "4.5 и выше", value: 4.5 },
        { id: 2, title: "4.0 и выше", value: 4.0 },
        { id: 3, title: "3.5 и выше", value: 3.5 },
      ],

    availableSortMasterItems: [
        { id: 1, title: "по ID", value: "-id"},
        { id: 0, title: "лучшие по рейтингу", value: "-rate" },
    ],

    availableSortOrderItems: [
        { id: 1, title: "по ID", value: "-id"},
        { id: 0, title: "лучшие по рейтингу", value: "-owner__rate" },
    ],

    findCity: undefined,
    findCategory: undefined,
    findServices: [],
    findRating: { id: 0, title: "с любым рейтингом", value: 0.0 },
    findName: '',
    findMinPrice: 0,
    findMaxPrice: 100000,
    findHasHistory: false,
    findHasAttachments: false,

    findOrderBy: { id: 1, title: "по ID", value: "-id"},

    //datetime for initial find request
    findRequestDate: undefined,
    //load more button showint
    showMoreIsHidden: true,

    //bucket fetching
    limit: 2,
    offset: 0,

})


export default function reduce(state = initialState, action = {}) {

    switch (action.type) {
        case findTypes.CATEGORY_CHANGED:

            return state.merge({
                findCategory: action.newCategory
            })

        case findTypes.CITY_CHANGED:

            return state.merge({
                findCity: action.newCity
            })

        case findTypes.RATE_CHANGED:

            return state.merge({
                findRating: action.newRate
            })

        case findTypes.NAME_CHANGED:

            return state.merge({
                findName: action.newName
            })

        case findTypes.MIN_PRICE_CHANGED:

            return state.merge({
                findMinPrice: action.newMinPrice
            })

        case findTypes.MAX_PRICE_CHANGED:

            return state.merge({
                findMaxPrice: action.newMaxPrice
            })

        case findTypes.HAS_HISTORY_CHANGED:

            return state.merge({
                findHasHistory: action.newValue
            })

        case findTypes.HAS_ATTACHMENT_CHANGED:

            return state.merge({
                findHasAttachments: !state.findHasAttachments
            })

        case findTypes.SKILL_ADDED:

            var mutableState = Immutable.asMutable(state, {deep: true});
            var addedSkills = mutableState.findServices
            addedSkills.push(action.newSkill)
            return state.merge({
                findServices: addedSkills,
            })

        case findTypes.SKILL_REMOVED:

            var mutableState = Immutable.asMutable(state, {deep: true});
            var existedSkills = mutableState.findServices

            var removedElementSkills = existedSkills.filter(function(e) { return e.id !== action.removedSkill.id })

            return state.merge({
                findServices: removedElementSkills,
            })
            

        case findTypes.SORT_FILTER_CHANGED:

            return state.merge({
                findOrderBy: action.sortBy
            })

        case findTypes.APPLY_FILTER:

            let dt = action.datetime

            var mutableState = Immutable.asMutable(state, {deep: true});

            var limit = mutableState.limit

            //reset offset value
            var newOffset = 0
            var newShowMoreIsHidden = mutableState.showMoreIsHidden


            if (action.results.length != 0) {
                //update offset value
                newOffset = newOffset + limit
                //hide load more button if loaded data count < limit - it mean that all data loaded
                if (action.results.length < limit) {
                    newShowMoreIsHidden = true    
                } else {
                    newShowMoreIsHidden = false
                }
            } else {
                newShowMoreIsHidden = true
            }

            //clear dt if results is empty

            //important rewrite results array
            return state.merge({
                results: action.results,
                findRequestDate: dt,
                offset: newOffset,
                showMoreIsHidden: newShowMoreIsHidden,
                error: action.error,
            })
        
        case findTypes.LOAD_MORE:

            var mutableState = Immutable.asMutable(state, {deep: true});

            var limit = mutableState.limit

            var newResults = mutableState.results

            var newOffset = mutableState.offset
            var newShowMoreIsHidden = mutableState.showMoreIsHidden

            if (action.results.length != 0) {
                //merge results arrays and update offset value
                newResults = newResults.concat(action.results)
                newOffset = newOffset + limit
                //hide load more button if loaded data count < limit - it mean that all data loaded
                if (action.results.length < limit) {
                    newShowMoreIsHidden = true    
                } else {
                    newShowMoreIsHidden = false
                }
            } else {
                newShowMoreIsHidden = true
            }

            return state.merge({
                results: newResults,
                offset: newOffset,
                showMoreIsHidden: newShowMoreIsHidden,
            })

        default:
            return state;
    }
}

//selectors
export function getResults(state) {
    const results = state.find.results;
    const showMoreIsHidden = state.find.showMoreIsHidden
    return [results, showMoreIsHidden];
}

export function getSearchCriteria(state) {

    var searchCriteria = {
        findServices: state.find.findServices,
        findCity: state.find.findCity,
        findRating: state.find.findRating,
        findName: state.find.findName,
        findMinPrice: state.find.findMinPrice,
        findMaxPrice: state.find.findMaxPrice,
        findHasAttachments: state.find.findHasAttachments,
        findCategory: state.find.findCategory,
        findOrderBy: state.find.findOrderBy,
        limit: state.find.limit,
        offset: state.find.offset,
    }
    return searchCriteria
}

export function getRatingListItems(state) {
    return state.find.availableRatingItems;
}

export function getSortMasterListItems(state) {
    return state.find.availableSortMasterItems;
}

export function getSortOrderListItems(state) {
    return state.find.availableSortOrderItems;
}

export function getFindRequestInitialDatetime(state) {
    return state.find.findRequestDate;
}