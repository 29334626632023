import React from 'react'

import GalaryItem from './GalaryItem'
import SvgIcon from '../../common/SvgIcon'

class PortfolioItem extends React.Component {

    render() {
    const isOwner = this.props.isOwner
        return (
            <div className="portfolio-item">
                <div className="portfolio-item__gallery compact-gallery">
                    <GalaryItem/>
                    <GalaryItem/>
                    <GalaryItem/>
                    <div className="editable-over">
                        <button className="compact-gallery__delete-btn delete-btn-2" type="button">
                            <SvgIcon name='icon-close'/>
                        </button>
                        <button className="compact-gallery__edit-btn edit-portfolio-btn live-edit-btn btn--1 btn--sm" type="button" data-state="Редактирование заказа">Редактировать</button>
                    </div>
                </div>
                <div className="portfolio-item__title">Укладка плитки</div>
                <div className="portfolio-item__count">14 фото
                    <SvgIcon name='icon-checked'/>
                </div>
            </div>
        )
    }
}

export default PortfolioItem