import React from 'react'
import SvgIcon from './SvgIcon'

class RemoveButton extends React.Component {

  render() {
    return (  
      <button className="delete-exp-btn delete-btn" type="button" title="Удалить" 
      onClick={this.props.onClick} value={this.props.value}>
        <SvgIcon name='icon-close'/>
      </button>
    );
  }
}

export default RemoveButton