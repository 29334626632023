import React from 'react'
import autoBind from 'react-autobind';
import { connect } from 'react-redux';

import EducationItem from '../components/EducationItem'
import EditButton from '../../common/EditButton'

import * as profileActions from '../../store/profile/actions';
import * as profileSelectors from '../../store/profile/reducer';
import AuthService from '../../services/AuthService';

//TODO: - REFACTOR -> make it as a container

class Education extends React.Component {

    constructor(props) {
        super(props);
        autoBind(this);
    }

    createEducationTable() {

        let table = []

        if (this.props.profile == undefined) {
            return table
        }
        
        const educations = this.props.profile.education

        educations.forEach(element => {
            table.push(<EducationItem education={element} />)
        });
    
        return table
    }

    render() {

        var isEmpty = true
        if (this.props.profile == undefined) {
            isEmpty = false
        } else {
            if (this.props.profile.education) {
                const educations = this.props.profile.education
                if (educations.length == 0) {
                    isEmpty = true
                } else {
                    isEmpty = false
                }
            }
        }

        let isMyProfile = AuthService.shared().isMyProfile()

        return (
            <section class="user-card-section">
                <h2 class="user-card-section-title has-edit-btn"><span>Образование</span>
                    {isMyProfile ? (<EditButton onClick={this.onEditClick} />) : ''}
                </h2>

                {isEmpty ? (
                <div className="empty-data">
                    { isMyProfile ? 
                        (<p>Пожалуйста, укажите своё образование. Это позволит заказчикам оценить Ваш профессиональный уровень.</p>)
                        :
                        (<p>Мастер не указал свое образование.</p>)
                    }
                </div>                
              ) : (
                <div class="user-edu-list">
                    {this.createEducationTable()}
                </div>
              )}

                
            </section>
        );
    }

    onEditClick(event) {
        event.preventDefault();
        if (this.props.isEducationEditMode) {
            this.props.dispatch(profileActions.educationEditStateChanged(false))
        } else {
            this.props.dispatch(profileActions.educationEditStateChanged(true))
        }

    }
}

// export default MasterPersonInfoContainer
function mapStateToProps(state) {
    const profile = profileSelectors.getProfile(state);
    const isEducationEditMode = profileSelectors.isEditModeEnabled(state);
    return {
        profile,
        isEducationEditMode,
    }
}

export default connect(mapStateToProps)(Education);
