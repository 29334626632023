import React, { Component } from 'react';
import ReactSlider from 'react-slider';
import styled from 'styled-components';

const StyledSlider = styled(ReactSlider)`
	width: 100%;
	height: 25px;
`;

const StyledThumb = styled.div`
	width: 29px !important;
	height: 37px !important;
	background: green;
	background-size: cover;
	border-radius: 0;
	right: -20px !important;
	top: -18px;
`;

const Thumb = (props, state) => (
	<StyledThumb {...props}>{state.value}</StyledThumb>
);

const StyledTrack = styled.div`
	background: ${(props) => (props.index === 1 ? '#FAFAFA' : '#f89020')};
	border-width: 63px 497px 0px 0px;
	position: absolute;
	bottom: 2px;
	left: 0;
	width: 0;
	height: 0;
	border-style: solid;
	border-color: #fff transparent transparent;
`;

const Track = (props, state) => <StyledTrack {...props} index={state.index} />;

const StyledMark = styled.div`
	width: 13px;
	height: 13px;
	background-color: #f9f7f2;
	border: 4px solid #fff;
	border-radius: 50%;
	position: absolute;
	bottom: -3px;
`;
const StyledSeparator = styled.div`
	width: 3px;
	height: 50px;
	position: absolute;
	bottom:10px;
	background-color: #fff;


`;
const Mark = (props, state) => <StyledMark {...props} >
	<StyledSeparator/>
</StyledMark>;

export default class PriceSlider extends Component {
	render() {
		return (
			<div style={{ width: '100%' }}>
				<StyledSlider
					marks
					min={0}
					max={9}
					renderMark={Mark}
					renderTrack={Track}
					renderThumb={Thumb}
					ariaValuetext={'dsfd'}				/>
			</div>
		);
	}
}
