import React from 'react'
import autoBind from 'react-autobind';
import _ from 'lodash';

import SelectList from '../../common/SelectList'

class FindMasterRating extends React.Component {


  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      isSelectListOpenned: false,
    }
  }

  prepareSelectListData() {

    const availableRatingItems = this.props.findRatingListItems
    const selectedRatingId = this.props.selectedRatingId.id

    var data = []

    _.forEach(availableRatingItems, function (value) {

      var item = {}

      if (selectedRatingId !== undefined) {
        if (selectedRatingId == value.id) {
          item = {
            id: value.id,
            text: value.title,
            selected: true,
          }

        } else {
          item = {
            id: value.id,
            text: value.title,
          }
        }
      } else {
        item = {
          id: value.id,
          text: value.title,
        }
      }



      data.push(item)

    });

    return [data, selectedRatingId]

  }




  render() {
    
    const [data, selectedRatingId] = this.prepareSelectListData()

    return (
      <React.Fragment>
        <SelectList
          data={data}
          // orderNumber={orderNumber}
          selectedId={selectedRatingId}
          onSelectListChange={this.onSelectListChange}
          onSelectListOpen={this.onSelectListOpen}
          onSelectListClose={this.onSelectListClose}
        />
      </React.Fragment>
    );

  }

  onSelectListChange(inputId, event) {

    if (this.state.isSelectListOpenned) {
      return
    }

    this.props.onRatingChanged(event.params.data.id)
  }



  onSelectListOpen = () => {
    this.setState({
      isSelectListOpenned: true
    })
  }

  onSelectListClose = () => {
    this.setState({
      isSelectListOpenned: false
    })
  }



}

export default FindMasterRating