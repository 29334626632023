import Immutable from 'seamless-immutable';
import _ from 'lodash';
import * as myJobsTypes from './actionTypes';

const initialState = Immutable({
    incomeJobs: [],

    activeJobs: [],

    closedJobs: [],
})

export default function reduce(state = initialState, action = {}) {

    switch (action.type) {
        case myJobsTypes.INCOME_JOBS_CHANGED:
            return state.merge({
                incomeJobs: action.incomeJobs,
            })

        case myJobsTypes.ACTIVE_JOBS_CHANGED:
            return state.merge({
                activeJobs: action.activeJobs,
            })

        case myJobsTypes.CLOSED_JOBS_CHANGED:
            return state.merge({
                closedJobs: action.closedJobs,
            })
        default:
            return state;
    }
}

//selectors
export function getMyJobsInfo(state) {
    const incomeJobsInfo = state.myJobs.incomeJobs
    const activeJobsInfo = state.myJobs.activeJobs
    const closedJobsInfo = state.myJobs.closedJobs
    return [incomeJobsInfo, activeJobsInfo, closedJobsInfo]
}