import Immutable from 'seamless-immutable';
import _ from 'lodash';
import * as vuuteTypes from './actionTypes';
import { stat } from 'fs';

const initialState = Immutable({
    services: [],
    countries: [],
    cities: [],
    educationObjects: [],
    categories: [],
})

export default function reduce(state = initialState, action = {}) {
    
    switch(action.type) {
        case vuuteTypes.REQUEST_SERVICES:
            return state.merge({
                services: action.services
            });
        
        case vuuteTypes.REQUEST_COUNTRIES:
            return state.merge({
                countries: action.countries
            });

        case vuuteTypes.REQUEST_CITIES:
            return state.merge({
                cities: action.cities
            });
        
        case vuuteTypes.REQUEST_EDUCATION_OBJECTS:
            return state.merge({
                educationObjects: action.educationObjects
            });
        case vuuteTypes.REQUEST_CATEGORIES:
            return state.merge({
                categories: action.categories
            });
        default:
            return state;
    }
}


//selectors
export function getAvailableVuuteServices(state) {
    const services = state.vuuteServices.services;
    if (state.profile.modifiedProfile && Array.isArray(state.profile.modifiedProfile.services)){
    const servicesChooseId=state.profile.modifiedProfile?.services.map(value=>value.serviceObject.id)
    const servicesFilter= services.filter((value)=>(!(servicesChooseId.includes( value.id))))
    return servicesFilter;
    }
    else{
        return services;
    }
}

export function getAvailableVuuteCountries(state) {

    const countries = state.vuuteServices.countries;
    return countries;
}

export function getAvailableVuuteCities(state) {
    const cities = state.vuuteServices.cities;
    return cities;
}

export function getAvailableVuuteEducationObjects(state) {
    const educationObjects = state.vuuteServices.educationObjects;
    return educationObjects;
}

export function getAvailableCategories(state) {
    const categories = state.vuuteServices.categories;
    return categories;
}