import _ from 'lodash';
import ProfileBioParser from '../../profile/parsers/ProfileBioParser';
import MainReview from '../model/MainReview';
import OrderParser from '../../order/parsers/OrderParser';
import JobState from '../model/JobState';
import ReviewParser from './ReviewParser';

import JSONParser from '../../common/parsers/JSONParser';
import JobParser from './JobParser';

class MainReviewParser extends JSONParser {
	parse() {
		super.parse();

		var reviewer = null;
		if (
			_.get(this.data, 'profile_estimated') !== undefined &&
			_.get(this.data, 'profile_estimated') !== null
		) {
			reviewer = new ProfileBioParser(
				_.get(this.data, 'profile_estimated')
			).parse();
		}
		var job = null;
		if (
			_.get(this.data, 'job_id') !== undefined &&
			_.get(this.data, 'job_id') !== null
		) {
			job = new JobParser(_.get(this.data, 'job_id')).parse();
    }
    var 	review = new ReviewParser(this.data).parse();
    let mainReview = new MainReview(job, review, reviewer);

		return mainReview;
	}
}

export default MainReviewParser;
