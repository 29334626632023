
class CityVuute {
    id;
    title;
    country;

    constructor(id, title, country = null) {
        this.id = id
        this.title = title
        this.country = country
    }
}

export default CityVuute;