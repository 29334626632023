
class ProfileEducation {
    id;
    educationObject;
    speciality;
    dateStarted;
    dateFinished;

    constructor(id, educationObject, speciality, dateStarted, dateFinished) {
        this.id = id
        this.educationObject = educationObject
        this.speciality = speciality
        this.dateStarted = dateStarted
        this.dateFinished = dateFinished
    }
}

export default ProfileEducation;