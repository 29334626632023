import React from 'react'

import SvgIcon from '../../common/SvgIcon'

import LoginMsgBox from '../../user/components/LoginMsgBox';

class OrderBidPopup extends React.Component {

    render() {

        const jobBidError = this.props.jobBidError

        return (
            <div className="fancybox-container fancybox-is-open fancybox-can-drag" role="dialog" tabIndex="-1" id="fancybox-container-3">
                <div className="fancybox-bg"></div>
                <div className="fancybox-inner">
                    <div className="fancybox-stage">
                        <div className="fancybox-slide popup-form fancybox-slide--html fancybox-slide--current fancybox-slide--complete">
                            <div className="popup popup--lg fancybox-content" id="master-bid-popup" style={{ display: "inline-block" }} >
                                <button data-fancybox-close className="fancybox-close-small" title="Закрыть" onClick={this.props.onBidOrderCloseClick}>
                                    <SvgIcon name='icon-close' />
                                </button>
                                <div className="popup__title"><span>{this.props.orderInfo.title}</span></div>
                                <div className="popup__inner">
                                    {(jobBidError !== undefined) ? <LoginMsgBox msgText={jobBidError.message} msgType={"error-msg"} /> : ''}
                                    <form className="popup-frm">
                                        <textarea
                                            id="master-bid-msg"
                                            name="master-bid-msg"
                                            placeholder="Ваше сообщение заказчику"
                                            value={this.props.orderBidComment}
                                            onChange={this.props.onBidCommentChanged}
                                        />
                                        <button className="popup-frm__submit btn--2" onClick={this.props.onBidOrderSendClick}>откликнуться</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default OrderBidPopup