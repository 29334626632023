export default [
    {
        'id': 0,
        'title': 'Разместите свой заказ',
        'is_icon': true,
        'icon': 'icon-girl-1',
        'text': 'Подробно опишите свой заказ, укажите ваши требования, а также желаемую стоимость и сроки',
    },
    {
        'id': 1,
        'title': 'Изучите отклики',
        'is_icon': true,
        'icon': 'icon-girl-2',
        'text': 'Дождитесь откликов от исполнителей и сравните их. Вся информация под рукой: цена, комментарии и рейтинг исполнителя',
    },
    {
        'id': 2,
        'title': 'Выберите исполнителя',
        'is_icon': false,
        'icon': 'man-1',
        'text': 'Выберите подходящего вам исполнителя и свяжитесь с ним напрямую для согласования условий выполнения Вашего заказа',
    },
    {
        'id': 3,
        'title': 'Оставьте отзыв',
        'is_icon': true,
        'icon': 'icon-girl-3',
        'text': 'После выполнения заказа — оставьте отзыв. Вместе мы сделаем наш сервис лучше!',
    },
]