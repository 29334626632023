import React from 'react';
import Select, { components } from 'react-select';
const Styles = {
	option: (styles, { data, isDisabled, isFocused, isSelected }) => {
		return {
			...styles,
			backgroundColor: isFocused ? '#f9f7f2' : '#fff',
			color: isDisabled ? 'black' : isSelected ? '#f89020' : 'black',
			cursor: 'pointer',
			zIndex: 5,
			fontSize: '16px !important',
			':active': {
				...styles[':active'],
				backgroundColor: '#fff',
			},
		};
	},
	indicatorSeparator: (styles) => ({ ...styles, display: 'none' }),
	dropdownIndicator: (styles) => {
		return {
			...styles,
			color: 'green',
		};
	},
	control: (base) => ({
		...base,
		border: 'none !important',
		boxShadow: 'none !important',
		height: 48,
		minHeight: 48,
		cursor: 'pointer',
		color: '#1fb579',
		backgroundColor: 'white',
	}),
	valueContainer: (provided, state) => ({
		...provided,
		height: '48px',
		padding: '0px 16px',
	}),
	singleValue: (provided, state) => ({
		...provided,
		overflow: 'visble',
		color: '#1fb579',
	}),
	indicatorsContainer: (provided, state) => ({
		...provided,
		height: '48px',
	}),
	input: (provided, state) => ({
		...provided,
		height: '48px',
		margin: '0px',
		color: '#1fb579',
	}),
	menu: (provided) => ({ ...provided, zIndex: 9999999 }),
	menuPortal: (base) => ({ ...base, zIndex: 999999 }),
	menuList: (base) => ({
		...base,

		'::-webkit-scrollbar': {
			width: '4px',
		},
		// '::-webkit-scrollbar-track': {
		//     background: 'red',
		// },
		'::-webkit-scrollbar-thumb': {
			background: '#ffb05b',
		},
		'::-webkit-scrollbar-thumb:hover': {
			background: '#ff8400',
		},
	}),
};
const DropdownIndicator = (props) => {
	return (
		<components.DropdownIndicator {...props}>
			<div className='' style={{ paddingRight: '8px' }}>
				<img
					src='/static/media/down.78a8a621.svg'
					alt=''
					style={{ width: '12px', height: '5px' }}
				/>
			</div>
		</components.DropdownIndicator>
	);
};
const NoOptionsMessage = (props) => {
	return (
		<components.NoOptionsMessage {...props}>
			Совпадений не найдено
		</components.NoOptionsMessage>
	);
};
function FindReactSelect({ data, selected, onSelect }) {
	const handleSelect = (e) => {
		if (selected === undefined || e.value !== selected.value) {
			onSelect(e);
		}
	};
	return (
		<div style={{ width: '100%', zIndex: '9999999' }}>
			<Select
				menuPortalTarget={document.body}
				menuPosition={'absolute'}
				className='basic-single'
				classNamePrefix='select'
				components={{ DropdownIndicator, NoOptionsMessage }}
				options={data}
				defaultValue={selected}
				onChange={handleSelect}
				placeholder='Выбрать'
				styles={Styles}
				hideSelectedOptions
				isSearchable={false}
			/>
		</div>
	);
}

export default FindReactSelect;
