import React from 'react'
import noAva from '../../../images/no-ava.png'

class ReviewerItem extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        var profileImage = ''
        var profileName = ''
        if (this.props.jobInfo.order.permissions.isClient | this.props.jobInfo.order.permissions.isClient===undefined ) {
            profileImage = this.props.jobInfo.master.photoUrl
            profileName = this.props.jobInfo.master.firstName
        } else {
            profileImage = this.props.jobInfo.order.owner.photoUrl
            profileName = this.props.jobInfo.order.owner.firstName
        }

        return (
            <div className="order-review-frm__sidebar">
                <div className="user-meta">
                    <div className="user-meta__ava">
                        {(profileImage) ?
                            <img src={profileImage} alt="" />
                            :
                            <img src={noAva} alt="" />
                        }
                    </div>
                    <div>
                        <div className="user-meta__name">{profileName}</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ReviewerItem