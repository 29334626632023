import _ from 'lodash';
import JSParseService from '../services/JSParseService';

const API_KEY = 'Qd8raS23d0QcjUjtITFl0QDNYfRcvHfuTtk_lGDa92M'

class GeolocationService {

    async getAddresses(address) {

        const url = `https://autocomplete.geocoder.ls.hereapi.com/6.2/suggest.json?apiKey=${API_KEY}&query=${address}&maxresults=1`
        const response = await fetch(url, {
            method: 'GET',
            mode: 'cors',
            credentials: "same-origin"
        });

        if (!response.ok) {
            throw new Error(`APIService getAddressChecker failed, HTTP status ${response.status}`);
        }

        const data = await response.json();

        if (data.suggestions !== undefined && data.suggestions.length > 0) {
            var checkedAddresses = JSParseService.parseCheckedAddresses(data)[0]
        } else {
            var checkedAddresses = []
        }

        return checkedAddresses
    }

    async getCoordinates(address) {

        var locationId = address.locationId

        const url = `https://geocoder.ls.hereapi.com/6.2/geocode.json?apiKey=${API_KEY}&locationId=${locationId}`
        const response = await fetch(url, {
            method: 'GET',
            mode: 'cors',
            credentials: "same-origin"
        });

        if (!response.ok) {
            throw new Error(`APIService getCoordinates failed, HTTP status ${response.status}`);
        }

        const data = await response.json();

        if (data.Response.View[0] !== undefined) {
            var latitude = data.Response.View[0].Result[0].Location.DisplayPosition.Latitude
            var longtitude = data.Response.View[0].Result[0].Location.DisplayPosition.Longitude
        } else {
            latitude = undefined
            longtitude = undefined
        }

        return [latitude, longtitude]
    }

    async getAddressesByCoords(latitude, longtitude) {

        const url = `https://reverse.geocoder.ls.hereapi.com/6.2/reversegeocode.json?apiKey=${API_KEY}&prox=${latitude}%2C${longtitude}%2C250&mode=retrieveAddresses&maxresults=1&gen=9`
        const response = await fetch(url, {
            method: 'GET',
            mode: 'cors',
            credentials: "same-origin"
        });

        if (!response.ok) {
            throw new Error(`APIService getAddressChecker failed, HTTP status ${response.status}`);
        }

        const data = await response.json();

        if (data.Response.View[0] !== undefined) {
            var checkedAddress = data.Response.View[0].Result[0].Location
        } else {
            checkedAddress = []
        }

        return checkedAddress
    }
}

export default new GeolocationService();