import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';

import * as profileActions from '../../store/profile/actions';
import * as profileSelectors from '../../store/profile/reducer';

import SvgIcon from "../../common/SvgIcon"


class PhotoPopupContainer extends React.Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    readFile(event) {
        var photoFile = event.target.files[0]
        this.props.dispatch(profileActions.previewPhoto(photoFile))
    }

    render() {

        return <React.Fragment>
            <div className="fancybox-container fancybox-is-open fancybox-can-drag" role="dialog" tabIndex={-1} id="fancybox-container-3" style={{ transitionDuration: '350ms' }}>
                <div className="fancybox-bg" />
                <div className="fancybox-slide popup-form fancybox-slide--html fancybox-slide--current fancybox-slide--complete">
                    <div className="popup popup--sm fancybox-content" id="master-profile-edit-ava" style={{ display: 'inline-block' }}><button data-fancybox-close className="fancybox-close-small" title="Закрыть" onClick={this.onCloseClick}><SvgIcon name='icon-close' /></button>
                        <div className="popup__title"><span>Редактирование фото</span></div>
                        <div className="popup__inner">
                            <div className="file-wrap file-wrap--lg file-wrap--single">
                                <input className="js-file-image-input" id="master-profile-ava" name="master-profile-ava" ref="upload" type="file" accept="image/*"
                                    onInput={(event) => {
                                        this.readFile(event)
                                    }}
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}
                                />

                                {(this.props.photoEditObject[0] == '') ? (
                                    <div className="attachment-wrap">
                                        <label className="file-image-label" htmlFor="master-profile-ava">выбрать новое фото</label>
                                    </div>
                                ) : (
                                    <div className="pip">
                                        <img className="image-thumb" src={this.props.photoEditObject[0]} title="undefined" />
                                        <button type="button" className="remove" onClick={this.onClearClick}><SvgIcon name='icon-close' /></button>
                                    </div>
                                )}

                                
                            </div>
                        </div>
                        <button className="close-popup btn--2 popup__wide-btn" onClick={this.onSaveClick}>Сохранить</button>
                    </div>
                </div>
            </div>
        </React.Fragment>

    }

    onCloseClick(e) {
        e.preventDefault()
        this.props.dispatch(profileActions.closePhoto())
        this.props.onChangeVisibility(e)
    }

    onClearClick(e) {
        e.preventDefault()
        this.props.dispatch(profileActions.closePhoto())
    }

    onSaveClick(e) {
        e.preventDefault()
        this.props.dispatch(profileActions.savePhoto(this.props.photoEditObject[1]))
        this.props.onChangeVisibility(e)
    }

}

// export default ClientPersonInfoEditContainer
function mapStateToProps(state) {
    const photoEditObject = profileSelectors.getPhotoEditObject(state);
    return {
        photoEditObject,
    }
}

export default connect(mapStateToProps)(PhotoPopupContainer);