import React from 'react'

const SvgIcon = props => {

    return (
        <React.Fragment>
            {props.name ? (
                <svg className={`icon ${props.name}`}>
                    <use xlinkHref={`${require('../images/sprite.svg')}#${props.name}`} />
                </svg>) : (
                    [(
                        props.file ? <img className={props.imgClassName} src={require("../images/" + props.file + ".svg")} alt="" /> : ''
                    )])
            }
        </React.Fragment>
    )
}

export default SvgIcon