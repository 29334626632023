import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import * as loginTypes from './store/auth/actionsTypes';

import * as reducers from './store/reducers';

import { composeWithDevTools } from 'redux-devtools-extension';

const appReducer = combineReducers(reducers)

const rootReducer = (state, action) => {
    if (action.type === loginTypes.LOGOUT) {
        state = undefined
    }
    return appReducer(state, action)
}

// const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))
const store = createStore(combineReducers(reducers), applyMiddleware(thunk))

export default store;