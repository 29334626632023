import _ from 'lodash';
import NotificationsSettings from '../model/NotificationsSettings'

const { default: JSONParser } = require("../../common/parsers/JSONParser");

class NotificationsSettingsParser extends JSONParser {

    parse() {
        super.parse()

        let notificationsSettings = new NotificationsSettings(_.get(this.data, 'id'),
                                            _.get(this.data, 'messages'),
                                            _.get(this.data, 'job_states'),
                                            _.get(this.data, 'sales'),
                                            _.get(this.data, 'news'))
        
        return notificationsSettings

    }

}

export default NotificationsSettingsParser;