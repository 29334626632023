import _ from 'lodash';
import CityVuute from "../model/CityVuute";
import CountryVuuteParser from "../parsers/CountryVuuteParser";

import JSONParser from '../../common/parsers/JSONParser'

class CityVuuteParser extends JSONParser {
    parse() {
        let data = super.parse()
        if (data === null) { return null }

        let country = new CountryVuuteParser(_.get(this.data, 'country_vuute')).parse()

        let city = new CityVuute(_.get(this.data, 'id'),
                                _.get(this.data, 'title'),
                                country)
        
        return city

    }
}

export default CityVuuteParser;