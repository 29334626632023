import _ from 'lodash';
import CategoryVuute from '../model/CategoryVuute';
import JSONParser from '../../common/parsers/JSONParser'

class CategoryVuuteParser extends JSONParser {

    parse() {
        super.parse()

        let category = new CategoryVuute(_.get(this.data, 'id'),
                                         _.get(this.data, 'title'))
        
        return category

    }
}

export default CategoryVuuteParser