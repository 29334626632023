import React, { useState } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import ru from "date-fns/locale/ru"
import "react-datepicker/dist/react-datepicker.css"

import DateService from '../services/DateService'

registerLocale("ru", ru)

class TermCalendar extends React.Component {

  render() {
    var selectedDate = this.props.selectedDate
    if (!DateService.isValidDate(selectedDate)) {
      selectedDate = null
    }

    return (
      <React.Fragment>
        <DatePicker
          locale="ru"
          className='date-input js-datepicker'
          dateFormat="dd MMMM yyyy"
          selected={selectedDate}
          onChange={this.props.onChange}
        />
      </React.Fragment>
    );
  }
}

export default TermCalendar;