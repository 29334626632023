import React from 'react'

import SvgIcon from '../../../common/SvgIcon'

/**
 * - allow user search needed dialog using string filter
 *
 * @class RoomViewSearch
 * @extends {React.Component}
 */
class RoomViewSearch extends React.Component {

  /**
   * Basic render function for React component
   *
   * @returns
   * @memberof RoomViewSearch
   */
  render() {
    return (
      <div className="messages-nav">
        <form className="messages-search-frm">
          <div className="input-wrap">
            <input className="search-input" type="text" placeholder="Поиск" />
            <button className="search-btn" type="submit">
              <SvgIcon name='icon-search' />
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default RoomViewSearch