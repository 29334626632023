import Immutable from 'seamless-immutable';
import * as loginTypes from './actionTypes';

const initialState = Immutable({
    phoneNumber: '',
    password: '',
    isPasswordRecovered: false,
    error: undefined,
})

export default function reduce(state = initialState, action = {}) {
    

    switch(action.type) {
        case loginTypes.PHONE_CHANGED:
            return state.merge({
                phoneNumber: action.phoneNumber
            });
        case loginTypes.PASSWORD_CHANGED:
            return state.merge({
                password: action.password
            });
        case loginTypes.FORGOT_PASSWORD_CLICKED:
            return state.merge({
                isPasswordRecovered: action.isPasswordRecovered,
                error: action.error
            });
        case loginTypes.CLEAR_LOGIN_FORM_ERRORS:
            return state.merge({
                isPasswordRecovered: false,
                error: undefined
            })
        case loginTypes.CLEAR_LOGIN_FORM:
            return initialState
        default:
            return state;
    }
}


//selectors
export function getCredentials(state) {
    return [state.login.phoneNumber, state.login.password];
}

export function getRecoverStatus(state) {
    return state.login.isPasswordRecovered
}

export function getError(state) {
    return state.login.error
}