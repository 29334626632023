import React, {Component} from 'react'

import StepItem from '../step/StepItem'

import masterStepsData from '../../data/masterStepsData'

class MasterGrid extends Component {
    render () {
        return(
            <div className="tabs-content">
                <div className="box visible">
                    <div className="steps-list">
                        <StepItem article = {masterStepsData[0]} svgName='icon-list'/>
                        <StepItem article = {masterStepsData[1]} svgFile='man-2'/>
                        <StepItem article = {masterStepsData[2]} svgFile='man-1'/>
                        <StepItem article = {masterStepsData[3]} svgName='icon-cache'/>
                    </div>
                </div>
            </div>
        )
    }
}   

export default MasterGrid