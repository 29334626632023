import _ from 'lodash';
import ProfileBioParser from '../../profile/parsers/ProfileBioParser';
import Job from '../model/Job';
import OrderParser from '../../order/parsers/OrderParser';
import JobState from '../model/JobState';
import ReviewParser from './ReviewParser';

import JSONParser from '../../common/parsers/JSONParser'

class JobParser extends JSONParser {

    parse() {
        super.parse()

        let order = new OrderParser(_.get(this.data, 'order')).parse()
        let master = new ProfileBioParser(_.get(this.data, 'master')).parse()
        let jobState = new JobState(_.get(this.data, 'state'))
        var reviewOwner = null
        if (_.get(this.data, 'review_owner') !== undefined && _.get(this.data, 'review_owner') !== null) {
            reviewOwner = new ReviewParser(_.get(this.data, 'review_owner')).parse()
        }

        var reviewMaster = null
        if (_.get(this.data, 'review_master') !== undefined && _.get(this.data, 'review_master') !== null) {
            reviewMaster = new ReviewParser(_.get(this.data, 'review_master')).parse()
        }

        var createdDate = new Date(_.get(this.data, 'created_date'))

        // fix parsing all dates because Date(null) return valid date JS :)
        var acceptedDate = undefined
        if (this.data.accepted_date != null) {
            acceptedDate = new Date(_.get(this.data, 'accepted_date'))
        }

        var closedDate = undefined
        if (this.data.closed_date != null) {
            closedDate = new Date(_.get(this.data, 'closed_date'))
        }

        var rejectedDate = undefined
        if (this.data.rejected_date != null) {
            rejectedDate = new Date(_.get(this.data, 'rejected_date'))
        }

        var cancelledDate = undefined
        if (this.data.canceled_date != null) {
            cancelledDate = new Date(_.get(this.data, 'canceled_date'))
        }

        let job = new Job(_.get(this.data, 'id'),
                         order, 
                         master,
                         jobState,
                         createdDate,
                         acceptedDate,
                         closedDate,
                         rejectedDate,
                         cancelledDate)
        job.reviewOwner = reviewOwner
        job.reviewMaster = reviewMaster
        return job

    }

}

export default JobParser;