import _ from 'lodash';
import CityVuuteParser from '../parsers/CityVuuteParser';

import JSONParser from '../../common/parsers/JSONParser'

class CitiesVuuteParser extends JSONParser {
    parse() {
        let data = super.parse()
        if (data === null) { return null }

        let cities = []
        _.forEach(this.data, function(cityData) {
            let city = new CityVuuteParser(cityData).parse()
            cities.push(city)
        });
        

        return cities
    }
}

export default CitiesVuuteParser;