import _ from 'lodash';
import MainReviewParser from './MainReviewParser';

import JSONParser from '../../common/parsers/JSONParser'


class MainReviewsParser extends JSONParser {
    parse() {
        let data = super.parse()
        if (data === null) { return null }


        let reviews = []
        _.forEach(this.data, function(reviewData) {
            let review = new MainReviewParser(reviewData).parse()
            reviews.push(review)
        });
        

        return reviews
    }
}

export default MainReviewsParser;