import React from 'react'

import MatchingSidebar from './MatchingSidebar';
import InProgressSidebar from './JobInProgressSidebarView'
import OnReviewSidebar from './JobOnReviewSidebarView';
import JobClosedSidebarView from './JobClosedSidebarView';
import JobRejectedSidebar from './JobRejectedSidebar';

import JobState from '../../model/JobState'

class JobSidebarContainerView extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        
        const jobState = this.props.jobInfo.state.statusTitle

        return (
            <React.Fragment>
                {this.props.jobInfo.state.statusTitle === 'MATCHING' && (
                    <MatchingSidebar
                        jobInfo={this.props.jobInfo}
                        onMasterConfirmationClick={this.props.onMasterConfirmationClick}
                        onMessageButtonClick={this.props.onMessageButtonClick}
                    />
                )}
                {this.props.jobInfo.state.statusTitle === 'ACCEPTED' && (
                    <InProgressSidebar
                        jobInfo={this.props.jobInfo}
                        onCloseConfirmationClick={this.props.onCloseConfirmationClick}
                        onReviewConfirmationClick={this.props.onReviewConfirmationClick}
                    />
                )}
                {this.props.jobInfo.state.statusTitle === 'ON_REVIEW' && (
                    <OnReviewSidebar jobInfo={this.props.jobInfo}
                        onReturnConfirmationClick={this.props.onReturnConfirmationClick}
                        onCloseConfirmationClick={this.props.onCloseConfirmationClick}
                    />
                )}
                {this.props.jobInfo.state.statusTitle === 'CLOSED' && (
                    <JobClosedSidebarView jobInfo={this.props.jobInfo} />
                )}
                {this.props.jobInfo.state.statusTitle === 'REJECTED' && (
                    <JobRejectedSidebar jobInfo={this.props.jobInfo} />
                )}
            </React.Fragment>
        );
    }
}

export default JobSidebarContainerView