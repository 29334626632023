class Notification {
    id;
    title;
    data;
    isRead;
    created;

    constructor(id, title, data, isRead, created) {
        this.id = id
        this.title = title
        this.data = data
        this.isRead = isRead
        this.created = created
    }
}

export default Notification;