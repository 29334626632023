import React from 'react'
import _ from 'lodash'

import ProfilePhotoEdit from '../../common/ProfilePhotoEdit'
import ProfileNameItemEdit from '../../common/ProfileNameItemEdit'
// import CitySelector from '../../common/CitySelector'
import CityList from '../../profile/components/CityList'
// import SkillsList from '../../common/skillsList/SkillsList'
import ProfileSaveEscButtons from '../../common/ProfileSaveEscButtons'
import PricesEdit from '../components/PricesEdit'

import autoBind from 'react-autobind';
import { connect } from 'react-redux';

import * as profileActions from '../../store/profile/actions';
import * as profileSelectors from '../../store/profile/reducer';

import * as vuuteServiceActions from '../../store/vuuteServices/actions';
import * as vuuteServiceSelectors from '../../store/vuuteServices/reducer';

import LoginMsgBox from '../../user/components/LoginMsgBox';

//TODO: Replace this file into containers
class MasterPersonInfoEdit extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    if (this.props.availableServices.length == 0) {
      this.props.dispatch(vuuteServiceActions.requestVuuteServices());
    }

    if (this.props.cities.length == 0) {
      this.props.dispatch(vuuteServiceActions.requestVuuteCities(1));
    }

  }

  render() {

    const errorBio = this.props.errorBio
    const errorServices = this.props.errorServices

    return (
      <React.Fragment>

        {(errorBio !== undefined) ? <LoginMsgBox msgText={errorBio.message} msgType={"error-msg"} /> : ''}

        <div className="person-info">
          

          {this.props.modifiedProfile ? (
            <ProfilePhotoEdit bio={this.props.modifiedProfile.bio}/>
          ) : (
            <ProfilePhotoEdit/>
          )}


          {this.props.modifiedProfile ? (
            <ProfileNameItemEdit bio={this.props.modifiedProfile.bio}
              onFirstNameChanged={this.onFirstNameChanged}
              onMidNameChanged={this.onMidNameChanged}
              onLastNameChanged={this.onLastNameChanged} />
          ) : (
              <ProfileNameItemEdit
                onFirstNameChanged={this.onFirstNameChanged}
                onMidNameChanged={this.onMidNameChanged}
                onLastNameChanged={this.onLastNameChanged} />
            )}

          {this.props.modifiedProfile ? (
            <div className="person-info__props editable">
              <CityList availableCities={this.props.cities}
                selectedCity={this.props.modifiedProfile.bio.city}
                onCityChanged={this.onCityChanged} />
            </div>
          ) : (
            <div className="person-info__props editable">
              <CityList availableCities={this.props.cities}
                onCityChanged={this.onCityChanged} />
            </div>
            )}
          

          {/* <CitySelector /> */}
          {/* <div className="person-info__skills editable">
            <SkillsList multiple={true}/>
          </div> */}
        </div>

        {(errorServices !== undefined) ? <LoginMsgBox msgText={errorServices.message} msgType={"error-msg"} /> : ''}
        {this.props.modifiedProfile ? (
          <PricesEdit services={this.props.modifiedProfile.services} 
            availableServices={this.props.availableServices} 
            onAddServiceClick={this.onAddServiceClick}
            onServiceItemRemoveClick={this.onServiceItemRemoveClick}
            onPriceChanged={this.onPriceChanged}
            onSelectListChange={this.onSelectListChange}
            onSelectListOpen={this.onSelectListOpen}
            onSelectListClose={this.onSelectListClose}/>
        ) : (
            <PricesEdit />
          )}


        <ProfileSaveEscButtons onCancelClick={this.onCancelClick} onSaveClick={this.onSaveClick} />
      </React.Fragment>
    );
  }

  //TODO: REFACTOR THIS PLACE
  onSaveClick(event) {
    event.preventDefault();
    this.props.dispatch(profileActions.saveProfile(this.props.modifiedProfile))
  }

  onCancelClick(event) {
    event.preventDefault();
    this.props.dispatch(profileActions.clearSaveBioErrors())
    this.props.dispatch(profileActions.clearSaveServicesErrors())
    this.props.dispatch(profileActions.discardChanges())
  }

  onFirstNameChanged(event) {
    this.props.dispatch(profileActions.changeFirstName(event.target.value))
  }

  onMidNameChanged(event) {
    this.props.dispatch(profileActions.changeMiddleName(event.target.value))
  }

  onLastNameChanged(event) {
    this.props.dispatch(profileActions.changeLastName(event.target.value))
  }

  onDescriptionChanged(event) {
    this.props.dispatch(profileActions.changeDescription(event.target.value))
  }

  onServiceItemRemoveClick(event) {
    this.props.dispatch(profileActions.deleteProfileService(event.currentTarget.value))
  }

  onAddServiceClick(event) {
    if (this.props.availableServices.length!==0) {
      this.props.dispatch(profileActions.addProfileService(this.props.availableServices))
    }
  }

  onPriceChanged(event) {
    const parsedInputName = event.target.name.split('-')
    const orderNumber = parsedInputName[parsedInputName.length - 1]
    this.props.dispatch(profileActions.changeServicePrice(orderNumber, event.target.value))
  }

  onSelectListChange(inputId, event) {
    const orderNumber = inputId
    var newService = event

    var newServiceId = parseInt(newService.value, 10)

    const vuuteServices = this.props.availableServices

    var newServiceForApi = undefined

    _.forEach(vuuteServices, function(service) {

      if (service.id == newServiceId) {
        newServiceForApi = service
        return false
      }
    });

    this.props.dispatch(profileActions.changeServiceItem(orderNumber, newServiceForApi))
  }

  onSelectListOpen() {
    this.props.dispatch(profileActions.changeSelectListState(true))
  }
            
  onSelectListClose() {
    this.props.dispatch(profileActions.changeSelectListState(false))
  }

  onCityChanged(cityId) {
    var newCityId = parseInt(cityId, 10)
    var newCity = null
    _.forEach(this.props.cities, function(city) {
        if (newCityId == city.id) {
            newCity = city
        }
    });

    this.props.dispatch(profileActions.changeCity(newCity))

  }

}

// export default MasterPersonInfoEditContainer
function mapStateToProps(state) {
  const availableServices = vuuteServiceSelectors.getAvailableVuuteServices(state);
  const modifiedProfile = profileSelectors.getModifiedProfile(state);
  const isSelectListOpenned = profileSelectors.getSelectListState(state);
  const cities = vuuteServiceSelectors.getAvailableVuuteCities(state);

  const errorBio = profileSelectors.getBioSavingError(state);
  const errorServices = profileSelectors.getServicesSavingError(state);
  return {
    modifiedProfile,
    availableServices,
    isSelectListOpenned,
    cities,
    errorBio,
    errorServices,
  }
}

export default connect(mapStateToProps)(MasterPersonInfoEdit);