import _ from 'lodash';
import ProfileServiceParser from './ProfileServiceParser'
import JSONParser from '../../common/parsers/JSONParser'

class ProfileServicesParser extends JSONParser {
    parse() {
        super.parse()
        let services = []
        _.forEach(this.data, function(serviceData) {
            let service = new ProfileServiceParser(serviceData).parse()
            services.push(service)
        });
        

        return services
    }
}

export default ProfileServicesParser;