import React from 'react'

import NotificationItemView from './NotificationItemView'
import Paginator from '../../common/Paginator';

class NotificationsListView extends React.Component {

  render() {
    var notifications = this.props.notifications
    var currentPage = this.props.currentPaginatorPage
    var notesInPage = 10
    var notificationItems = []

    var startItem = notesInPage * (currentPage - 1)
    var endItem = notesInPage * (currentPage)

    if (endItem > notifications.length) {
      endItem = notifications.length
    }

    for (var i = startItem; i < endItem; i++) {
      notificationItems.push(
        <NotificationItemView
          key={i.toString()}
          notificationInfo={notifications[i]}
          onUnreadNotificationClick={this.props.onUnreadNotificationClick}
        />
      )
    }

    return (
      <table className="notifications-tbl">
        <tbody>
          {notificationItems}
        </tbody>
        <Paginator
          totalNotes={notifications.length}
          notesInPage={notesInPage}
          currentPage={currentPage}
          onCurrentPageChanged={this.props.onCurrentPageChanged}
        />
      </table>
    );
  }
}

export default NotificationsListView