import React from 'react';

import NotifyItem from './NotifyItem';

export class SettingsNotifications extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        const titles = [
            { title: 'messages', id: 1, content: 'Уведомления о новых сообщениях' },
            { title: 'jobStates', id: 2, content: 'Уведомления об изменении состояния заказов' },
            { title: 'sales', id: 3, content: 'Уведомления о подарках и акциях сервиса' },
            { title: 'news', id: 4, content: 'Уведомления о новостях и событиях сервиса' },
        ]

        var settingsItems = titles.map((title) =>
            <NotifyItem
                key={title.id.toString()}
                title={title.title}
                id={title.id}
                keyStatus={this.props.notificationsInfo[title.title]}
                content={title.content}
                onNotifyItemClick={this.props.onNotifyItemClick}
            />
        )

        return (
            <form className="settings-section settings-frm ajax-form" id="notify">
                <div className="settings-frm__main">
                    <h2 className="settings-section__title">Уведомления</h2>
                    {settingsItems}
                </div>
                <div className="settings-frm__sidebar">
                    <button className="btn--1" type="submit" onClick={this.props.onReceiveSettingsClick}>Сохранить</button>
                </div>
            </form>
        )
    }
}

export default SettingsNotifications