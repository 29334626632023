import React from 'react'

import Rating from '../../../common/Rating'

import autoBind from 'react-autobind';
import { withRouter } from 'react-router-dom'

/**
 * View which contain all information about messages participants for selected room
 *
 * @class MessagesHeaderView
 * @extends {React.Component}
 */
class MessagesHeaderView extends React.Component {

  constructor(props) {
    super(props);
    autoBind(this);
  }
  
  /**
   * Prepare data for MessagesHeaderView
   *
   * @memberof MessagesHeaderView
   */
  //TODO: Refactor this method
  prepareRoomHeaderData() {

    var name = ''
    var jobTitle = ''
    var jobId = undefined
    var rate = 0
    var profileId = undefined
    var profileType = 'CLIENT'
    var jobState = ''

    const selectedChat = this.props.selectedChat

    if (selectedChat !== undefined) {
      name = selectedChat.profile.firstName
      profileId = selectedChat.profile.id
      rate = selectedChat.profile.rate
      profileType = selectedChat.profile.profileType
      if (selectedChat.job !== undefined) {
        jobTitle = selectedChat.job.order.title
        jobId = selectedChat.job.id
        jobState = selectedChat.job.state.statusTitle
      }
    }

    // only client profile can accept master for new job and for jobs in matching state
    const showAcceptButton = (profileType === 'MASTER') && (jobState === 'MATCHING')

    return [name, jobTitle, jobId, rate, profileId, showAcceptButton]
  }

  /**
   * Basic render function for React component
   *
   * @returns
   * @memberof MessagesHeaderView
   */
  render() {

    const roomHeaderData = this.prepareRoomHeaderData()
    const name = roomHeaderData[0]
    const jobTitle = roomHeaderData[1]
    const jobId = roomHeaderData[2]
    const rate = roomHeaderData[3]
    const profileId = roomHeaderData[4]
    const showAcceptButton = roomHeaderData[5]

    

    return (
      <div className="messages-chat__top">
        <div className="messages-chat__user-data"><a className="messages-chat__user-name" href="javascript:;" onClick={(e) => this.onProfileClick(profileId, e)}>{name}</a>
          <Rating rating={rate} isReadOnly={true} isShow={true} />
          <div className="messages-chat__user-contract">
            Заказ: <a href="javascript:;" onClick={(e) => this.props.onJobClick(jobId, e)}>{jobTitle}</a> 
            {/* {endDate && 
              <span className="deadline light-text">(до 20.10.2018)</span>
            } */}
            

          </div>
        </div>
        {(showAcceptButton === true) ? (
          <div className="messages-chat__user-control">
            <a className="new-contract-btn btn--2 btn--sm" href="javascript:;" onClick={this.props.onBidRejectClick}>Отклонить заявку</a>
            <a className="accept-request-btn btn--1 btn--sm" href="javascript:;" onClick={this.props.onBidConfirmationClick}>Принять заявку</a>
          </div>
        ) : 
        ('')
        }

        
      </div>
    );
  }

  onProfileClick(profileId, e) {
    e.preventDefault()
    this.props.history.push(`/profile/${profileId}/`)
    this.props.history.go(0)
  }

}

export default withRouter(MessagesHeaderView)