import React from 'react'
import autoBind from 'react-autobind';

/**
 * View which contain new message input
 *
 * @class NewMessageView
 * @extends {React.Component}
 */
class NewMessageView extends React.Component {

    /**
     * Creates an instance of NewMessageView
     * and define internal state parameter: newMessageText
     * @param {*} props
     * @memberof NewMessageView
     */
    constructor(props) {
        super(props);

        autoBind(this);

        this.state = {
            newMessageText: ''
        }
    }


    /**
     * Basic render function for React component
     *
     * @returns
     * @memberof NewMessageView
     */
    render() {

        var inputNewMessageText = this.state.newMessageText

        return (
            <div className="messages-chat__new-msg">
                <form className="messages-chat-frm">
                    <textarea id="messages-chat-msg" name="messages-chat-msg" placeholder="Новое сообщение…" value={inputNewMessageText}
                        onChange={this.onMessageChange} />
                    <div className="messages-chat__bottom">
                        <div className="messages-chat__attachment file-wrap">
                            {/* <label className="file-label" htmlFor="messages-chat-files">Прикрепить файл</label> */}
                            <input className="js-file-image-input" type="file" id="messages-chat-files" name="messages-chat-files[]" multiple />
                            <div className="attachment-wrap" />
                        </div>
                        <button className="messages-chat-frm__submit btn--2 btn--sm" type="submit"
                            onClick={(e) => this.onSendButtonClick({ nM: inputNewMessageText }, e)}><span className="mobile-text">Отправить</span><span>Отправить</span></button>
                    </div>
                </form>
            </div>
        );
    }

    /** Actions and events */

    /**
     * Handle user input new value in UI input view
     *
     * @param {*} event
     * @memberof NewMessageView
     */
    onMessageChange(event) {
        this.setState({
            newMessageText: event.target.value
        })

    }

    /**
     * Handle user click on button Send
     *
     * @param {*} msg - message for sending
     * @param {*} event
     * @memberof NewMessageView
     */
    onSendButtonClick(msg, event) {
        
        this.props.onNewMessageChanged(msg, event)

        //clear input view after message sending
        this.setState({
            newMessageText: ''
        })

    }

}

export default NewMessageView