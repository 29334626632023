import React from 'react'

import CategoryList from './CategoryList'

class CreateOrderTitleView extends React.PureComponent {
    
    render() {
        let title = ''
        if (this.props.title) {
            title = this.props.title
        }

        return (
            <div className="form-row">
                <div className="option-wrap">
                    <label htmlFor="order-short-title">Краткий заголовок <span className="imp">*</span></label>
                    {this.props.isValidInput.isOrderTitleValid === false ? (
                        <React.Fragment>
                            <input className="error input-data-edit" id="order-short-title" type="text" name="order-short-title" value={title} onChange={this.props.onTitleChanged} />
                            <div className="error-msg">Введите краткое название вашего заказа.</div>
                        </React.Fragment>
                    ) : (
                            <input className="js-data-input" id="order-short-title" type="text" name="order-short-title" value={title} onChange={this.props.onTitleChanged} />
                        )}
                </div>
                <div className="option-wrap">
                    <label htmlFor="order-cat">Укажите категорию <span className="imp">*</span></label>
                    {this.props.isValidInput.isOrderCategoryValid === false ? (
                        <React.Fragment>
                            <CategoryList
                                availableCategories={this.props.availableCategories}
                                orderSelectedCategory={this.props.orderSelectedCategory}
                                onCategoryChanged={this.props.onCategoryChanged}
                            />
                            <div className="error-msg">Выберите категорию.</div>
                        </React.Fragment>
                    ) : (
                            <CategoryList
                                availableCategories={this.props.availableCategories}
                                orderSelectedCategory={this.props.orderSelectedCategory}
                                onCategoryChanged={this.props.onCategoryChanged}
                            />
                        )}
                </div>
            </div>
        )
    }
}

export default CreateOrderTitleView