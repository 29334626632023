import _ from 'lodash';
import MessageParser from './MessageParser';

const { default: JSONParser } = require("../../common/parsers/JSONParser");

class MessagesParser extends JSONParser {
    parse() {
        let data = super.parse()
        if (data === null) { return null }


        let messages = []
        _.forEach(this.data, function(messageData) {
            let message = new MessageParser(messageData).parse()
            messages.push(message)
        });
        

        return messages
    }
}

export default MessagesParser;