import React from 'react'

import Rating from '../../common/Rating'
import dateService from '../../services/DateService'

import SvgIcon from '../../common/SvgIcon'

function BidOrderAvailable(props) {
    const permissions = props.permissions
    const isOrderClosed = permissions.isOrderClosed
    return (
        <React.Fragment>
        {isOrderClosed ? (
            <div className="guarantor">
            <div className="guarantor__icon-wrap">
                <div className="order-promo__is-active">
                    <div className="guarantor__desc_closed">
                        Данный заказ закрыт заказчиком.
                    </div>
                </div>
            </div>
            </div>
        ) : (
            <button className="order__accept-btn btn--2 js-open-form" type="button" data-src="#master-bid-popup" onClick={props.onBidOrderClick}>
                Откликнуться
            </button>
        )}
        </React.Fragment>
    )
}

function BidAlreadyExist(props) {
    return (
        <React.Fragment>
            <div className="guarantor">
                <div className="guarantor__icon-wrap">
                    <div className="order-promo__is-active">
                        <SvgIcon name='icon-girl-3' />
                        <div className="guarantor__desc">
                            Вы уже откликнулись на этот заказ!
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

class CheckOrderSidebarView extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        const createdProfilePassedTime = dateService.passedTimeCalc(this.props.orderInfo.owner.created)

        return (
            <div className="order__sidebar stick-sidebar-wrap">
                <div className="js-card-sidebar stick-sidebar">
                    <div className="job__customer"><a className="job__customer-name" href="javascript:;">{this.props.orderInfo.owner.first_name}</a>
                        <div className="job__customer-exp">{createdProfilePassedTime + ' назад'}</div>
                    </div>
                    <Rating rating={this.props.orderInfo.owner.rate} isShow={true} isReadOnly={true} />
                    <table className="stat-tbl">
                        {/* <tbody>
                            <tr>
                                <td>Заказов:</td>
                                <td>20</td>
                            </tr>
                            <tr>
                                <td>Успешность:</td>
                                <td><div className="stat-cases"><span className="stat-cases__success">5</span> / <span className="stat-cases__total">15</span></div></td>
                            </tr>
                        </tbody> */}
                    </table>

                    {(this.props.orderInfo.permissions.isAuthorizedUser && !this.props.orderInfo.permissions.hasBid) ? (
                        <BidOrderAvailable permissions={this.props.orderInfo.permissions} onBidOrderClick={this.props.onBidOrderClick}/>
                        // <button className="order__accept-btn btn--2 js-open-form" type="button" data-src="#master-bid-popup" onClick={this.props.onBidOrderClick}>
                        //     Откликнуться
                        // </button>
                    ) : (
                        <BidAlreadyExist/>
                        // <div className="guarantor">
                        // <div className="guarantor__icon-wrap">
                        //     <div className="order-promo__is-active">
                        //         <SvgIcon name='icon-girl-3' />
                        //         <div className="guarantor__desc">
                        //             Вы уже откликнулись на этот заказ!
                        //         </div>
                        //     </div>
                        // </div>
                        // </div>
                    )}

                </div>
            </div>
        )
    }
}

export default CheckOrderSidebarView