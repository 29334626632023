import React from 'react'

import ReviewItem from './ReviewItem';
import ReviewerItem from './ReviewerItem';

class ReviewInfo extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <div className="container">
                <section className="order-review">
                    <h2 className="order-review__title">Оставьте отзыв {(this.props.jobInfo.order.permissions.isClient| this.props.jobInfo.order.permissions.isClient===undefined) ? 'исполнителю' : 'заказчику'}</h2>
                    <div className="order-review__inner">
                        <form className="order-review-frm">
                            
                            <ReviewItem
                                reviewInfo={this.props.reviewInfo}
                                reviewError={this.props.reviewError}
                                onReviewTextChanged={this.props.onReviewTextChanged}
                                onQualityRateChanged={this.props.onQualityRateChanged}
                                onDeadlineRateChanged={this.props.onDeadlineRateChanged}
                                onCommunicationRateChanged={this.props.onCommunicationRateChanged}
                                onCreateReviewClick={this.props.onCreateReviewClick}
                            />
                            <ReviewerItem
                                jobInfo={this.props.jobInfo}
                                reviewInfo={this.props.reviewInfo}
                                onReviewTextChanged={this.props.onReviewTextChanged}
                                deadline
                                onCreateReviewClick={this.props.onCreateReviewClick}
                            />
                        </form>
                    </div>
                </section>
            </div>
        );
    }
}

export default ReviewInfo