import React, { useEffect, useState } from 'react';
import * as disputSelectors from '../../../../store/disput/reducer';
import { connect } from 'react-redux';
import APIService from '../../../../services/APIService';
import freezeScrollbar from '../../../../services/utils/freezeScrollbar';
import * as disputActions from '../../../../store/disput/actions';
import DisputPopup from './DisputPopup';
function DisputPopupContainer(props) {
	const [disputText, setDisputText] = useState(null);
	const handleChange = (event) => {
		setDisputText(event.target.value);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		await props
			.dispatch(disputActions.createDisput(props.jobInfo, disputText))
			.then((data) => {
				if (data.error === undefined) {
					props.onClose();
					freezeScrollbar(false);
				}
			});
	};
	const handleClose = (e) => {
		console.log(e);
		props.dispatch(disputActions.clearDisputErrors());
		freezeScrollbar(false);
		props.onClose();
	};

	useEffect(() => {
		freezeScrollbar(true);
	}, []);
	return (
		<div>
			<DisputPopup
				disputError={props.disputError}
				handleClose={handleClose}
				handleSubmit={handleSubmit}
				handleChange={handleChange}
			></DisputPopup>
		</div>
	);
}
function mapStateToProps(state) {
	const disputError = disputSelectors.getDisputError(state);
	return {
		disputError,
	};
}
export default connect(mapStateToProps)(DisputPopupContainer);
