import React from 'react'
import noAva from '../../../images/no-ava.png'

/**
 * - contain profile data about message sender in room
 *
 * @class RoomPersonItem
 * @extends {React.Component}
 */
class RoomPersonItem extends React.Component {

  /**
   * Format recieved data using RoomPersonItem UI view useful structure
   *
   * @returns formattedRoom = formatted room data dict
   * @memberof RoomPersonItem
   */
  prepateRoomData() {

    let formattedRoom = {
      personName: '',
      orderTitle: '',
      lastMessageText: '',
      unreadMsgCount: 0,
      id: ''
    }

    const chat = this.props.chat
    const selectedChatId = this.props.selectedChatId
    
    if (chat !== undefined) {
      formattedRoom.chatOrderNumber = this.props.orderNumber
      formattedRoom.id = this.props.chat.id
      formattedRoom.personName = `${chat.profile.firstName} ${chat.profile.lastName}`
      formattedRoom.orderTitle = chat.job.order.title
      formattedRoom.lastMessageText = chat.message.message
      formattedRoom.unreadMsgCount = chat.unreadCount
      formattedRoom.profilePhoto = chat.profile.photoUrl
      formattedRoom.id = chat.id
    }

    return formattedRoom

  }


  /**
   * Basic render function for React component
   *
   * @returns
   * @memberof RoomPersonItem
   */
  render() {

    const roomData = this.prepateRoomData()

    var photoLink = ''
    if (roomData.profilePhoto) {
      photoLink = roomData.profilePhoto
    }

    return (
      <>
        <a 
          className={roomData.isSelected ? 'messages-contacts-item messages-contacts-item--active' : 'messages-contacts-item'}
          onClick={(e) => {this.props.onChatItemSelectClick({ formattedChat: roomData.id }, e)}}
        >
          {(photoLink != '') ?
            <div className="messages-contacts-item__ava"><img src={roomData.profilePhoto} alt="" /></div>
            :
            <div className="messages-contacts-item__ava"><img src={noAva} alt="" /></div>
          }

          <div className="messages-contacts-item__body">
            <div className="messages-contacts-item__name">{roomData.personName}</div>
            <div className="messages-contacts-item__job">{roomData.orderTitle}</div>
            <div className="messages-contacts-item__msg">{roomData.lastMessageText}</div>
          </div>
          {roomData.unreadMsgCount != 0 &&
            <span className="messages-contacts-item__msg-count items-count msg-count">{roomData.unreadMsgCount}</span>
          }
        </a>
      </>
    );
  }
}

export default RoomPersonItem

