import React from 'react'

import MasterPersonInfo from './MasterPersonInfo'
import MasterPersonInfoEdit from './MasterPersonInfoEdit'
import Education from './Education'
import EducationEdit from './EducationEdit'
import Sidebar from '../../common/Sidebar'

import autoBind from 'react-autobind';
import { connect } from 'react-redux';

import * as profileSelectors from '../../store/profile/reducer';

//TODO: - REFACTOR -> make it as a container

class MasterProfileInfo extends React.Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {

        return (
            <div className="profile-header bg-block">
                <div className="container">
                    <div className="user-card user-card--master">
                        <div className="user-card__inner">
                            <div className="user-card__main">
                                    {this.props.isEditMode ? (
                                        <form className="user-data-frm js-card-main js-live-edit-wrap is-editable" encType="multipart/form-data">
                                            <MasterPersonInfoEdit/> 
                                        </form>                   
                                    ) : (
                                        <form className="user-data-frm js-card-main js-live-edit-wrap" encType="multipart/form-data">
                                            <MasterPersonInfo/>
                                        </form>
                                    )}
                                    {this.props.isEducationEditMode ? (
                                        <form class="user-data-frm js-card-edu js-live-edit-wrap is-editable" enctype="multipart/form-data">
                                            <EducationEdit/>
                                        </form>
                                    ) : (
                                        <form class="user-data-frm js-card-edu js-live-edit-wrap" enctype="multipart/form-data">
                                            <Education/>
                                        </form>                                        
                                    )}                         
                            </div>
                            <Sidebar isOwner={true} bio={this.props.profileBio}/> 
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

// export default ClientProfileInfoContainer
function mapStateToProps(state) {
    const isEditMode = profileSelectors.isEditModeEnabled(state);
    const isEducationEditMode = profileSelectors.isEducationEditModeEnabled(state);
    const profileBio = profileSelectors.getProfileBio(state);
    return {
        isEditMode,
        isEducationEditMode,
        profileBio,
    }
}

export default connect(mapStateToProps)(MasterProfileInfo);