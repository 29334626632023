import React from 'react';

import dateService from '../../services/DateService'

import autoBind from 'react-autobind';
import { withRouter } from 'react-router-dom'

export class MyJobsUserSidebar extends React.Component {
  constructor(props) {
    super(props);

    this.onHandleClick = this.onHandleClick.bind(this)

    autoBind(this);
  }

  render() {

    var profileId;
    if (this.props.profileType === 'CLIENT') {
      profileId = this.props.jobInfo.master.id
    } else {
      profileId = this.props.jobInfo.order.owner.id
    }

    return (
      <div className="job__sidebar">
        <div className="job__customer"><a className="job__customer-name" href="javascript:;" onClick={(e) => this.onProfileClick(profileId, e)}>
          {this.props.profileType === 'CLIENT' ? (
            <React.Fragment>
              {this.props.jobInfo.master.firstName}
            </React.Fragment>
          ) : (
              <React.Fragment>
                {this.props.jobInfo.order.owner.firstName}
              </React.Fragment>
            )}
        </a>
          <div className="job__customer-exp">
            {this.props.profileType === 'CLIENT' ? (
              <React.Fragment>
                {dateService.passedTimeCalc(this.props.jobInfo.master.created) + ' на сайте'}
              </React.Fragment>
            ) : (
                <React.Fragment>
                  {dateService.passedTimeCalc(this.props.jobInfo.order.owner.created) + ' на сайте'}
                </React.Fragment>
              )}
                    </div>
        </div><a className="send-msg btn--1" href="javascript:;" onClick={this.onHandleClick}>Новое сообщение</a>
      </div>
    )
  }

  onHandleClick(event) {
    event.preventDefault()

    this.props.onMessageButtonClick()
  }

  onProfileClick(profileId, e) {
    e.preventDefault()
    this.props.history.push(`/profile/${profileId}/`)
    this.props.history.go(0)
  }
}

export default withRouter(MyJobsUserSidebar);