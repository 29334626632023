import React from 'react'
import _ from 'lodash';

import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { menuItem } from '../../page/components/MenuEnum';

import Menu from '../../page/components/Menu'
import CreateOrderTitleView from '../components/CreateOrderTitleView'
import CreateOrderDescriptionView from '../components/CreateOrderDescriptionView'
import CreateOrderSliderView from '../components/CreateOrderSliderView'
import CreateOrderPriceView from '../components/CreateOrderPriceView'
import OrderAdditionalContent from '../components/OrderAdditionalContentView'
import CreateOrderSidebarView from '../components/CreateOrderSidebarView'
import Footer from '../../page/components/Footer'

import * as orderActions from '../../store/order/actions';
import * as orderSelectors from '../../store/order/reducer';

import * as geolocationActions from '../../store/geolocation/actions'
import * as geolocationSelectors from '../../store/geolocation/reducer'

import * as vuuteServiceActions from '../../store/vuuteServices/actions';
import * as vuuteServiceSelectors from '../../store/vuuteServices/reducer';

import * as menuActions from '../../store/menu/actions';

import { withRouter } from 'react-router-dom'
import AuthService from '../../services/AuthService';

import LoginMsgBox from '../../user/components/LoginMsgBox';

class CreateOrderContainer extends React.Component {
    constructor(props) {
        super(props);

        autoBind(this);
    }

    componentWillMount() {
        this.props.dispatch(menuActions.selectMenuItem(menuItem.UNSELECTED));

        let profile = AuthService.shared().getProfileBio()
        if (profile !== null) {
            if (profile.profileType !== 'CLIENT') {
                this.props.history.push(`/404`)
            }
        }

    }

    componentDidMount() {
        if (this.props.availableCategories.length == 0) {
            this.props.dispatch(vuuteServiceActions.requestCategories());
        }
    }

    render() {

        const error = this.props.error

        return (
            <div className="page">
                <Menu />
                <div className="content bg-block">
                    <div className="container">
                        <form className="order-frm">
                            <div className="order-frm__inner">
                                <div className="order-frm__main">

                                {(error != undefined) ? <LoginMsgBox msgText={error.message} msgType={"error-msg"} /> : ''}

                                    <h1 className="order-frm__title">Описание заказа</h1>
                                    <CreateOrderTitleView
                                        onTitleChanged={this.onTitleChanged}
                                        title={this.props.orderTitle}
                                        isValidInput={this.props.isValidInput}
                                        availableCategories={this.props.availableCategories}
                                        orderSelectedCategory={this.props.orderCategory}
                                        onCategoryChanged={this.onCategoryChanged}
                                    />
                                    <CreateOrderDescriptionView
                                        onDescriptionChanged={this.onDescriptionChanged}
                                        description={this.props.orderDescription}
                                        isValidInput={this.props.isValidInput}
                                    />
                                    {/* <CreateOrderSliderView /> */}
                                    <CreateOrderPriceView
                                        onPriceChanged={this.onPriceChanged}
                                        onPriceChangedSlider={this.onPriceChangedSlider}
                                        price={this.props.orderPrice}
                                        orderStartDate={this.props.orderStartDate}
                                        orderEndDate={this.props.orderEndDate}
                                        isValidInput={this.props.isValidInput}
                                        onOrderStartDateChanged={this.onOrderStartDateChanged}
                                        onOrderEndDateChanged={this.onOrderEndDateChanged}
                                    />
                                    <OrderAdditionalContent
                                        isEditable={true}
                                        onAddressChanged={this.onAddressChanged}
                                        // onBlurAddressInput={this.onBlurAddressInput}
                                        orderAddress={this.props.orderAddress}
                                        orderCheckedAddress={this.props.orderCheckedAddress}
                                        orderCoords={this.props.orderCheckedCoords}
                                        onLocationChanged={this.onLocationChanged}
                                    />
                                </div>
                                <CreateOrderSidebarView
                                    orderInfo={this.props.orderInfo}
                                    onCreateOrderClick={this.onCreateOrderClick}
                                    onSaveOrderChangesClick={this.onSaveOrderChangesClick}
                                    onDiscardOrderChangesClick={this.onDiscardOrderChangesClick}
                                />
                            </div>
                        </form>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }

    // FIELDS CHANGE EVENTS
    onTitleChanged(event) {
        event.preventDefault()
        this.props.dispatch(orderActions.changeTitle(event.target.value))
    }

    onCategoryChanged(categoryId) {
        var newCategoryId = parseInt(categoryId, 10)
        var newCategory = null
        _.forEach(this.props.availableCategories, function (category) {
            if (newCategoryId == category.id) {
                newCategory = category
            }
        });

        this.props.dispatch(orderActions.changeCategory(newCategory))
    }

    onDescriptionChanged(event) {
        event.preventDefault()
        this.props.dispatch(orderActions.changeDescription(event.target.value))
    }

    onPriceChanged(event) {
        event.preventDefault()
        this.props.dispatch(orderActions.changePrice(this.props.orderPrice, event.target.value))
    }
    onPriceChangedSlider(value)
    {
        this.props.dispatch(orderActions.changePrice(this.props.orderPrice, value))
    }

    onOrderStartDateChanged(date) {
        this.props.dispatch(orderActions.changeStartDate(date))
    }

    onOrderEndDateChanged(date) {
        this.props.dispatch(orderActions.changeEndDate(this.props.orderStartDate, date))
    }

    onAddressChanged(inputAddress) {
        this.props.dispatch(geolocationActions.changeAddress(inputAddress))
        this.props.dispatch(geolocationActions.getdAddressByInputData(inputAddress))
    }

    onLocationChanged(coords) {
        this.props.dispatch(geolocationActions.changeLocation(coords))
    }

    // BL
    async onCreateOrderClick(event) {
        event.preventDefault()

        // check is required fields is correctly filled
        
            this.props.dispatch(orderActions.clearCreateOrderErrors())
            await this.props.dispatch(orderActions.requestCreateOrder(this.props.orderTitle, this.props.orderCategory, this.props.orderDescription, this.props.orderPrice,
                this.props.orderStartDate, this.props.orderEndDate, this.props.orderCheckedCoords, this.props.orderCheckedAddress)
            ).then(() => { 
                if (this.props.orderInfo) {
                    this.props.dispatch(orderActions.clearCreateOrderForm())
                    this.props.history.push(`/order/${this.props.orderInfo.id}`) 
                }
            })
        

        // this.props.dispatch(orderActions.fieldsValidator(this.props.orderTitle, this.props.orderCategory, this.props.orderDescription, this.props.orderPrice,
        //     this.props.orderStartDate, this.props.orderEndDate))

        // if (this.props.isValidInput.isOrderTitleValid == true && this.props.isValidInput.isOrderCategoryValid == true
        //     && this.props.isValidInput.isOrderDescriptionValid == true && this.props.isValidInput.isOrderPriceValid == true
        //     && this.props.isValidInput.isOrderStartDateValid == true && this.props.isValidInput.isOrderEndDateValid == true) {
        //     await this.props.dispatch(orderActions.requestCreateOrder(this.props.orderTitle, this.props.orderCategory, this.props.orderDescription, this.props.orderPrice,
        //         this.props.orderStartDate, this.props.orderEndDate, this.props.orderCheckedCoords, this.props.orderCheckedAddress)
        //     ).then(() => { 
        //         if (this.props.orderInfo) {
        //             this.props.history.push(`/order/${this.props.orderInfo.id}`) 
        //         }
        //     })
        // }
    }

    async onSaveOrderChangesClick(event) {
        event.preventDefault()

        // check is required fields is correctly filled
        if (this.props.orderInfo && this.props.orderTitle && this.props.orderCategory && this.props.orderDescription && this.props.orderPrice && this.props.orderCheckedAddress) {
            // clear form errors
            this.props.dispatch(orderActions.clearCreateOrderErrors())
            await this.props.dispatch(orderActions.requestSaveOrderChanges(this.props.orderInfo.id, this.props.orderTitle, this.props.orderCategory, this.props.orderDescription, this.props.orderPrice,
                this.props.orderStartDate, this.props.orderEndDate, this.props.orderCheckedCoords, this.props.orderCheckedAddress)
            ).then(() => { 
                if (this.props.orderInfo) {
                    this.props.history.push(`/order/${this.props.orderInfo.id}`) 
                }
            })
        }
    }

    onDiscardOrderChangesClick(event) {
        event.preventDefault()
        this.props.dispatch(orderActions.requestDiscardChanges(this.props.orderInfo))
        this.props.history.push(`/order/${this.props.orderInfo.id}`)
    }
}

function mapStateToProps(state) {
    const [orderTitle, orderCategory, orderDescription, orderSliderPrice, orderPrice, orderStartDate, orderEndDate, isValidInput, error] = orderSelectors.getOrderInfo(state)
    const [orderInfo, getOrderByIdError] = orderSelectors.getOrderInfoById(state)

    const [orderAddress, orderCheckedAddress, orderCheckedCoords] = geolocationSelectors.getGeolocationInfo(state)

    const availableCategories = vuuteServiceSelectors.getAvailableCategories(state);

    return {
        orderInfo,
        orderTitle,
        orderCategory,
        orderDescription,
        orderSliderPrice,
        orderPrice,
        orderStartDate,
        orderEndDate,
        orderAddress,
        orderCheckedAddress,
        orderCheckedCoords,
        availableCategories,
        isValidInput,
        error,
        getOrderByIdError,
    }
}

export default withRouter(connect(mapStateToProps)(CreateOrderContainer));