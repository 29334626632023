import _ from 'lodash';
import * as popupTypes from '../popup/actionTypes';

export function showAuthPopup() {
    return ({ type: popupTypes.AUTH_POPUP_SHOW_CLICKED})
}

export function hideAuthPopup() {
    return ({ type: popupTypes.AUTH_POPUP_HIDE_CLICKED})
}

export function showSMSPopup() {
    return ({ type: popupTypes.SMS_POPUP_SHOW_CLICKED})
}

export function hideSMSPopup() {
    return ({ type: popupTypes.SMS_POPUP_HIDE_CLICKED})
}

export function hidePopup() { 
    return ({ type: popupTypes.POPUP_HIDE_CLICKED})
}