import React from 'react';

import AuthService from '../../services/AuthService';
import SvgIcon from '../../common/SvgIcon';
import dateService from '../../services/DateService'

import MyJobsUserSidebar from './MyJobsUserSidebar';
import JobFormButton from './JobFormButton';
import Job from '../../jobs/model/Job';

export class MyJobFormTemplate extends React.Component {
    constructor(props) {
        super(props);

        this.onJobTitleClick = this.onJobTitleClick.bind(this)
    }

    render() {

        const profileBio = AuthService.shared().getProfileBio()
        const profileType = profileBio.profileType

        const jobType = this.props.jobType

        let startDate = ''
        if (this.props.jobInfo.order.startDate) {
            startDate = dateService.toDayMonthYearDateTransform(this.props.jobInfo.order.startDate)
        } else if (this.props.jobInfo.order.createdDate) {
            startDate = dateService.toDayMonthYearDateTransform(this.props.jobInfo.order.createdDate)
        }

        let endDate = ''
        if (this.props.jobInfo.order.endDate) {
            endDate = dateService.toDayMonthYearDateTransform(this.props.jobInfo.order.endDate)
        } else {
            endDate = dateService.toDayMonthYearDateTransform(this.props.jobInfo.closedDate)
        }

        const createdOrderPassedTime = dateService.passedTimeCalc(this.props.jobInfo.order.createdDate)

        const orderPrice = this.props.jobInfo.order.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

        return (
            <div className="job">
                <div className="job__inner">
                    <div className="job__main">
                        <div className="job__header">
                            <div><a className="job__title" href="javascript:;" onClick={this.onJobTitleClick}>{this.props.jobInfo.order.title}</a>
                                <div className="job__meta">
                                    <div className="job__city">{this.props.jobInfo.order.city.title}</div>
                                    <div className="job__ago">{createdOrderPassedTime + ' назад'}</div>
                                </div>
                            </div>
                            <div className="job__price">
                                <div className="job__is-active">
                                    <SvgIcon name='icon-time' />
                                </div>
                                <div className="price">
                                    {orderPrice}
                                    <span className="rouble">₽</span>
                                </div>
                            </div>
                        </div>
                        <div className="job__info">
                            <div className="job__stat">
                                <table className="data-tbl">
                                    <tbody><tr>
                                        <td><span>Начало заказа</span></td>
                                        <td>{startDate}</td>
                                    </tr>
                                        <tr>
                                            <td><span>Окончание заказа</span></td>
                                            <td>{endDate}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <JobFormButton
                                jobType={jobType}
                                jobInfo={this.props.jobInfo}
                                onReviewButtonClick={this.props.onReviewButtonClick}
                            />
                        </div>
                    </div>
                    <MyJobsUserSidebar
                        profileType={profileType}
                        jobInfo={this.props.jobInfo}
                        onMessageButtonClick={this.props.onMessageButtonClick}
                    />
                </div>
            </div>
        )
    }

    onJobTitleClick(event) {
        event.preventDefault()

        this.props.onJobTitleClick(this.props.jobInfo.id)
    }
}

export default MyJobFormTemplate