import React from "react";

import Rating from "./Rating";
import Socials from "./Socials";
import InviteButton from "./InviteButton";

import DateService from "../services/DateService";
import AuthService from "../services/AuthService";

class Sidebar extends React.Component {
  render() {
    const isOwner = this.props.isOwner;

    const profileBio = this.props.bio;

    var rating = 3.6;
    var redisterDate = null;
    var differenceDate = null;
    if (profileBio) {
      rating = profileBio.rate;
      var registeredAtDate = new Date(profileBio.created);
      redisterDate = DateService.getCreatedAtDateToStr(registeredAtDate);
      differenceDate = DateService.passedTimeCalc(registeredAtDate);
    }
    let isMyProfile = AuthService.shared().isMyProfile();
    return (
      <div className="user-card__sidebar">
        <div className="js-card-sidebar">
          <Rating rating={rating} isShow={true} isReadOnly={true} />
          <div className="progress">
            {/* <div className="progress__value">87% надежности</div>
                    <div className="progress__line js-progress" data-progress={87}>
                        <div className="progress__filled js-progress-filled" />
                    </div> */}
                </div>
                <table className="stat-tbl">
                    <tbody>
                        {/* <tr>
                            <td>Всего заказов:</td>
                            <td>20</td>
                        </tr>
                        <tr>
                            <td>Успешно:</td>
                            <td><div className="stat-cases"><span className="stat-cases__success">5</span> / <span className="stat-cases__total">15</span></div></td>
                        </tr> */}

              <tr>
                <td>Регистрация:</td>
                {redisterDate !== null ? (
                  <td>
                    {redisterDate}
                    <span className="service-term">
                      {differenceDate} на сайте
                    </span>
                  </td>
                ) : (
                  <td>
                    Не установлено
                    <span className="service-term">0 лет на сайте</span>
                  </td>
                )}
              </tr>
            </tbody>
          </table>

          {isMyProfile ? <Socials id={this.props.bio.id} /> : ""}

          {/* // {isOwner ? (
                //     <Socials/>
                // ) : ( 
                //     <InviteButton/>
                // )}   */}
        </div>
      </div>
    );
  }
}

export default Sidebar;
