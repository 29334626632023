import React from 'react'

import SvgIcon from '../../common/SvgIcon';

class CheckOrderNavView extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
        <div className="top-control">
          {/* <a className="back-link btn--1" href="javascript:;">
            <SvgIcon name='icon-left'/>
            <span >Назад</span>
          </a> */}
          {this.props.orderInfo.permissions.isOrderOwner ? <button className="btn--2" type="button" onClick={this.props.onEditOrderClick}>Редактировать</button> : ''}
      </div>
    )
  }
}

export default CheckOrderNavView