export const GET_CHATS = 'chat.GET_CHATS';
export const SELECT_CHAT = 'chat.SELECT_CHAT';
export const DESELECT_CHAT = 'chat.DESELECT_CHAT';
export const GET_MESSAGES = 'chat.GET_MESSAGES';
export const LOAD_MORE_MESSAGES = 'chat.LOAD_MORE_MESSAGES';

export const NEW_MESSAGE = 'chat.NEW_MESSAGE';
export const NEW_MESSAGE_STREAM = 'chat.NEW_MESSAGE_STREAM';
export const SET_FIRST_MESSAGE_ID = 'SET_FIRST_MESSAGE_ID';
export const GET_FIRST_MESSAGE_ID = 'GET_FIRST_MESSAGE_ID';
