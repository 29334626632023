import _ from 'lodash';
import * as reviewTypes from './actionTypes';
import apiService from '../../services/APIService';


export function changeReviewText(newReviewText) {
    return({ type: reviewTypes.CHANGE_REVIEW_TEXT, newReviewText: newReviewText})
}

export function changeQualityRate(newQualityRate) {
    return ({ type: reviewTypes.CHANGE_QUALITY_RATE, newQualityRate: newQualityRate})
}

export function changeDeadlineRate(newDeadlineRate) {
    return ({ type: reviewTypes.CHANGE_DEADLINE_RATE, newDeadlineRate: newDeadlineRate})
}

export function changeCommunicationRate(newCommunicationRate) {
    return ({ type: reviewTypes.CHANGE_COMMUNICATION_RATE, newCommunicationRate: newCommunicationRate})
}

export function clearReviewErrors() {
    return ({ type: reviewTypes.CLEAR_REVIEW_ERROR})
}

export function clearReviewForm() {
    return ({type: reviewTypes.CLEAR_REVIEW_FORM})
}

export function createReview(jobInfo, reviewInfo) {
    return async (dispatch) => {
        try {
            const reviewData = await apiService.createReview(jobInfo, reviewInfo)
            dispatch({ type: reviewTypes.CREATE_REVIEW, error: reviewData.error})
        } catch(error) {
            console.log(error)
        }
    }
}