import React, {Component} from "react"
import autoBind from 'react-autobind';
import { connect } from 'react-redux';

import * as popupActions from '../../../../../store/popup/actions';
import * as popupSelectors from '../../../../../store/popup/reducer';

import * as authActions from '../../../../../store/auth/actions';

import * as registerActions from '../../../../../store/register/actions';

class MainPromoFooter extends Component {

	constructor(props) {
        super(props);
        autoBind(this);
    }

    render () {   
        return (
            <div className="main-promo-footer">
				<div className="main-promo-footer__desc">Нанимайте подрядчиков <br/>или выполняйте работу!</div>
				<div className="main-promo-footer__btns">
					<div className="main-promo-footer__btn add-order-btn btn--2 btn--lg js-open-form" data-fancybox data-src="#reg-popup" data-status="0" href="javascript:;" onClick={this.onOrderClick}>Разместить заказ</div>
					<div className="main-promo-footer__btn make-account-btn btn--1 btn--lg js-open-form" data-fancybox data-src="#reg-popup" data-status="1" href="javascript:;" onClick={this.onMasterClick}>Стать исполнителем</div>
				</div>
			</div>
        )
    }

	onOrderClick(event) {
        event.preventDefault();
        this.props.dispatch(authActions.signUpFormTypeSelected());
        this.props.dispatch(registerActions.clientTypeSelected())
        this.props.dispatch(popupActions.showAuthPopup());
    }

    onMasterClick(event) {
        event.preventDefault();
        this.props.dispatch(authActions.signUpFormTypeSelected());
        this.props.dispatch(registerActions.masterTypeSelected())
        this.props.dispatch(popupActions.showAuthPopup());
    }

}
function mapStateToProps(state) {
    const [isLoginPopupHidden, isSMSPopupHidden] = popupSelectors.getCurrentPopupState(state);
    return {
        isLoginPopupHidden
    };
}

export default connect(mapStateToProps)(MainPromoFooter);