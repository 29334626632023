import React from 'react';
import $ from 'jquery'
import 'select2'

import SvgIcon from '../../common/SvgIcon';

class MainSearchDropdownView extends React.Component {
    render() {
        $(document).ready(function() {
          $('.js-input-select').select2({
            minimumResultsForSearch: Infinity,
          }); 
        })

        return (
            <form className="search-job-frm">
                <div className="input-wrap select-input-wrap">
                        <input className="input-sm search-input" id="search-job" type="text" placeholder="Найти заказ" data-search-category="cat-2"/>
                    <select className="input-select js-input-select select2-hidden-accessible" id="search-job-select" name="search-job-select" data-input="#search-job" ref={el => this.el = el}>
                        <option value="cat-1">Найти исполнителя</option>
                        <option value="cat-2" defaultValue>Найти заказ</option>
                    </select>
                    <icon_search id='icon-search'/>
                    <button className="search-btn" type="submit">
                        <SvgIcon name='icon-search'/>
                    </button>
                </div>
            </form>
        );
    }
}

export default MainSearchDropdownView;


