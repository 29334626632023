import React, {Component} from 'react'

import noAva from '../../../../../../images/no-ava.png'

class ReviewMaster extends Component {
    
    render() {
        const master = this.props.master 
        if (!master)
        {
            return <></>;
        }
        return(
            <div className="reviews-item__person">
                <a className="reviews-item__person-ava">
                    {(master.photo !== '') ? 
                        <img className="ava-img" src={master.photo} alt="" />
                        : 
                        <img className="ava-img" src={noAva} alt="" />
                    }              
                </a>
                <a className="reviews-item__person-name">
                    {master.name}
                </a>
                <div className="reviews-item__person-desc">
                    {master.desc}
                </div>
            </div>
        )
    }
    
}

export default ReviewMaster