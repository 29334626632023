
class ProfileExperience {
    id;
    companyName;
    position;
    dateStarted;
    dateFinished;

    constructor(id, companyName, position, dateStarted, dateFinished) {
        this.id = id
        this.companyName = companyName
        this.position = position
        this.dateStarted = dateStarted
        this.dateFinished = dateFinished
    }
}

export default ProfileExperience;