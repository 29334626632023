import _ from 'lodash';
import ServiceVuuteParser from '../../vuuteServices/parsers/ServiceVuuteParser';
import ProfileService from "../model/ProfileService";

import JSONParser from '../../common/parsers/JSONParser'

class ProfileServiceParser extends JSONParser {
    parse() {
        super.parse()
        let serviceObject = new ServiceVuuteParser(_.get(this.data, 'service_object')).parse()
        let profileService = new ProfileService(_.get(this.data, 'id'), serviceObject, _.get(this.data, 'price'))
        return profileService
    }
}

export default ProfileServiceParser;