import React from 'react'

import FindMasterSelect from './FindMasterSelect'
// import PaidMasterItem from './PaidMasterItem'
import FindMasterItem from './FindMasterItem'
import SearchPageCatalog from '../../common/SearchPageCatalog'

class FindMasterResults extends React.Component {


    createMasterResultsTable() {

        let table = []
    
        const results = this.props.results[0]
    
        var counter = 0
    
        results.forEach(element => {
            table.push(<FindMasterItem 
                profile={element} 
                orderNumber={counter}
                onProfileItemClick={this.props.onProfileItemClick}/>)
            
            counter = counter + 1
    
        });
    
        return table
    }


    render() {

        const results = this.props.results[0]
        const showMoreIsHidden = this.props.results[1]
        const searchCriteria = this.props.searchCriteria

        return (
            <div className="catalog">
                <div className="catalog__inner">
                    <div className="catalog__main">
                        <div className="catalog__sort" style={{ justifyContent: 'space-between' }}>
                            <div className="search-result">
                                {results.length} исполнителей найдено
                            </div>
                            <FindMasterSelect findSortListItems={this.props.findSortListItems}
                                        selectedSortId={searchCriteria.findOrderBy}
                                onSortChanged={(categoryId) =>
                                {
                                    new Promise(async(resolve)=>{
                                        resolve(this.props.onSortChanged(categoryId))
                                    }).then(()=>{
                                        const event=new Event('click')
                                        this.props.onApplyFiltersClick(event)
                                    })
                                }}
                            />
                        </div>
                        {/* <div className="catalog__top">
                            <PaidMasterItem />
                            <PaidMasterItem />
                        </div> */}
                        <div class="catalog-list">
                            {this.createMasterResultsTable()}
                        </div>

                        {!showMoreIsHidden ?
                            <button className="back-link btn--1" type="submit" onClick={this.props.onLoadMoreClicked}>ДАЛЕЕ</button>
                            :
                            ''
                        }

                    </div>
                    <SearchPageCatalog 
                        searchCriteria={this.props.searchCriteria}
                        categories={this.props.categories}
                        onCategoryChanged={(categoryId)=>{
                                new Promise(async(resolve)=>{
                                    resolve(this.props.onCategoryChanged(categoryId))
                                }).then(()=>{
                                    const event=new Event('click')
                                    this.props.onApplyFiltersClick(event)
                                })
                            }} />
                </div>
            </div>
        );
    }
}

export default FindMasterResults