import React from 'react'

import DateService from '../../../services/DateService'
/**
 * View which contain data about one message item in MessagesView
 *
 * @class MessagesItem
 * @extends {React.Component}
 */
class MessageItem extends React.Component {

    /**
     * Format recieved data using MessageItem UI view useful structure
     *
     * @returns formattedMsg = formatted message data dict
     * @memberof MessageItem
     */
    prepateMsgData() {
        const message = this.props.message
        const chatRoomProfile = this.props.chatRoomProfile

        let formattedMsg = {
            id: undefined,
            msgText: '',
            isOwner: false,
            createdDateStr: '',
        }

        if (message !== undefined) {
            formattedMsg.msgText = message.message
            formattedMsg.id = message.id

            if (message.sender.id == chatRoomProfile) {
                formattedMsg.isOwner = false
            } else {
                formattedMsg.isOwner = true
            }

            formattedMsg.createdDateStr = DateService.getMessageTime(message.created)
        }

        // Set setFirstMessageId only for first message for setting an anchor on it and that anchor allow to scroll directly to this message
 
        return formattedMsg
    }


    /**
     * Basic render function for React component
     *
     * @returns
     * @memberof MessageItem
     */
    render() {
        
        const messageData = this.prepateMsgData()

        return (
            <React.Fragment>
                {messageData.isOwner ? (
                    <div id={`chat-message-${messageData.id}`} className="messages-chat__msg">
                        <div className="messages-chat__msg-body">
                            {`${messageData.msgText} [id: ${messageData.id}]`}
                        </div>
                        {/* <div className="messages-chat__msg-body">{messageData.msgText}</div> */}
                        <div className="messages-chat__msg-timestamp">{messageData.createdDateStr}</div>
                    </div>
                ) : (
                        <div  id={`chat-message-${messageData.id}`} className="messages-chat__msg messages-chat__msg--own">
                             <div className="messages-chat__msg-body">
                            {`${messageData.msgText} [id: ${messageData.id}]`}
                        </div>
                            {/* <div className="messages-chat__msg-body">{messageData.msgText}</div> */}
                            <div className="messages-chat__msg-timestamp">{messageData.createdDateStr}</div>
                        </div>
                    )}
            </React.Fragment>
        )
    }
}


export default MessageItem;