import React, { Component } from "react"
import autoBind from 'react-autobind';

import { withRouter } from 'react-router-dom'

import AuthService from '../../services/AuthService';

import SvgIcon from '../../common/SvgIcon'

class Footer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isCustomerMenuOpen: false,
            isMasterMenuOpen: false,
            isHelpMenuOpen: false,
        }

        autoBind(this);
    }

    render() {

        const isAuthorised = AuthService.shared().isCorrectUserExist()
        const profileType = isAuthorised ? AuthService.shared().getProfileBio().profileType : undefined

        return (
            <footer className="page-footer">
                <div className="page-footer__inner">
                    <div className="page-footer__col">
                        <div>
                            <div className="logo" href="/">
                                <SvgIcon file='logo' />
                            </div>
                            <div className="social">
                                <a className="social__item" href="https://www.instagram.com/vuute.rf/" target="_blank">
                                    <SvgIcon name='icon-inst' />
                                </a>
                                <a className="social__item" href="https://vk.com/public208405446" target="_blank">
                                    <SvgIcon name='icon-vk' />
                                </a>
                                <a className="social__item" href="https://www.facebook.com/groups/589726832293964" target="_blank">
                                    <SvgIcon name='icon-facebook' />
                                </a>
                            </div>
                        </div>
                        <div className="note">
                            <div className="copy">© вуюте.рф, 2017 — 2021</div>
                        </div>
                    </div>
                    <div className="page-footer__col">
                        {(profileType == 'CLIENT') && (
                            <div>
                                <div className={
                                    this.state.isCustomerMenuOpen ? "page-footer__title js-page-footer-title opened" : "page-footer__title js-page-footer-title"}
                                    onClick={this.onCustomerMenuClick}>Заказчику
                                    <SvgIcon name='icon-down' />
                                </div>
                                <ul className={this.state.isCustomerMenuOpen ? "secondary-menu opened" : "secondary-menu"} >
                                    <li className="secondary-menu__item"><a href="javascript:;" onClick={(e) => this.onCreateOrderClick(e)}>Разместить задание</a></li>
                                    <li className="secondary-menu__item"><a href="javascript:;" onClick={(e) => this.onFindMasterClick(e)}>Найти исполнителя</a></li>
                                </ul>
                            </div>
                        )}
                        {(profileType == 'MASTER') && (
                            <div>
                                <div className={
                                    this.state.isMasterMenuOpen ? "page-footer__title js-page-footer-title opened" : "page-footer__title js-page-footer-title"}
                                    onClick={this.onMasterMenuClick}>Мастеру
                                    <SvgIcon name='icon-down' />
                                </div>
                                <ul className={this.state.isMasterMenuOpen ? "secondary-menu opened" : "secondary-menu"}>
                                    <li className="secondary-menu__item"><a href="javascript:;" onClick={(e) => this.onEditMasterProfileClick(e)}>Заполнить профиль</a></li>
                                    <li className="secondary-menu__item"><a href="javascript:;" onClick={(e) => this.onFindOrderClick(e)}>Найти задание</a></li>
                                </ul>
                            </div>
                        )}
                        <div>
                            <div className={this.state.isHelpMenuOpen ? "page-footer__title js-page-footer-title opened" : "page-footer__title js-page-footer-title"}
                                onClick={this.onHelpMenuClick}>Помощь
                                <SvgIcon name='icon-down' />
                            </div>
                            <ul className={this.state.isHelpMenuOpen ? "secondary-menu opened" : "secondary-menu"}>
                                <li className="secondary-menu__item">
                                    <a href='https://vuuteimagespoc.s3.eu-central-1.amazonaws.com/docs/UserAgreementVuute.pdf' target="_blank">
                                        Правила сервиса
									</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="page-footer__bottom">
                    <div className="page-footer__col">
                        <div className="copy">© вуюте.рф, 2017 — 2020</div>
                    </div>
                    <div className="page-footer__col">
                        <a className="email-link" href="mailto:vuuteSup@gmail.com">
                            <SvgIcon name='icon-down' />
                            vuuteSup@gmail.com
                        </a>
                    </div>
                </div>
            </footer>
        )
    }

    onCustomerMenuClick() {
        this.setState({
            isCustomerMenuOpen: !this.state.isCustomerMenuOpen
        })
    }

    onMasterMenuClick() {
        this.setState({
            isMasterMenuOpen: !this.state.isMasterMenuOpen
        })
    }

    onHelpMenuClick() {
        this.setState({
            isHelpMenuOpen: !this.state.isHelpMenuOpen
        })
    }

    onFindMasterClick(event) {
        event.preventDefault();
        this.props.history.push('/profile_find')
    }

    onFindOrderClick(event) {
        event.preventDefault();
        this.props.history.push('/order_find')
    }

    onFindOrderClick(event) {
        event.preventDefault();
        this.props.history.push('/order_find')
    }

    onEditMasterProfileClick(event) {
        event.preventDefault();
        this.props.history.push('/profile')
    }

    onCreateOrderClick(event) {
        event.preventDefault();
        this.props.history.push('/create')
    }

}


export default withRouter(Footer)