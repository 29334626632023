import Immutable from 'seamless-immutable';
import _ from 'lodash';
import * as settingsTypes from './actionTypes';

const initialState = Immutable({
    contactInfo: {
        email: undefined,
        error: undefined,
    },

    security: {
        oldPassword: undefined,
        newPassword: undefined,
        repeatedNewPassword: undefined,
        error: undefined,
    },

    notifications: {
        messages: false,
        jobStates: false,
        sales: false,
        news: false
    },
})

export default function reduce(state = initialState, action = {}) {

    switch (action.type) {
        case settingsTypes.EMAIL_CHANGED:
            return state.merge({
                contactInfo: {
                    email: action.newEmail,
                    error: state.contactInfo.error,
                }
            })

        case settingsTypes.GET_EMAIL:
            return state.merge({
                contactInfo: {
                    email: action.email,
                    error: state.contactInfo.error,
                }
            })
        
        case settingsTypes.EMAIL_SAVED:
            return state.merge({
                contactInfo: {
                    email: state.contactInfo.email,
                    error: action.error
                }
            })

        case settingsTypes.CLEAR_SETTINGS_CONTACT_FORM_ERRORS:
            return state.merge({
                contactInfo: {
                    email: state.contactInfo.email,
                    error: undefined
                }
            })

        case settingsTypes.OLD_PASSWORD_CHANGED:
            var mutableState = Immutable.asMutable(state, { deep: true });
            var security = mutableState.security

            security.oldPassword = action.newPassword

            return state.merge({
                security: security
            })

        case settingsTypes.NEW_PASSWORD_CHANGED:
            var mutableState = Immutable.asMutable(state, { deep: true });
            var security = mutableState.security

            security.newPassword = action.newPassword

            return state.merge({
                security: security
            })

        case settingsTypes.REPEATED_NEW_PASSWORD_CHANGED:
            var mutableState = Immutable.asMutable(state, { deep: true });
            var security = mutableState.security

            security.repeatedNewPassword = action.newPassword

            return state.merge({
                security: security
            })

        case settingsTypes.PASSWORD_SAVED:
            return state.merge({
                security: {
                    oldPassword: state.security.oldPassword,
                    newPassword: state.security.newPassword,
                    repeatedNewPassword: state.security.repeatedNewPassword,
                    error: action.error,
                }
            })

        case settingsTypes.CLEAR_SETTINGS_SECURITY_FORM_ERRORS:
            return state.merge({
                security: {
                    oldPassword: state.security.oldPassword,
                    newPassword: state.security.newPassword,
                    repeatedNewPassword: state.security.repeatedNewPassword,
                    error: undefined,
                }
            })

        case settingsTypes.NOTIFY_ITEM_CLICKED:
            var mutableState = Immutable.asMutable(state, { deep: true });
            var notifications = mutableState.notifications

            notifications[action.key] = !notifications[action.key]

            return state.merge({
                notifications: notifications
            })

            // todo: refactor
        case settingsTypes.GET_RECEIVE_SETTINGS:
                return state.merge({
                    notifications: {
                        messages: action.settingsInfo.messages,
                        jobStates: action.settingsInfo.jobStates,
                        sales: action.settingsInfo.sales,
                        news: action.settingsInfo.news
                    },
                })

        default:
            return state;
    }
}

//selectors
export function getSettingsInfo(state) {
    const contactInfo = state.settings.contactInfo
    const securityInfo = state.settings.security
    const notificationsInfo = state.settings.notifications
    return [contactInfo, securityInfo, notificationsInfo]
}

export function getContactInfo(state) {
    return state.settings.contactInfo
}

export function getSecurityInfo(state) {
    return state.settings.security
}