import React from 'react'

class FindOrderPrice extends React.Component {
  onSlide = (render, handle, value, un) => {
    this.props.onSliderValuesChanged(value)
  }

  render() {
    const searchCriteria = this.props.searchCriteria

    return (
      <div className='option-wrap range-slider-wrap'>
        <label htmlFor='orders-filter-price-1'>Цена заказа</label>
        <div className='range-slider'>
          <div className='range-slider__data'>
            <div className='range-slider-inputs'>
              <div className='input-wrap'>
                <span className='input-prefix'>от</span>
                <input id='orders-filter-price-1' name='orders-filter-price-1' type='number' value={searchCriteria.findMinPrice} onChange={this.props.onMinPriceChanged} />
              </div>
              <div className='input-wrap'>
                <span className='input-prefix'>до</span>
                <input id='orders-filter-price-2' name='orders-filter-price-2' type='number' value={searchCriteria.findMaxPrice} onChange={this.props.onMaxPriceChanged} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default FindOrderPrice
