import Immutable from 'seamless-immutable';
import * as types from './actionTypes';
import _ from 'lodash';
import AuthService from '../../services/AuthService';

const initialState = Immutable({
    chats: [],
    error: null,
    selectedChat: undefined,
    messages: [],
    firstMessageId: 0,
    
    //datetime for initial find request
    findRequestDate: undefined,

    //bucket fetching
    limit: 6,
    offset: 0,
    allMessagesLoaded: false,
})

export default function reduce(state = initialState, action = {}) {

    switch(action.type) {
        case types.SET_FIRST_MESSAGE_ID:
            return state.merge({
                firstMessageId: action.payload
            });

        case types.GET_FIRST_MESSAGE_ID:
            return state.firstMessageId

        case types.GET_CHATS:
            return state.merge({
                chats: action.chats,
                error: action.error
            });

        case types.SELECT_CHAT:

            const selectedChatId = parseInt(action.selectedId, 10)
            var selectedChat = state.chats.find(chat => chat.id == selectedChatId);
            return state.merge({
                selectedChat: selectedChat,
                limit: 6,
                offset: 0,
                findRequestDate: undefined,
                allMessagesLoaded: false,
            })

        case types.DESELECT_CHAT:

            return state.merge({
                selectedChat: undefined,
            })

        case types.GET_MESSAGES:

            let dt = action.datetime

            var mutableState = Immutable.asMutable(state, {deep: true});

            var limit = action.limit

            var newOffset = 0
            var newAllMessagesLoaded = mutableState.allMessagesLoaded

            if (action.messages.length != 0) {
                //update offset value
                newOffset = newOffset + limit
                //hide load more button if loaded data count < limit - it mean that all data loaded
                if (action.messages.length < limit) {
                    newAllMessagesLoaded = true
                } else {
                    newAllMessagesLoaded = false
                }
            } else {
                newAllMessagesLoaded = true
            }

            action.messages.forEach((message) => {
                const date = message.created
            })

            return state.merge({
                messages: action.messages,
                findRequestDate: action.datetime,
                offset: newOffset,
                allMessagesLoaded: newAllMessagesLoaded,
                error: action.error
            })

        case types.LOAD_MORE_MESSAGES:

            var mutableState = Immutable.asMutable(state, {deep: true});

            var limit  = action.limit

            var newMessages = []
            var oldMessages = mutableState.messages

            var newOffset = mutableState.offset
            var newAllMessagesLoaded = mutableState.allMessagesLoaded

            var actionMessages = action.messages

            if (action.messages.length != 0) {
                //merge results arrays and update offset value
                newMessages = actionMessages//newMessages.concat(action.messages)
                newMessages = newMessages.concat(oldMessages)
                newOffset = newOffset + limit
                //hide load more button if loaded data count < limit - it mean that all data loaded
                if (action.messages.length < limit) {
                    newAllMessagesLoaded = true    
                } else {
                    newAllMessagesLoaded = false
                }
            } else {
                newAllMessagesLoaded = true
                newMessages = oldMessages
            }

            return state.merge({
                messages: newMessages,
                offset: newOffset,
                allMessagesLoaded: newAllMessagesLoaded,
            })
            
        case types.NEW_MESSAGE:

            var mutableState = Immutable.asMutable(state, {deep: true});
            var messages = mutableState.messages

            const newMessage = action.newMessage

            messages.push(newMessage)

            //TODO: ADD MERGE FUNCTION

            return state.merge({
                messages: messages,
            })

        case types.NEW_MESSAGE_STREAM:

            var mutableState = Immutable.asMutable(state, {deep: true});
            var chats = mutableState.chats

            const roomId = action.roomId
            const newStreamMessage = action.newMessage

            //don't handle stream message if it's income in already selected chat
            //in this case it will be handled by chat socket
            if (mutableState.selectedChat === undefined) {
                return state
            }

            //todo: check this logic -> seems not used now
            //don't handle stream message if it's income in already selected chat
            //in this case it will be handled by chat socket
            if (mutableState.selectedChat.id == roomId) {
                // return state
            }

            //else -> execute next logic
            _.forEach(chats, function(value) {
    
                if (roomId == value.id) {
                    value.message = newStreamMessage
                    if (mutableState.selectedChat.profile.id === AuthService.shared().getProfileBio().id) {
                        value.unreadCount += 1
                    }
                }
    
            });

            let orderedByLastMessage = _.orderBy(chats, ['message.created'], ['desc'])

            return state.merge({
                chats: orderedByLastMessage,
            })
            

        default:
            return state;
    }

}

//selectors
export function getChats(state) {
    const chats = state.chat.chats;
    return chats;
}

export function getSelectedChat(state) {
    const selectedChat = state.chat.selectedChat;
    return selectedChat;
}

export function getMessages(state) {
    const messages = state.chat.messages
    return messages
}

export function getMessagesRequestInitialDatetime(state) {
    return state.chat.findRequestDate;
}

export function getMessagesOffsetLimit(state) {
    return [state.chat.offset, state.chat.limit]
}

export function getAllMessagesLoaded(state) {
    return state.chat.allMessagesLoaded
}

export function getFirstMessageId(state)
{
    return state.chat.firstMessageId
}