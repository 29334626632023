import React, {Component} from 'react'
import ReviewPhoto from './ReviewPhoto'
import Reviewer from './Reviewer'

class ReviewFooter extends Component {
    render () {
        const photos = this.props.cont.photos
        const reviewer = this.props.cont.reviewer
        if (!reviewer)
        {
            return <></>
        }
        return (
            <div className="review__footer">
                {/* <div className="inline-gallery">
                    <ReviewPhoto photo = {photos}/>
                    <ReviewPhoto photo = {photos}/>
                    <ReviewPhoto photo = {photos}/>
                </div> */}
                <Reviewer reviewer = {reviewer}/>
            </div>
        )
    }
}

export default ReviewFooter