import React, { Component } from 'react';
import Slider, { createSliderWithTooltip } from 'rc-slider';
import 'rc-slider/assets/index.css';
import arrow from './../images/slider-handle.svg';
const SliderWithTooltip = createSliderWithTooltip(Slider);

const values = {
	0: ['Минимальный бюджет', 'до 10 000 рублей'],
	16: ['Неболщой бюджет', 'до 50 000 рублей'],
	32: ['Средний бюджет', 'до 100 000 рублей'],
	48: ['Крупный бюджет', 'до 200 000 рублей'],
	64: ['Большой бюджет', 'до 500 000 рублей'],
	80: ['Большущий бюджет', 'до 800 000 рублей'],
	96: ['Максимальный бюджет', 'до 1 000 000 рублей'],
};
const marks = {
	0: 10000,
	16: 50000,
	32: 100000,
	48: 200000,
	64: 500000,
	80: 800000,
	96: 1000000,
};
export default class PriceRcSlider extends Component {
	render() {
		let price = 0;
		for (const [key, value] of Object.entries(marks)) {
			if (this.props.price >= value) {
				price = key;
			}
		}
		return (
			<div className='rc-slider-container'>
				<SliderWithTooltip
					tipFormatter={(value) => (
						<div style={{ whiteSpace: 'nowrap' }}>
							<div>{values[value][0]}</div> {values[value][1]}
						</div>
					)}
					dots
					tipProps={{
						placement: 'top',
						prefixCls: 'rc-slider-tooltip',
					}}
					style={{ height: '63px' }}
					step={16}
					trackStyle={{
						height: '63px',
						backgroundColor: '#f89020',
						transition: '  .2s',
						borderRadius: '0',
					}}
					activeDotStyle={{
						backgroundColor: '#f89020',
					}}
					dotStyle={{
						width: '13px',
						height: '13px',
						backgroundColor: '#f9f7f2',
						border: '4px solid #fff',
						borderRadius: '50%',
						position: 'absolute',
						left: '0',
					}}
					railStyle={{
						background: '#FAFAFA',
						height: '63px',
						borderRadius: '0',
					}}
					handleStyle={{
						width: '29px ',
						height: '37px ',
						backgroundImage: `url(${arrow})`,
						backgroundSize: 'cover',
						backgroundColor: 'transparent ',
						borderRadius: 0,
						border: 0,
						boxShadow: 0,
					}}
					//value = {price}
					onChange = {(event) => this.props.onChange(marks[event].toString())}
				/>
				<div className='slider-border'></div>
			</div>
		);
	}
}
