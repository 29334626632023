import React from 'react'
import _ from 'lodash';

import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { menuItem } from '../../page/components/MenuEnum';

import Menu from '../../page/components/Menu'
import SettingsContactInfo from "../components/SettingsContactInfo";
import SettingsSecurity from '../components/SettingsSecurity';
import SettingsNotifications from '../components/SettingsNotifications';
import SettingsSidebar from '../components/SettingsSidebar';

import * as settingsActions from '../../store/settings/actions';
import * as settingsSelectors from '../../store/settings/reducer';

import * as menuActions from '../../store/menu/actions';

import { withRouter } from 'react-router-dom'


class SettingsContainer extends React.Component {
    constructor(props) {
        super(props);

        autoBind(this);
    }

    componentWillMount() {
        this.props.dispatch(menuActions.selectMenuItem(menuItem.UNSELECTED));
    }

    componentDidMount() {
        this.props.dispatch(settingsActions.getEmailInfo())
        this.props.dispatch(settingsActions.getReceiveSettingsInfo())
      }

    render() {

        return (
            <div className="page">
                <Menu />
                <div className="settings">
                    <div className="settings__main">
                        <h1 className="settings__title">Настройки аккаунта</h1>
                        <SettingsContactInfo/>
                        <SettingsSecurity/>
                        <SettingsNotifications
                            notificationsInfo={this.props.notificationsInfo}
                            onNotifyItemClick={this.onNotifyItemClick}
                            onReceiveSettingsClick={this.onReceiveSettingsClick}
                        />
                    </div>
                    <SettingsSidebar />
                </div>
            </div>
        )
    }

    // todo: implement SettingsNotifications handle related events itself
    onNotifyItemClick(key) {
        this.props.dispatch(settingsActions.clickedNotifyItem(key))
    }

    onReceiveSettingsClick(event) {
        event.preventDefault()
        this.props.dispatch(settingsActions.changeReceiveSettings(this.props.notificationsInfo))
    }

}

function mapStateToProps(state) {
    const [contactInfo, securityInfo, notificationsInfo] = settingsSelectors.getSettingsInfo(state)

    return {
        contactInfo,
        securityInfo,
        notificationsInfo,
    }
}

export default withRouter(connect(mapStateToProps)(SettingsContainer));