import React from 'react'

import FindMasterPrice from './FindMasterPrice'
import FindMasterRating from './FindMasterRating.js'
import FindOrderSkills from './FindOrderSkills'

import CityList from '../../profile/components/CityList'

class FindMasterFilter extends React.Component {

    render() {

        const searchCriteria = this.props.searchCriteria


        return (
            <div className="catalog-filter-wrap bg-block">
                <div className="container">
                    <div className="catalog-filter masters-filter">
                        <form className="catalog-filter-frm masters-filter-frm">
                            <div className="form-row">
                                <div className="option-wrap">
                                    <label htmlFor="orders-filter-region">Регион</label>
                                    <CityList availableCities={this.props.cities}
                                        selectedCity={searchCriteria.findCity}
                                        onCityChanged={this.props.onCityChanged} />
                                </div>
                                
                                <div className="option-wrap">
                                    <label htmlFor="masters-filter-rating">Рейтинг исполнителя</label>
                                    <FindMasterRating findRatingListItems={this.props.findRatingListItems}
                                        selectedRatingId={searchCriteria.findRating}
                                        onRatingChanged={this.props.onRatingChanged}/>
                                </div>

                                <div className="option-wrap">
                                    <label htmlFor="masters-filter-name">Имя или фамилия</label>
                                    <input id="masters-filter-name" name="masters-filter-name" type="text" 
                                        value={searchCriteria.findName}
                                        onChange={this.props.onNameChanged}/>
                                </div>
                            </div>
                            <div className="form-row">
                                <FindMasterPrice 
                                    searchCriteria={searchCriteria}
                                    onSliderValuesChanged={this.props.onSliderValuesChanged}
                                    onMaxPriceChanged={this.props.onMaxPriceChanged}
                                    onMinPriceChanged={this.props.onMinPriceChanged} />
                                <div className="option-wrap">
                                    <label htmlFor="masters-filter-skills">Необходимые навыки</label>
                                    <FindOrderSkills
                                        searchCriteria={searchCriteria}
                                        services={this.props.services}
                                        onServiceAdded={this.props.onServiceAdded}
                                        onServiceRemoved={this.props.onServiceRemoved} />
                                </div>
                            </div>
                            <div className="form-footer">
                                <div className="check-wrap" style={{marginBottom: '10px'}}>
                                    <input id="masters-filter-only-old" type="checkbox" name="masters-filter-only-old" 
                                        checked={searchCriteria.findHasAttachments}
                                        onChange={this.props.onHasAttachmentChanged} />
                                    <label className="check-label-lg" htmlFor="masters-filter-only-old"><span />
                                        <div>Только исполнители с историей</div>
                                    </label>
                                </div>
                                <button className="masters-filter-frm__submit btn--2" type="submit" style = {{whiteSpace:"nowrap"}}
                                onClick={this.props.onApplyFiltersClick}>Найти исполнителя</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default FindMasterFilter