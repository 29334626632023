import React from 'react'

import { Map, GoogleApiWrapper, Marker } from 'google-maps-react'

class MapContainer extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {

        const mapStyles = require('./GoogleMapStyles.json')
        const mapMarker = require('../images/placemark.png')

        if (this.props.latitude !== undefined) {
            var coords = {
                'lat': this.props.latitude,
                'lng': this.props.longtitude
            }
        } else {
            coords = {
                'lat': 55.755015,
                'lng': 37.627140
            }
        }

        return (
            <div className='location-map-wrap' id='location-map'>
                <Map
                    google={this.props.google}
                    zoom={13}
                    styles={mapStyles}
                    center={coords}
                    initialCenter={coords}
                    mapTypeControl={false}
                    onChange={this.onChange1}
                >
                    {this.props.isEditable ? (
                        <Marker
                            draggable={true}
                            icon={mapMarker}
                            animation={this.props.google.maps.Animation.DROP}
                            position={coords}
                            onDragend={(t, map, coords) => this.props.onLocationChanged(coords)}
                        />
                    ) : (
                            <Marker
                                draggable={true}
                                icon={mapMarker}
                                animation={this.props.google.maps.Animation.DROP}
                                position={coords}
                            />
                        )}


                </Map>
            </div>
        )
    }
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyBvxZ71woncqxUMAuOahFsMBnXdAoMBvHQ',
    language: 'ru'
})(MapContainer)