import React, {Component} from "react"

import SvgIcon from '../../../../../../common/SvgIcon'


class AdvBottomBlock extends React.Component {
    
    render() {
        const article = this.props.article
        const body = <section>{article.text}</section>

        return(
            <div className="warning warning--light">
                <div className="warning__icon-wrap">
                    <SvgIcon name='icon-checked'/>
                </div>
                <div className="warning__body">
                    <div className="warning__title">
                        {article.title}
                    </div>
                    <div className="warning__desc">
                        {body}
                    </div>
                </div>
            </div>
        )

    }

}

export default AdvBottomBlock