import React from 'react'

import SvgIcon from '../../common/SvgIcon'

class SettingsSidebar extends React.Component {
    constructor(props) {
        super(props);
    }
    handleClick = (event,id)=> {
        event.preventDefault();
        document.getElementById(id).scrollIntoView({ behavior: 'smooth', block: 'start' });
      };
    render() {

        return (
            <div className="settings__sidebar js-acc-item">
                {/* <button className="show-sidebar-btn js-acc-title" type="button">
                    <SvgIcon name='icon-down' />
                    Показать меню
                </button> */}
                <div className="settings__sidebar-inner js-acc-content">
                    <div className="sidebar-section">
                        <div className="sidebar-section__title">Настройки аккаунта</div>
                        <ul className="sidebar-menu">
                            <li className="sidebar-menu__item"><a href="#contact" onClick={(e)=>this.handleClick(e,"contact")}>Контактная информация</a></li>
                            <li className="sidebar-menu__item"><a href="#security" onClick={(e)=>this.handleClick(e,"security")}>Изменение пароля</a></li>
                            <li className="sidebar-menu__item"><a href="#notify"   onClick={(e)=>this.handleClick(e,"notify")}>Уведомления</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default SettingsSidebar