import React from 'react'
import _ from 'lodash';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';

import EducationItemEdit from '../components/EducationItemEdit'
import EducationEditForm from '../components/EducationEditForm'

import * as profileActions from '../../store/profile/actions';
import * as profileSelectors from '../../store/profile/reducer';

import * as vuuteServiceActions from '../../store/vuuteServices/actions';
import * as vuuteServiceSelectors from '../../store/vuuteServices/reducer';
import { da } from 'date-fns/locale';
import { stat } from 'fs';

//TODO: - REFACTOR -> make it as a container

class EducationEdit extends React.Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    componentDidMount() {
        if (this.props.availableCountries.length == 0) {
          this.props.dispatch(vuuteServiceActions.requestVuuteCountries());
        }
      }


    createEducationTable() {

        let table = []

        if (this.props.modifiedProfile == undefined) {
            return table
        }

        const educations = this.props.modifiedProfile.education

        var counter = 0

        if (!educations) {
            return table
        }

        educations.forEach(element => {
            table.push(<EducationItemEdit 
                education={element} 
                orderNumber={counter}
                onEducationItemRemoveClick={this.onEducationItemRemoveClick}
                onEducationItemEditClick={this.onEducationItemEditClick}/>)
            
            counter = counter + 1

        });

        return table
    }

    render() {
        return (
            <React.Fragment>

                {this.props.isEditEducationFormHidden ? (
                    <EducationEditForm 
                        availableCountries={this.props.availableCountries}
                        selectedCountry={this.props.selectedCountry}
                        onCountryChanged={this.onCountryChanged}
                        availableCities={this.props.availableCities}
                        selectedCity={this.props.selectedCity}
                        onCityChanged={this.onCityChanged}
                        availableEducationsObjects={this.props.availableEducationsObjects}
                        onEducationObjectChanged={this.onEducationObjectChanged}
                        onSpecialityChanged={this.onSpecialityChanged}
                        onStartYearChanged={this.onStartYearChanged}
                        onEndYearChanged={this.onEndYearChanged}
                        selectedEducationObject={this.props.selectedEducationObject}
                        newEducationObject={this.props.newEducationObject}
                        onEducationSaveClick={this.onEducationSaveClick}
                        onEducationCloseClick={this.onEducationCloseClick}
                        errorEducation={this.props.errorEducation}
                        />
                ) : (
                    ''
                )}

                <section className="user-card-section">
                    <h2 class="user-card-section-title has-edit-btn"><span>Образование</span></h2>
                    <div className="user-edu-list">
                        {this.createEducationTable()}
                        <div className="editable">
                            <a className="add-edu-btn btn--1 btn--sm btn--flex" href="javascript:;" data-state="Новое образование" onClick={this.onAddClick}>
                                Добавить образование
                        </a>
                        </div>
                    </div>
                </section>
                <div className="user-data-frm__footer editable">
                    <button className="live-edit-cancel-btn btn--4-2 btn--sm btn--flex" type="button" onClick={this.onCancelClick}>Отменить</button>
                    <button className="live-edit-submit-btn btn--2 btn--sm btn--flex" type="button" onClick={this.onSaveClick}>Сохранить изменения</button>
                </div>
            </React.Fragment>
        );
    }

    onSaveClick(event) {
        event.preventDefault();
        this.props.dispatch(profileActions.saveEducation(this.props.modifiedProfile))
    }

    onCancelClick(event) {
        event.preventDefault();
        this.props.dispatch(profileActions.discardChanges())
    }

    onEducationItemRemoveClick(event) {
        event.preventDefault();
        this.props.dispatch(profileActions.deleteProfileEducation(event.currentTarget.value))
    }

    onEducationItemEditClick(orderNumber, event) {
        event.preventDefault();
        const educationOrderNumber = orderNumber
        //TODO: CALL ACTION HERE
        this.props.dispatch(profileActions.editEducationItem(educationOrderNumber))
        // this.props.dispatch(vuuteServiceActions.requestVuuteCities(newCountryId))
        this.props.dispatch(vuuteServiceActions.requestVuuteCities())
        this.props.dispatch(vuuteServiceActions.requestVuuteEducations())
        const ed = this.props.newEducationObject

        this.props.dispatch(profileActions.educationFormHiddenStateChanged(!this.props.isEditEducationFormHidden))
    }

    onAddClick(event) {
        event.preventDefault();
        this.props.dispatch(profileActions.educationFormHiddenStateChanged(!this.props.isEditEducationFormHidden))
    }


    //EducationEditForm events
    onCountryChanged(countryId) {
        // event.preventDefault();
        var newCountryId = parseInt(countryId, 10)
        var newCountry = null
        _.forEach(this.props.availableCountries, function(country) {
            if (newCountryId == country.id) {
                newCountry = country
            }
        });

        this.props.dispatch(profileActions.selectCountry(newCountry))
        this.props.dispatch(vuuteServiceActions.requestVuuteCities(newCountryId))
    }

    onCityChanged(cityId) {
        var newCityId = parseInt(cityId, 10)
        var newCity = null
        _.forEach(this.props.availableCities, function(city) {
            if (newCityId == city.id) {
                newCity = city
            }
        });
        this.props.dispatch(profileActions.selectCity(newCity))
        this.props.dispatch(vuuteServiceActions.requestVuuteEducations(newCityId, ''))
    }

    onEducationObjectChanged(educationId) {
        var newEducationId = parseInt(educationId, 10)
        var newEducationObject = null
        _.forEach(this.props.availableEducationsObjects, function(educationObject) {
            if (newEducationId == educationObject.id) {
                newEducationObject = educationObject
            }
        });
        this.props.dispatch(profileActions.selectEducation(newEducationObject))
    }

    onSpecialityChanged(event) {
        this.props.dispatch(profileActions.changeEducationSpeciality(event.target.value))
    }

    onStartYearChanged(event) {
        this.props.dispatch(profileActions.changeStartYear(event))
    }

    onEndYearChanged(event) {
        this.props.dispatch(profileActions.changeEndYear(event))
    }

    onEducationCloseClick(event) {
        event.preventDefault();
        this.props.dispatch(profileActions.clearSaveEducationErrors())
        this.props.dispatch(profileActions.educationFormHiddenStateChanged(!this.props.isEditEducationFormHidden))
    }

    onEducationSaveClick(event) {
        event.preventDefault();

        this.props.dispatch(profileActions.clearSaveEducationErrors())

        // let newEducationObj = this.props.newEducationObject
        // if all fields filled -> continue
        // if (newEducationObj.speciality && newEducationObj.dateFinished && newEducationObj.dateStarted && this.props.selectedEducationObject) {
            this.props.dispatch(profileActions.addProfileEducation(this.props.newEducationObject))
            // this.props.dispatch(profileActions.educationFormHiddenStateChanged(!this.props.isEditEducationFormHidden))
        // } 
        // todo: else case

        
        
    }

}

// export default MasterPersonInfoContainer
function mapStateToProps(state) {
    const modifiedProfile = profileSelectors.getModifiedProfile(state);
    const isEditEducationFormHidden = profileSelectors.isEditEducationFormHidden(state);

    const availableCountries = vuuteServiceSelectors.getAvailableVuuteCountries(state);
    const availableCities = vuuteServiceSelectors.getAvailableVuuteCities(state);
    const availableEducationsObjects = vuuteServiceSelectors.getAvailableVuuteEducationObjects(state);

    const selectedCountry = profileSelectors.getSelectedCountry(state);
    const selectedCity = profileSelectors.getSelectedCity(state);
    const selectedEducationObject = profileSelectors.getSelectedEducationObject(state);
    const newEducationObject = profileSelectors.getNewEducationObject(state);

    const errorEducation = profileSelectors.getEducationSavingError(state);
    
    return {
        modifiedProfile,
        isEditEducationFormHidden,
        availableCountries,
        availableCities,
        availableEducationsObjects,
        selectedCountry,
        selectedCity,
        selectedEducationObject,
        newEducationObject,
        errorEducation,
    }
}

export default connect(mapStateToProps)(EducationEdit);