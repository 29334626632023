import _ from 'lodash';
import ChatRoomParser from './ChatRoomParser';

const { default: JSONParser } = require("../../common/parsers/JSONParser");

class ChatRoomsParser extends JSONParser {
    parse() {
        let data = super.parse()
        if (data === null) { return null }


        let rooms = []
        _.forEach(this.data, function(roomData) {
            let room = new ChatRoomParser(roomData).parse()
            rooms.push(room)
        });
        

        return rooms
    }
}

export default ChatRoomsParser;