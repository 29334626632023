import React from 'react'

import SvgIcon from './SvgIcon';

class ProfileStatusBar extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {

    let city = ''
    if (this.props.bio) {
      if (this.props.bio.city) {
        if (this.props.bio.city.title !== undefined) {
         city = this.props.bio.city.title
        }
      }
    }

    return (
      <div className="person-info__props not-editable">

        {city.length > 0 &&
          <div className="location">
            <SvgIcon name='icon-location' />
            {city}
          </div>
        }


        {/* <div className="state">
          <SvgIcon name='icon-checked' />
          Освоившийся
        </div> */}
      </div>
    );
  }
}

export default ProfileStatusBar