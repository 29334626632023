import _ from 'lodash';
import { register } from '../store/register/actions';
import AuthService from '../services/AuthService';
import JSParseService from '../services/JSParseService';
import { da } from 'date-fns/locale';
import { FIND_FILTER_TYPE } from '../store/find/actions'
import Configuration, { ENV } from '../config';
import HttpRouter, { API_CALL } from '../httpRouter';
import TokenParser from '../user/parsers/TokenParser';
import User from '../user/model/User';
import UserParser from '../user/parsers/UserParser';
import APIErrorParser from '../common/parsers/APIErrorParser';
import InternalAPIParser from '../common/parsers/InternalAPIParser';
import ProfileParser from '../profile/parsers/ProfileParser';
import APIError from '../common/model/APIError';
import ProfileServicesParser from '../profile/parsers/ProfileServicesParser';
import ProfileBioParser from '../profile/parsers/ProfileBioParser';
import CitiesVuuteParser from '../vuuteServices/parsers/CitiesVuuteParser'
import CountriesVuuteParser from '../vuuteServices/parsers/CountriesVuuteParser';
import EducationsVuuteParser from '../vuuteServices/parsers/EducationsVuuteParser';
import CategoriesVuuteParser from '../vuuteServices/parsers/CategoriesVuuteParser';
import ServicesVuuteParser from '../vuuteServices/parsers/ServicesVuuteParser';
import ProfileExperiencesParser from '../profile/parsers/ProfileExperiencesParser';
import ProfileEducationsParser from '../profile/parsers/ProfileEducationsParser';
import OrderParser from '../order/parsers/OrderParser';
import OrdersParser from '../order/parsers/OrdersParser';
import ProfilesParser from '../profile/parsers/ProfilesParser';
import JobParser from '../jobs/parsers/JobParser';
import JobsListState from '../jobs/model/JobsListState';
import JobsParser from '../jobs/parsers/JobsParser';
import ChatRoomsParser from '../chat/parsers/ChatsRoomParser';
import MessagesParser from '../chat/parsers/MessagesParser';
import NotificationsParser from '../notifications/parsers/NotificationsParser';
import NotificationsSettingsParser from '../settings/parsers/NotificationsSettingsParser';
import MainReviewsParser from '../jobs/parsers/MainReviewsParser';

// LEGACY:
// const API_DOMAIN = 'http://127.0.0.1:8000'
// const API_DOMAIN = 'https://vr-python-prod.herokuapp.com';
// const API_ENDPOINT = `${API_DOMAIN}/api`;
// const API_KEY = 'Qd8raS23d0QcjUjtITFl0QDNYfRcvHfuTtk_lGDa92M'


class APIService {
    #config;

    constructor() {
        this.#config = new Configuration(ENV.PROD)
    }

    async executeRequest(request) {
        const response = await fetch(request)
        const status = response.status
        var js = undefined
        // todo: check if no body -> in this case this method throw error on .json()
        if (status != 204) {
            js = await response.json()
        }

        // if token invalid -> logout user
        if (status == 401) {
            AuthService.shared().clearCredentials()
            window.location.href = "/"
        }

        return [status, js]
    }

    prepareRequest(path, method, params, headers) {
        return new Request(`${this.#config.API_ENDPOINT}${path}`, {
            method: method,
            headers: headers,
            body: params,
            credentials: "same-origin",
        })
    }

    async login(phoneNumber, password) {

        var payload = {
            "phone": phoneNumber,
            "password": password
        }

        let route = new HttpRouter(API_CALL.LOGIN, payload)

        const req = this.prepareRequest(route.URL_PATH, 
                                        route.HTTP_METHOD, 
                                        route.JSON_PAYLOAD, 
                                        route.HEADERS)

        let [status, json] = await this.executeRequest(req)

        if (status == 200) {
            let token = new TokenParser(json).parse()
            let user = new UserParser(json).parse()
            return {user: user, token: token, error: null}
        } else if (status == 400) {
            let apiError = new InternalAPIParser(json).parse()
            return {user: null, token: null, error: apiError}
        } else {
            let defaultError = new APIError('Что-то пошло не так')
            return {user: null, token: null, error: defaultError}
        }
    }

    async logout() {
        
        let route = new HttpRouter(API_CALL.LOGOUT)

        const req = this.prepareRequest(route.URL_PATH, 
                                        route.HTTP_METHOD, 
                                        route.JSON_PAYLOAD, 
                                        route.HEADERS)

        let [status, json] = await this.executeRequest(req)

        if (status == 204) {
            return true
        } else {
            return false
        }
    }


    async forgotPassword(phoneNumber) {
        var payload= {
            "phone": phoneNumber
        }

        let route = new HttpRouter(API_CALL.RECOVER_PASSWORD, payload)

        const req = this.prepareRequest(route.URL_PATH, 
                                        route.HTTP_METHOD, 
                                        route.JSON_PAYLOAD, 
                                        route.HEADERS)

        let [status, json] = await this.executeRequest(req)

        if (status == 200) {
            return {recovered: true, error: null}
        } else if (status == 400) {
            let apiError = new InternalAPIParser(json).parse()
            return {recovered: false, error: apiError}
        } else {
            let defaultError = new APIError('Что-то пошло не так')
            return {recovered: false, error: defaultError}
        }
    }


    async register(name, phoneNumber, password) {

        var payload = {
            "name": name,
            "phone": phoneNumber,
            "password": password
        }

        let route = new HttpRouter(API_CALL.SIGNUP, payload)

        const req = this.prepareRequest(route.URL_PATH, 
                                        route.HTTP_METHOD, 
                                        route.JSON_PAYLOAD, 
                                        route.HEADERS)

        let [status, json] = await this.executeRequest(req)

        if (status == 201) {
            let token = new TokenParser(json).parse()
            let user = new UserParser(json).parse()
            return {user: user, token: token, error: null}
        } else if (status == 400) {
            let apiError = new InternalAPIParser(json).parse()
            return {user: null, token: null, error: apiError}
        } else {
            let defaultError = new APIError('Что-то пошло не так')
            // let apiError = new APIErrorParser(json).parse()
            return {user: null, token: null, error: defaultError}
        }
    }



    async sendSMSCode() {

        let route = new HttpRouter(API_CALL.SEND_SMS_CODE)

        const req = this.prepareRequest(route.URL_PATH, 
                                        route.HTTP_METHOD, 
                                        route.JSON_PAYLOAD, 
                                        route.HEADERS)

        let [status, json] = await this.executeRequest(req)

        if (status == 200) {
            return true
        } else {
            return false
        }

    }

    async checkSMSCode(enteredCode) {

        var payload = {
            "code": enteredCode
        }

        let route = new HttpRouter(API_CALL.CHECK_SMS_CODE, payload)

        const req = this.prepareRequest(route.URL_PATH, 
                                        route.HTTP_METHOD, 
                                        route.JSON_PAYLOAD, 
                                        route.HEADERS)

        let [status, json] = await this.executeRequest(req)

        if (status == 200) {
            return {isMatched: true, error: null}
        } else if (status == 400) {
            let apiError = new InternalAPIParser(json).parse()
            return {isMatched: false, error: apiError}
        } else {
            let defaultError = new APIError('Что-то пошло не так')
            // let apiError = new APIErrorParser(json).parse()
            return {isMatched: false, error: defaultError}
        }

    }

    async createProfile(name, profileType) {

        var payload = {
            "profile_type": profileType,
            "first_name": name
        }

        let route = new HttpRouter(API_CALL.CREATE_PROFILE, payload)

        const req = this.prepareRequest(route.URL_PATH, 
                                        route.HTTP_METHOD, 
                                        route.JSON_PAYLOAD, 
                                        route.HEADERS)

        let [status, json] = await this.executeRequest(req)

        if (status == 200) {
            let profile = new ProfileParser(json["profile"]).parse()
            return {profile: profile,  error: null}
        } else {
            let apiError = new APIErrorParser(json).parse()
            return {profile: null, error: apiError}
        }

    }

    async getProfile(profileId) {

        let route = new HttpRouter(API_CALL.GET_PROFILE, null, profileId)

        const req = this.prepareRequest(route.URL_PATH, 
                                        route.HTTP_METHOD, 
                                        route.JSON_PAYLOAD, 
                                        route.HEADERS)

        let [status, json] = await this.executeRequest(req)

        if (status == 200) {
            let profile = new ProfileParser(json["profile"]).parse()
            return {profile: profile,  error: null}
        } else {
            let apiError = new APIErrorParser(json).parse()
            return {profile: null, error: apiError}
        }
        
    }

    async getContracts(profileId) {

        let pk = `${profileId}/${JobsListState.INCOME.toRequestString()},${JobsListState.ACTIVE.toRequestString()},${JobsListState.CLOSED.toRequestString()}`

        let route = new HttpRouter(API_CALL.GET_JOBS_FOR_VIEW, null, pk)

        const req = this.prepareRequest(route.URL_PATH, 
                                        route.HTTP_METHOD, 
                                        route.JSON_PAYLOAD, 
                                        route.HEADERS)

        let [status, json] = await this.executeRequest(req)

        if (status == 200) {
            let incomeJobs = new JobsParser(json["jobs"]["income_jobs"]).parse()
            let activeJobs = new JobsParser(json["jobs"]["active_jobs"]).parse()
            let closedJobs = new JobsParser(json["jobs"]["closed_jobs"]).parse()
            return {incomeJobs: incomeJobs, activeJobs: activeJobs, closedJobs: closedJobs, error: null}
        } else {
            let apiError = new APIErrorParser(json).parse()
            return {incomeJobs: [], activeJobs: [], closedJobs: [], error: apiError}
        }

    }

    async updateProfileBio(profile) {

        if (profile.bio === null) {
            return {profile: profile, error: new APIError('Profile Bio data is null')}
        }

        var payload = {
            "first_name": profile.bio.firstName,
            "last_name": profile.bio.lastName,
            "middle_name": profile.bio.middleName,
            "profile_description": profile.bio.description,
            "city": profile.bio.city
        }

        let route = new HttpRouter(API_CALL.UPDATE_PROFILE_BIO, payload, profile.bio.id)

        const req = this.prepareRequest(route.URL_PATH, 
                                        route.HTTP_METHOD, 
                                        route.JSON_PAYLOAD, 
                                        route.HEADERS)

        let [status, json] = await this.executeRequest(req)

        if (status == 200) {
            let bioJson = _.get(json, 'profile.bio')
            let bio = new ProfileBioParser(bioJson).parse()
            return {bio: bio,  error: undefined}
        } else {
            let apiError = new APIErrorParser(json).parse()
            return {bio: undefined, error: apiError}
        }
        
    }

    async updateProfileServices(profile) {


        if (profile.services === undefined) {
            return {services: undefined,  error: undefined}
        }

        var services = []

        _.forEach(profile.services, function(value) {

            let serviceForUpdate = {
                service_object: value.serviceObject.id,
                price: value.price
            }

            services.push(serviceForUpdate)

        });

        var payload = {
            "service": services
        }

        let route = new HttpRouter(API_CALL.UPDATE_PROFILE_SERVICES, payload)

        const req = this.prepareRequest(route.URL_PATH, 
                                        route.HTTP_METHOD, 
                                        route.JSON_PAYLOAD, 
                                        route.HEADERS)

        let [status, json] = await this.executeRequest(req)

        if (status == 200) {
            let servicesJson = _.get(json, 'profile.service')
            let updatedServices = new ProfileServicesParser(servicesJson).parse()
            return {services: updatedServices,  error: undefined}
        } else if (status == 400) {
            let apiError = new InternalAPIParser(json).parse()
            return {services: undefined, error: apiError}
        } else {
            let defaultError = new APIError('Что-то пошло не так')
            // let apiError = new APIErrorParser(json).parse()
            return {services: undefined, error: defaultError}
        }

    }


    async updateProfileEducation(profile) {

        if (profile.education === null) {
            return {education: null,  error: null}
        }

        var educations = []

        _.forEach(profile.education, function(value) {

            const sDate = new Date(value.dateStarted)
            const sDateS = sDate.toISOString().substring(0, 10)

            const fDate = new Date(value.dateFinished)
            const fDateS = fDate.toISOString().substring(0, 10)

            let educationForUpdate = {
                education_object: value.educationObject.id,
                specialty: value.speciality,
                date_started: sDateS,
                date_finished: fDateS,
            }

            educations.push(educationForUpdate)

        });

        var payload = {
            "education": educations
        }

        let route = new HttpRouter(API_CALL.UPDATE_PROFILE_EDUCATIONS, payload)

        const req = this.prepareRequest(route.URL_PATH, 
                                        route.HTTP_METHOD, 
                                        route.JSON_PAYLOAD, 
                                        route.HEADERS)

        let [status, json] = await this.executeRequest(req)

        if (status == 200) {
            let educationJson = _.get(json, 'profile.education')
            let updatedEducation = new ProfileEducationsParser(educationJson).parse()
            return {education: updatedEducation,  error: null}
        } else {
            let apiError = new APIErrorParser(json).parse()
            return {education: null, error: apiError}
        }

    }

    async updateProfileExperience(profile) {


        if (profile.experience === null) {
            return {experience: null,  error: null}
        }

        var experiences = []

        var validationError = undefined

        _.forEach(profile.experience, function(value) {

            if (!value.dateStarted || !value.dateFinished || !value.companyName || !value.position) {
                validationError = new APIError("Вы не указали следующие поля: название компании, должность, дата начала заказа, дата окончания заказа", '20012')
                return
            }

            const sDate = new Date(value.dateStarted)
            const sDateS = sDate.toISOString().substring(0, 10)

            const fDate = new Date(value.dateFinished)
            const fDateS = fDate.toISOString().substring(0, 10)

            let experienceForUpdate = {
                company_name: value.companyName,
                date_started: sDateS,
                date_finished: fDateS,
                position: value.position,
            }

            experiences.push(experienceForUpdate)

        });

        if (validationError) {
            return {experience: null, error: validationError}
        }

        var payload = {
            "experience": experiences
        }

        let route = new HttpRouter(API_CALL.UPDATE_PROFILE_EXPERIENCES, payload)

        const req = this.prepareRequest(route.URL_PATH, 
                                        route.HTTP_METHOD, 
                                        route.JSON_PAYLOAD, 
                                        route.HEADERS)

        let [status, json] = await this.executeRequest(req)

        if (status == 200) {
            let experiencesJson = _.get(json, 'profile.experience')
            let updatedExperience = new ProfileExperiencesParser(experiencesJson).parse()
            return {experience: updatedExperience,  error: null}
        } else {
            let apiError = new APIErrorParser(json).parse()
            return {experience: null, error: apiError}
        }
    }

    async getVuuteServices() {

        let route = new HttpRouter(API_CALL.GET_SERVICES)

        const req = this.prepareRequest(route.URL_PATH, 
                                        route.HTTP_METHOD, 
                                        route.JSON_PAYLOAD, 
                                        route.HEADERS)

        let [status, json] = await this.executeRequest(req)

        if (status == 200) {
            let services = new ServicesVuuteParser(json).parse()
            return {services: services,  error: null}
        } else {
            let apiError = new APIErrorParser(json).parse()
            return {services: null, error: apiError}
        }
        
    }


    async getVuuteCountries() {

        let route = new HttpRouter(API_CALL.GET_COUNTRIES)

        const req = this.prepareRequest(route.URL_PATH, 
                                        route.HTTP_METHOD, 
                                        route.JSON_PAYLOAD, 
                                        route.HEADERS)

        let [status, json] = await this.executeRequest(req)

        if (status == 200) {
            let countries = new CountriesVuuteParser(json).parse()
            return {countries: countries,  error: null}
        } else {
            let apiError = new APIErrorParser(json).parse()
            return {countries: null, error: apiError}
        }
        
    }

    async getVuuteCities(countryId) {

        let route = new HttpRouter(API_CALL.GET_CITIES, null, countryId)

        const req = this.prepareRequest(route.URL_PATH, 
                                        route.HTTP_METHOD, 
                                        route.JSON_PAYLOAD, 
                                        route.HEADERS)

        let [status, json] = await this.executeRequest(req)

        if (status == 200) {
            let cities = new CitiesVuuteParser(json).parse()
            return {cities: cities,  error: null}
        } else {
            let apiError = new APIErrorParser(json).parse()
            return {cities: null, error: apiError}
        }
        
    }


    async getVuuteEducationObjects(cityId, queryString) {

        var pk = null
        if (cityId != undefined && queryString != undefined) {
            pk = `city_id=${cityId}&q=${queryString}`
        }

        let route = new HttpRouter(API_CALL.GET_EDUCATION_OBJECTS, null, pk)

        const req = this.prepareRequest(route.URL_PATH, 
                                        route.HTTP_METHOD, 
                                        route.JSON_PAYLOAD, 
                                        route.HEADERS)

        let [status, json] = await this.executeRequest(req)

        if (status == 200) {
            let educations = new EducationsVuuteParser(json).parse()
            return {educations: educations,  error: null}
        } else {
            let apiError = new APIErrorParser(json).parse()
            return {educations: null, error: apiError}
        }
        
    }

    async getCategories() {

        let route = new HttpRouter(API_CALL.GET_CATEGORIES)

        const req = this.prepareRequest(route.URL_PATH, 
                                        route.HTTP_METHOD, 
                                        route.JSON_PAYLOAD, 
                                        route.HEADERS)

        let [status, json] = await this.executeRequest(req)

        if (status == 200) {
            let categories = new CategoriesVuuteParser(json).parse()
            return {categories: categories,  error: null}
        } else {
            let apiError = new APIErrorParser(json).parse()
            return {categories: null, error: apiError}
        }

    }


    async getChats() {

        let route = new HttpRouter(API_CALL.GET_CHATS)

        const req = this.prepareRequest(route.URL_PATH, 
                                        route.HTTP_METHOD, 
                                        route.JSON_PAYLOAD, 
                                        route.HEADERS)

        let [status, json] = await this.executeRequest(req)

        if (status == 200) {
            let chats = new ChatRoomsParser(json).parse()
            return {chats: chats,  error: null}
        } else {
            let apiError = new APIErrorParser(json).parse()
            return {chats: null, error: apiError}
        }

        
    }

    async getMessages(chatId, limit, offset, datetime) {

        let params = `${chatId}/messages/?request_date=${datetime}&limit=${limit}&offset=${offset}`

        let route = new HttpRouter(API_CALL.GET_MESSAGES, null, params)

        const req = this.prepareRequest(route.URL_PATH, 
                                        route.HTTP_METHOD, 
                                        route.JSON_PAYLOAD, 
                                        route.HEADERS)

        let [status, json] = await this.executeRequest(req)

        if (status == 200) {
            let messages = new MessagesParser(json).parse()
            return {messages: messages,  error: null}
        } else {
            let apiError = new APIErrorParser(json).parse()
            return {messages: null, error: apiError}
        }

    }


    async getUnreadMsgCount() {
        
        let route = new HttpRouter(API_CALL.GET_UNREAD_COUNT)

        const req = this.prepareRequest(route.URL_PATH, 
                                        route.HTTP_METHOD, 
                                        route.JSON_PAYLOAD, 
                                        route.HEADERS)

        let [status, json] = await this.executeRequest(req)

        if (status == 200) {
            let unreadCount = _.get(json, 'unread_count')
            return {unreadCount: unreadCount,  error: null}
        } else {
            let apiError = new APIErrorParser(json).parse()
            return {unreadCount: null, error: apiError}
        }
    }




    async createOrder(orderTitle, orderCategory, orderDescription, orderPrice, orderStartDate, orderEndDate, orderCheckedCoords, orderCheckedAddress) {

        var payload = {
            'title': orderTitle,
            'category': orderCategory?.id,
            'description': orderDescription,
            'price': orderPrice,
            'start_date': orderStartDate,
            'end_date': orderEndDate,
            'latitude': orderCheckedCoords?.lat,
            'longtitude': orderCheckedCoords?.lng,
            'address': orderCheckedAddress?.address,
            'city': orderCheckedAddress?.city
        }

        let route = new HttpRouter(API_CALL.CREATE_ORDER, payload)

        const req = this.prepareRequest(route.URL_PATH, 
                                        route.HTTP_METHOD, 
                                        route.JSON_PAYLOAD, 
                                        route.HEADERS)

        let [status, json] = await this.executeRequest(req)

        if (status == 201) {
            let order = new OrderParser(json["order"]).parse()
            return {order: order,  error: undefined}
        } else if (status == 400) {
            let apiError = new InternalAPIParser(json).parse()
            return {order: undefined, error: apiError}
        } else {
            let defaultError = new APIError('Что-то пошло не так')
            return {order: undefined, error: defaultError}
        }
    }


    async updateOrder(orderId, orderTitle, orderCategory, orderDescription, orderPrice, orderStartDate, orderEndDate, orderCheckedCoords, orderCheckedAddress) {

        var payload = {
            'title': orderTitle,
            'category': orderCategory.id,
            'description': orderDescription,
            'price': orderPrice,
            'start_date': orderStartDate,
            'end_date': orderEndDate,
            'latitude': orderCheckedCoords.lat,
            'longitude': orderCheckedCoords.lng,
            'address': orderCheckedAddress.address,
            'city': orderCheckedAddress.city
        }


        let route = new HttpRouter(API_CALL.UPDATE_ORDER, payload, orderId)

        const req = this.prepareRequest(route.URL_PATH, 
                                        route.HTTP_METHOD, 
                                        route.JSON_PAYLOAD, 
                                        route.HEADERS)

        let [status, json] = await this.executeRequest(req)

        if (status == 200) {
            let order = new OrderParser(json["order"]).parse()
            return {order: order,  error: null}
        } else if (status == 400) {
            let apiError = new InternalAPIParser(json).parse()
            return {order: undefined, error: apiError}
        } else {
            let defaultError = new APIError('Что-то пошло не так')
            return {order: undefined, error: defaultError}
        }
    }

    async getClientOrders() {

        let route = new HttpRouter(API_CALL.GET_ACTIVE_ORDERS)

        const req = this.prepareRequest(route.URL_PATH, 
                                        route.HTTP_METHOD, 
                                        route.JSON_PAYLOAD, 
                                        route.HEADERS)

        let [status, json] = await this.executeRequest(req)

        if (status == 200) {
            let orders = new OrdersParser(json).parse()
            return {orders: orders,  error: null}
        } else {
            let apiError = new APIErrorParser(json).parse()
            return {orders: null, error: apiError}
        }
    }


    async getOrderInfoById(orderId) {

        let route = new HttpRouter(API_CALL.GET_ORDER, null, orderId)

        const req = this.prepareRequest(route.URL_PATH, 
                                        route.HTTP_METHOD, 
                                        route.JSON_PAYLOAD, 
                                        route.HEADERS)

        let [status, json] = await this.executeRequest(req)

        if (status == 200) {
            let order = new OrderParser(json["order"]).parse()
            return {order: order,  error: undefined}
        } else if (status == 400) {
            let apiError = new InternalAPIParser(json).parse()
            return {order: undefined, error: apiError}
        } else {
            let defaultError = new APIError('Что-то пошло не так')
            return {order: undefined, error: defaultError}
        }
        
    }

    async findResults(filterType, searchCriteria, datetime) {

        var par = ''
        par += `low_price=${searchCriteria.findMinPrice}&top_price=${searchCriteria.findMaxPrice}&rating=${searchCriteria.findRating.value}&sorted_by=${searchCriteria.findOrderBy.value}&request_date=${datetime}&limit=${searchCriteria.limit}&offset=${searchCriteria.offset}`
        
        if (searchCriteria.findCity) {
            par += `&city=${searchCriteria.findCity.id}`
        }

        if (searchCriteria.findCategory) {
            par += `&category=${searchCriteria.findCategory.id}`
        }

        if (searchCriteria.findServices.length != 0) {
            var serviceIds = searchCriteria.findServices.map(e => e.id).join(",")
            par += `&services=[${serviceIds}]`
        }

        if (searchCriteria.findName) {
            par += `&name=${searchCriteria.findName}`
        }

        if (filterType == FIND_FILTER_TYPE.FIND_ORDERS) {
            var route = new HttpRouter(API_CALL.FIND_ORDER, null, par)
        } else {
            var route = new HttpRouter(API_CALL.FIND_MASTER, null, par)
        }

        const req = this.prepareRequest(route.URL_PATH, 
                                        route.HTTP_METHOD, 
                                        route.JSON_PAYLOAD, 
                                        route.HEADERS)

        let [status, json] = await this.executeRequest(req)

        if (status == 200) {
            if (filterType == FIND_FILTER_TYPE.FIND_ORDERS) {
                var results = new OrdersParser(json).parse()
            } else {
                var results = new ProfilesParser(json).parse()
            }
            return {results: results,  error: null}
        } else {
            let apiError = new APIErrorParser(json).parse()
            return {results: null, error: apiError}
        }
        
    }


    async createJob(orderId, bidComment) {

        var payload = {
            'order': orderId,
            'comment': bidComment
        }

        let route = new HttpRouter(API_CALL.CREATE_JOB, payload)

        const req = this.prepareRequest(route.URL_PATH, 
                                        route.HTTP_METHOD, 
                                        route.JSON_PAYLOAD, 
                                        route.HEADERS)

        let [status, json] = await this.executeRequest(req)

        if (status == 201) {
            let job = new JobParser(json["job"]).parse()
            return {job: job,  error: undefined}
        } else if (status == 400) {
            let apiError = new InternalAPIParser(json).parse()
            return {job: undefined, error: apiError}
        } else {
            let defaultError = new APIError('Что-то пошло не так')
            return {job: undefined, error: defaultError}
        }
    }

    async getJobInfoById(jobId) {

        let route = new HttpRouter(API_CALL.GET_JOB, null, jobId)

        const req = this.prepareRequest(route.URL_PATH, 
                                        route.HTTP_METHOD, 
                                        route.JSON_PAYLOAD, 
                                        route.HEADERS)

        let [status, json] = await this.executeRequest(req)

        if (status == 200) {
            let job = new JobParser(json["job"]).parse()
            return {job: job,  error: null}
        } else if (status == 400) {
            let apiError = new InternalAPIParser(json).parse()
            return {job: undefined, error: apiError}
        } else {
            let defaultError = new APIError('Что-то пошло не так')
            return {job: undefined, error: defaultError}
        }
    }

    async changeJobStatus(jobId, newStatus) {

        let pk = `${jobId}/${newStatus.toRequestString()}`

        let route = new HttpRouter(API_CALL.CHANGE_JOB_STATUS, null, pk)

        const req = this.prepareRequest(route.URL_PATH, 
                                        route.HTTP_METHOD, 
                                        route.JSON_PAYLOAD, 
                                        route.HEADERS)

        let [status, json] = await this.executeRequest(req)

        if (status == 200) {
            let job = new JobParser(json["job"]).parse()
            return {job: job,  error: null}
        } else {
            let apiError = new APIErrorParser(json).parse()
            return {job: null, error: apiError}
        }

    }

    async getMyJobsInfo() {


        let pk = `${JobsListState.INCOME.toRequestString()},${JobsListState.ACTIVE.toRequestString()},${JobsListState.CLOSED.toRequestString()}`

        let route = new HttpRouter(API_CALL.GET_JOBS, null, pk)

        const req = this.prepareRequest(route.URL_PATH, 
                                        route.HTTP_METHOD, 
                                        route.JSON_PAYLOAD, 
                                        route.HEADERS)

        let [status, json] = await this.executeRequest(req)

        if (status == 200) {
            let incomeJobs = new JobsParser(json["jobs"]["income_jobs"]).parse()
            let activeJobs = new JobsParser(json["jobs"]["active_jobs"]).parse()
            let closedJobs = new JobsParser(json["jobs"]["closed_jobs"]).parse()
            return {incomeJobs: incomeJobs, activeJobs: activeJobs, closedJobs: closedJobs, error: null}
        } else {
            let apiError = new APIErrorParser(json).parse()
            return {incomeJobs: [], activeJobs: [], closedJobs: [], error: apiError}
        }

    }
    async getRandomReviews() {
        let route = new HttpRouter(API_CALL.GET_RANDOM_REVIEWS)

        const req = this.prepareRequest(route.URL_PATH, 
                                        route.HTTP_METHOD, 
                                        route.JSON_PAYLOAD, 
                                        route.HEADERS)

        let [status, json] = await this.executeRequest(req)
        
        if (status == 200) {
            console.log(json);
            let mainReviews = new MainReviewsParser(json["reviews"]).parse();
            console.log(mainReviews)
            let reviews = [];
			const mounths = [
				'Января',
				'Февраля',
				'Марта',
				'Апреля',
				'Мая',
				'Июня',
				'Июля',
				'Августа',
				'Сентября',
				'Октября',
				'Ноября',
				'Декабря',
            ];
            if (mainReviews == null)
            {
                return {reviews: []}
            }
            for (let index = 0; index < 3; index++) {
                let date = new Date();
                if (mainReviews[index].job)
                {
                    date.setTime(Date.parse(mainReviews[index].job?.closedDate));
                }
				reviews[index] = {
					master: {
						name: mainReviews[index].review?.owner?.firstName,
						photo: mainReviews[index].review?.owner?.photoUrl,
					},
					review: {
						desc: {
							title: mainReviews[index].job?.order?.title,
							body: mainReviews[index].review?.reviewText,
						},
						reviewer: {
							reviewer: {
								name: mainReviews[index].reviewer?.firstName,
								isPositive: mainReviews[index].review?.rate > 3.4,
								id: mainReviews[index].review?.id,
								date: `${date.getDate()} ${
									mounths[date.getMonth()]
								} ${date.getFullYear()}`,
							},
						},
					},
				};
			}
            return {reviews: reviews}
        } else {
            let apiError = new APIErrorParser(json).parse()
            return { error: apiError}
        }
    }
    async createReview(jobInfo, reviewInfo) {

        var payload = {
            'review_text': reviewInfo.reviewText,
            'quality_rate': reviewInfo.qualityRate,
            'deadline_rate': reviewInfo.deadlineRate,
            'communication_rate': reviewInfo.communicationRate,
        }

        let route = new HttpRouter(API_CALL.CREATE_REVIEW, payload, jobInfo.id)

        const req = this.prepareRequest(route.URL_PATH, 
                                        route.HTTP_METHOD, 
                                        route.JSON_PAYLOAD, 
                                        route.HEADERS)

        let [status, json] = await this.executeRequest(req)

        if (status == 201) {
            return {error: undefined}
        } else if (status == 400) {
            let apiError = new InternalAPIParser(json).parse()
            return {error: apiError}
        } else {
            let defaultError = new APIError('Что-то пошло не так')
            return {error: defaultError}
        }
    }

    async getNotifications() {

        let route = new HttpRouter(API_CALL.GET_NOTIFICATIONS)

        const req = this.prepareRequest(route.URL_PATH, 
                                        route.HTTP_METHOD, 
                                        route.JSON_PAYLOAD, 
                                        route.HEADERS)

        let [status, json] = await this.executeRequest(req)

        if (status == 200) {
            let unreadCount = _.get(json, 'unread')
            let notifications = new NotificationsParser(json["notifications"]).parse()
            return {unread: unreadCount, notifications: notifications, error: null}
        } else {
            let apiError = new APIErrorParser(json).parse()
            return {unread: 0, notifications: null, error: apiError}
        }
    }

    async readAllNotifications() {

        let route = new HttpRouter(API_CALL.READ_NOTIFICATION)

        const req = this.prepareRequest(route.URL_PATH, 
                                        route.HTTP_METHOD, 
                                        route.JSON_PAYLOAD, 
                                        route.HEADERS)

        let [status, json] = await this.executeRequest(req)

        // todo: finish error implementation
        if (status == 200) {
            
        } else {
            
        }
    }

    async readSingleNotification(notificationId) {

        let route = new HttpRouter(API_CALL.READ_NOTIFICATION, null, notificationId)

        const req = this.prepareRequest(route.URL_PATH, 
                                        route.HTTP_METHOD, 
                                        route.JSON_PAYLOAD, 
                                        route.HEADERS)

        let [status, json] = await this.executeRequest(req)

        // todo: finish error implementation
        if (status == 200) {
            
        } else {
            
        }

        return notificationId

    }

    async uploadPhoto(photoFile) {

        var dataToUpload = new FormData()
        dataToUpload.append('file', photoFile)

        let route = new HttpRouter(API_CALL.UPDATE_PHOTO, dataToUpload)

        const req = this.prepareRequest(route.URL_PATH, 
                                        route.HTTP_METHOD, 
                                        route.JSON_PAYLOAD, 
                                        route.HEADERS)

        let [status, json] = await this.executeRequest(req)

        if (status == 200) {
            let photoUrl = _.get(json, 'photo_url');
            return {photoUrl: photoUrl,  error: null}
        } else {
            let apiError = new APIError('Photo not updated')
            return {photoUrl: null, error: apiError}
        }

    }

    async changeEmail(email) {

        var payload = {
            'email': email,
        }

        let route = new HttpRouter(API_CALL.CHANGE_EMAIL, payload)

        const req = this.prepareRequest(route.URL_PATH, 
                                        route.HTTP_METHOD, 
                                        route.JSON_PAYLOAD, 
                                        route.HEADERS)

        let [status, json] = await this.executeRequest(req)

        // todo: finish error implementation
        if (status == 200) {
            return {isEmailChanged: true, error: undefined}
        } else if (status == 400) {
            let apiError = new InternalAPIParser(json).parse()
            return {isEmailChanged: false, error: apiError}
        } else {
            let defaultError = new APIError('Что-то пошло не так')
            return {isEmailChanged: false, error: defaultError}
        }

        

    }

    async getEmail() {

        let route = new HttpRouter(API_CALL.GET_EMAIL)

        const req = this.prepareRequest(route.URL_PATH, 
                                        route.HTTP_METHOD, 
                                        route.JSON_PAYLOAD, 
                                        route.HEADERS)

        let [status, json] = await this.executeRequest(req)

        // todo: finish error implementation
        if (status == 200) {
            var email = _.get(json, 'email')
            return {email: email, error: null}
        } else {
            return {email: '', error: null}
        }
    }

    async changePassword(oldPassword, newPassword) {

        var payload = {
            'old_password': oldPassword,
            'new_password': newPassword,
        }

        let route = new HttpRouter(API_CALL.CHANGE_PASSWORD, payload)

        const req = this.prepareRequest(route.URL_PATH, 
                                        route.HTTP_METHOD, 
                                        route.JSON_PAYLOAD, 
                                        route.HEADERS)

        let [status, json] = await this.executeRequest(req)

        // todo: finish error implementation
        if (status == 200) {
            return {isPasswordSaved: true, error: undefined}
        } else if (status == 400) {
            let apiError = new InternalAPIParser(json).parse()
            return {isPasswordSaved: false, error: apiError}
        } else {
            let defaultError = new APIError('Что-то пошло не так')
            return {isPasswordSaved: false, error: defaultError}
        }

    }

    async changeReceiveSettings(settingsDict) {

        var payload = {
            'messages': settingsDict.messages,
            'job_states': settingsDict.jobStates,
            'sales': settingsDict.sales,
            'news': settingsDict.news,
        }

        let route = new HttpRouter(API_CALL.CHANGE_NOTIFICATIONS_SETTINGS, payload)

        const req = this.prepareRequest(route.URL_PATH, 
                                        route.HTTP_METHOD, 
                                        route.JSON_PAYLOAD, 
                                        route.HEADERS)

        let [status, json] = await this.executeRequest(req)

        // todo: finish error implementation
        if (status == 200) {
            
        } else {
            
        }
    }

    async getReceiveSettingsInfo() {

        let route = new HttpRouter(API_CALL.GET_NOTIFICATIONS_SETTINGS)

        const req = this.prepareRequest(route.URL_PATH, 
                                        route.HTTP_METHOD, 
                                        route.JSON_PAYLOAD, 
                                        route.HEADERS)

        let [status, json] = await this.executeRequest(req)

        if (status == 200) {
            let notificationsSettings = new NotificationsSettingsParser(json["settings"]).parse()
            return {settings: notificationsSettings, error: null}
        } else {
            let apiError = new APIErrorParser(json).parse()
            return {settings: null, error: apiError}
        }
    }

    async createDisput(jobInfo, comment) {

        var payload = {
            'comment': comment,
        }

        let route = new HttpRouter(API_CALL.OPEN_DISPUT, payload, jobInfo.id)

        const req = this.prepareRequest(route.URL_PATH, route.HTTP_METHOD, route.JSON_PAYLOAD, route.HEADERS)
        let [status, json] = await this.executeRequest(req);

        if (status == 201) {
            return {error: undefined}
        } else if (status == 400) {
            let apiError = new InternalAPIParser(json).parse()
            return {error: apiError}
        } else {
            let defaultError = new APIError('Что-то пошло не так')
            return {error: defaultError}
        }
    }
}

export default new APIService();