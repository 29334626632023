import React from 'react'
import _ from 'lodash';

import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { menuItem } from '../../page/components/MenuEnum';

import Menu from '../../page/components/Menu'
import CheckOrderView from '../components/CheckOrderView'
import OrderBidPopup from '../components/OrderBidPopup';
import Page404 from '../../page/components/Page404'

import Footer from '../../page/components/Footer'

import * as orderActions from '../../store/order/actions';
import * as orderSelectors from '../../store/order/reducer';

import * as geolocationActions from '../../store/geolocation/actions'

import * as jobActions from '../../store/job/actions'
import * as jobSelectors from '../../store/job/reducer'

import * as vuuteServiceSelectors from '../../store/vuuteServices/reducer';

import * as menuActions from '../../store/menu/actions';

import { withRouter } from 'react-router-dom'

class CheckOrderContainer extends React.Component {
  constructor(props) {
    super(props);

    autoBind(this);
  }

  componentWillMount() {
    this.props.dispatch(menuActions.selectMenuItem(menuItem.UNSELECTED));
  }

  componentDidMount() {
    if (!this.props.orderInfo || (this.props.match.params.id !== this.props.orderInfo.id)) {
      this.props.dispatch(orderActions.getOrderInfoById(this.props.match.params.id))
    }
  }

  render() {

    const jobBidError = this.props.jobBidError

    if (this.props.orderInfo) {
      return (
        <React.Fragment>
          <div className="page">
            <Menu />
            <CheckOrderView
              orderInfo={this.props.orderInfo}
              isEditable={false}
              onBidOrderClick={this.onBidOrderClick}
              onEditOrderClick={this.onEditOrderClick}
            />
            <Footer />
          </div>
          <React.Fragment>
            {this.props.isOrderBidPopupOpened ?
              <OrderBidPopup
                orderInfo={this.props.orderInfo}
                orderBidComment={this.props.orderBidComment}
                onBidCommentChanged={this.onBidCommentChanged}
                onBidOrderCloseClick={this.onBidOrderCloseClick}
                onBidOrderSendClick={this.onBidOrderSendClick}
                jobBidError={jobBidError}
              /> : ''}
          </React.Fragment>
        </React.Fragment>
      )
    } else {
      if (this.props.error) {
        return <Page404 />
      } else {
        return <div></div>
      }
    }
  }

  onEditOrderClick(event) {
    event.preventDefault()
    this.props.dispatch(orderActions.editOrder(this.props.orderInfo))
    // this.props.dispatch(geolocationActions.changeAddress(this.props.orderInfo.geolocation.address,
    this.props.dispatch(geolocationActions.importOrderLocation(this.props.orderInfo.geolocation.address,
      this.props.orderInfo.geolocation.latitude, this.props.orderInfo.geolocation.longtitude))
    this.props.history.push('/create')
  }

  onBidOrderClick(event) {
    event.preventDefault()
    this.props.dispatch(orderActions.onBidOrderClick())
  }

  onBidCommentChanged(event) {
    event.preventDefault()
    this.props.dispatch(orderActions.changeBidComment(event.target.value))
  }

  onBidOrderCloseClick(event) {
    event.preventDefault()
    this.props.dispatch(jobActions.clearBidOrderErrors())
    this.props.dispatch(orderActions.clearOrderBidForm())
    this.props.dispatch(orderActions.onBidOrderCloseClick(event.target.value))
  }

  async onBidOrderSendClick(event) {
    event.preventDefault()
    this.props.dispatch(jobActions.clearBidOrderErrors())
    await this.props.dispatch(jobActions.createJob(this.props.match.params.id, this.props.orderBidComment))
    .then(() => { 
      // if job created -> call success order bid method
      if (this.props.jobBidError === undefined) {
        this.props.dispatch(orderActions.clearOrderBidForm())
        this.props.dispatch(orderActions.successOrderBid()) 
      }
    })
  }
}

function mapStateToProps(state) {
  const [orderInfo, error] = orderSelectors.getOrderInfoById(state)
  const [orderBidComment, isOrderBidPopupOpened] = orderSelectors.getCurrentOrderBidPopupState(state)

  const availableCategories = vuuteServiceSelectors.getAvailableCategories(state);
  const orderCategory = orderSelectors.getOrderCategory(state);

  const jobBidError = jobSelectors.getJobBidError(state);

  return {
    orderInfo,
    orderBidComment,
    isOrderBidPopupOpened,
    availableCategories,
    orderCategory,
    error,
    jobBidError,
  }
}

export default withRouter(connect(mapStateToProps)(CheckOrderContainer));