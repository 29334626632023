import React from 'react'

import SvgIcon from '../../../common/SvgIcon'

class ClosedJobItem extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <div className="content content--with-back bg-block">
                <div className="container">
                    <div className="order-completed job">
                        <div className="order-completed__header">
                            <div><a className="order-completed__title" href="javascript:;">{this.props.jobInfo.order.title}</a>
                                <div className="order-completed__state state">
                                    <SvgIcon name={'icon-checked'} />
                                    завершён
                                </div>
                            </div>
                            <div className="order-completed__price job__price">
                                <div className="job__is-active">
                                    <SvgIcon name={'icon-time'} />
                                </div>
                                <div className="price">{this.props.jobInfo.order.price}<span className="rouble">₽</span></div>
                            </div>
                        </div>
                        <div className="job__info">
                            {this.props.jobInfo.order.description ? (
                                <p>{this.props.jobInfo.order.description}</p>
                            ) : (
                                    <p>Описание заказа отсутствует.</p>
                                )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ClosedJobItem