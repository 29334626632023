import React, { useState, useEffect, useRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

function CustomScrollbars(props) {
	const [isFirstRender, setFirstRender] = useState(true);
	const { children } = props;
	const containerEl = useRef();

	const onScroll = () => {
		if (!isFirstRender) {
			props.onScroll();
		} else {
			setFirstRender(false);
		}
	};

	// Executes if user changes selected chat
	// useEffect(() => {
	// }, [props.selectedChat.id]);

	useEffect(() => {
		// Executes if it is a first render
		if (props.messages.length > 0 && isFirstRender) {
			containerEl.current.scrollToBottom()
		}
	}, [props.messages]);

	return (
		<Scrollbars
			ref={containerEl}
			id='messages'
			className='mCustomScrollBox mCS-light mCSB_vertical mCSB_inside'
			style={{ height: 340 }}
			renderTrackHorizontal={(items) => (
				<div {...items} style={{ display: 'none' }} />
			)}
			renderThumbVertical={(items) => (
				<div
					className='messages-chat__scrollbar'
					{...items}
					style={{ backgroundColor: 'rgb(255, 176, 91)' }}
				/>
			)}
			renderView={(items) => (
				<div {...items} onScroll={() => onScroll()} className='mCSB_container' id ="chatContainer"/>
			)}
		>
			{children}
		</Scrollbars>
	);
}

export default CustomScrollbars;
