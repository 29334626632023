const { min } = require("lodash");

class ServiceVuute {
    id;
    category;
    title;
    minPrice;
    maxPrice;
    dimension;

    constructor(id, category, title, minPrice, maxPrice, dimension) {
        this.id = id
        this.category = category
        this.title = title
        this.minPrice = minPrice
        this.maxPrice = maxPrice
        this.dimension = dimension
    }
}

export default ServiceVuute;