import React from 'react'
import autoBind from 'react-autobind';
import _ from 'lodash';

import SelectList from '../../common/SelectList'
import FindReactSelect from '../../common/FindReactSelect';

class FindMasterSelect extends React.Component {

    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            isSelectListOpenned: false,
        }
    }


    prepareSelectListData() {

        const findSortListItems = this.props.findSortListItems
        const selectedSortId = this.props.selectedSortId.id
        var selectedFilter = undefined;
        var data = [];
        if (
            selectedSortId !== undefined &&
            selectedSortId !== null
        ) {
            selectedFilter = {
                value: this.props.selectedSortId.id,
                label: this.props.selectedSortId.title,
            };
        }

        _.forEach(findSortListItems, function (value) {

            var item = {
                    value: value.id,
                    label: value.title,
                }
            data.push(item)

        });

        return [data, selectedFilter]

    }


    render() {
        const [data, selectedRating] = this.prepareSelectListData()

        return (
            <div style={{display:'flex',alignItems:'center'}}>
                <span>Сначала: </span>
                <div   style = {{width:"220px", fontSize:"16px"}}>
                    <FindReactSelect
                    data={data}
                    // orderNumber={orderNumber}
                    selected={selectedRating}
                    onSelect={this.onSelectListChange}
                    />
                </div>
            </div>
        );
    }

    onSelectListChange(event)
    {
        console.log(event);
        this.props.onSortChanged(event.value)
    }



    onSelectListOpen = () => {
        this.setState({
            isSelectListOpenned: true
        })
    }

    onSelectListClose = () => {
        this.setState({
            isSelectListOpenned: false
        })
    }

}

export default FindMasterSelect