import React from "react"

import SvgIcon from '../../../../../../common/SvgIcon'

class StepItem extends React.Component {
    render() {
        const {article} = this.props
        const body = article.text
        const svgFile = this.props.svgFile
        const svgName = this.props.svgName
            return(
            <div className="steps-item">
                <div className="steps-item__icon-wrap">
                    <SvgIcon file={svgFile} name={svgName}/>
                </div>
                <div className="steps-item__title">{article.title}</div>
                    <div className="steps-item__desc">
                        <p>{body}</p>
                    </div>
                <div className="steps-item__num">{article.id + 1}</div>
            </div>
            )
    }
}

export default StepItem