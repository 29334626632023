import _ from 'lodash';
import CategoryVuuteParser from '../parsers/CategoryVuuteParser';
import ServiceVuute from "../model/ServiceVuute";
import JSONParser from '../../common/parsers/JSONParser'

class ServiceVuuteParser extends JSONParser {
    parse() {
        super.parse()

        let category = new CategoryVuuteParser(_.get(this.data, 'category_vuute')).parse()
        let service = new ServiceVuute(_.get(this.data, 'id'),
                                       category,
                                       _.get(this.data, 'title'),
                                       _.get(this.data, 'min_price'),
                                       _.get(this.data, 'max_price'),
                                       _.get(this.data, 'dimension'))
        
        return service
    }
}

export default ServiceVuuteParser;