import React from 'react'
import _ from 'lodash';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { menuItem } from '../../page/components/MenuEnum';
import { withRouter } from 'react-router-dom'

import ClientMenu from '../../common/clientMenu'
import FindMasterFilter from '../components/FindMasterFilter'
import FindMasterResults from '../components/FindMasterResults'
import Footer from '../../page/components/Footer'

import * as findSelectors from '../../store/find/reducer';
import * as findActions from '../../store/find/actions';

import * as menuActions from '../../store/menu/actions';

import * as vuuteServiceActions from '../../store/vuuteServices/actions';
import * as vuuteServiceSelectors from '../../store/vuuteServices/reducer';

class FindMaster extends React.Component {

  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentWillMount() {

    this.props.dispatch(menuActions.selectMenuItem(menuItem.FIND_MASTER));

    if (this.props.categories.length == 0) {
      this.props.dispatch(vuuteServiceActions.requestCategories());
    }

    if (this.props.cities.length == 0) {
      this.props.dispatch(vuuteServiceActions.requestVuuteCities(1));
    }

    if (this.props.services.length == 0) {
      this.props.dispatch(vuuteServiceActions.requestVuuteServices());
    }

    if (!this.props.results) {
      this.props.dispatch(findActions.applyFilter())
    }

  }

  render() {
    return (
      <div className="page">
        <ClientMenu />
        <FindMasterFilter 
          searchCriteria={this.props.searchCriteria}
          cities={this.props.cities}
          onCityChanged={this.onCityChanged}
          services={this.props.services}
          onServiceAdded={this.onServiceAdded}
          onServiceRemoved={this.onServiceRemoved}
          findRatingListItems={this.props.findRatingListItems}
          onRatingChanged={this.onRatingChanged}
          onNameChanged={this.onNameChanged}
          onHasAttachmentChanged={this.onHasAttachmentChanged}
          onMinPriceChanged={this.onMinPriceChanged}
          onMaxPriceChanged={this.onMaxPriceChanged}
          onSliderValuesChanged={this.onSliderValuesChanged}
          onApplyFiltersClick={this.onApplyFiltersClick}/>
        <FindMasterResults results={this.props.results}
          onProfileItemClick={this.onProfileItemClick}
          searchCriteria={this.props.searchCriteria}
          categories={this.props.categories} 
          onCategoryChanged={this.onCategoryChanged}
          onApplyFiltersClick={this.onApplyFiltersClick}
          findSortListItems={this.props.findSortListItems}
          onSortChanged={this.onSortChanged}
          onLoadMoreClicked={this.onLoadMoreClicked}/>
        <Footer />
      </div>
    );
  }

  onCategoryChanged(categoryId) {
    var newCategoryId = parseInt(categoryId, 10)
    var newCategory = null
    if (!this.props.searchCriteria.findCategory || newCategoryId !== this.props.searchCriteria.findCategory.id){
      _.forEach(this.props.categories, function(category) {
        if (newCategoryId == category.id) {
          newCategory = category
        }
      });
    }
    this.props.dispatch(findActions.changeCategory(newCategory))
  }

  onServiceAdded(serviceId) {
    var newServiceId = parseInt(serviceId, 10)
    var newService = null
    _.forEach(this.props.services, function(service) {
        if (newServiceId == service.id) {
          newService = service
        }
    });
    this.props.dispatch(findActions.addSkill(newService))
  }

  onServiceRemoved(serviceId) {
    var newServiceId = parseInt(serviceId, 10)
    var newService = null
    _.forEach(this.props.services, function(service) {
        if (newServiceId == service.id) {
          newService = service
        }
    });
    this.props.dispatch(findActions.removeSkill(newService))
  }

  onCityChanged(cityId) {
    var newCityId = parseInt(cityId, 10)
    var newCity = null
    _.forEach(this.props.cities, function(city) {
        if (newCityId == city.id) {
            newCity = city
        }
    });
    this.props.dispatch(findActions.changeCity(newCity))
  }

  onRatingChanged(newRatingId) {
    var newRatingItemId = parseInt(newRatingId, 10)
    var newRatingItem = null
    _.forEach(this.props.findRatingListItems, function(item) {
        if (newRatingItemId == item.id) {
          newRatingItem = item
        }
    });
    this.props.dispatch(findActions.changeRate(newRatingItem))
  }

  onSortChanged(newSortId) {
    var newSortItemId = parseInt(newSortId, 10)
    var newSortItem = null
    _.forEach(this.props.findSortListItems, function(item) {
        if (newSortItemId == item.id) {
          newSortItem = item
        }
    });
    this.props.dispatch(findActions.changeSortFilter(newSortItem))
  }

  onMinPriceChanged(event) {
    this.props.dispatch(findActions.changeMinPrice(event.target.value))
  }

  onMaxPriceChanged(event) {
    this.props.dispatch(findActions.changeMaxPrice(event.target.value))
  }

  onSliderValuesChanged(value) {
    this.props.dispatch(findActions.changeMinPrice(value[0].toFixed()))
    this.props.dispatch(findActions.changeMaxPrice(value[1].toFixed()))
  }

  onNameChanged(event) {
    this.props.dispatch(findActions.changeName(event.target.value))
  }

  onHasAttachmentChanged(event) {
    this.props.dispatch(findActions.changeHasAttachment())
  }

  onApplyFiltersClick(event) {
    event.preventDefault();
    this.props.dispatch(findActions.applyFilter(
      findActions.FIND_FILTER_TYPE.FIND_MASTERS, 
      this.props.searchCriteria,
      undefined)
    )
  }

  //TODO: CHECK CORRENT USING. MAYBE WE NEED TO CALL APPLY FILTER
  onLoadMoreClicked(event) {
    event.preventDefault()
    this.props.dispatch(findActions.applyFilter(
      findActions.FIND_FILTER_TYPE.FIND_MASTERS,
      this.props.searchCriteria,
      this.props.initialDateTime)
    )
  }  

  onProfileItemClick(profileId, event) {
    event.preventDefault()
    this.props.history.push(`/profile/${profileId}`)
  }

}

// export default ClientProfileInfoContainer
function mapStateToProps(state) {
  const results = findSelectors.getResults(state);
  const searchCriteria = findSelectors.getSearchCriteria(state);
  const findRatingListItems = findSelectors.getRatingListItems(state);
  const findSortListItems = findSelectors.getSortMasterListItems(state);

  const categories = vuuteServiceSelectors.getAvailableCategories(state);
  const cities = vuuteServiceSelectors.getAvailableVuuteCities(state);
  const services = vuuteServiceSelectors.getAvailableVuuteServices(state);

  const initialDateTime = findSelectors.getFindRequestInitialDatetime(state);
  
  return {
      results,
      searchCriteria,
      initialDateTime,
      findRatingListItems,
      findSortListItems,
      categories,
      cities,
      services,
  }
}

export default withRouter(connect(mapStateToProps)(FindMaster));