import React from 'react'

import EvaluationItem from './EvaluationItem'

import LoginMsgBox from '../../../user/components/LoginMsgBox';

class ReviewItem extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        const reviewError = this.props.reviewError

        return (
            <div className="order-review-frm__main">
                {(reviewError != undefined) ? <LoginMsgBox msgText={reviewError.message} msgType={"error-msg"} /> : ''}
                <div className="form-row">
                    <textarea name="order-review-text" placeholder="Опишите впечатление от сотрудничества" value={this.props.reviewInfo.reviewText} onChange={this.props.onReviewTextChanged} />
                </div>
                <div className="form-row">
                    <div className="order-review-frm__evaluation">
                        <div className="evaluation">
                            <EvaluationItem
                                title={'Качество работы'}
                                rating={this.props.reviewInfo.qualityRate}
                                onRateChanged={this.props.onQualityRateChanged}
                            />
                            <EvaluationItem
                                title={'Соблюдение сроков'}
                                rating={this.props.reviewInfo.deadlineRate}
                                onRateChanged={this.props.onDeadlineRateChanged}
                            />
                            <EvaluationItem
                                title={'Коммуникация'}
                                rating={this.props.reviewInfo.communicationRate}
                                onRateChanged={this.props.onCommunicationRateChanged}
                            />
                        </div>
                    </div>
                </div>
                <div className="order-review-frm__footer">
                    <button className="order-review-frm__submit btn--2" type="submit" onClick={this.props.onCreateReviewClick}>Оставить отзыв</button>
                </div>
            </div>
        );
    }
}

export default ReviewItem