class JSONParser {
    data;
    constructor (data) {
        this.data = data
    }

    parse() {  
        if (this.data === undefined) {
            return null
        } else {
            return this.data
        }
    }
}

export default JSONParser;