import React, {Component} from 'react'

import ReviewFooter from "./ReviewFooter"

class ReviewBody extends Component {
    render () {       
        const desc= this.props.review.desc
        const reviewer = this.props.review.reviewer
        if (!desc | !reviewer)
        {
            return <></>
        }
        return (
            <div className="reviews-item__body">
                <a className="reviews-item__job">
                    {desc.title}
                </a>
                <div className="review">
                    {/* <div className="review__title">
                        {desc.header}
                    </div> */}
                    <div className="review__body">
                        <p>{desc.body}</p>
                    </div>
                    <ReviewFooter cont = {reviewer}/>
                </div>
            </div>
        )
    }
}

export default ReviewBody