
class CountryVuute {
    id;
    title;

    constructor(id, title, country = null) {
        this.id = id
        this.title = title
    }
}

export default CountryVuute;