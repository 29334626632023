import React from 'react'
import { number } from 'prop-types';

import PaginatorItem from './PaginatorItem';

class Paginator extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        var maxPages = 5

        var totalNotes = this.props.totalNotes
        var notesInPage = this.props.notesInPage
        var currentPage = this.props.currentPage

        let totalPages = Math.ceil(totalNotes / notesInPage)
        let startPage = number
        let endPage = number

        if (totalPages < maxPages) {
            maxPages = totalPages
        }

        if (currentPage < 1) {
            currentPage = 1
        } else {
            if (currentPage > totalPages) {
                currentPage = totalPages
            }
        }

        if (totalPages <= maxPages) {
            startPage = 1
            endPage = totalPages
        } else {
            let maxPagesBeforeCurrentPage = Math.ceil(maxPages / 2) - 1
            let maxPagesAfterCurrentPage = Math.floor(maxPages / 2)

            if (currentPage <= maxPagesBeforeCurrentPage) {
                startPage = 1
                endPage = maxPages
            } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
                startPage = totalPages - maxPages + 1
                endPage = totalPages
            } else {
                startPage = currentPage - maxPagesBeforeCurrentPage
                endPage = currentPage + maxPagesAfterCurrentPage
            }
        }

        let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i)
        var pageItems = []

        for (var i = 0; i < maxPages; i++) {
            pageItems.push(
                <PaginatorItem
                    paginatorNumber={pages[i]}
                    isActive={pages[i] === currentPage}
                    onCurrentPageChanged={this.props.onCurrentPageChanged}
                />
            )
        }

        return (
            <ul className="pagination pagination--sm">
                {pageItems}
            </ul>
        );
    }
}

export default Paginator