import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';

import * as popupActions from '../../../../../store/popup/actions';
import * as popupSelectors from '../../../../../store/popup/reducer';

import * as authActions from '../../../../../store/auth/actions';

import * as registerActions from '../../../../../store/register/actions';

class MainPromo extends Component {
	constructor(props) {
		super(props);
		autoBind(this);
	}

	hasTriedRegister() {
		return window.localStorage.getItem('token');
	}

	render() {
		return (
			<div className='main-promo'>
				<div className='main-promo__inner'>
					<div className='main-promo__caption'>
						<h1 className='main-promo__header'>
							<div className='main-promo__title'>
								<span className='accent-text'>Вуюте.рф</span> — удобный способ
								<br /> найти исполнителя и заказчика <br /> для ремонтных работ
								любой сложности
								<div className='main-promo__sub-title'>
									Нанимайте подрядчиков или выполняйте работу!
								</div>
							</div>
						</h1>
						<div className='main-promo__btns'>
							<a
								className='main-promo__btn add-order-btn btn--2 btn--lg js-open-form'
								data-fancybox
								data-src='#reg-popup'
								data-status={0}
								href='#'
								onClick={this.onOrderClick}
							>
								Разместить заказ
							</a>
							<a
								className='main-promo__btn make-account-btn btn--1 btn--lg js-open-form'
								data-fancybox
								data-src='#reg-popup'
								data-status={1}
								href='#'
								onClick={this.onMasterClick}
							>
								Стать исполнителем
							</a>
						</div>
					</div>
				</div>
			</div>
		);
	}

	onOrderClick(event) {
		if (this.hasTriedRegister()) {
			this.props.dispatch(popupActions.showSMSPopup());
			return;
		}
		event.preventDefault();
		this.props.dispatch(authActions.signUpFormTypeSelected());
		this.props.dispatch(registerActions.clientTypeSelected());
		this.props.dispatch(popupActions.showAuthPopup());
	}

	onMasterClick(event) {
		if (this.hasTriedRegister()) {
			this.props.dispatch(popupActions.showSMSPopup());
			return;
		}
		event.preventDefault();
		this.props.dispatch(authActions.signUpFormTypeSelected());
		this.props.dispatch(registerActions.masterTypeSelected());
		this.props.dispatch(popupActions.showAuthPopup());
	}
}

function mapStateToProps(state) {
	const [isLoginPopupHidden, isSMSPopupHidden] =
		popupSelectors.getCurrentPopupState(state);
	return {
		isLoginPopupHidden,
	};
}

export default connect(mapStateToProps)(MainPromo);
