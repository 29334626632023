import React from 'react';
import autoBind from 'react-autobind';
import _ from 'lodash';

import ReactSelect from '../../common/ReactSelect';

class CityList extends React.Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    prepareSelectListData() {
        const vuuteCities = this.props.availableCities;
        var selectedCity = undefined;
        if (
            this.props.selectedCity !== undefined &&
            this.props.selectedCity !== null
        ) {
            selectedCity = {
                value: this.props.selectedCity.id,
                label: this.props.selectedCity.title,
            };
        }

        var data = [];

        _.forEach(vuuteCities, function (value) {
            var item = {
                value: value.id,
                label: value.title,
            };

            data.push(item);
        });

        return [data, selectedCity];
    }

    render() {
        const [data, selectedCity] = this.prepareSelectListData();

        return (
            <React.Fragment>
                <ReactSelect
                    data={data}
                    selected={selectedCity}
                    onSelect={this.onSelectListChange}
                ></ReactSelect>
            </React.Fragment>
        );
    }

    onSelectListChange(event) {
        this.props.onCityChanged(event.value);
    }
}

export default CityList;
