export const CATEGORY_CHANGED = 'find.CATEGORY_CHANGED';

export const CITY_CHANGED = 'find.CITY_CHANGED';
export const MIN_PRICE_CHANGED = 'find.MIN_PRICE_CHANGED';
export const MAX_PRICE_CHANGED = 'find.MAX_PRICE_CHANGED';
export const RATE_CHANGED = 'find.RATE_CHANGED';

export const NAME_CHANGED = 'find.NAME_CHANGED';

export const SKILL_ADDED = 'find.SKILL_ADDED';
export const SKILL_REMOVED = 'find.SKILL_REMOVED';

export const HAS_HISTORY_CHANGED = 'find.HAS_HISTORY_CHANGED';
export const HAS_ATTACHMENT_CHANGED = 'find.HAS_ATTACHMENT_CHANGED';

export const SORT_FILTER_CHANGED = 'find.SORT_FILTER_CHANGED';

export const APPLY_FILTER = 'find.APPLY_FILTER';
export const LOAD_MORE = 'find.LOAD_MORE';