import React from 'react'

import autoBind from 'react-autobind';
import { connect } from 'react-redux';

import JobFormItem from '../components/JobFormItem'

import * as profileActions from '../../store/profile/actions';
import * as profileSelectors from '../../store/profile/reducer';

import LoginMsgBox from '../../user/components/LoginMsgBox';

//TODO: - REFACTOR -> make it as a container

class ExpEdit extends React.Component {
  
  constructor(props) {
    super(props);
    autoBind(this);
  }

  createExperienceTable() {

    let table = []

    if (this.props.modifiedProfile == undefined) {
        return table
    }
    
    const experiences = this.props.modifiedProfile.experience
    
    var counter = 0

    if (!experiences) {
      return table
  }

    experiences.forEach(element => {
        table.push(<JobFormItem experience={element}
          orderNumber={counter}
          onExperienceItemRemoveClick={this.onExperienceItemRemoveClick}
          onExperienceCompanyChanged={this.onExperienceCompanyChanged} 
          onExperiencePositionChanged={this.onExperiencePositionChanged}
          onExperienceStartDateChanged={this.onExperienceStartDateChanged}
          onExperienceEndDateChanged={this.onExperienceEndDateChanged} />)
        counter = counter + 1
    });

    return table
  }

  render() {

    const error = this.props.errorExperience

    return (  
        <React.Fragment>
        {(error !== undefined) ? <LoginMsgBox msgText={error.message} msgType={"error-msg"} /> : ''}
        <form className="experience-frm editable">
          <div className="experience-frm__body">
            {this.createExperienceTable()}
          </div>
          <div className="experience-frm__footer">
            <div>
              <button className="add-exp-btn btn--1 btn--sm btn--flex" type="button" data-limit={5} onClick={this.onAddBtnClick}>Добавить место работы</button>
            </div>
            <div>
              <button className="live-edit-cancel-btn btn--4-2 btn--sm btn--flex" type="button" onClick={this.onCancelClick}>Отменить</button>
              <button className="live-edit-submit-btn btn--2 btn--sm btn--flex" type="button" onClick={this.onSaveClick}><span className="mobile-text" >Сохранить</span><span>Сохранить изменения</span></button>
            </div>
          </div>
        </form>
        </React.Fragment>
    );
  }

  onAddBtnClick(event) {
    event.preventDefault();
    this.props.dispatch(profileActions.addProfileExperience())
  }

  onCancelClick(event) {
    event.preventDefault();
    this.props.dispatch(profileActions.clearSaveExperienceErrors())
    this.props.dispatch(profileActions.discardChanges())
  }

  onSaveClick(event) {
    //TODO: FINISH AND API CALL HERE
    event.preventDefault();
    this.props.dispatch(profileActions.clearSaveExperienceErrors())
    this.props.dispatch(profileActions.saveExperience(this.props.modifiedProfile))
  }

  onExperienceItemRemoveClick(event) {
    event.preventDefault();
    this.props.dispatch(profileActions.clearSaveExperienceErrors())
    this.props.dispatch(profileActions.deleteProfileExperience(event.currentTarget.value))
  }

  onExperienceCompanyChanged(orderNumber, event) {
    this.props.dispatch(profileActions.changeExperienceCompany(orderNumber, event.target.value))
  }

  onExperiencePositionChanged(orderNumber, event) {
    this.props.dispatch(profileActions.changeExperiencePosition(orderNumber, event.target.value))
  }

  onExperienceStartDateChanged(orderNumber, event) {
    this.props.dispatch(profileActions.changeExperienceStartDate(orderNumber, event))
  }

  onExperienceEndDateChanged(orderNumber, event) {
    this.props.dispatch(profileActions.changeExperienceEndDate(orderNumber, event))
  }

}

// export default ClientProfileInfoContainer
function mapStateToProps(state) {
  const modifiedProfile = profileSelectors.getModifiedProfile(state);
  const errorExperience = profileSelectors.getExperienceSavingError(state);
  return {
    modifiedProfile,
    errorExperience,
  }
}

export default connect(mapStateToProps)(ExpEdit);