class JobState {
    static MATCHING = new JobState('MATCHING')
    static ACCEPTED = new JobState('ACCEPTED')
    static REJECTED = new JobState('REJECTED')
    static ON_REVIEW = new JobState('ON_REVIEW')
    static CANCELED = new JobState('CANCELED')
    static CLOSED = new JobState('CLOSED')
    static RETURN = new JobState('RETURN')

    constructor(statusTitle) {
        this.statusTitle = statusTitle
    }

    toString() {
        return this.statusTitle;
    }

    toRequestString() {
        if (this.statusTitle == JobState.ACCEPTED.toString()) {
            return 'ACCEPT'.toLowerCase()
        } else if (this.statusTitle == JobState.REJECTED.toString()) {
            return 'REJECT'.toLowerCase()
        } else if (this.statusTitle == JobState.ON_REVIEW.toString()) {
            return 'ON_REVIEW'.toLowerCase()
        } else if (this.statusTitle == JobState.RETURN.toString()) {
            return 'RETURN'.toLowerCase()
        } else if (this.statusTitle == JobState.CLOSED.toString()) {
            return 'CLOSE'.toLowerCase()
        }
    }
}

export default JobState;