import React from 'react'
import { Switch, Route } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'


import MainPage from '../page/components/main/MainPage'
import ProfilePageContainer from '../profile/containers/ProfilePageContainer'
import Page404 from '../page/components/Page404'
import CreateOrder from '../order/containers/CreateOrderContainer'
import CheckOrder from '../order/containers/CheckOrderContainer'
import Job from '../jobs/containers/CheckJobContainer'
import MyJobs from '../myJobs/containers/MyJobsContainer'
import ReviewPage from '../jobs/containers/ReviewContainer'
import Notifications from '../notifications/containers/NotificationsContainer'

import ChatContainer from '../chat/containers/ChatContainer'

import FindMaster from '../find/containers/FindMaster'
import FindOrder from '../find/containers/FindOrder'
import Settings from '../settings/containers/SettingsContainer'



// The Main component renders one of the three provided
// Routes (provided that one matches). Both the /roster
// and /schedule routes will match any pathname that starts
// with /roster or /schedule. The / route will only match
// when the pathname is exactly the string "/"
const VuuteRoute = () => (
  <main>
    <Switch>
      <Route exact path='/' component={MainPage}/>
      <PrivateRoute path='/profile' component={ProfileRoute}/>
      <PrivateRoute path='/chat' component={ChatContainer}/>
      <PrivateRoute path='/order/:id' component={CheckOrder}/>
      <PrivateRoute path='/job/:id' component={Job}/>
      <PrivateRoute path='/create' component={CreateOrder}/>
      <PrivateRoute path='/order_find' component={FindOrder}/>
      <PrivateRoute path='/review/:id' component={ReviewPage}/>
      <PrivateRoute path='/profile_find' component={FindMaster}/>
      <PrivateRoute path='/notifications' component={Notifications}/>
      <PrivateRoute path='/settings' component={Settings}/>
      <PrivateRoute path='/my_jobs' component={MyJobs}/>
      <Route path="*" component={Page404} /> 
    </Switch>
  </main>
)

const ProfileRoute = () => (
  <Switch>
    <PrivateRoute exact path='/profile' component={ProfilePageContainer}/>
    <PrivateRoute path='/profile/:id' component={ProfilePageContainer}/>
  </Switch>
)

export default VuuteRoute
