import React from 'react'

class ClientDescription extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {

    let description = ''

    if (this.props.bio) {

      const incomeDescr = this.props.bio.description

      if (incomeDescr != undefined) {
        if (!incomeDescr.toString().length) {
          const firstName = this.props.bio.firstName
          description = firstName + " обещал найти немного времени и заполнить свой профиль!"
        } else {
          description = incomeDescr
        }
      }

      

    }

    return (  
        <section className="user-card-section">
            <div className="not-editable">
              <p>{description}</p>
            </div>
        </section>
    );
  }
}

export default ClientDescription