import React from 'react'
import AsyncSelect from 'react-select/async'
import _ from 'lodash'

import GoogleApiWrapper from './GoogleMapApi';

class Location extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {

        const filterColors = () => {
            var options = []
            if (this.props.orderCheckedAddress.address) {
                return options = [{
                    value: this.props.orderCheckedAddress.address,
                    label: this.props.orderCheckedAddress.address
                }]
            } else {
                return options = [{ value: '', label: 'Адрес не найден' }]
            }
        };

          
        const loadOptions = (inputValue, callback) => {
            setTimeout(() => {
                callback(filterColors());
            }, 1000);
        };

    
    

        return (
            <React.Fragment>
                {this.props.isEditable ? (
                    <div className="option-wrap">
                        <label htmlFor="order-skills">Геолокация <span className="imp">*</span></label>
                        <AsyncSelect
                            className="placepicker js-data-input js-input-location"
                            id="order-location-adr"
                            type="text"
                            name="order-location-adr"
                            // cacheOptions={true}
                            // defaultOptions
                            // inputValue={this.props.orderAddress}
                            // defaultValue={this.props.orderAddress}
                            placeholder={'Введите адрес'}
                            inputValue={this.props.orderAddress}                                                        
                            onInputChange={(inputAddress) => this.props.onAddressChanged(inputAddress)}
                            loadOptions={loadOptions}
                            // components={{ DropdownIndicator:() => null }}
                            components={{ DropdownIndicator: () => null }}
                            noOptionsMessage={() => null}
                            blurInputOnSelect={false}
                            hideSelectedOptions={true}

                        />
                        <GoogleApiWrapper
                            latitude={this.props.orderCoords.lat}
                            longtitude={this.props.orderCoords.lng}
                            onLocationChanged={this.props.onLocationChanged}
                            isEditable={this.props.isEditable}
                        />
                    </div>
                ) : (
                        <section className="order-section">
                            <h2 className="order-section__title">{this.props.orderInfo.geolocation.address}</h2>
                            <input
                                className="placepicker"
                                id="order-location-adr"
                                type="hidden"
                                name="order-location-adr"
                            />
                            <GoogleApiWrapper
                                latitude={this.props.orderInfo.geolocation.latitude}
                                longtitude={this.props.orderInfo.geolocation.longitude}
                                isEditable={this.props.isEditable}
                            />
                        </section>
                    )}
            </React.Fragment>
        )
    }
}

export default Location