import React from 'react'

import SvgIcon from './SvgIcon'

class IconClose extends React.Component {
  render() {
    return (
      <button data-fancybox-close className='fancybox-close-small' title='Закрыть' onClick={this.props.onClick}>
        <SvgIcon name='icon-close' />
      </button>
    )
  }
}

export default IconClose
