import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import InputMask from 'react-input-mask';

import * as loginActions from '../../store/login/actions';
import * as loginSelectors from '../../store/login/reducer';

import * as popupActions from '../../store/popup/actions';

import * as authActions from '../../store/auth/actions';
import * as authSelectors from '../../store/auth/reducer';

import AuthService from '../../services/AuthService';

import LoginMsgBox from '../components/LoginMsgBox';

class LoginContainer extends Component {

    constructor(props) {
        super(props);
        autoBind(this);
    }


    render() {

        const currentUser = AuthService.shared().getUser()

        if (currentUser) {
            if (currentUser.isVerified === false) {
                this.props.dispatch(popupActions.showSMSPopup())
            } else {
                this.props.dispatch(popupActions.hidePopup())
                return <Redirect to='/profile' />
            }
        }

        const apiError1 = this.props.apiError
        const isPasswordRecoevered1 = this.props.recoverStatus

        return <React.Fragment>
            <form className="popup-frm ajax-form">
                <div className="popup__inner">
                    {(apiError1 != null) ? <LoginMsgBox msgText={apiError1.message} msgType={"error-msg"} /> : ''}
                    {(isPasswordRecoevered1 == true) ? <LoginMsgBox msgText={'Новый пароль отправлен на Ваш номер телефона'} msgType={"info-msg"} /> : ''}
                    <div className="input-holder">
                        <InputMask
                            className="is-required js-phone js-data-input"
                            id="login-phone"
                            type="tel"
                            name="login-phone"
                            placeholder="Телефон"
                            mask="+7 (999)-999-99-99"
                            value={this.props.phoneNumber}
                            onChange={this.onPhoneChanged}>
                        </InputMask>
                    </div>
                    <div className="password-holder">
                        <input className="is-required" id="login-pass" type="password" name="login-pass" value={this.props.password} onChange={this.onPasswordChanged} placeholder="Пароль">
                        </input>
                        <a className="forgot-link" href="javascript:;" onClick={this.handleForgotPasswordClick}>Напомнить</a>
                    </div>
                </div>
                <button className="popup-frm__submit btn--2 popup__wide-btn" onClick={(e) => {
                    this.onLoginClick(e)
                    this.props.onClick()
                    }}>Войти</button>
            </form>
        </React.Fragment>
    }

    

    onPhoneChanged(event) {
        this.props.dispatch(loginActions.changePhoneNumber(event.target.value))
    }

    onPasswordChanged(event) {
        this.props.dispatch(loginActions.changePassword(event.target.value));
    }

    handleForgotPasswordClick() {
        this.props.dispatch(loginActions.clearLoginErrors())
        this.props.dispatch(loginActions.forgotPasswordRequested(this.props.phoneNumber))
    }

    onLoginClick(event) {
        event.preventDefault();
        this.props.dispatch(loginActions.clearLoginErrors())
        this.props.dispatch(authActions.login(this.props.phoneNumber, this.props.password));
    }

}

function mapStateToProps(state) {
    const [phoneNumber, password] = loginSelectors.getCredentials(state);
    const [user, token, error] = authSelectors.getUser(state);
    var apiError = error
    if ((apiError === undefined) || (apiError === null)) {
        apiError = loginSelectors.getError(state);
    }

    const recoverStatus = loginSelectors.getRecoverStatus(state)

    return {
        phoneNumber,
        password,
        user,
        token,
        apiError,
        recoverStatus,
    };
}

export default connect(mapStateToProps)(LoginContainer);