import React from 'react'

import SelectList from './SelectList'

class CitySelector extends React.Component {

  render() {
    return (
        <div className="person-info__props editable">
            <SelectList
                className="js-select"
                id="master-profile-city"
                name="master-profile-city"
                options={['Москва', 'Санкт-Петербург', 'Краснодар', 'Ростов-на-Дону', 'Нижний Новгород', 'Белгород']}
                multiple={false}
            />
        </div>
    );
  }
}

export default CitySelector