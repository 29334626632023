import _ from 'lodash';
import JSONParser from '../../common/parsers/JSONParser';
import CityVuuteParser from '../../vuuteServices/parsers/CityVuuteParser';
import ClientBio from '../model/ClientBio';
import MasterBio from '../model/MasterBio';
import ProfileBio from '../model/ProfileBio';

class ProfileBioParser extends JSONParser {
    
    parse() {
        super.parse()

        let city = new CityVuuteParser(_.get(this.data, 'city')).parse()


        var createdDate = new Date(_.get(this.data, 'created'))

        let profileBio = new ProfileBio(_.get(this.data, 'id'),
                                        _.get(this.data, 'first_name'),
                                        _.get(this.data, 'last_name'),
                                        _.get(this.data, 'middle_name'),
                                        _.get(this.data, 'profile_type'),
                                        _.get(this.data, 'photo_url'),
                                        _.get(this.data, 'rate'),
                                        city,
                                        createdDate)

        if (profileBio.profileType == 'CLIENT') {
            let clientBio = new ClientBio(profileBio.id,
                                          profileBio.firstName,
                                          profileBio.lastName,
                                          profileBio.middleName,
                                          profileBio.photoUrl,
                                          profileBio.rate,
                                          profileBio.city,
                                          profileBio.created,
                                          _.get(this.data, 'profile_description'))
            return clientBio
        } else {
            let masterBio = new MasterBio(profileBio.id,
                                          profileBio.firstName, 
                                          profileBio.lastName,  
                                          profileBio.middleName,
                                          profileBio.photoUrl,
                                          profileBio.rate,
                                          profileBio.city,
                                          profileBio.created)
            return masterBio
        }
        

    }
}

export default ProfileBioParser;