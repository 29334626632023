import React from 'react'
import autoBind from 'react-autobind';
import _ from 'lodash';

import SelectList from '../../common/SelectList'

class FindOrderSkills extends React.Component {

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      isSelectListOpenned: false
    }
  }

  prepareSelectListData() {

    const services = this.props.services
    const selectedServices = this.props.searchCriteria.findServices

    //prepare selected services ids
    var selectedServicesIds = []
    _.forEach(selectedServices, function(service) {
      selectedServicesIds.push(service.id)
    })


    var data = []

    _.forEach(services, function (service) {

      var item = {}

      if (selectedServicesIds.includes(service.id)) {
        item = {
          id: service.id,
          text: service.title,
          selected: true,
        }
      } else {
        item = {
          id: service.id,
          text: service.title,
        }
      }


      data.push(item)

    });

    return [data, selectedServicesIds]

  }




  render() {

    const [data, selectedServicesIds] = this.prepareSelectListData()

    return (
      <React.Fragment>
        <SelectList
          data={data}
          selectedId={selectedServicesIds}
          multiple={true}
          onSelectListChange={this.onSelectListChange}
          onSelectListUnselect={this.onSelectListUnselect}
          onSelectListOpen={this.onSelectListOpen}
          onSelectListClose={this.onSelectListClose}
        />
      </React.Fragment>
    );
  }

  onSelectListChange(inputId, event) {
    if (this.state.isSelectListOpenned) {
      return
    }

    this.props.onServiceAdded(event.params.data.id)
  }

  onSelectListUnselect(inputId, event) {
    if (this.state.isSelectListOpenned) {
      return
    }

    this.props.onServiceRemoved(event.params.data.id)
  }



  onSelectListOpen = () => {
    this.setState({
      isSelectListOpenned: true
    })
  }

  onSelectListClose = () => {
    this.setState({
      isSelectListOpenned: false
    })
  }

}

export default FindOrderSkills