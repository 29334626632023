import _ from 'lodash';
import ServiceVuuteParser from '../parsers/ServiceVuuteParser';

import JSONParser from '../../common/parsers/JSONParser'

class ServicesVuuteParser extends JSONParser {
    parse() {
        let data = super.parse()
        if (data === null) { return null }

        let services = []
        _.forEach(this.data, function(serviceData) {
            let service = new ServiceVuuteParser(serviceData).parse()
            services.push(service)
        });
        

        return services
    }
}

export default ServicesVuuteParser;