class Message {
    id;
    message;
    sender;
    isRead;
    created;

    constructor(id, message, sender, isRead, created) {
        this.id = id
        this.message = message
        this.sender = sender
        this.isRead = isRead
        this.created = created
    }
}

export default Message;