import _ from 'lodash';
import * as myJobsTypes from './actionTypes';
import apiService from '../../services/APIService';

export function getMyJobsInfo() {
    return async (dispatch, getState) => {
        try {
            const jobsData = await apiService.getMyJobsInfo();
            dispatch({ type: myJobsTypes.INCOME_JOBS_CHANGED, incomeJobs: jobsData.incomeJobs })
            dispatch({ type: myJobsTypes.ACTIVE_JOBS_CHANGED, activeJobs: jobsData.activeJobs })
            dispatch({ type: myJobsTypes.CLOSED_JOBS_CHANGED, closedJobs: jobsData.closedJobs })
        } catch (error) {
            console.error(error)
        }
    }
}