import _ from 'lodash';
import JobParser from './JobParser';

import JSONParser from '../../common/parsers/JSONParser'


class JobsParser extends JSONParser {
    parse() {
        let data = super.parse()
        if (data === null) { return null }


        let jobs = []
        _.forEach(this.data, function(jobData) {
            let job = new JobParser(jobData).parse()
            jobs.push(job)
        });
        

        return jobs
    }
}

export default JobsParser;