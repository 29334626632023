import React from 'react'

import FindOrderPrice from './FindOrderPrice'
import FindMasterRating from './FindMasterRating'
import FindOrderSkills from './FindOrderSkills'

import CityList from '../../profile/components/CityList'

class FindOrderFilter extends React.Component {

  render() {

    const searchCriteria = this.props.searchCriteria

    return (
        <div className="catalog-filter-wrap bg-block">
            <div className="container">
                <div className="catalog-filter orders-filter">
                    <form className="catalog-filter-frm orders-filter-frm">
                        <div className="form-row">
                            <div className="option-wrap">
                                <label htmlFor="orders-filter-region">Регион</label>
                                <CityList availableCities={this.props.cities}
                                    selectedCity={searchCriteria.findCity}
                                    onCityChanged={this.props.onCityChanged} />
                            </div>
                            <FindOrderPrice 
                                searchCriteria={searchCriteria}
                                onSliderValuesChanged={this.props.onSliderValuesChanged}
                                onMaxPriceChanged={this.props.onMaxPriceChanged}
                                onMinPriceChanged={this.props.onMinPriceChanged} />
                            <div className="option-wrap">
                                    <label htmlFor="masters-filter-rating">Рейтинг заказчика</label>
                                    <FindMasterRating findRatingListItems={this.props.findRatingListItems}
                                        selectedRatingId={searchCriteria.findRating}
                                        onRatingChanged={this.props.onRatingChanged}/>
                                </div>
                                <div className="check-group">
                                <div className="check-wrap">
                                    <input id="orders-filter-only-old" type="checkbox" name="orders-filter-only-old" 
                                        checked={searchCriteria.findHasAttachments}
                                        onChange={this.props.onHasAttachmentChanged}/>
                                    <label className="check-label-lg" htmlFor="orders-filter-only-old"><span />
                                        <div>Только заказчики с историей</div>
                                    </label>
                                </div>
                                {/* <div className="check-wrap">
                                    <input id="orders-filter-only-attachment" type="checkbox" name="orders-filter-only-attachment" disabled/>
                                    <label className="check-label-lg" htmlFor="orders-filter-only-attachment"><span />
                                        <div>C вложенными файлами</div>
                                    </label>
                                </div> */}
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="option-wrap">
                                    <label htmlFor="masters-filter-skills">Необходимые навыки</label>
                                    <FindOrderSkills
                                        searchCriteria={searchCriteria}
                                        services={this.props.services}
                                        onServiceAdded={this.props.onServiceAdded}
                                        onServiceRemoved={this.props.onServiceRemoved} />
                            </div>
                            <button className="orders-filter-frm__submit btn--2" type="submit" 
                            onClick={this.props.onApplyFiltersClick}>Найти заказ</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
  }
}

export default FindOrderFilter