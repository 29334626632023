import Immutable from 'seamless-immutable';
import _ from 'lodash';
import * as notificationTypes from './actionTypes';

const initialState = Immutable({
    unread: 0,
    notifications: [],
    currentPaginatorPage: 1,
    error: null,
})

export default function reduce(state = initialState, action = {}) {

    switch (action.type) {
        case notificationTypes.GET_NOTIFICATIONS:
            return state.merge({
                unread: action.unread,
                notifications: action.notifications,
                error: action.error,
            })

        case notificationTypes.READ_ALL_NOTIFICATIONS:
            var mutableState = Immutable.asMutable(state, { deep: true });
            var notifications = mutableState.notifications
            for (var i = 0; i < notifications.length; i++) {
                notifications[i].isRead = true
            }

            return state.merge({
                unread: 0,
                notifications: notifications
            })

        case notificationTypes.READ_SINGLE_NOTIFICATION:
            var mutableState = Immutable.asMutable(state, { deep: true });
            var unread = mutableState.unread
            var unread = unread - 1

            var notifications = mutableState.notifications
            for (var i = 0; i < notifications.length; i++) {
                if (notifications[i].id === action.notificationId) {
                    notifications[i].isRead = true
                }
            }

            return state.merge({
                unread: unread,
                notifications: notifications
            })

        case notificationTypes.CHANGE_CURRENT_PAGE:
            return state.merge({
                currentPaginatorPage: action.newCurrentPage
            })


        default:
            return state;
    }
}

//selectors
export function getNotifications(state) {
    const unread = state.notification.unread;
    const notifications = state.notification.notifications
    const currentPaginatorPage = state.notification.currentPaginatorPage
    return [unread, notifications, currentPaginatorPage];
}