import React from 'react'
import _ from 'lodash';

import autoBind from 'react-autobind';
import { connect } from 'react-redux';

import Menu from '../../page/components/Menu'
import JobView from '../components/JobView'
import Page404 from '../../page/components/Page404';

import Popup from '../components/popups/Popup'

import Footer from '../../page/components/Footer'

import * as jobActions from '../../store/job/actions'
import * as jobSelectors from '../../store/job/reducer'

import { withRouter } from 'react-router-dom'


class CheckJobContainer extends React.Component {

    constructor(props) {
        super(props);
        autoBind(this);
    }

    componentDidMount() {
        this.props.dispatch(jobActions.getJobInfoById(this.props.match.params.id))
    }

    render() {
        if (this.props.jobInfo) {
            return (
                <React.Fragment>
                    <div className="page">
                        <Menu />
                        <JobView
                            jobInfo={this.props.jobInfo}
                            onMasterConfirmationClick={this.onMasterConfirmationClick}
                            onMessageButtonClick={this.onMessageButtonClick}
                            onReviewConfirmationClick={this.onReviewConfirmationClick}
                            onReturnConfirmationClick={this.onReturnConfirmationClick}
                            onCloseConfirmationClick={this.onCloseConfirmationClick}
                        />
                        <Footer />
                    </div>
                    <React.Fragment>
                        {this.props.isBidJobConfirmationPopupOpen &&
                            <Popup title={`Выбрать мастера`} question={`Выбрать мастера на выполнение заказа?`} onClose={this.onMasterConfirmationCloseClick} onSubmit={this.onMasterConfirmationAcceptClick} />
                        }
                    </React.Fragment>
                    <React.Fragment>
                        {this.props.isOnReviewJobConfirmationPopupOpen &&
                            <Popup title={`Подтверждение выполнения заказа`} question={`Подтвердить выполнение заказа?`} onClose={this.onReviewConfirmationCloseClick} onSubmit={this.onReviewConfirmationAcceptClick} />
                        }
                    </React.Fragment>
                    <React.Fragment>
                        {this.props.isReturnJobConfirmationPopupOpen &&
                            <Popup title={`Возврат заказа на доработку`} question={`Вернуть заказ на доработку?`} onClose={this.onReviewConfirmationCloseClick} onSubmit={this.onReviewConfirmationAcceptClick} />
                        }
                    </React.Fragment>
                    <React.Fragment>
                        {this.props.isCloseJobConfirmationPopupOpen && <Popup title={`Завершение работы`} question={`Закрыть работу?`} onClose={this.onCloseConfirmationCloseClick} onSubmit={this.onCloseConfirmationAcceptClick} />}
                    </React.Fragment>
                </React.Fragment>
            )
        } else {
            if (this.props.error) {
                return <Page404 />
            } else {
                return <div></div>
            }
            // return <div></div>
            // return <Page404 />
        }
    }

    onMasterConfirmationClick(e) {
        e.preventDefault()
        this.props.dispatch(jobActions.bidJobConfirmationOpen())
    }

    onMasterConfirmationCloseClick(e) {
        e.preventDefault()
        this.props.dispatch(jobActions.bidJobConfirmationClose())
    }

    onMasterConfirmationAcceptClick(e) {
        e.preventDefault()
        this.props.dispatch(jobActions.bidJobConfirmationAccept(this.props.jobInfo.id))
    }

    onMessageButtonClick(e) {
        e.preventDefault()
        this.props.history.push('/chat')
    }

    onReviewConfirmationClick(e) {
        e.preventDefault()
        this.props.dispatch(jobActions.onReviewConfirmationOpen())
    }
    onReviewConfirmationCloseClick(e) {
        e.preventDefault()
        this.props.dispatch(jobActions.onReviewConfirmationClose())
    }
    onReviewConfirmationAcceptClick(e) {
        e.preventDefault()
        this.props.dispatch(jobActions.onReviewConfirmationAccept(this.props.match.params.id))
    }

    onReturnConfirmationClick(e) {
        e.preventDefault()
        this.props.dispatch(jobActions.returnConfirmationOpen())
    }

    onReturnConfirmationCloseClick(e) {
        e.preventDefault()
        this.props.dispatch(jobActions.returnConfirmationClose())
    }

    onReturnConfirmationAcceptClick(e) {
        e.preventDefault()
        this.props.dispatch(jobActions.returnConfirmationAccept(this.props.match.params.id))
    }

    onCloseConfirmationClick(e) {
        e.preventDefault()
        this.props.dispatch(jobActions.closeConfirmationOpen())
    }

    onCloseConfirmationCloseClick(e) {
        e.preventDefault()
        this.props.dispatch(jobActions.closeConfirmationClose())
    }

    onCloseConfirmationAcceptClick(e) {
        e.preventDefault()
        this.props.dispatch(jobActions.closeConfirmationAccept(this.props.match.params.id))
        this.props.history.push(`/review/${this.props.jobInfo.id}`)
    }
}

function mapStateToProps(state) {
    const [jobInfo, error] = jobSelectors.getJobInfo(state)

    const [isBidJobConfirmationPopupOpen, isRejectedJobConfirmationPopupOpen, isOnReviewJobConfirmationPopupOpen,
        isReturnJobConfirmationPopupOpen, isCloseJobConfirmationPopupOpen] = jobSelectors.getCurrentJobPopupState(state);

    return {
        jobInfo,
        error,
        isBidJobConfirmationPopupOpen,
        isRejectedJobConfirmationPopupOpen,
        isOnReviewJobConfirmationPopupOpen,
        isReturnJobConfirmationPopupOpen,
        isCloseJobConfirmationPopupOpen
    }
}

export default withRouter(connect(mapStateToProps)(CheckJobContainer));