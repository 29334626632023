import _ from 'lodash';
import EducationVuuteParser from '../parsers/EducationVuuteParser';

import JSONParser from '../../common/parsers/JSONParser'

class EducationsVuuteParser extends JSONParser {
    parse() {
        let data = super.parse()
        if (data === null) { return null }

        let educations = []
        _.forEach(this.data, function(educationData) {
            let education = new EducationVuuteParser(educationData).parse()
            educations.push(education)
        });
        

        return educations
    }
}

export default EducationsVuuteParser;