import Immutable from 'seamless-immutable';
import _ from 'lodash';
import * as geolocationTypes from './actionTypes';

const initialState = Immutable({
    inputAddress: undefined,

    checkedAddress: {
        address: undefined,
        locationId: undefined,
        country: undefined,
        city: undefined,
        street: undefined,
        houseNumber: undefined,
        postalCode: undefined,
    },

    checkedCoords: {
        lat: undefined,
        lng: undefined,
    },

})

export default function reduce(state = initialState, action = {}) {

    switch (action.type) {
        case geolocationTypes.CLEAR_STORE:
            return state.merge({
                inputAddress: undefined,

                checkedAddress: {
                    address: undefined,
                    locationId: undefined,
                    country: undefined,
                    city: undefined,
                    street: undefined,
                    houseNumber: undefined,
                    postalCode: undefined,
                },
                
                checkedCoords: {
                    lat: undefined,
                    lng: undefined
                }
            })

        case geolocationTypes.ADDRESS_CHANGED:
            return state.merge({
                inputAddress: action.inputAddress,
            })

        case geolocationTypes.GET_ADDRESS_BY_INPUT_DATA:
            return state.merge({

                checkedAddress: {
                    address: action.availableAddress.address,
                    locationId: action.availableAddress.locationId,
                    country: action.availableAddress.country,
                    city: action.availableAddress.city,
                    street: action.availableAddress.street,
                    houseNumber: action.availableAddress.houseNumber,
                    postalCode: action.availableAddress.postalCode,
                },

                checkedCoords: {
                    lat: action.newLatitude,
                    lng: action.newLongtitude,
                },
            })

        case geolocationTypes.LOCATION_CHANGED:
            return state.merge({
                inputAddress: action.newInputAddress,

                checkedAddress: {
                    address: action.availableAddresses.Address.Label,
                    locationId: action.availableAddresses.LocationId,
                    country: action.availableAddresses.Address.Country,
                    city: action.availableAddresses.Address.City,
                    street: action.availableAddresses.Address.Street,
                    houseNumber: action.availableAddresses.Address.HouseNumber,
                    postalCode: action.availableAddresses.Address.PostalCode,
                },

                checkedCoords: {
                    lat: action.newLatitude,
                    lng: action.newLongtitude,
                },
            })

                
        case geolocationTypes.IMPORT_ORDER_LOCATION:
            return state.merge({
                inputAddress: action.inputAddress,

                checkedCoords: {
                    lat: action.latitude,
                    lng: action.longtitude,
                },
            })    

        default:
            return state;
    }
}

//selectors
export function getGeolocationInfo(state) {
    const inputAddress = state.geolocation.inputAddress
    const checkedAddress = state.geolocation.checkedAddress
    const checkedCoords = state.geolocation.checkedCoords
    return [inputAddress, checkedAddress, checkedCoords]
}