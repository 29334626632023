import * as vuuteTypes from './actionTypes';
import { async } from 'q';
import apiService from '../../services/APIService';


export function requestVuuteServices() {
    return async(dispatch, getState) => {
        try {
            const servicesData = await apiService.getVuuteServices();
            dispatch({type: vuuteTypes.REQUEST_SERVICES, services: servicesData.services});
        } catch (error) {
            console.error(error)
        }
    }
}


export function requestVuuteCountries() {
    return async(dispatch, getState) => {
        try {
            const countriesData = await apiService.getVuuteCountries();
            dispatch({type: vuuteTypes.REQUEST_COUNTRIES, countries: countriesData.countries});
        } catch (error) {
            console.error(error)
        }
    }
}


export function requestVuuteCities(countryId) {
    return async(dispatch, getState) => {
        try {
            const citiesData = await apiService.getVuuteCities(countryId);
            dispatch({type: vuuteTypes.REQUEST_CITIES, cities: citiesData.cities});
        } catch (error) {
            console.error(error)
        }
    }
}


export function requestVuuteEducations(cityId, queryString) {
    return async(dispatch, getState) => {
        try {
            const educationsData = await apiService.getVuuteEducationObjects(cityId, queryString);
            dispatch({type: vuuteTypes.REQUEST_EDUCATION_OBJECTS, educationObjects: educationsData.educations});
        } catch (error) {
            console.error(error)
        }
    }
}


export function requestCategories() {
    return async(dispatch, getState) => {
        try {
            const сategoriesData = await apiService.getCategories();
            dispatch({type: vuuteTypes.REQUEST_CATEGORIES, categories: сategoriesData.categories});
        } catch (error) {
            console.error(error)
        }
    }
}