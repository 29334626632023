

class APIError {
    message;
    errorCode;

    constructor(message, errorCode = '') {
        this.message = message
        this.errorCode = errorCode
    }
}

export default APIError;