import Immutable from 'seamless-immutable';
import _ from 'lodash';
import * as profileTypes from './actionTypes';

import DateService from '../../services/DateService'
import Profile from '../../profile/model/Profile'
import ClientBio from '../../profile/model/ClientBio';
import MasterBio from '../../profile/model/MasterBio';

import APIError from '../../common/model/APIError';

const initialState = Immutable({
    error: null,
    profile: undefined,
    modifiedProfile: undefined,

    errorBio: undefined,
    errorServices: undefined,

    isSelectListOpenned: false,
    isEditModeEnabled: false,
    isEducationEditModeEnabled: false,
    isExperienceEditModeEnabled: false,

    isEducationFormIsHidden: false,

    errorExperience: undefined,

    selectedCountry: undefined,
    selectedCity: undefined,
    selectedEducationObject: undefined,
    selectedProfileEducationOrderNumber: undefined,
    newEducation: {
        speciality: undefined,
        dateStarted: undefined,
        dateFinished: undefined,
    },

    errorEducation: undefined,

    orders: {
        newOrders: undefined,
        activeOrders: undefined,
        closedOrders: undefined,
    },

    photoFile: undefined,
    photoUrl: '',

    incomeJobs: [],
    activeJobs: [],
    closedJobs: [],

})

export default function reduce(state = initialState, action = {}) {

    switch(action.type) {
        case profileTypes.CREATE_PROFILE:
            return state.merge({
                profile: action.profile,
                modifiedProfile: action.profile,
                error: action.error
            });
        case profileTypes.GET_PROFILE:
            return state.merge({
                profile: action.profile,
                modifiedProfile: action.profile,
                error: action.error
            });
        case profileTypes.SAVE_PROFILE:
            if (action.bioError === undefined) {

                // profile bio saved success
                // use already existed services
                let updatedProfile = new Profile(action.bio,
                    state.profile.education,
                    state.modifiedProfile.services,
                    state.profile.experience)

                if (action.servicesError === undefined) {
                    // services also saved success 
                    // update already saved services
                    updatedProfile.services = action.services
                }
                
                let isEditModeEnabled = true
                if (action.bioError === undefined && action.servicesError === undefined) {
                    isEditModeEnabled = false
                }

                // if no error -> update profiles and disable edit mode
                if (isEditModeEnabled === false) {
                    return state.merge({
                        profile: updatedProfile,
                        modifiedProfile: updatedProfile,
                        isEditModeEnabled: isEditModeEnabled,
                        errorBio: undefined,
                        errorServices: undefined,
                    })
                } else {
                    // else -> update only errors
                    return state.merge({
                        errorBio: undefined,
                        errorServices: action.servicesError,
                    })
                }
                
                
            } else {
                return state.merge({
                    errorBio: action.bioError, 
                })
            }      
        
        case profileTypes.CLEAR_SAVE_BIO_FORM_ERRORS:

            return state.merge({
                errorBio: undefined, 
            })

        case profileTypes.SAVE_EDUCATION:

            if (action.error === null) {
                let updatedProfile = new Profile(state.profile.bio,
                                                action.educations,
                                                state.profile.services,
                                                state.profile.experience)
                return state.merge({
                    profile: updatedProfile,
                    modifiedProfile: updatedProfile,
                    isEducationEditModeEnabled: false,
                })
            } else {
                return state.merge({
                    error: action.error, 
                })
            }  


            // const educations = action.educations

            // var mutableState = Immutable.asMutable(state, {deep: true});
            // var profile = mutableState.profile
            // profile.education = educations
            // return state.merge({
            //     profile: profile,
            //     modifiedProfile: profile,
            //     isEducationEditModeEnabled: false,
            // })

        case profileTypes.EDIT_MODE_ENABLE:
            return state.merge({
                isEditModeEnabled: true,
            })
        case profileTypes.EDIT_MODE_DISABLE:
            return state.merge({
                isEditModeEnabled: false,
            })
        case profileTypes.DISCARD_CHANGES:
            return state.merge({
                modifiedProfile: state.profile,
                isEditModeEnabled: false,
                isEducationEditModeEnabled: false,
                isExperienceEditModeEnabled: false,
            })
        
        case profileTypes.FIRSTNAME_CHANGED:
            //get mutable copy of modified profile
            var mutableState = Immutable.asMutable(state, {deep: true});
            var modifiedProfile = mutableState.modifiedProfile
            modifiedProfile.bio.firstName = action.firstname
            return state.merge({
                modifiedProfile: modifiedProfile,
            })

        case profileTypes.LASTNAME_CHANGED:
            //get mutable copy of modified profile
            var mutableState = Immutable.asMutable(state, {deep: true});
            var modifiedProfile = mutableState.modifiedProfile
            modifiedProfile.bio.lastName = action.lastname
            return state.merge({
                modifiedProfile: modifiedProfile,
            })

        case profileTypes.MIDDLENAME_CHANGED:
            //get mutable copy of modified profile
            var mutableState = Immutable.asMutable(state, {deep: true});
            var modifiedProfile = mutableState.modifiedProfile
            modifiedProfile.bio.middleName = action.middlename
            return state.merge({
                modifiedProfile: modifiedProfile,
            })

        case profileTypes.DESCRIPTION_CHANGED:
            //get mutable copy of modified profile
            var mutableState = Immutable.asMutable(state, {deep: true});
            var modifiedProfile = mutableState.modifiedProfile
            modifiedProfile.bio.description = action.description
            return state.merge({
                modifiedProfile: modifiedProfile,
            })

        case profileTypes.CITY_CHANGED:
            //get mutable copy of modified profile
            var mutableState = Immutable.asMutable(state, {deep: true});
            var modifiedProfile = mutableState.modifiedProfile
            modifiedProfile.bio.city = action.city
            return state.merge({
                modifiedProfile: modifiedProfile,
            })

        case profileTypes.DELETE_PROFILE_SERVICE:
            var mutableState = Immutable.asMutable(state, {deep: true});
            var modifiedProfile = mutableState.modifiedProfile
            
            const index = parseInt(action.serviceNumber, 10)

            if (index > -1) {
                modifiedProfile.services.splice(index, 1 );
            }

            return state.merge({
                modifiedProfile: modifiedProfile,
            })
        
        case profileTypes.ADD_PROFILE_SERVICE:
            var mutableState = Immutable.asMutable(state, {deep: true});
            var modifiedProfile = mutableState.modifiedProfile
            
            let service = modifiedProfile.services[modifiedProfile.services.length-1]
            var id = 0 

            if (service) {
                id = service.id + 1
            }

            //let defaultService = action.availableVuuteServices[action.availableVuuteServices.length-1]
            let defaultService ={id:-1,title:'Выбрать...'}

            let newService = {
                id: id,
                price: 0,
                serviceObject: defaultService
            }

            modifiedProfile.services.push(newService)

            return state.merge({
                modifiedProfile: modifiedProfile,
            })

        case profileTypes.CHANGE_PROFILE_SERVICE_PRICE:

            var mutableState = Immutable.asMutable(state, {deep: true});
            var modifiedProfile = mutableState.modifiedProfile
            
            //TODO: REFACTOR
            const index1 = parseInt(action.serviceNumber, 10)
            
            var editService = modifiedProfile.services[index1]

            editService.price = action.newPrice

            return state.merge({
                modifiedProfile: modifiedProfile,
            })

        case profileTypes.CHANGE_PROFILE_SERVICE_ITEM:

            var mutableState = Immutable.asMutable(state, {deep: true});
            var modifiedProfile = mutableState.modifiedProfile

            const index2 = parseInt(action.serviceNumber, 10)
            
            var editService = modifiedProfile.services[index2]

            editService.serviceObject = action.newService

            return state.merge({
                modifiedProfile: modifiedProfile,
            })

        case profileTypes.CLEAR_SAVE_SERVICES_FORM_ERRORS:
            return state.merge({
                errorServices: undefined,
            })

        case profileTypes.CHANGE_SELECT_LIST_STATE:
            return state.merge({
                isSelectListOpenned: action.newState,
            })

        case profileTypes.EDUCATION_EDIT_MODE_STATE_CHANGED:
            return state.merge({
                isEducationEditModeEnabled: action.newState,
            })


        case profileTypes.DELETE_PROFILE_EDUCATION:

            var mutableState = Immutable.asMutable(state, {deep: true});
            var modifiedProfile = mutableState.modifiedProfile
            
            const deleteIndex = parseInt(action.educationNumber, 10)

            if (deleteIndex > -1) {
                modifiedProfile.education.splice(deleteIndex, 1 );
            }

            return state.merge({
                modifiedProfile: modifiedProfile,
            })

        case profileTypes.CHANGE_EDUCATION_FORM_HIDDEN_STATE:

            var newEducation = state.newEducation
            var selectedCountry = state.selectedCountry
            var selectedCity = state.selectedCity
            var selectedEducationObject = state.selectedEducationObject

            //if we close form -> clear newEducation object
            if (action.newState === false) {
                newEducation = {
                    speciality: undefined,
                    dateStarted: undefined,
                    dateFinished: undefined,
                }

                selectedEducationObject = undefined
                selectedCountry = undefined
                selectedCity = undefined
            }

            return state.merge({
                isEducationFormIsHidden: action.newState,
                selectedCountry: selectedCountry,
                selectedCity: selectedCity,
                selectedEducationObject: selectedEducationObject,
                newEducation: newEducation,
            })

        case profileTypes.EDUCATION_SPECIALITY_CHANGED:
            var mutableState = Immutable.asMutable(state, {deep: true});
            var newEducation = mutableState.newEducation

            newEducation.speciality = action.newSpeciality

            return state.merge({
                newEducation: newEducation,
            })
        
        case profileTypes.EDUCATION_START_YEAR_CHANGED:
                var mutableState = Immutable.asMutable(state, {deep: true});
                var newEducation = mutableState.newEducation
    
                newEducation.dateStarted = action.newYear
    
                return state.merge({
                    newEducation: newEducation,
                })

        case profileTypes.EDUCATION_END_YEAR_CHANGED:
                var mutableState = Immutable.asMutable(state, {deep: true});
                var newEducation = mutableState.newEducation
    
                newEducation.dateFinished = action.newYear
    
                return state.merge({
                    newEducation: newEducation,
                })

        case profileTypes.SELECT_EDUCATION:
                return state.merge({
                    selectedEducationObject: action.newEducation,
                })
        
        case profileTypes.SELECT_COUNTRY:
                return state.merge({
                    selectedCountry: action.newCountry
                })
    
        case profileTypes.SELECT_CITY:
            
            return state.merge({
                selectedCity: action.newCity
            })

        case profileTypes.ADD_PROFILE_EDUCATION:

            if (!action.newEducationObject.speciality || !action.newEducationObject.dateFinished || !action.newEducationObject.dateStarted || !state.selectedEducationObject) {
                let educationValidError = new APIError("Вы не указали следующие поля: id учреждения, специальность, дата начала обучения, дата окончания обучения", '20018')
                return state.merge({
                    errorEducation: educationValidError,
                })
            }

            var mutableState = Immutable.asMutable(state, {deep: true});
            var modifiedProfile = mutableState.modifiedProfile
            
            var addedEducationCity = mutableState.selectedCity
            addedEducationCity.country = state.selectedCountry

            var addedEducationObject = mutableState.selectedEducationObject
            addedEducationObject.cityVuute = addedEducationCity

            var newEducationForAdd = Immutable.asMutable(action.newEducationObject, {deep: true});
            newEducationForAdd.educationObject = addedEducationObject

            const selectedEdObjOrderNumber = state.selectedProfileEducationOrderNumber

            if (selectedEdObjOrderNumber !== undefined) {
                modifiedProfile.education[selectedEdObjOrderNumber] = newEducationForAdd
            } else {
                modifiedProfile.education.push(newEducationForAdd)
            }
            

            return state.merge({
                modifiedProfile: modifiedProfile,
                isEducationFormIsHidden: false,
                errorEducation: undefined,
            })

        case profileTypes.CLEAR_SAVE_EDUCATION_FORM_ERRORS:
            return state.merge({
                errorEducation: undefined,
            })

        case profileTypes.EDIT_PROFILE_EDUCATION_ITEM:

            const selectedEducation = state.modifiedProfile.education[action.orderNumber]
            const selectedEduEdObject = selectedEducation.educationObject
            const selectedEduCity = selectedEduEdObject.city
            const selectedEduCountry = selectedEduCity.country

            return state.merge({
                newEducation: selectedEducation,
                selectedProfileEducationOrderNumber: action.orderNumber,

                selectedCountry: selectedEduCountry,
                selectedCity: selectedEduCity,
                selectedEducationObject: selectedEduEdObject,
            })

        case profileTypes.EXPERIENCE_EDIT_MODE_STATE_CHANGED:
            return state.merge({
                isExperienceEditModeEnabled: action.newState,
            })

        case profileTypes.ADD_PROFILE_EXPERIENCE:
            var mutableState = Immutable.asMutable(state, {deep: true});
            var modifiedProfile = mutableState.modifiedProfile

            let experience = {
                id: '',
                companyName: '',
                position: '',
                dateStarted: '',
                dateFinished: '',
            }

            modifiedProfile.experience.push(experience)
                
            return state.merge({
                modifiedProfile: modifiedProfile,
            })

        case profileTypes.DELETE_PROFILE_EXPERIENCE:

            var mutableState = Immutable.asMutable(state, {deep: true});
            var modifiedProfile = mutableState.modifiedProfile
            
            const deleteExpIndex = parseInt(action.experienceNumber, 10)

            if (deleteExpIndex > -1) {
                modifiedProfile.experience.splice(deleteExpIndex, 1 );
            }

            return state.merge({
                modifiedProfile: modifiedProfile,
            })

        case profileTypes.EXPERIENCE_COMPANY_CHANGED:

            var mutableState = Immutable.asMutable(state, {deep: true});
            var modifiedProfile = mutableState.modifiedProfile

            const changeExpCompanyIndex = parseInt(action.orderNumber, 10)

            if (changeExpCompanyIndex > -1) {
                modifiedProfile.experience[changeExpCompanyIndex].companyName = action.newCompany
            }

            return state.merge({
                modifiedProfile: modifiedProfile,
            })

        case profileTypes.EXPERIENCE_POSITION_CHANGED:

            var mutableState = Immutable.asMutable(state, {deep: true});
            var modifiedProfile = mutableState.modifiedProfile

            const changeExpPositionIndex = parseInt(action.orderNumber, 10)

            if (changeExpPositionIndex > -1) {
                modifiedProfile.experience[changeExpPositionIndex].position = action.newPosition
            }

            return state.merge({
                modifiedProfile: modifiedProfile,
            })

        case profileTypes.EXPERIENCE_STARTDATE_CHANGED:

            var mutableState = Immutable.asMutable(state, {deep: true});
            var modifiedProfile = mutableState.modifiedProfile

            const changeExpStartDateIndex = parseInt(action.orderNumber, 10)

            if (changeExpStartDateIndex > -1) {

                

                var actionDateStarted = action.newStartDate
                var expDateFinished = modifiedProfile.experience[changeExpStartDateIndex].dateFinished
                
                // if started date more than now -> use now date
                if (actionDateStarted > new Date()) {
                    actionDateStarted = new Date()
                }

                // if started date more than finished date for same experience object -> use finished date
                if (actionDateStarted > expDateFinished) {
                    actionDateStarted = expDateFinished
                }

                // var dateS = DateService.convertExperienceDateToStr(action.newStartDate)
                modifiedProfile.experience[changeExpStartDateIndex].dateStarted = actionDateStarted
            }

            return state.merge({
                modifiedProfile: modifiedProfile,
            })

        case profileTypes.EXPERIENCE_ENDDATE_CHANGED:

            var mutableState = Immutable.asMutable(state, {deep: true});
            var modifiedProfile = mutableState.modifiedProfile

            const changeExpEndDateIndex = parseInt(action.orderNumber, 10)

            if (changeExpEndDateIndex > -1) {

                var actionDateFinished = action.newEndDate
                var expDateStarted = modifiedProfile.experience[changeExpEndDateIndex].dateStarted

                // if finished date less than date started for same experience object -> use date started
                if (actionDateFinished < expDateStarted) {
                    actionDateFinished = expDateStarted
                }

                // if date finished more than now date -> use now date
                if (actionDateFinished > new Date()) {
                    actionDateFinished = new Date()
                }


                // var dateF = DateService.convertExperienceDateToStr(action.newEndDate)
                modifiedProfile.experience[changeExpEndDateIndex].dateFinished = actionDateFinished//action.newEndDate
            }

            return state.merge({
                modifiedProfile: modifiedProfile,
            })

        case profileTypes.SAVE_EXPERIENCE:

            if (action.error === null) {
                let updatedProfile = new Profile(state.profile.bio,
                                                state.profile.education,
                                                state.profile.services,
                                                action.experience)
                return state.merge({
                    profile: updatedProfile,
                    modifiedProfile: updatedProfile,
                    isExperienceEditModeEnabled: false,
                })
            } else {
                return state.merge({
                    errorExperience: action.error, 
                })
            }  

        case profileTypes.CLEAR_SAVE_EXPERIENCE_FORM_ERRORS:
            return state.merge({
                errorExperience: undefined, 
            })

        case profileTypes.SAVE_PHOTO:

            if (action.error === null) {
                var newProfileBio = null
                if (state.profile.bio.profileType == 'MASTER') {
                    newProfileBio = new MasterBio(state.profile.bio.id,
                        state.profile.bio.firstName,
                        state.profile.bio.lastName,
                        state.profile.bio.middleName,
                        action.photoUrl,
                        state.profile.bio.rate,
                        state.profile.bio.city,
                        state.profile.bio.created)
                } else {
                    newProfileBio = new ClientBio(state.profile.bio.id,
                        state.profile.bio.firstName,
                        state.profile.bio.lastName,
                        state.profile.bio.middleName,
                        action.photoUrl,
                        state.profile.bio.rate,
                        state.profile.bio.city,
                        state.profile.bio.created,
                        state.profile.bio.description)
                }

                let updatedProfile = new Profile(newProfileBio,
                                                state.profile.education,
                                                state.profile.services,
                                                state.profile.experience)
                return state.merge({
                    profile: updatedProfile,
                    modifiedProfile: updatedProfile,
                    photoFile: undefined,
                    photoUrl: action.photoUrl
                })
            } else {
                return state.merge({
                    error: action.error, 
                })
            }

            // var mutableState = Immutable.asMutable(state, {deep: true});
            // var profile = mutableState.profile
            // var modifiedProfile = mutableState.modifiedProfile

            // profile.bio.photoImage = action.photoUrl
            // modifiedProfile.bio.photoImage = action.photoUrl

            // return state.merge({
            //     profile: profile,
            //     modifiedProfile: modifiedProfile,
            //     photoFile: undefined,
            //     photoUrl: action.photoUrl
            // })

        case profileTypes.CLOSE_PHOTO:

            return state.merge({
                photoFile: undefined,
                photoUrl: ''
            })

        case profileTypes.PREVIEW_PHOTO:

            var photoPreviewUrl = URL.createObjectURL(action.photoFile)

            return state.merge({
                photoFile: action.photoFile,
                photoUrl: photoPreviewUrl,
            })

        case profileTypes.GET_CONTRACTS:
            return state.merge({
                incomeJobs: action.incomeJobs,
                activeJobs: action.activeJobs,
                closedJobs: action.closedJobs,
            })

        
        default:
            return state;
    }
}

//selectors
export function getProfile(state) {
    return state.profile.profile;
}

export function getProfileBio(state) {
    return state.profile.profile.bio
}

export function getProfileServices(state) {
    return state.profile.profile.services
}

export function getModifiedProfile(state) {
    return state.profile.modifiedProfile;
}

export function getSelectListState(state) {
    const isSelectListOpenned = state.profile.isSelectListOpenned;
    return isSelectListOpenned;
}

export function getNewEducationObject(state) {
    const newEducationObject = state.profile.newEducation;
    return newEducationObject
}

export function isEditEducationFormHidden(state) {
    const isEditEducationFormHidden = state.profile.isEducationFormIsHidden;
    return isEditEducationFormHidden;
}

export function isEditModeEnabled(state) {
    return state.profile.isEditModeEnabled;
}

export function isEducationEditModeEnabled(state) {
    return state.profile.isEducationEditModeEnabled;
}

export function isExperienceEditModeEnabled(state) {
    return state.profile.isExperienceEditModeEnabled;
}

export function getSelectedCountry(state) {
    const selectedCountry = state.profile.selectedCountry;
    return selectedCountry;
}

export function getSelectedCity(state) {
    const selectedCity = state.profile.selectedCity;
    return selectedCity;
}

export function getSelectedEducationObject(state) {
    const selectedEducationObject = state.profile.selectedEducationObject;
    return selectedEducationObject;
}

export function getPhotoEditObject(state) {
    const photoLink = state.profile.photoUrl
    const photoFile = state.profile.photoFile
    return [photoLink, photoFile]
}

export function getProfileJobs(state) {
    return [state.profile.incomeJobs, state.profile.activeJobs, state.profile.closedJobs]
}

export function getExperienceSavingError(state) {
    return state.profile.errorExperience
}

export function getBioSavingError(state) {
    return state.profile.errorBio
}

export function getServicesSavingError(state) {
    return state.profile.errorServices
}

export function getEducationSavingError(state) {
    return state.profile.errorEducation
}
