import _ from 'lodash';
import Geolocation from '../model/Geolocation';

import JSONParser from '../../common/parsers/JSONParser'


class GeolocationParser extends JSONParser {

    parse() {
        super.parse()

        let longitude = _.get(this.data, 'longitude')
        let latitude = _.get(this.data, 'latitude')
        let address = _.get(this.data, 'address')

        let geolocation = new Geolocation(longitude, latitude, address)
        return geolocation

    }

}

export default GeolocationParser;