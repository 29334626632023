import React from 'react';
import '../../App.css';

class LoginMsgBox extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            msgText: this.props.msgText,
            msgType: this.props.msgType
        }
    }

    render() {
        return (
            <React.Fragment>
                {(this.props.msgText) ? <div className={this.props.msgType}>
                    {this.props.msgText}
                </div> : ''}
                
            </React.Fragment>
            
        )
    }
}

export default LoginMsgBox