import React from 'react'

import CheckOrderNavView from './CheckOrderNavView'
import CheckOrderContentView from './CheckOrderContentView'
import CheckOrderSidebarView from './CheckOrderSidebarView'

class CheckOrderView extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        
        return (
            <div className="content content--with-back bg-block">
                <div className="container">
                    <CheckOrderNavView orderInfo={this.props.orderInfo} onEditOrderClick={this.props.onEditOrderClick} />
                    <div className="order job">
                        <div className="order__inner">
                            {this.props.orderInfo.permissions.isClient ? (
                                <div className="order__main order__main--full-width">
                                    <CheckOrderContentView orderInfo={this.props.orderInfo} isEditable={this.props.isEditable} />
                                </div>
                            ) : (
                                    <React.Fragment>
                                        <div className="order__main">
                                            <CheckOrderContentView orderInfo={this.props.orderInfo} isEditable={this.props.isEditable} />
                                        </div>
                                        <CheckOrderSidebarView orderInfo={this.props.orderInfo} onBidOrderClick={this.props.onBidOrderClick} />
                                    </React.Fragment>
                                )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CheckOrderView