import _ from 'lodash';
import * as settingsTypes from './actionTypes';
import apiService from '../../services/APIService';

export function changedEmail(newEmail) {
    return ({ type: settingsTypes.EMAIL_CHANGED, newEmail: newEmail})
}

export function getEmailInfo() {
    return async (dispatch, getState) => {
        try {
            const getEmailData = await apiService.getEmail();

            dispatch({ type: settingsTypes.GET_EMAIL, email: getEmailData.email })
        } catch (error) {
            console.error(error)
        }
    }
}

export function changeEmail(email) {
    return async (dispatch) => {
        const changeEmailData = await apiService.changeEmail(email)
        dispatch({ type: settingsTypes.EMAIL_SAVED, isSaved: changeEmailData.isEmailChanged, error: changeEmailData.error})
    }
}

export function clearContactInfoSettingsErrors() {
    return ({ type: settingsTypes.CLEAR_SETTINGS_CONTACT_FORM_ERRORS})
}

export function changedOldPassword(newPassword) {
    return ({ type: settingsTypes.OLD_PASSWORD_CHANGED, newPassword: newPassword})
}

export function changedNewPassword(newPassword) {
    return ({ type: settingsTypes.NEW_PASSWORD_CHANGED, newPassword: newPassword})
}

export function changedRepeatedNewPassword(newPassword) {
    return ({ type: settingsTypes.REPEATED_NEW_PASSWORD_CHANGED, newPassword: newPassword})
}

export function changePassword(oldPassword, newPassword) {
    return async (dispatch) => {
        const changePasswordData = await apiService.changePassword(oldPassword, newPassword)
        dispatch({ type: settingsTypes.PASSWORD_SAVED, isSaved: changePasswordData.isPasswordSaved, error: changePasswordData.error})
    }
}

export function clearSecurityInfoSettingsErrors() {
    return ({ type: settingsTypes.CLEAR_SETTINGS_SECURITY_FORM_ERRORS})
}

export function clickedNotifyItem(key) {
    return ({ type: settingsTypes.NOTIFY_ITEM_CLICKED, key: key })
}

export function changeReceiveSettings(settingsDict) {
    return async (dispatch) => {
        await apiService.changeReceiveSettings(settingsDict)
    }
}

export function getReceiveSettingsInfo() {
    return async (dispatch, getState) => {
        try {
            const settingsData = await apiService.getReceiveSettingsInfo();

            dispatch({ type: settingsTypes.GET_RECEIVE_SETTINGS, settingsInfo: settingsData.settings, error: settingsData.error })
        } catch (error) {
            console.error(error)
        }
    }
}