export const GET_JOB_INFO_BY_ID = 'job.GET_JOB_INFO_BY_ID'

export const BID_JOB_CONFIRMATION_OPEN = 'job.BID_JOB_CONFIRMATION_OPEN'
export const BID_JOB_CONFIRMATION_CLOSE = 'job.BID_JOB_CONFIRMATION_CLOSE'
export const BID_JOB_CONFIRMATION_ACCEPT = 'job.BID_JOB_CONFIRMATION_ACCEPT'
export const BID_JOB_CONFIRMATION_CALLBACK_RECIEVED = 'job.BID_JOB_CONFIRMATION_CALLBACK_RECIEVED'
export const CLEAR_BID_JOB_ERRORS = 'job.CLEAR_CREATE_ORDER_FORM_ERRORS';

export const REJECTED_JOB_CONFIRMATION_OPEN = 'job.REJECTED_JOB_CONFIRMATION_OPEN'
export const REJECTED_JOB_CONFIRMATION_CLOSE = 'job.REJECTED_JOB_CONFIRMATION_CLOSE'
export const REJECTED_JOB_CONFIRMATION_ACCEPT = 'job.REJECTED_JOB_CONFIRMATION_ACCEPT'

export const ON_REVIEW_JOB_CONFIRMATION_OPEN = 'job.ON_REVIEW_JOB_CONFIRMATION_OPEN'
export const ON_REVIEW_JOB_CONFIRMATION_CLOSE = 'job.ON_REVIEW_JOB_CONFIRMATION_CLOSE'
export const ON_REVIEW_JOB_CONFIRMATION_ACCEPT = 'job.ON_REVIEW_JOB_CONFIRMATION_ACCEPT'

export const RETURN_JOB_CONFIRMATION_OPEN = 'job.RETURN_JOB_CONFIRMATION_OPEN'
export const RETURN_JOB_CONFIRMATION_CLOSE = 'job.RETURN_JOB_CONFIRMATION_CLOSE'
export const RETURN_JOB_CONFIRMATION_ACCEPT = 'job.RETURN_JOB_CONFIRMATION_ACCEPT'

export const CLOSE_JOB_CONFIRMATION_OPEN = 'job.CLOSE_JOB_CONFIRMATION_OPEN'
export const CLOSE_JOB_CONFIRMATION_CLOSE = 'job.CLOSE_JOB_CONFIRMATION_CLOSE'
export const CLOSE_JOB_CONFIRMATION_ACCEPT = 'job.CLOSE_JOB_CONFIRMATION_ACCEPT'