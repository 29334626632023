import React from 'react'
import autoBind from 'react-autobind';

import PhotoPopupContainer from '../profile/containers/PhotoPopupContainer';

import noAva from '../images/no-ava.png'

class ProfilePhotoEdit extends React.Component {

    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            isPhotoPopupHidden: true,
        }
    }

    render() {

        var photoLink = ''
        if (this.props.bio != undefined) {
            photoLink = this.props.bio.photoUrl
        }

        return (
            <div className="person-info__media ava-wrap">

                <div className="ava-img-wrap">
                    {(photoLink != '') ?
                        <img className="ava-img" src={photoLink} alt="" />
                        :
                        <img className="ava-img" src={noAva} alt="" />
                    }
                </div>
                
                <div className="editable">
                    <a className="live-edit-btn btn--1 btn--sm popup-change-ava-btn" href="javascript:;" onClick={this.onChangePhotoClick}>Изменить</a>
                </div>
                {(!this.state.isPhotoPopupHidden) ? <PhotoPopupContainer onChangeVisibility={this.onChangeVisibility} /> : ''}
            </div>
        );
    }

    onChangePhotoClick(event) {
        event.preventDefault();

        this.setState({
            isPhotoPopupHidden: !this.state.isPhotoPopupHidden
        })
    }

    onChangeVisibility(e) {
        e.preventDefault()
        this.onChangePhotoClick(e)
    }

}

export default ProfilePhotoEdit