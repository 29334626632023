class Profile {
    bio;
    education;
    services;
    experience;

    constructor(bio, education, services, experience) {
        this.bio = bio
        this.education = education
        this.services = services
        this.experience = experience
    }
}

export default Profile;