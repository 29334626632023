import React from 'react';

import ServiceItemEdit from './ServiceItemEdit';

//TODO: REMOVE THIS LINES
//send all events to uplevel
//download services in MasterPersonInfoEdit and send it in props next
//download profile services in MasterPersonInfoEdit and send it in props next
//<ServiceItemEdit availableServices={services} selectedService={element}/>
//send next add and delete events to uplevel to MasterPersonInfoEdit

class PricesEdit extends React.Component {
  createTable() {
    const services = this.props.services;
    const availableServices = this.props.availableServices;
    let table = [];

    let children = [];
    if (services === null) {
      return table;
    }

    children = services.map((element, index) => (
      <ServiceItemEdit
        key={element.id}
        service={element}
        availableServices={[element.serviceObject, ...availableServices]}
        onServiceItemRemoveClick={this.props.onServiceItemRemoveClick}
        orderNumber={index}
        onPriceChanged={this.props.onPriceChanged}
        onSelectListChange={this.props.onSelectListChange}
        onSelectListOpen={this.props.onSelectListOpen}
        onSelectListClose={this.props.onSelectListClose}
      />
    ));
  
    //Create the parent and add the children
    table.push(<tbody>{children}</tbody>);

    return table;
  }

  render() {
    return (
      <section className='user-card-section'>
        <h2 className='user-card-section-title'>Стоимость услуг</h2>
        <div id='selParent' className='editable'>
          <table className='services-edit-tbl'>{this.createTable()}</table>
          {this.props.availableServices.length !== 0 && (
            <button
              className='add-service-btn btn--1 btn--sm btn--flex'
              type='button'
              data-limit={7}
              onClick={this.props.onAddServiceClick}
            >
              Добавить услугу
            </button>
          )}
        </div>
      </section>
    );
  }
}

export default PricesEdit;
