import Immutable from 'seamless-immutable';
import _ from 'lodash';
import * as reviewTypes from './actionTypes';

const initialState = Immutable({
    reviewInfo: {
        reviewText: '',
        qualityRate: 0,
        deadlineRate: 0,
        communicationRate: 0
    },

    reviewError: undefined,

})

export default function reduce(state = initialState, action = {}) {

    switch (action.type) {
        case reviewTypes.CREATE_REVIEW:
            return state.merge({
                reviewInfo: {
                    reviewText: '',
                    qualityRate: 0,
                    deadlineRate: 0,
                    communicationRate: 0
                },
                reviewError: action.error,
            })

        case reviewTypes.CLEAR_REVIEW_ERROR:
            return state.merge({
                reviewError: undefined,
            })

        case reviewTypes.CLEAR_REVIEW_FORM:
            return initialState

        case reviewTypes.CHANGE_REVIEW_TEXT:
            var mutableState = Immutable.asMutable(state, { deep: true });
            var reviewInfo = mutableState.reviewInfo
            reviewInfo.reviewText = action.newReviewText

            return state.merge({
                reviewInfo: reviewInfo
            })

        case reviewTypes.CHANGE_QUALITY_RATE:
                var mutableState = Immutable.asMutable(state, { deep: true });
                var reviewInfo = mutableState.reviewInfo
                reviewInfo.qualityRate = action.newQualityRate

            return state.merge({
                reviewInfo: reviewInfo
            })

        case reviewTypes.CHANGE_DEADLINE_RATE:
                var mutableState = Immutable.asMutable(state, { deep: true });
                var reviewInfo = mutableState.reviewInfo
                reviewInfo.deadlineRate = action.newDeadlineRate

            return state.merge({
                reviewInfo: reviewInfo
            })

        case reviewTypes.CHANGE_COMMUNICATION_RATE:
                var mutableState = Immutable.asMutable(state, { deep: true });
                var reviewInfo = mutableState.reviewInfo
                reviewInfo.communicationRate = action.newCommunicationRate

            return state.merge({
                reviewInfo: reviewInfo
            })

        default:
            return state;
    }
}

//selectors
export function getReviewInfo(state) {
    const reviewInfo = state.review.reviewInfo
    return reviewInfo
}

export function getReviewError(state) {
    return state.review.reviewError
}