import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import AuthService from '../services/AuthService'

const PrivateRoute = ({ component: Component, ...rest }) => {

    const isUserLoggedIn = AuthService.shared().isCorrectUserExist()
  
    return (
      <Route
        {...rest}
        render={props =>
          isUserLoggedIn ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: '/', state: { from: props.location } }} />
          )
        }
      />
    )
  
  }

  export default PrivateRoute;