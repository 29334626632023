import React, {useState} from 'react';
import DatePicker, {registerLocale} from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import 'react-datepicker/dist/react-datepicker.css';

import DateService from '../services/DateService';

registerLocale('ru', ru);

class EduCalendar extends React.Component {
  render() {
    var selectedDate = this.props.selectedDate;
    // if (!DateService.isValidDate(selectedDate)) {
    //   selectedDate = null
    // }

    const orderNumber = this.props.orderNumber;
    const id = 'order-stage-date-from-' + orderNumber;
    const name = 'order-stage-date-from-' + orderNumber;
    const handleDateChangeRaw = (e) => {
      e.preventDefault();
    };

    return (
      <React.Fragment>
        <DatePicker
          locale='ru'
          className='date-input js-datepicker'
          //   id={id}
          //   name={name}
          // minDate="months"
          dateFormat='yyyy'
          showYearPicker
          onChangeRaw={handleDateChangeRaw}
          selected={selectedDate}
          onChange={this.props.onChange}
        />
      </React.Fragment>
    );
  }
}

export default EduCalendar;
