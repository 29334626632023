import _ from 'lodash';
import * as orderTypes from './actionTypes';
import apiService from '../../services/APIService';
import dateService from '../../services/DateService'

export function changeTitle(newTitle) {
    return ({ type: orderTypes.TITLE_CHANGED, newTitle: newTitle })
}

export function changeCategory(newCategory) {
    return ({ type: orderTypes.CATEGORY_CHANGED, newCategory: newCategory })
}

export function changeDescription(newDescription) {
    return ({ type: orderTypes.DESCRIPTION_CHANGED, newDescription: newDescription })
}

export function changeSlider(newSlider) {
    return ({ type: orderTypes.SLIDER_CHANGED, newSliderPrice: newSlider })
}

export function changePrice(oldPrice, newPrice) {
    newPrice = newPrice.replace(/[^\d]/g, '')
    if (newPrice.length >= 8) {
        newPrice = oldPrice
    }
    var intOrderPrice = Number(newPrice)

    return ({ type: orderTypes.PRICE_CHANGED, newPrice: intOrderPrice })
}

export function changeStartDate(newDate) {
    var orderStartDate = dateService.isStartDateValid(newDate)
    return ({ type: orderTypes.START_DATE_CHANGED, newStartDate: orderStartDate })
}

export function changeEndDate(orderStartDate, newOrderEndDate) {
    var orderEndDate = dateService.isEndDateValid(orderStartDate, newOrderEndDate)
    return ({ type: orderTypes.END_DATE_CHANGED, newEndDate: orderEndDate })
}

export function changeBidComment(newBidComment) {
    return ({ type: orderTypes.BID_COMMENT_CHANGED, newBidComment: newBidComment })
}

export function clearStore() {
    return ({ type: orderTypes.CLEAR_STORE })
}

export function fieldsValidator(orderTitle, orderCategory, orderDescription, orderPrice, orderStartDate, orderEndDate) {
    return ({
        type: orderTypes.FIELDS_VALIDATOR, isOrderTitleValid: (orderTitle !== undefined), isOrderCategoryValid: (orderCategory !== undefined),
        isOrderDescriptionValid: (orderDescription !== undefined), isOrderPriceValid: (orderPrice !== undefined), isOrderStartDateValid: (orderStartDate !== undefined),
        isOrderEndDateValid: (orderEndDate !== undefined)
    })
}

export function requestCreateOrder(orderTitle, orderCategory, orderDescription, orderPrice, orderStartDate, orderEndDate, orderCheckedCoords, orderCheckedAddress) {
    var transformOrderStartDate = dateService.fromFullDateTransform(orderStartDate)
    var transformOrderEndDate = dateService.fromFullDateTransform(orderEndDate)

    return async (dispatch) => {
        const newOrderData = await apiService.createOrder(orderTitle, orderCategory, orderDescription, orderPrice, transformOrderStartDate,
            transformOrderEndDate, orderCheckedCoords, orderCheckedAddress)
        dispatch({ type: orderTypes.CREATE_ORDER, order: newOrderData.order, error: newOrderData.error });
    }
}

export function editOrder(order) {
    var startDate = dateService.toFullDateTransform(order.startDate)
    var endDate = dateService.toFullDateTransform(order.endDate)

    return ({
        type: orderTypes.EDIT_ORDER, orderTitle: order.title, orderCategory: order.category, orderDescription: order.description,
        orderPrice: order.price, orderStartDate: startDate, orderEndDate: endDate
    })
}

export function requestSaveOrderChanges(orderId, orderTitle, orderCategory, orderDescription, orderPrice, orderStartDate, orderEndDate, orderCheckedCoords, orderCheckedAddress) {
    var transformOrderStartDate = dateService.fromFullDateTransform(orderStartDate)
    var transformOrderEndDate = dateService.fromFullDateTransform(orderEndDate)

    return async (dispatch) => {
        const newOrderData = await apiService.updateOrder(orderId, orderTitle, orderCategory, orderDescription, orderPrice, transformOrderStartDate,
            transformOrderEndDate, orderCheckedCoords, orderCheckedAddress)
        dispatch({ type: orderTypes.CREATE_ORDER, order: newOrderData.order, error: newOrderData.error });
    }
}

export function requestDiscardChanges(orderInfo) {
    return ({ type: orderTypes.CREATE_ORDER, order: orderInfo });
}

export function getOrderInfoById(orderId) {
    return async (dispatch, getState) => {
        try {
            const orderData = await apiService.getOrderInfoById(orderId);
            dispatch({ type: orderTypes.GET_ORDER_INFO_BY_ID, order: orderData.order, error: orderData.error })
        } catch (error) {
            console.error(error)
        }
    }
}

export function onBidOrderClick() {
    return ({ type: orderTypes.ON_BID_ORDER_CLICK })
}

export function onBidOrderCloseClick() {
    return ({ type: orderTypes.ON_BID_ORDER_CLOSE_CLICK })
}

// export function onBidOrderSendClick(orderId, orderBidComment) {
//     return async (dispatch) => {
//         try {
//             const bidOrder = await apiService.bidOrder(orderId, orderBidComment);
//             dispatch({ type: orderTypes.ON_BID_ORDER_SEND_CLICK })
//         } catch (error) {
//             console.error(error)
//         }
//     }
// }

export function getClientOrders() {
    return async (dispatch) => {
        try {
            const activeOrdersData = await apiService.getClientOrders();
            dispatch({ type: orderTypes.GET_CLIENT_ORDERS, newOrders: activeOrdersData.orders, error: activeOrdersData.error })
        } catch (error) {
            console.error(error)
        }
    }
}

export function successOrderBid() {
    return ({ type: orderTypes.SUCCESS_ORDER_BID })
}

export function clearCreateOrderErrors() {
    return ({ type: orderTypes.CLEAR_CREATE_ORDER_FORM_ERRORS})
}

export function clearCreateOrderForm() {
    return ({ type: orderTypes.CLEAR_CREATE_ORDER_FORM})
}

export function clearOrderBidForm() {
    return ({ type: orderTypes.ON_BID_ORDER_FORM_CLEAR})
}