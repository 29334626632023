import React, {Component} from 'react'
import ReviewGrid from './reviewGrid/ReviewGrid'

import { connect } from 'react-redux';
import autoBind from 'react-autobind';

import * as popupActions from '../../../../../store/popup/actions';

import * as authActions from '../../../../../store/auth/actions';

import * as registerActions from '../../../../../store/register/actions';

class Reviews extends Component {

    constructor(props) {
        super(props);
        autoBind(this);
    }

    render () {   
        return (
            <div className="reviews">
                <div className="container">
                    <section className="main-page-section">
                        <h2 className="main-page-section__title centered">Отзывы о выполненных работах</h2>
                        <ReviewGrid></ReviewGrid>
                    </section>
                    <div className="action-btns">
                        <div className="action-btns__item add-order-btn btn--2 btn--lg js-open-form" data-fancybox data-src="#reg-popup" data-status={0} href="javascript:;" onClick={(e) => this.onCreateOrderClick(e)}>Разместить заказ</div>
                        <div className="action-btns__item make-account-btn btn--1 btn--lg js-open-form" data-fancybox data-src="#reg-popup" data-status={1} href="javascript:;" onClick={(e) => this.onCreateProfileMasterClick(e)}>Стать исполнителем</div>
                    </div>
                </div>
            </div>
        )
    }

    onCreateOrderClick(e) {
        e.preventDefault()
        this.props.dispatch(popupActions.showAuthPopup())
        this.props.dispatch(authActions.signUpFormTypeSelected())
        this.props.dispatch(registerActions.clientTypeSelected())
    }

    onCreateProfileMasterClick(e) {
        e.preventDefault()
        this.props.dispatch(popupActions.showAuthPopup())
        this.props.dispatch(authActions.signUpFormTypeSelected())
        this.props.dispatch(registerActions.masterTypeSelected())
    }

} 

function mapStateToProps(state) {

    return {


    }
}

export default connect(mapStateToProps)(Reviews);