import React from 'react'
import _ from 'lodash';

import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { menuItem } from '../../page/components/MenuEnum';

import Menu from '../../page/components/Menu'
import IncomeJobsView from '../components/IncomeJobsView';
import ActiveJobsView from '../components/ActiveJobsView';
import ClosedJobsView from '../components/ClosedJobsView';
import Footer from '../../page/components/Footer'

import * as settingsActions from '../../store/myJobs/actions';
import * as settingsSelectors from '../../store/myJobs/reducer';

import * as menuActions from '../../store/menu/actions';

import { withRouter } from 'react-router-dom'

class MyJobsContainer extends React.Component {
    constructor(props) {
        super(props);

        autoBind(this);
    }

    componentWillMount() {
        this.props.dispatch(menuActions.selectMenuItem(menuItem.JOBS));
    }

    componentDidMount() {
        this.props.dispatch(settingsActions.getMyJobsInfo())
    }

    render() {

        return (
            <div className="page">
                <Menu />
                <IncomeJobsView
                    incomeJobsInfo={this.props.incomeJobsInfo}
                    onJobTitleClick={this.onJobTitleClick}
                    onMessageButtonClick={this.onMessageButtonClick}
                />
                <div className="content container">
                    <ActiveJobsView
                        activeJobsInfo={this.props.activeJobsInfo}
                        onJobTitleClick={this.onJobTitleClick}
                        onMessageButtonClick={this.onMessageButtonClick}
                    />
                    <ClosedJobsView
                        closedJobsInfo={this.props.closedJobsInfo}
                        onJobTitleClick={this.onJobTitleClick}
                        onReviewButtonClick={this.onReviewButtonClick}
                        onMessageButtonClick={this.onMessageButtonClick}
                    />
                </div>
                <Footer />
            </div>
        )
    }

    onJobTitleClick(id) {
        this.props.history.push(`job/${id}`)
    }

    onReviewButtonClick(id) {
        this.props.history.push(`/review/${id}`)
    }

    onMessageButtonClick() {
        this.props.history.push('/chat')
    }
}

function mapStateToProps(state) {
    const [incomeJobsInfo, activeJobsInfo, closedJobsInfo] = settingsSelectors.getMyJobsInfo(state)

    return {
        incomeJobsInfo,
        activeJobsInfo,
        closedJobsInfo
    }
}

export default withRouter(connect(mapStateToProps)(MyJobsContainer));