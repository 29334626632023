import React from 'react'

import ProfilePhoto from '../../common/ProfilePhoto'
import ProfileNameItem from '../../common/ProfileNameItem'
import ProfileStatusBar from '../../common/ProfileStatusBar'
import ClientDescription from '../components/ClientDescription'

import autoBind from 'react-autobind';
import { connect } from 'react-redux';

import * as profileActions from '../../store/profile/actions';
import * as profileSelectors from '../../store/profile/reducer';

//TODO: - REFACTOR -> make it as a container

class ClientPersonInfo extends React.Component {

  constructor(props) {
    super(props);
    autoBind(this);
  }

  render() {
    return (
      <React.Fragment>
        <div className="person-info">
          {this.props.profile ? (
            <ProfilePhoto bio={this.props.profile.bio}/>
          ) : (
            <ProfilePhoto/>
          )}
          

          {this.props.profile ? (
            <ProfileNameItem isOwner={true} bio={this.props.profile.bio} onEditClick={this.onEditClick}/>
          ) : (
              <ProfileNameItem isOwner={true} onEditClick={this.onEditClick}/>
            )}

          {this.props.profile ? (
            <ProfileStatusBar bio={this.props.profile.bio} />
          ) : (
            <ProfileStatusBar />
            )}
          
        </div>

        {this.props.profile ? (
            <ClientDescription bio={this.props.profile.bio} />
        ) : (
            <ClientDescription />
          )}
        
      </React.Fragment>
    );
  }

  onEditClick(event) {
    event.preventDefault();
    if (this.props.isEditMode) {
      this.props.dispatch(profileActions.disableEditMode())
    } else {
      this.props.dispatch(profileActions.enableEditMode())
    }
    
  }
}

// export default ClientPersonInfoContainer
function mapStateToProps(state) {
  const profile = profileSelectors.getProfile(state);
  const isEditMode = profileSelectors.isEditModeEnabled(state);
  return {
      profile,
      isEditMode,
  }
}

export default connect(mapStateToProps)(ClientPersonInfo);