import React from 'react';
import _ from 'lodash';

import autoBind from 'react-autobind';
import { connect } from 'react-redux';

import * as profileActions from '../../store/profile/actions';
import * as profileSelectors from '../../store/profile/reducer';

import { useParams, withRouter } from 'react-router-dom';
import AuthService from '../../services/AuthService';
import ClientContractItem from '../components/ClientContractItem';

function EmptyData(props) {
	let isMyProfile = AuthService.shared().isMyProfile();
	return (
		<div className='empty-data'>
			{isMyProfile ? (
				<p>На данный момент у Вас нет активных контрактов</p>
			) : (
				<p>На данный момент у клиента нет активных контрактов.</p>
			)}
		</div>
	);
}

function ContractItemsList(props) {
	let table = [];

	const contracts = props.activeJobs;

	contracts.forEach((element) => {
		table.push(<ClientContractItem job={element} />);
	});

	return <div class='contracts-list'>{table}</div>;
}

class ClientActiveJobs extends React.Component {
	constructor(props) {
		super(props);

		autoBind(this);
	}

	componentDidMount() {
		let urlParamId = this.props.match.params.id;
		if (urlParamId === undefined) {
			urlParamId = AuthService.shared().getProfileBio().id;
		}
		// let profileId = AuthService.shared().getProfileBio().id
		// todo: check -> crash
		this.props.dispatch(profileActions.getContracts(urlParamId));
	}

	render() {
		const activeJobs = this.props.jobs[1];


		return (
			<section class='active-contracts page-section'>
				<header>
					<h2 class='page-section-title'>Заказы в работе</h2>
				</header>
				{activeJobs.length > 0 ? (
					<ContractItemsList
						activeJobs={activeJobs}
					/>
				) : (
					<EmptyData />
				)}
			</section>
		);
	}
}

function mapStateToProps(state) {
	const jobs = profileSelectors.getProfileJobs(state);
	return {
		jobs,
	};
}

export default withRouter(connect(mapStateToProps)(ClientActiveJobs));
