import _ from 'lodash';
import CountryVuute from "../model/CountryVuute";

import JSONParser from '../../common/parsers/JSONParser'

class CountryVuuteParser extends JSONParser {
    parse() {
        let data = super.parse()
        if (data === null) { return null }
        
        let country = new CountryVuute(_.get(this.data, 'id'),
                                       _.get(this.data, 'title'))
        
        return country

    }
}

export default CountryVuuteParser;